import {
  FETCH_TAXES_LISTING_FAILURE,
  FETCH_TAXES_LISTING_SUCCESS,
  RESET,
} from "./TaxesTypes";

import { initialState } from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TAXES_LISTING_SUCCESS:
      return {
        taxesData: action.payload.taxData,
        totalTax: action.payload.totalTax,
        error: null,
      };
    case FETCH_TAXES_LISTING_FAILURE:
      return {
        taxesData: [],
        totalTax: 0,
        error: action.payload,
      };

    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
