import { combineReducers } from 'redux'
import loginUserReducer from "./loginUser/loginUserReducer";
import commonReducer from "./common/commonReducer";
import userManagementReducer from "./userManagement/userManagementReducer";
import ticketReducer from "./Tickets/ticketReducer";
import coinReducer from "./CoinTicket/coinReducer";
import BlockListReducer from "./BlockList/BlockListReducer";
import buyersReducer from "./Buyers/buyersReducer";
import coinItemsReducer from "./CoinItems/coinItemsReducer";
import ReceiptReducer from "./Receipt/ReceiptReducer";
import clerksReducer from "./Clerks/clerksReducer";
import keywordsReducer from "./LeadsOnline/keywordsReducer";
import payeesReducer from "./Payees/payeesReducer";
import fromOptionsReducer from "./FromOptions/fromOptionsReducer";
import voidTicketsReducer from "./VoidTickets/voidTicketsReducer";
import dailyReducer from "./Daily/dailyReducer";
import ipAddressReducer from "./IpAddresses/ipAddressReducer";
import JewelryReceiptReducer from "./JewelryReceipt/JewelryReceiptReducer";
import ChecksReducer from "./Checks/ChecksReducer";
import TaxesReducer from "./Taxes/TaxesReducer";
import boothReducer from "./Booth/boothReducer";
import metalsReducer from "./Metals/metalsReducer";
import pettyCashUsersReducer from "./PettyCashUsers/pettyCashUsersReducer";
import transactionsReducer from "./Transactions/transactionsReducer";
import transactionDailyCountReducer from "./Transactions/transactionDailyCountReducer";
import CryptoReducer from "./Crypto/CryptoReducer";
import pettyCashReportsReducer from "./PettyCashReports/pettyCashReportsReducer";
import InvoicesReducer from "./Invoices/InvoicesReducer";
import quoteReducer from "./Quotes/quoteReducer";

const rootReducer = combineReducers({
  loginUserReducer:loginUserReducer,
  userManagementReducer:userManagementReducer,
  commonReducer:commonReducer,
  ticketReducer:ticketReducer,
  coinReducer:coinReducer,
  BlockListReducer:BlockListReducer,
  buyersReducer:buyersReducer,
  coinItemsReducer:coinItemsReducer,
  ReceiptReducer:ReceiptReducer,
  clerksReducer:clerksReducer,
  fromOptionsReducer:fromOptionsReducer,
  voidTicketsReducer:voidTicketsReducer,
  dailyReducer:dailyReducer,
  ipAddressReducer:ipAddressReducer,
  JewelryReceiptReducer:JewelryReceiptReducer,
  ChecksReducer:ChecksReducer,
  payeesReducer:payeesReducer,
  TaxesReducer:TaxesReducer,
  invoicesReducer:InvoicesReducer,
  boothReducer:boothReducer,
  metalsReducer:metalsReducer,
  pettyCashUsersReducer:pettyCashUsersReducer,
  transactionsReducer:transactionsReducer,
  CryptoReducer:CryptoReducer,
  pettyCashReportsReducer:pettyCashReportsReducer,
  transactionDailyCountReducer:transactionDailyCountReducer,
  quoteReducer:quoteReducer,
  keywordsReducer:keywordsReducer
})

export default rootReducer