import {
  FETCH_STORES_LISTING_SUCCESS,
  FETCH_STORES_LISTING_FAILURE,
  FETCH_CLERK_LISTING_FAILURE,
  FETCH_CLERK_LISTING_SUCCESS,
  FETCH_TICKETS_LISTING_SUCCESS,
  FETCH_TICKETS_LISTING_FAILURE,
  SAVE_TICKETS_FORM_DETAILS,
  SAVE_TICKETS_ITEMS_DETAILS,
  RESET,
  RESET_SAVE_TICKETS_DETAILS,
  FETCH_TICKET_ITEMS__LISTING_FAILURE,
  FETCH_TICKET_ITEMS_LISTING_SUCCESS,
  UPDATE_TICKET_DATA_AFTER_UPDATE,
  COPY_TICKETS_DETAILS_IN_METRO,
} from "./ticketTypes";

import { initialState } from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORES_LISTING_SUCCESS:
      return {
        ...state,
        stores: {
          allStores: action.payload,
          error: null,
        },
      };
    case FETCH_STORES_LISTING_FAILURE:
      return {
        ...state,
        stores: {
          allStores: [],
          error: action.payload,
        },
      };
    case FETCH_CLERK_LISTING_SUCCESS:
      return {
        ...state,
        clerks: {
          allClerks: action.payload,
          error: null,
        },
      };
    case FETCH_CLERK_LISTING_FAILURE:
      return {
        ...state,
        clerks: {
          allClerks: initialState.clerks.allClerks,
          error: action.payload,
        },
      };
    case FETCH_TICKETS_LISTING_SUCCESS:
      return {
        ...state,
        tickets: {
          allTickets: action.payload.tickets,
          page: action.payload.page,
          count: action.payload.count,
          error: null,
        },
      };
    case UPDATE_TICKET_DATA_AFTER_UPDATE:
      return {
        ...state,
        tickets: {
          ...state.tickets,
          allTickets: action.payload,
          error: null,
        },
      };
    case FETCH_TICKETS_LISTING_FAILURE:
      return {
        ...state,
        tickets: {
          page: 0,
          count: 0,
          allTickets: [],
          error: action.payload,
        },
      };
    case FETCH_TICKET_ITEMS_LISTING_SUCCESS:
      return {
        ...state,
        tickets_items: {
          items: action.payload.data,
          ticketDetails: action.payload.ticketDetails,
          error: null,
        },
      };
    case FETCH_TICKET_ITEMS__LISTING_FAILURE:
      return {
        ...state,
        tickets_items: {
          items: [],
          ticketDetails: {},
          error: action.payload,
        },
      };

    case SAVE_TICKETS_FORM_DETAILS:
      return {
        ...state,
        addTicketFormdetails: {
          ...state.addTicketFormdetails,
          [action.payload.name]: action.payload.value,
        },
      };

    case SAVE_TICKETS_ITEMS_DETAILS:
      return {
        ...state,
        metroItemdetails: {
          items: action.payload,
          count: action.payload.length,
        },
      };
    case COPY_TICKETS_DETAILS_IN_METRO:
      return {
        ...state,
        addTicketFormdetails: {...initialState.addTicketFormdetails, ...action.payload},
      };
    case RESET_SAVE_TICKETS_DETAILS:
      return {
        ...state,
        addTicketFormdetails: initialState.addTicketFormdetails,
        metroItemdetails: {
          items: [],
          count: 0,
        },
      };

    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
