import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { resetState } from "../../redux/common/commonAction";
import Unauthorized from "views/Pages/Unauthorized";
import Header from "components/Headers/Header.js";
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  Button,
  Table,
  CustomInput
} from "reactstrap";
import {
    GetApiActionWithAuthorization,
    PostApiWithAuthorizationAction
} from "constantFunctions/apiActions";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import AddNotificationEmailModal from "views/Modal/NotificationEmail/AddNotificationEmailModal";
import EditNotificationEmailModal from "views/Modal/NotificationEmail/EditNotificationEmailModal";
import Moment from "react-moment";
import { DropdownList } from "react-widgets";

function Printers(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [station, setStation] = useState('station1');
  const [stations, setStations] = useState([]);
  const [printers, setPrinters] = useState([]);
  const [ticketInvoicePrinter, setTicketInvoicePrinter] = useState("");
  const [ticketLabelPrinter, setTicketLabelPrinter] = useState("");
  const [frontCheckPrinter, setFrontCheckPrinter] = useState("");
  const [backCheckPrinter, setBackCheckPrinter] = useState("");
  const [printServer, setPrintServer] = useState("http://print.dmhq.com/");
  useEffect(() => {
    //load available stations
    loadStations();
    //load printers
    loadPrinters(station);
  }, []);

  const loadStations = () => {
    GetApiActionWithAuthorization("printers/get-all-stations").then((res) => {
        setStations(res.data);
    }).catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
    });
  };

  const loadPrinters = (printerStation) => {
    GetApiActionWithAuthorization("printers/get-printers?station=" + printerStation).then((res) => {
        setPrinters(res.data);
        return GetApiActionWithAuthorization("printers/get-settings?station=" + printerStation)
    }).then((res) => {
        setTicketInvoicePrinter(res.data.ticketInvoicePrinterId);
        setTicketLabelPrinter(res.data.ticketLabelPrinterId);
        setBackCheckPrinter(res.data.backCheckPrinterId);
        setFrontCheckPrinter(res.data.frontCheckPrinterId);
    }).catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
    });
  };

  const saveSettingHandler = (e) => {
    let body = {
      ticketInvoicePrinter,
      ticketLabelPrinter,
      frontCheckPrinter,
      backCheckPrinter
    };
    PostApiWithAuthorizationAction("printers/save-setting?station=" + station, body)
      .then((res) => {
        toast.success("Setting Updated Successfully!", { position: toast.POSITION.TOP_RIGHT });
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader className="">
            <div className="text-center">
              <h1>Station Printers</h1>
            </div>
          </CardHeader>
          <CardBody className="checks-main-content page-main-content">
          <Row className="mb-2">
            <Col md="3">
                <Label
                    className="form-control-label font-weight-bold mb-0"
                    for="coinAmount">Computer's Name</Label>
                    <DropdownList
                        id="station"
                        data={stations}
                        value={station}
                        onChange={(e) => {
                          setStation(e)
                          loadPrinters(e)
                        }}
                    />
                </Col>
            </Row>
            <Row className="mb-2">
                <Col md="4">
                    <Label className="form-control-label font-weight-bold mb-0">Ticket Invoice Printer</Label>
                        <DropdownList
                            id="ticketInvoicePrinter"
                            data={printers}
                            value={ticketInvoicePrinter}
                            onChange={(e) => setTicketInvoicePrinter(e)}
                            />
                </Col>
                <Col md="4">
                    <Label
                        className="form-control-label font-weight-bold mb-0"
                        for="coinAmount">Ticket Label Printer</Label>
                        <DropdownList
                            id="ticketLabelPrinter"
                            data={printers}
                            value={ticketLabelPrinter}
                            onChange={(e) => setTicketLabelPrinter(e)}
                            />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md="3">
                    <Label
                        className="form-control-label font-weight-bold mb-0"
                        for="coinAmount">Front Check Printer</Label>
                        <DropdownList
                            id="frontCheckPrinter"
                            data={printers}
                            value={frontCheckPrinter}
                            onChange={(e) => setFrontCheckPrinter(e)}
                            />
                </Col>
                <Col md="1" className="pr-0" style={{ display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
                    <Button
                        color="warning"
                        size="sm"
                    >
                        <i className="fas fa-print"></i> Test Print
                    </Button>
                </Col>
                <Col md="3">
                    <Label
                        className="form-control-label font-weight-bold mb-0"
                        for="coinAmount">Back Check Printer</Label>
                        <DropdownList
                            id="backCheckPrinter"
                            data={printers}
                            value={backCheckPrinter}
                            onChange={(e) => setBackCheckPrinter(e)}
                            />
                </Col>
                <Col md="1" className="pr-0" style={{ display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
                    <Button
                        color="warning"
                        size="sm"
                    >
                        <i className="fas fa-print"></i> Test Print
                    </Button>
                </Col>
            </Row>
            <div className="text-left">
                <Button
                    id="add-btn"
                    color="primary"
                    onClick={saveSettingHandler}
                >
                    Save Changes
                </Button>
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}


const mapStateToProps = (state) => {
    return {
      
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      resetState: () => dispatch(resetState()),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Printers);