import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import Moment from "react-moment";
import moment from "moment";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import { monthOptions, dayOptions, paidOptions } from "../../constants/ticketConstants";
import { DropdownList } from "react-widgets";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";

function Demographics(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [years, setYears] = useState([]);
    const [demographics, setDemographics] = useState({
        data: [],
        demo: 'Age',
        demographics: [],
        sortAttributes: [],
        sortDirections: []
    });
    const [filter, setFilter] = useState({
        page: 0,
        itemSearch: '',
        demo: 'Age',
        sortField: 'Profit',
        sortDirection: 'DESC',
        startMonth: monthOptions.find(m=>m.value==(moment().subtract(1, 'months').format('M'))).value,
        startDay: moment().add(1, 'days').format('D'),
        startYear: (new Date()).getFullYear(),
        endMonth: monthOptions.find(m=>m.value==((new Date()).getMonth()+1)).value,
        endDay: (new Date()).getDate(),
        endYear: (new Date).getFullYear()
    });

    useEffect(() => {
        load();
        //
        GetApiActionWithAuthorization("invoices/get-all-years").then((res) => {
            setYears(res.data)
        });
    }, []);

    if (demographics.error && !unauthorized) {
        if (demographics.error.status === 403) {
          setUnauthorized(true);
        } else if (demographics.error.status === 440) {
          toast.error(demographics.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

    const resetFilter = () => {
        setFilter({
            ... filter,
            page: 0,
            clerk: '',
            soldTo: '',
            paidInFull: null
        })
    };

  const load = (params) => {
    params = Object.assign(filter, params);
    GetApiActionWithAuthorization("demographics/get-all", filter)
    .then((res) => {
        setDemographics(res)
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

    const renderDemographicsTableData = () => {
        let totalProfit = demographics.data.map(d=>d.profit).reduce((a, b) => a + b, 0);
        let totalCount = demographics.data.map(d=>d.count).reduce((a, b) => a + b, 0);
        //
        return demographics.data.map((_data, i) => {
            return (
                <>
                    <tr>
                        <td scope="col">
                            {demographics.demo == 'Zip' && (
                                _data.value.slice(0, 5)
                            )}
                            {demographics.demo != 'Zip' && (
                                _data.value
                            )}
                        </td>
                        <td scope="col">{_data.count}</td>
                        <td scope="col">{(parseFloat((_data.count/totalCount).toFixed(4))*100).toFixed(2)}%</td>
                        <td scope="col">{_data.profit}</td>
                        <td scope="col">{(parseFloat((_data.profit/totalProfit).toFixed(4))*100).toFixed(2)}%</td>
                    </tr>
                </>
            )
        });
    };

    //
    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader>
                <div className="text-center">
                    <h1>Demographics</h1>
                </div>
              </CardHeader>
              <CardBody className="checks-main-content">
                    <Row className="mb-2">
                        <Col md="4"></Col>
                        <Col md="4" className="d-flex d-flex-full mt-1">
                            <span class="mx-1" style={{fontSize: "22px"}}>Start: </span>
                            <DropdownList
                                data={monthOptions}
                                value={filter.startMonth}
                                placeholder="Month"
                                textField="label"
                                valueField='value'
                                onChange={(e) => {
                                    setFilter({
                                        ... filter,
                                        startMonth: e.value
                                    })
                                }}
                            />
                        <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                        <DropdownList
                            data={dayOptions}
                            value={filter.startDay}
                            placeholder="Day"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                startDay: e
                            })
                            }}
                        />
                        <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                        <DropdownList
                            data={years}
                            value={filter.startYear}
                            placeholder="Year"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                startYear: e
                            })
                            }}
                        />
                        </Col>
                        <Col md="4" className="d-flex d-flex-full pl-0 mt-1">
                            <span class="mx-1" style={{fontSize: "22px"}}>End: </span>
                            <DropdownList
                            data={monthOptions}
                            value={filter.endMonth}
                            placeholder="Month"
                            textField="label"
                            valueField='value'
                            onChange={(e) => {
                                setFilter({
                                ... filter,
                                endMonth: e.value
                                })
                            }}
                            />
                        <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                        <DropdownList
                            data={dayOptions}
                            value={filter.endDay}
                            placeholder="Day"
                            onChange={(e) => {
                            setFilter({
                                ... filter,
                                endDay: e
                            })
                            }}
                        />
                        <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                        <DropdownList
                            data={years}
                            value={filter.endYear}
                            placeholder="Year"
                            onChange={(e) => {
                                setFilter({
                                    ... filter,
                                    endYear: e
                                })
                            }}
                        />
                        <Button
                            id="btn-get-worksheet"
                            size="sm"
                            className="ml-2"
                            onClick={(e) => {
                                load();
                            }}
                            color="primary">Find</Button>
                        </Col>
                    </Row>
                    <Row className="align-items-center search-container mb-2">
                        <Col md="6" lg="6" xl="6"></Col>
                        <Col md="6" lg="6" xl="6">
                            <Form
                                className="d-flex"
                                onSubmit={(e) => {
                                    load();
                                    e.preventDefault();
                                }}
                            >
                            <Input
                                type="text"
                                className="mr-2"
                                placeholder="Item Search"
                                value={filter.itemSearch}
                                onChange={(e) => setFilter({
                                    ... filter,
                                    itemSearch: e.target.value
                                })}
                            />
                            <DropdownList
                                data={demographics.demographics}
                                className="mr-2"
                                containerClassName="filter-By"
                                value={filter.demo}
                                placeholder="Select"
                                onChange={(e) => setFilter({
                                    ... filter,
                                    demo: e
                                })}
                            />
                            <DropdownList
                                data={demographics.sortAttributes}
                                containerClassName="filter-By"
                                className="mr-2"
                                value={filter.sortField}
                                placeholder="Sort Field"
                                onChange={(e) => setFilter({
                                    ... filter,
                                    sortField: e
                                })}
                            />
                            <DropdownList
                                data={demographics.sortDirections}
                                containerClassName="filter-By"
                                value={filter.sortDirection}
                                placeholder="Sort Field"
                                onChange={(e) => setFilter({
                                    ... filter,
                                    sortDirection: e
                                })}
                            />
                            <Button type="submit" className="mx-2" color="primary">Apply</Button>
                            </Form>
                        </Col>
                        </Row>
                <Table className="ticket-table table-listing items-list-table" responsive bordered>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{demographics.demo}</th>
                      <th scope="col">Count</th>
                      <th scope="col">% of count</th>
                      <th scope="col">Profit</th>
                      <th scope="col">% of profit</th>
                    </tr>
                  </thead>
                  <tbody>{renderDemographicsTableData()}</tbody>
                </Table>
              </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Demographics);