import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

function EditCategoryModal(props) {
  const [categoryName, setCategoryName] = useState(props.editData.categoryName);

  const updateCategoryHandler = (e) => {
    let body = {
      id: props.editData.id,
      categoryName: categoryName.trim(),
    };
    PostApiWithAuthorizationAction(
      "coin-tickets/update-coin-item-category",
      body
    )
      .then((res) => {
        //toast.success(res.message);
        props.fetchItemsCategoriesListingSuccess(res.data);
        props.toggle();
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Update category</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="form-control-label" for="b_name">
            Category Name
          </Label>
          <Input
            id={"category_name"}
            type="text"
            placeholder="Category Name"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
        id="update-btn"
          color="primary"
          onClick={updateCategoryHandler}
          disabled={categoryName.length === 0}
        >
          Update
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditCategoryModal;
