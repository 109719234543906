import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Label,
  Input
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import {
  fetchTransactionListing,
  fetchTransactionListingSuccess,
} from "../../redux/Transactions/transactionsAction";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import Moment from "react-moment";
import CreateNewTransaction from "views/Modal/Tranactions/CreateNewTransaction";
import UpdateFullTransactionModal from "views/Modal/Tranactions/UpdateFullTransactionModal";
import AddPettyCashTransaction from "views/Modal/Tranactions/AddPettyCashTransaction";
import UpdatePettyCashTransactionModal from "views/Modal/Tranactions/UpdatePettyCashTransactionModal";
import { Link } from "react-router-dom";
import { Multiselect } from "react-widgets";
import { monthOptions, dayOptions, hourOptions, minuteOptions } from "../../constants/ticketConstants";
import { DropdownList } from "react-widgets";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import moment from "moment";

function Transactions(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [addTransactionModal, setTransactionAddModal] = useState(false);
  const [addPCTransactionModal, setPCTransactionAddModal] = useState(false);
  const [updatePCTransactionModal, setPCTransactionUpdateModal] = useState(false);
  const [years, setYears] = useState([]);
  const [transactionOptions, setTransactionOptions] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [getTransactionStatus, setGetTransactionStatus] = useState(false);
  const [editData, setEditData] = useState({});
  const [transactionStartDate, setTransactionStartDate] = useState({});
  const [transactionEndDate, setTransactionEndDate] = useState({});
  const [transactionFilterOption, setTransactionFilterOption] = useState("");

  useEffect(() => {
    setTransactionStartDate(moment().subtract(1, 'month').format("YYYY-MM-DD"));
    setTransactionEndDate(moment().format("YYYY-MM-DD"));
    props.fetchTransactionListing({
      returnCount: true,
      toFrom: 'pettycash'
    });
    //
    GetApiActionWithAuthorization("invoices/get-all-years").then((res) => {
      setYears(res.data)
    });
    //
    GetApiActionWithAuthorization("transactions/get-all-options").then((res) => {
      setTransactionOptions(res.data)
    });
  }, []);

  if (props.transaction.error && !unauthorized) {
    if (props.transaction.error.status === 403) {
      setUnauthorized(true);
    } else if (props.transaction.error.status === 440) {
      props.resetState();
      toast.error(props.transaction.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleAddTransaction = (e) => {
    setTransactionAddModal(!addTransactionModal);
  };

  const toggleAddPCTransaction = (e) => {
    setPCTransactionAddModal(!addPCTransactionModal);
  };

  const toggleUpdatePCTransaction = (id = "") => {
    setEditData({ id });
    setPCTransactionUpdateModal(!updatePCTransactionModal);
  };

  const toggleEdit = (id = "") => {
    setEditData({ id });
    setEditModal(!editModal);
  };

  const removePrefix = function (string, prefix) {
    const hasPrefix = string.indexOf(prefix) === 0;
    return hasPrefix ? string.substr(prefix.length) : string.toString();
  }

  const renderTicketsTableData = (e) => {
    return props.transaction.allTransactions.map((transaction, i) => {
      return (
        <tr key={i}>
          <td>
            {removePrefix(transaction.comment.trim(), 'For:')}
          </td>
          <td>
            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
              {transaction.time.toString().length === 10
                ? new Date(transaction.time * 1000)
                : new Date(transaction.time)}
            </Moment>
          </td>
          <td>{transaction.dest === 'retail' ? transaction.dest.replace('retail', 'coinretail') : transaction.dest}</td>
          <td>{transaction.src}</td>
          <td>
            $
            {transaction.denom100 * 100 +
              transaction.denom50 * 50 +
              transaction.denom20 * 20 +
              transaction.denom10 * 10 +
              transaction.denom5 * 5 +
              transaction.denom1 * 1}
          </td>
          {props.userType === "admin" && (
            <td>
              <span
                id={"update-transaction" + i}
                className="delete-item"
                onClick={(e) => {
                    if(transaction.dest.split("-").length > 1){
                      toggleUpdatePCTransaction(transaction.id);

                    }
                    else{
                      toggleEdit(transaction.id);
                    }
                }}
              >
                View/Edit
              </span>
            </td>
          )}
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content transaction-page" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Petty Cash Transactions</h1>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="8" className="search-left-btn">
                <a href="/portal/transactions/daily" id="transaction-daily">
                  <Button
                    id="btn-transaction-daily"
                    className="mr-2"
                    color="primary">
                    Daily Count
                  </Button>
                </a>
                <Button
                  id="btn-petty-cash"
                  color="primary"
                  onClick={toggleAddPCTransaction}
                >
                  New Transaction
                </Button>
              </Col>
            </Row>
            <Row className="daily-count-total mb-2">
              <Col md="5">
                <Row>
                  <Col className="d-flex align-items-center mt-2">
                    <Label className="form-control-label mb-0">Start:</Label>
                    <Input
                      type="date"
                      value={transactionStartDate}
                      onChange={(e) => setTransactionStartDate(e.target.value)}
                    />
                  </Col>
                  <Col className="d-flex align-items-center mt-2">
                    <Label className="form-control-label mb-0">End:</Label>
                    <Input
                      type="date"
                      value={transactionEndDate}
                      onChange={(e) => setTransactionEndDate(e.target.value)}
                    />
                  </Col>
                </Row>
                </Col>
                  <Col md="3" style={{marginTop: '2px'}}>
                    <Row>
                      <Col className="d-flex align-items-center mt-2">
                        <Button
                          color="primary"
                          className="custom-btn-info"
                          size="sm"
                          onClick={(e) => {
                            setGetTransactionStatus(true);
                            props.fetchTransactionListing({
                              returnCount: true,
                              startDate: transactionStartDate,
                              endDate: transactionEndDate,
                              toFrom: 'pettycash',
                              page: 0
                            });
                          }}
                        >
                          Get Transactions
                        </Button>
                      </Col>
                    </Row>
                  </Col>
              <Col md="4">
                <div className="daily-count-total">
                  {(props.userType !== 'drawer') && (
                    <>
                  <div className="text-right" style={{fontSize: "16px"}}>
                    Metro Total:&nbsp;
                    <span className="font-weight-600">
                      ${props.transaction.count.metroTotal}
                    </span>
                  </div>
                  <div className="text-right" style={{fontSize: "16px"}}>
                    Coin Total:&nbsp;
                    <span className="font-weight-600">
                      ${props.transaction.count.coinTotal}
                    </span>
                  </div>
                  <div className="text-right" style={{fontSize: "16px"}}>
                    Coin Dep:&nbsp;
                    <span className="font-weight-600">
                      ${props.transaction.count.coinDeposit}
                    </span>
                  </div>
                  <div className="text-right" style={{fontSize: "16px"}}>
                    Coin Daily:&nbsp;
                    <span className="font-weight-600">
                      ${props.transaction.count.coinDailyTotal}
                    </span>
                  </div>
                  </>
                  )}
                  <div className="text-right" style={{fontSize: "16px"}}>
                    Coin Change:&nbsp;
                    <span className="font-weight-600">
                      ${props.transaction.count.coinChangeTotal}
                    </span>
                  </div>
                  {(props.userType !== 'drawer') && (
                    <>
                      <div className="text-right" style={{fontSize: "16px"}}>
                        Jewelry Dep:&nbsp;
                        <span className="font-weight-600">
                          ${props.transaction.count.jewelryDeposit}
                        </span>
                      </div>
                    </>
                  )}
                  <div className="text-right" style={{fontSize: "16px"}}>
                    Jewelry Change:&nbsp;
                    <span className="font-weight-600">
                      ${props.transaction.count.jewelryChangeTotal}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Ticket #</th>
                  <th scope="col">Date</th>
                  <th scope="col">To</th>
                  <th scope="col">From</th>
                  <th scope="col">Amount</th>
                  {props.userType === "admin" && (
                    <th scope="col">Details</th>
                  )}
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
            {props.transaction.allTransactions.length > 0
                && props.userType === "admin"
                && (
                <ReactPaginate
                  previousLabel={
                    <i className="fas fa-chevron-left text-white"></i>
                  }
                  nextLabel={
                    <i className="fas fa-chevron-right text-white"></i>
                  }
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  initialPage={props.transaction.count.page}
                  pageCount={props.transaction.count.allTransactions / 50}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={(e) => {
                    if (props.transaction.count.page !== e.selected) {
                      let params = {
                        returnCount: true,
                        page: e.selected
                      };
                      //
                      if (getTransactionStatus) {
                        params.startDate = transactionStartDate;
                        params.endDate = transactionEndDate;
                      }
                      params.toFrom = 'pettycash';
                      props.fetchTransactionListing(params);
                    }
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
            )}
          </CardBody>
        </Card>
      </Container>
      {addTransactionModal && (
        <CreateNewTransaction
          {...props}
          show={addTransactionModal}
          toggle={toggleAddTransaction}
        />
      )}
      {addPCTransactionModal && (
        <AddPettyCashTransaction
          {...props}
          show={addPCTransactionModal}
          toggle={toggleAddPCTransaction}
        />
      )}

      {updatePCTransactionModal && (
        <UpdatePettyCashTransactionModal
          {...props}
          show={updatePCTransactionModal}
          toggle={toggleUpdatePCTransaction}
          editData={editData}
        />
      )}
      {editModal && (
        <UpdateFullTransactionModal
          {...props}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transactionsReducer,
    userType: state.loginUserReducer.userType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransactionListing: (params) => dispatch(fetchTransactionListing(params)),
    addUpdatedData: (data) => dispatch(fetchTransactionListingSuccess(data)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
