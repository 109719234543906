import React from "react";
import {
  Modal,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Button,
} from "reactstrap";
import {
  GetApiActionWithAuthorization,
  PostApiWithAuthorizationAction,
} from "../../../constantFunctions/apiActions";
import {
  getErrorMessages,
  formValid,
  getallErrors,
} from "../../../constantFunctions/formValidations";
import { toast } from "react-toastify";
import CustomInput from "reactstrap/lib/CustomInput";

class CreateNewGroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: [],
      selectedPermissionIds: [],
      showMysqlPermissions: false,
      formValue: {
        name: "",
        description: "",
      },
      formErrors: {
        name: "",
        description: "",
      },
      loading: false,
    };
  }
  componentDidMount() {
    GetApiActionWithAuthorization("users/all-users-permissions", {
      mySqlOnly: this.state.showMysqlPermissions,
    })
      .then((res) => {
        this.setState({
          permissions: res.data,
        });
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          this.props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  }

  //Handle input changes
  handleChange = (e) => {
    const { name, value } = e.target;
    let formValues = this.state.formValue;
    formValues[name] = value;
    this.setState(
      {
        formValue: formValues,
      },
      () => {
        let formErrors = { ...this.state.formErrors };
        formErrors = getErrorMessages(formErrors, name, value);
        this.setState({ formErrors: formErrors });
      }
    );
  };

  //CheckBox Handler
  checkboxHandler = (e) => {
    let checked = e.target.checked,
      id = parseInt(e.target.id);
    if (checked) {
      let stateArr = this.state.selectedPermissionIds;
      stateArr.push(id);
      this.setState({
        selectedPermissionIds: stateArr,
      });
    } else {
      let stateArr = this.state.selectedPermissionIds,
        index = stateArr.indexOf(id);
      stateArr.splice(index, 1);
      this.setState({
        selectedPermissionIds: stateArr,
      });
    }
  };
  resetForm() {
    this.setState({
      selectedPermissionIds: [],
      formValue: {
        name: "",
        description: "",
      },
      formErrors: {
        name: "",
        description: "",
      },
    });
  }

  //Create function to create a group
  createGroupHandler = (e) => {
    e.preventDefault();
    if (formValid(this.state)) {
      let body = {
        name: this.state.formValue.name,
        description: this.state.formValue.description,
        permissionIds: this.state.selectedPermissionIds,
      };
      this.setState({ loading: true });
      PostApiWithAuthorizationAction("users/create-new-group", body)
        .then((res) => {
          this.setState({ loading: false });
          //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
          this.resetForm();
          this.props.toggle();
          this.props.fetchUserGroupListing();
        })
        .catch((err) => {
          this.setState({ loading: false });
          if (err.status === 440) {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            this.props.history.push("/auth/login");
          } else {
            toast.error("Something went wrong! /n Please clear and retry", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    } else {
      let errors = getallErrors(this.state);
      this.setState({ formErrors: errors });
    }
  };

  //Get all permission checkboxes
  renderAllPermissions() {
    return this.state.permissions.map((permission, i) => {
      return (
        <Col md="6" key={i}>
          <FormGroup check>
            <Label className="form-control-label" check>
              <Input
                type="checkbox"
                id={permission.id}
                checked={this.state.selectedPermissionIds.includes(
                  permission.id
                )}
                onChange={this.checkboxHandler}
              />{" "}
              {permission.name}
            </Label>
          </FormGroup>
        </Col>
      );
    });
  }
  render() {
    const { formErrors } = this.state;
    return (
      <Modal isOpen={this.props.show} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Create New Group</ModalHeader>
        <ModalBody>
          <Form role="form" onSubmit={this.handleSubmit}>
            <FormGroup className="mb-3">
              <Label className="form-control-label" for="name">
                Group Name
              </Label>
              <Input
                id="name"
                placeholder="Group Name"
                type="text"
                name="name"
                value={this.state.formValue.name}
                autoComplete="off"
                onChange={this.handleChange}
              />
              {formErrors.name.length > 0 && (
                <span className="text-danger">
                  <small>{formErrors.name}</small>
                </span>
              )}
            </FormGroup>
            <FormGroup className="mb-3">
              <Label className="form-control-label" for="name">
                Group Description
              </Label>
              <textarea
                className="form-control"
                style={{ resize: "none" }}
                id="description"
                placeholder="Group Descriptions"
                type="textarea"
                rows="3"
                name="description"
                value={this.state.formValue.description}
                autoComplete="off"
                onChange={this.handleChange}
              />
              {formErrors.description.length > 0 && (
                <span className="text-danger">
                  <small>{formErrors.description}</small>
                </span>
              )}
            </FormGroup>
            <div className="d-flex align-items-center mb-2">
              <CustomInput
                type="switch"
                id={"mySqlUserSwitch"}
                name="showMysqlPermissions"
                checked={this.state.showMysqlPermissions}
                onChange={(e) => {
                  this.setState({ showMysqlPermissions: e.target.checked });
                  GetApiActionWithAuthorization("users/all-users-permissions", {
                    mySqlOnly: e.target.checked,
                  })
                    .then((res) => {
                      this.setState({
                        permissions: res.data,
                      });
                    })
                    .catch((err) => {
                      if (err.status === 440) {
                        toast.error(err.message, {
                          position: toast.POSITION.TOP_CENTER,
                        });
                        this.props.history.push("/auth/login");
                      } else {
                        toast.error(
                          "Something went wrong! /n Please clear and retry",
                          {
                            position: toast.POSITION.TOP_CENTER,
                          }
                        );
                      }
                    });
                }}
              />
              <Label className="form-control-label m-0" for="mySqlUserSwitch">
                Show MySql Permissions
              </Label>
            </div>
            <FormGroup className="mb-3">
              <Label className="form-control-label" for="name">
                Permissions
              </Label>
              <Row>{this.renderAllPermissions()}</Row>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          {this.state.loading ? (
            <Button color="primary" disabled>
              loading <i className="fas fa-spinner fa-spin"></i>
            </Button>
          ) : (
            <Button color="primary" id="group-create" onClick={this.createGroupHandler}>
              Create
            </Button>
          )}{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.resetForm();
              this.props.toggle();
            }}
            disabled={this.state.loading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CreateNewGroupModal;
