import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";

function ConfirmDrawerCountModal(props) {
  const [showDisparityForm, setShowDisparityForm] = useState(false);
  const [transactionOptionDrawerCount, setTransactionOptionDrawerCount] = useState({
    data: [
      {
        name: 'Metro',
        amount: 0,
        count: 0,
        mapKey: 'metrodrawer'
      },
      {
        name: 'Coin',
        amount: 0,
        count: 0,
        mapKey: 'coindrawer'
      },
      {
        name: 'CR',
        amount: 0,
        count: 0,
        mapKey: 'retail'
      },
      {
        name: 'JR',
        amount: 0,
        count: 0,
        mapKey: 'jewelryretail'
      }
    ]
  });

  useEffect(() => {
    GetApiActionWithAuthorization("common/get-drawer-amount")
    .then((res) => {
      res.data.forEach(o => {
        let drawerOptions = transactionOptionDrawerCount;
        let drawerRowIndex = drawerOptions.data.findIndex(u=>u.mapKey===o.name);
        drawerOptions.data[drawerRowIndex].amount = o.value;
        setTransactionOptionDrawerCount({
          ...drawerOptions
        })
      });
    })
    .catch((err) => {
      if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      } else {
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  }, []);

  const confirmDisparity = () => {
    PostApiWithAuthorizationAction("common/add-drawer-disparity-log", {
      details: transactionOptionDrawerCount.data
    })
    .then((res) => {
      props.confirmedDrawerCount();
    })
    .catch((err) => {
      if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      } else {
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const renderTableData = (e) => {
    return transactionOptionDrawerCount.data.map((drawer, i) => {
      return (
        <tr key={i}>
          <td>{drawer.name}</td>
          <td className="text-center">{drawer.amount}</td>
          <td>
            <Input
              id={"drawerCount-" + i}
              type="text"
              placeholder="Drawer Count"
              name={"drawerCount-" + i}
              value={drawer.count}
              autoComplete="off"
              onChange={(e) => {
                let drawerCount = transactionOptionDrawerCount
                drawerCount.data[i].count = e.target.value
                setTransactionOptionDrawerCount({
                  ...drawerCount
                })
              }}
            />
          </td>
        </tr>
      )
    });
  };

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Drawer Count Confirmation</ModalHeader>
      {(showDisparityForm) && (
        <ModalBody className="py-1">
          <h2 className="text-center">ENTER WRONG DRAWER COUNT(S)</h2>
          <Table className="ticket-table table-listing" responsive bordered>
            <thead className="thead-light">
              <tr>
                <th scope="col">Drawer</th>
                <th scope="col">Drawer Amt</th>
                <th scope="col">Count</th>
              </tr>
            </thead>
            <tbody>{renderTableData()}</tbody>
          </Table>
          <div className="text-right pb-1">
            <Button color="warning" onClick={e => {
              confirmDisparity();
            }}>Confirm Disparity</Button>
          </div>
        </ModalBody>
      )}
      {(!showDisparityForm) && (
        <>
          <ModalBody className="py-1 pt-3">
            <div className="form-group mb-0">
              <div className="row">
                <div className="col-md-12 text-center">
                  {props.message ? (props.message) :(<h4>Did all drawers balance?</h4>)}
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button id="confirm-btn" color="primary" onClick={e => props.confirmedDrawerCount()}>
              YES
            </Button>{" "}
            <Button color="secondary" onClick={e => {
              setShowDisparityForm(true);
            }}>NO</Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}

export default ConfirmDrawerCountModal;
