import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { resetState } from "redux/common/commonAction";
import {
  fetchReceiptListing,
  updateReceiptTableAfterUpdate,
  resetAddReceiptForm,
} from "../../redux/JewelryReceipt/JewelryReceiptAction";
import action from "../../assets/img/action.png";
import Unauthorized from "views/Pages/Unauthorized";
import Moment from "react-moment";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import { DropdownList } from "react-widgets";
import StationSelectModal from "views/Modal/StationSelectModal";
import qs from "query-string";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import ViewAttachment from "views/Modal/ViewAttachment";
import EditTicketNote from "views/Modal/EditTicketNote";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import SetVoidReasonModal from "views/Modal/VoidLogger/SetVoidReasonModal";
import ViewChangeHistory from "views/Modal/ViewChangeHistory";

function Receipts(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [search, setSearch] = useState("");
  // const [editModal, setEditModal] = useState(false);
  // const [id, setId] = useState("");
  const [sortId, setSortId] = useState("id");
  const [sortOrder, setSortOrder] = useState("-1");
  const [filterBy, setFilterBy] = useState("Receipt");
  const [stationModal, setStationModal] = useState(false);
  const [viewAttachmentModal, setViewAttachmentModal] = useState(false);
  const [printId, setPrintId] = useState("");
  const [todayOnly, setTodayOnly] = useState(true);
  const [dateFilter, setDateFilter] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");
  const [ticketAttachments, setTicketAttachments] = useState([]);
  const [receiptLogs, setReceiptLogs] = useState([]);
  const [changeHistoryModal, setChangeHistoryModal] = useState(false);
  const [changeNoteModal, setChangeNoteModal] = useState(false);
  const [receipt, setReceipt] = useState({});

  useEffect(() => {
    let date = qs.parse(props.location.search, { ignoreQueryPrefix: true })
      .date;
    if (date) {
      setTodayOnly(false);
      setDateFilter(date.split("-").join("/"));
    }
    props.fetchReceiptListing(
      props.receipts.page,
      search,
      sortId,
      sortOrder,
      filterBy,
      date ? false : true,
      date ? date.split("-").join("/") : ""
    );
  }, []);

  //_______________if props change on link click(refresh data when change in props for removing date filter)
  if (props.location.search === "" && dateFilter !== "") {
    setDateFilter("");
    props.fetchReceiptListing(
      props.receipts.page,
      search,
      sortId,
      sortOrder,
      filterBy,
      todayOnly,
      ""
    );
  }

  if (props.receipts.error && !unauthorized) {
    if (props.receipts.error.status === 403) {
      setUnauthorized(true);
    } else if (props.receipts.error.status === 440) {
      props.resetState();
      toast.error(props.receipts.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const showChangeHistory = (id) => {
    GetApiActionWithAuthorization(`common/get-log-data/${id}`)
      .then((res) => {
        setReceiptLogs(res.data);
        setChangeHistoryModal(!changeHistoryModal);
      }).catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const changeOneTimeEditStatus = (isEnabled, id) => {
    let body = {
      id: id,
      oneTimeEdit: isEnabled,
      page: props.receipts.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Receipt" ? 0 : 1,
      todayOnly,
    };
    PostApiWithAuthorizationAction(
      "jewelry-receipts/edit-receipt-render-table-data",
      body
    )
      .then((res) => {
        props.updateReceiptTableAfterUpdate(res.data);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const toggleStation = (id = "") => {
    setPrintId(id);
    setStationModal(!stationModal);
  };

  const toggleViewAttachment = () => {
    setViewAttachmentModal(!viewAttachmentModal);
  };

  const preloadViewAttachment = (id) => {
    GetApiActionWithAuthorization(`jewelry-receipts/get-particular-receipt-data/${id}`)
      .then((res) => {
        setTicketAttachments(res.details.jewelry_receipts_attachments);
        setViewAttachmentModal(!viewAttachmentModal);
      }).catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const printInvoiceHandler = (id) => {
    if (localStorage.getItem("lastUsedPrinter")) {
      let body = {
        printerId: localStorage.getItem("lastUsedPrinter"),
        ticketId: id,
        ticketType: "jewelryReceipt"
      };
      PostApiWithAuthorizationAction("common/print-invoice", body)
        .then((res) => {
          //toast.success(res.message);
        })
        .catch((err) => {
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    } else {
      toggleStation(id);
    }
  };

  const toggleChangeHistory = () => {
    setChangeHistoryModal(!changeHistoryModal);
  };

  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  const openNoteDialog = (id)  => {
    GetApiActionWithAuthorization(`jewelry-receipts/get-particular-receipt-data/${id}`)
    .then((res) => {
      setReceipt(res.details)
      toggleNoteDialog()
    }).catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  }

  const toggleNoteDialog = ()  => {
    setChangeNoteModal(!changeNoteModal)
  };

  const confirmed = (reason) => {
    if (reason) {
      markTicketVoidHandler(true, delId, reason);
      setConfirm(false);
    } else {
      toast.error("Please choose a valid void reason!", { position: toast.POSITION.TOP_CENTER });
    }
  };

  const markTicketVoidHandler = (isVoid, id, reason = "") => {
    let body = {
      id,
      void: isVoid,
      voidReason: reason,
      page: props.receipts.page,
      sortBy: sortId,
      sortOrder: sortOrder,
      search: search.trim(),
      filterByItem: filterBy === "Receipt" ? 0 : 1,
      todayOnly,
      dateFilter,
    };
    PostApiWithAuthorizationAction(
      "jewelry-receipts/edit-receipt-render-table-data",
      body
    )
      .then((res) => {
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        props.updateReceiptTableAfterUpdate(res.data);
        setConfirm(!confirm);
        setDelId("");
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderReceiptsTableData = (e) => {
    return props.receipts.allReceipts.map((recpt, i) => {
      let paidBy = recpt.paidBy.replace('_', ' & ')
      return (
        <tr
          key={i}
          className={classnames({
            "void-true": recpt.void,
          })}>
          <td>
            <UncontrolledDropdown id={"action-drop" + i}>
              <DropdownToggle
                className="p-1 bg-transparent border-0"
                disabled={recpt.void}>
                <img src={action} width="18px" />
              </DropdownToggle>
              <DropdownMenu className="action-menu">
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Actions!</h6>
                </DropdownItem>
                {props.userType === "admin" &&
                  (recpt.oneTimeEdit ? (
                    <DropdownItem
                      id="disable-ticket"
                      onClick={(e) => {
                        changeOneTimeEditStatus(false, recpt.id);
                      }}
                    >
                      <i className="fas fa-toggle-off" />
                      <span>Disable</span>
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      id="enable-ticket"
                      onClick={(e) => {
                        changeOneTimeEditStatus(true, recpt.id);
                      }}
                    >
                      <i className="fas fa-toggle-on" />
                      <span>Enable</span>
                    </DropdownItem>
                  ))}

                <DropdownItem
                  id="edit-ticket"
                  onClick={(e) =>
                    props.history.push(
                      "/portal/jewelry-receipts/edit/" + recpt.id
                    )
                  }
                >
                  <i className="fas fa-edit" />
                  <span>Edit</span>
                </DropdownItem>
                {props.userType === "employee" && (
                  <DropdownItem onClick={(e) => openNoteDialog(recpt.id)}>
                    <i className="fas fa-sticky-note" />
                    <span>Save Note</span>
                  </DropdownItem>
                )}
                {props.userType === "admin" && (
                  <>
                    <DropdownItem
                      onClick={(e) =>
                        props.history.push("/invoice-receipt/JR" + recpt.id)
                      }
                    >
                      <i className="fas fa-eye" />
                      <span>View Invoice</span>
                    </DropdownItem>
                    <DropdownItem
                      id="print-invoice"
                      onClick={(e) => showChangeHistory(recpt.id)}>
                      <i className="fas fa-history" />
                      <span>Show Changes History</span>
                    </DropdownItem>
                    </>
                )}
                <DropdownItem
                  id="print-invoice"
                  onClick={(e) => printInvoiceHandler(recpt.id)}
                >
                  <i className="fas fa-print" />
                  <span>Print Invoice</span>
                </DropdownItem>
                {/*
                {props.userType === "admin" && (
                <DropdownItem onClick={(e) => toggleConfirm(recpt.id)}>
                  <i className="fas fa-ban" />
                  <span>Void</span>
                </DropdownItem>
                )}*/}
              </DropdownMenu>
            </UncontrolledDropdown>
            {recpt.jewelry_receipts_attachments.length > 0 && (
              <Button
                id="create-ticket"
                color="warning"
                size="sm"
                onClick={(e) => preloadViewAttachment(recpt.id)}
                title={recpt.jewelry_receipts_attachments.length + " files"}>
                <i class="fas fa-paperclip"></i>
              </Button>
            )}
          </td>
          <td>{recpt.void ? <strike>{paidBy}</strike> : paidBy}</td>
          <td>{recpt.void ? <strike>{recpt.id}</strike> : recpt.id}</td>
          <td>
          {recpt.void ? (
              <strike>
                <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                  {recpt.ticketTime.toString().length === 10
                    ? recpt.ticketTime * 1000
                    : recpt.ticketTime}
                </Moment>
              </strike>
            ) : (
              <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
              {recpt.ticketTime.toString().length === 10
                ? recpt.ticketTime * 1000
                : recpt.ticketTime}
              </Moment>
            )}
          </td>
          <td>{recpt.void ? <strike>{recpt.firstName + " " + recpt.lastName}</strike> : recpt.firstName + " " + recpt.lastName}</td>
          <td>${recpt.void ? <strike>{parseInt(recpt.ticketAmount)}</strike> : parseInt(recpt.ticketAmount)}</td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Jewelry Receipts</h1>
            </div>
          </CardHeader>
          <CardBody className="page-main-content">
            <Row className="align-items-center search-container">
              <Col md="5" lg="6" xl="7" className="search-left-btn">
                <a
                  id="create-new"
                  href="/portal/jewelry-receipts/create-new"
                  className="btn btn-primary"
                  onClick={(e) => props.resetAddReceiptForm()}
                >Create New</a>
              </Col>
              <Col md="7" lg="6" xl="5">
                <Form
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.fetchReceiptListing(
                      0,
                      search.trim(),
                      sortId,
                      sortOrder,
                      filterBy
                    );
                  }}
                >
                  <Input
                    type="text"
                    className="mr-2"
                    placeholder="Search by id,first name,last name"
                    placeholder={
                      filterBy === "Receipt"
                        ? "Search by id,ticket amount,first name,last name,clerk.etc"
                        : "Search by items description"
                    }
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <DropdownList
                    data={["Receipt", "Receipt Items"]}
                    containerClassName="filter-By"
                    value={filterBy}
                    placeholder="Select"
                    onChange={(e) => setFilterBy(e)}
                  />
                  <Button className="mx-2" color="primary" type="submit">
                    Search
                  </Button>
                </Form>
                {/* <Button
                  color="info"
                  onClick={(e) => {
                    props.fetchCoinsListing(props.ticketDetails.page, "");
                    setSearch("");
                  }}
                >
                  Reset
                </Button> */}
              </Col>
            </Row>
            {props.userType === "admin" && (
              <FormGroup className="mt-4" check>
                <Label className="form-control-label" check>
                  <Input
                    type="checkbox"
                    id="todayOnly"
                    checked={todayOnly}
                    onChange={(e) => {
                      setTodayOnly(e.target.checked);
                      props.fetchReceiptListing(
                        props.receipts.page,
                        search,
                        sortId,
                        sortOrder,
                        filterBy,
                        e.target.checked,
                        dateFilter
                      );
                    }}
                  />{" "}
                  Today Only
                </Label>
                <span style={{float: 'right'}}>
                  <strong className="mr-1">Total:</strong>
                  ${props.receipts.allReceipts.reduce((prev, curr) => {
                      let amount = !isNaN(curr.ticketAmount) ? parseInt(curr.ticketAmount) : 0
                      return prev + amount;
                    }, 0)}
                </span>
              </FormGroup>
            )}
            <Table
              className="ticket-table table-listing mt-3"
              responsive="md"
              bordered
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Payment Type</th>
                  <th scope="col">Receipt # </th>
                  <th scope="col">Date</th>
                  <th scope="col">Name</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>{renderReceiptsTableData()}</tbody>
            </Table>
            {props.receipts.allReceipts.length > 0 &&
              props.userType === "admin" && (
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left"></i>}
                  nextLabel={<i className="fas fa-chevron-right"></i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  initialPage={props.receipts.page}
                  pageCount={props.receipts.count / 50}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={(e) => {
                    if (props.receipts.page !== e.selected) {
                      props.fetchReceiptListing(
                        e.selected,
                        search,
                        sortId,
                        sortOrder,
                        filterBy,
                        todayOnly,
                        dateFilter
                      );
                    }
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
          </CardBody>
        </Card>
      </Container>
      {stationModal && (
        <StationSelectModal
          show={stationModal}
          toggle={toggleStation}
          data={{ id: printId, type: "receipt" }}
        />
      )}
      {confirm && (
        <SetVoidReasonModal
          show={confirm}
          message={<h4>Please tell us why you want to mark this ticket as "VOID".</h4>}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
      {viewAttachmentModal && (
        <ViewAttachment
          show={viewAttachmentModal}
          toggle={toggleViewAttachment}
          data={ticketAttachments}
        />
      )}
      {changeHistoryModal && (
        <ViewChangeHistory
          show={changeHistoryModal}
          toggle={toggleChangeHistory}
          data={receiptLogs}
          viewType="receipt"
        />
      )}
      {(changeNoteModal && (
        <EditTicketNote
          show={changeNoteModal}
          toggle={toggleNoteDialog}
          receipt={receipt}
        />
      ))}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userType: state.loginUserReducer.userType,
    receipts: state.JewelryReceiptReducer.receipts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(resetState()),
    updateReceiptTableAfterUpdate: (body) =>
      dispatch(updateReceiptTableAfterUpdate(body)),
    fetchReceiptListing: (
      page,
      search,
      sortId,
      order,
      filterBy,
      todayOnly,
      dateFilter
    ) =>
      dispatch(
        fetchReceiptListing(
          page,
          search,
          sortId,
          order,
          filterBy,
          todayOnly,
          dateFilter
        )
      ),
    resetAddReceiptForm: () => dispatch(resetAddReceiptForm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Receipts);
