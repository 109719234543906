import {
  FETCH_USERS_LISTING_SUCCESS,
  FETCH_USERS_LISTING_FAILURE,
  FETCH_USERS_GROUP_LISTING_FAILURE,
  FETCH_USERS_GROUP_LISTING_SUCCESS,
} from "./userManagementTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________User Listing __________________________________________________________________ */

//Api Call Function for user listing
export const fetchUserListing = (groupId) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("users/all-users-listing/"+groupId)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchUserListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchUserListingFailure(err));
      });
  };
};

//Userlisting Success save Payload data to store
export const fetchUserListingSuccess = (users) => {
  return {
    type: FETCH_USERS_LISTING_SUCCESS,
    payload: users,
  };
};

//Userlisting Failure save Payload
export const fetchUserListingFailure = (error) => {
  return {
    type: FETCH_USERS_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */

/*_________________________________________________________________User Group Listing __________________________________________________________________ */

//Api Call Function for user listing
export const fetchUserGroupListing = () => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("users/all-users-groups")
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchUserGroupListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchUserGroupListingFailure(err));
      });
  };
};

//User Group Listing Success save Payload data to store
export const fetchUserGroupListingSuccess = (users) => {
  return {
    type: FETCH_USERS_GROUP_LISTING_SUCCESS,
    payload: users,
  };
};

//User Group Listing Failure save Payload
export const fetchUserGroupListingFailure = (error) => {
  return {
    type: FETCH_USERS_GROUP_LISTING_FAILURE,
    payload: error,
  };
};

/*__________________________________________________________________________________________________________________________________________ */
