import {
  FETCH_TRANSACTION_LISTING_FAILURE,
  FETCH_TRANSACTION_LISTING_SUCCESS,
  FETCH_TRANSACTION_DAILY_COUNT_SUCCESS,
  FETCH_TRANSACTION_DAILY_COUNT_FAILURE,
  SAVE_DC_DETAILS_IN_FORM,
  RESET_RECONCILE_FORM,
  RESET_DC_FORM
} from "./transactionsTypes";

import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";

import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________User Listing __________________________________________________________________ */

//Api Call Function for user listing
export const fetchTransactionListing = (params = {}) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-all-transactions", params)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchTransactionListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchTransactionListingFailure(err));
      });
  };
};

//Transaction listing Success save Payload data to store
export const fetchTransactionListingSuccess = (data) => {
  return {
    type: FETCH_TRANSACTION_LISTING_SUCCESS,
    payload: data,
  };
};

//Transaction listing Failure save Payload
export const fetchTransactionListingFailure = (error) => {
  return {
    type: FETCH_TRANSACTION_LISTING_FAILURE,
    payload: error,
  };
};

/*__________________________________________________________________________________________________________________________________________ */

/*---------------------------------Daily Count-------------------------*/
//Api Call Function for user listing
export const fetchTransactionDailyCount = () => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("transactions/get-daily-data")
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchTransactionDailyCountSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchTransactionDailyCountFailure(err));
      });
  };
};

//Transaction listing Success save Payload data to store
export const fetchTransactionDailyCountSuccess = (data) => {
  return {
    type: FETCH_TRANSACTION_DAILY_COUNT_SUCCESS,
    payload: data,
  };
};

//Transaction listing Failure save Payload
export const fetchTransactionDailyCountFailure = (error) => {
  return {
    type: FETCH_TRANSACTION_DAILY_COUNT_FAILURE,
    payload: error,
  };
};

export const saveItemsDetails = (items) => {
  return {
    type: SAVE_DC_DETAILS_IN_FORM,
    payload: items
  };
};

export const resetDailyCountForm = () => {
  return {
    type: RESET_DC_FORM,
  };
};

export const resetReconcileForm = () => {
  return {
    type: RESET_RECONCILE_FORM
  };
};