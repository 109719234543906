import React, { useEffect, useState } from "react";
// reactstrap components
import { Card, Container, CardHeader, CardBody, Row, Col, Button } from "reactstrap";
import ReactTooltip from "react-tooltip";
// core components
import Header from "components/Headers/Header.js";
import Unauthorized from "views/Pages/Unauthorized";
import Table from "reactstrap/lib/Table";
import { DropdownList } from "react-widgets";
import moment from "moment";
import { monthOptions, dayOptions, paidOptions } from "../../constants/ticketConstants";

import { fetchMetalsListing, fetchMetalItemsListing } from "../../redux/Metals/metalsAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import ViewMetalItemsModal from "../Modal/Metal/ViewMetalItemsModal";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import Collapsible from "react-collapsible";

function Metals(props) {
  let _moment = moment().tz('US/Pacific');
  let startTimestamp = moment();
  const [years, setYears] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [viewMetalItemsModal, setViewMetalItemsModal] = useState(false);
  const [metal, setMetal] = useState({});
  const [filter, setFilter] = useState({
    buyer: "",
    startMonth: monthOptions.find(m=>m.value==(startTimestamp.format('M'))).value,
    startDay: parseInt(startTimestamp.format('D')),
    startYear: startTimestamp.format('YYYY'),
    endMonth: monthOptions.find(m=>m.value==moment().format('M')).value,
    endDay: moment().format('D'),
    endYear: moment().format('YYYY'),
    isSameDay: true
  });

  const load = () => {
    props.fetchMetalsListing(filter);
  };

  //Hook to get data
  useEffect(() => {
    props.fetchMetalsListing(filter);
    //
    GetApiActionWithAuthorization("metals/get-all-years").then((res) => {
      setYears(res.data)
    });
    GetApiActionWithAuthorization("metals/get-all-buyers").then((res) => {
      setBuyers(res.data)
    });
  }, []);

  if (props.metals.error && !unauthorized) {
    if (props.metals.error.status === 403) {
      setUnauthorized(true);
    } else if (props.metals.error.status === 440) {
      props.resetState();
      toast.error(props.metals.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const viewMetalItemsModalToggle = (metal = {}, weightNotSold = false) => {
    if (!weightNotSold) {
      delete metal.weightNotSold;
    }
    setMetal(metal);
    setViewMetalItemsModal(!viewMetalItemsModal);
  };

  const renderMetalData = () => {
    return props.metals.allMetals.map((data, i) => {
      return (
        <>
          <Collapsible
            trigger={data.name == "" ? "Unsold" : data.name}
            open={true}>
            <Table className="check-table table-listing" bordered striped>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Metal</th>
                  <th scope="col">Total</th>
                  <th scope="col">Spot</th>
                  <th scope="col">Weight</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
              { renderTicketsTableData(data.metals) }
                <tr>
                  <th colSpan="4" className="text-right pr-6" style={{padding: '7px'}}>
                    {" "}
                    Grand Total
                  </th>
                  <th style={{padding: '7px'}}>
                    $
                    {data.metals
                      .reduce((prev, curr) => {
                        return prev + curr.total;
                      }, 0)
                      .toFixed(2).replace('.00', '')}
                  </th>
                </tr>
              </tbody>
            </Table>
          </Collapsible>
        </>
      )
    });
  };

  const renderTicketsTableData = (metals) => {
    return metals.map((metal, i) => {
      return (
        <tr key={i}>
          <td className="text-capitalize">{metal.name}</td>
          <td>${parseFloat(metal.weightNotSold.toFixed(2)*metal.unitPrice.toFixed(2)).toFixed(2).replace('.00', '')}</td>
          <td>${metal.spotPrice.toFixed(2).replace('.00', '')}</td>
          {/*<td>
            {metal.weight > 0 ? (
              <>
                <a
                  href="javascript:;"
                  id={"view-metal-weight" + i}
                  onClick={(e) => viewMetalItemsModalToggle(metal)}>
                <strong>
                {metal.name === "btc" || metal.name === "eth"
                  ? metal.weight.toFixed(8)
                  : Number(metal.weight).toFixed(2).replace('.00', '')}
                </strong>
                </a>
              </>
            ) : '0'}
            </td>*/}
          <td>
            {metal.weightNotSold > 0 ? (
              <>
                <a
                  href="javascript:;"
                  id={"view-metal-weight" + i}
                  onClick={(e) => viewMetalItemsModalToggle(metal, true)}>
                <strong>
                {metal.name === "btc" || metal.name === "eth"
                  ? metal.weightNotSold.toFixed(8)
                  : Number(metal.weightNotSold).toFixed(2).replace('.00', '')}
                </strong>
                </a>
              </>
            ) : '0'}
          </td>
          <td>
            ${metal.unitPrice.toFixed(2).replace('.00', '')}
            {metal.weightNotSold > 0 ? (
              <>
                <sup data-tip data-for={'unitPrice' + i + 'Tooltip'}>
                  <i className="fas fa-question-circle"></i>
                </sup>
                <ReactTooltip id={'unitPrice' + i + 'Tooltip'} place="top" effect="solid">
                  {metal.unitPriceFormula}
                </ReactTooltip>
              </>
            ) : ''}
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container
        className="mt--9 main-content"
        fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Metro Metals</h1>
            </div>
          </CardHeader>
          <CardBody className="page-main-content metal-page-content">
            <Row>
              <Col md="10" style={{margin: 'auto'}}>
                <Row className="mb-2">
                  <Col md="6" className="d-flex d-flex-full mt-1">
                            <input
                                id={"isSameDay"}
                                type="checkbox"
                                style={{marginTop: '8px'}}
                                checked={filter.isSameDay}
                                onChange={(e) => {
                                  let isSameDay = document.getElementById("isSameDay").checked;
                                  let startTimestamp = moment();
                                  if (!isSameDay) {
                                    startTimestamp = startTimestamp.startOf('month');
                                  }
                                  setFilter({
                                    ... filter,
                                    startMonth: monthOptions.find(m=>m.value==(startTimestamp.format('M'))).value,
                                    startDay: parseInt(startTimestamp.format('D')),
                                    startYear: startTimestamp.format('YYYY'),
                                    isSameDay
                                  });
                                }}
                                />&nbsp;<span style={{marginTop: '4px'}}>Single&nbsp;Day&nbsp;&nbsp;</span>
                                {(!filter.isSameDay) && (
                                  <span class="mx-1" style={{fontSize: "22px"}}>Start: </span>
                                )}
                                {(filter.isSameDay) && (
                                  <span class="mx-1" style={{fontSize: "22px"}}>Date: </span>
                                )}
                                <DropdownList
                                    data={monthOptions}
                                    value={filter.startMonth}
                                    placeholder="Month"
                                    textField="label"
                                    valueField='value'
                                    onChange={(e) => {
                                        setFilter({
                                            ... filter,
                                            startMonth: e.value
                                        })
                                    }}
                                />
                            <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                            <DropdownList
                                data={dayOptions}
                                value={filter.startDay}
                                placeholder="Day"
                                onChange={(e) => {
                                setFilter({
                                    ... filter,
                                    startDay: e
                                })
                                }}
                            />
                            <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                            <DropdownList
                                data={years}
                                value={filter.startYear}
                                placeholder="Year"
                                onChange={(e) => {
                                  setFilter({
                                    ... filter,
                                    startYear: e
                                  })
                                }}
                            />
                            </Col>
                            {(!filter.isSameDay) && (
                              <>
                            <Col md="5" className="d-flex d-flex-full pl-0 mt-1">
                                <span class="mx-1" style={{fontSize: "22px"}}>End: </span>
                                <DropdownList
                                data={monthOptions}
                                value={filter.endMonth}
                                placeholder="Month"
                                textField="label"
                                valueField='value'
                                onChange={(e) => {
                                  setFilter({
                                    ... filter,
                                    endMonth: e.value
                                  })
                                }}/>
                            <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                            <DropdownList
                                data={dayOptions}
                                value={filter.endDay}
                                placeholder="Day"
                                onChange={(e) => {
                                setFilter({
                                    ... filter,
                                    endDay: e
                                })
                                }}
                            />
                            <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                            <DropdownList
                                data={years}
                                value={filter.endYear}
                                placeholder="Year"
                                onChange={(e) => {
                                    setFilter({
                                        ... filter,
                                        endYear: e
                                    })
                                }}
                            />
                            </Col>
                          </>
                          )}
                          <Col md="1" className="d-flex d-flex-full mt-1 p-0">
                            {/*
                            <span class="mx-1" style={{fontSize: "22px"}}>Buyer: </span>
                            <DropdownList
                              data={buyers}
                              value={filter.buyer}
                              placeholder="Buyer"
                              textField="name"
                              valueField="name"
                              onChange={(e) => {
                                setFilter({
                                  ... filter,
                                  buyer: e.name
                                })
                              }}
                            />*/}
                            <Button
                                id="btn-get-worksheet"
                                size="sm"
                                className="ml-2"
                                onClick={(e) => {
                                  load();
                                }}
                                color="primary">Apply</Button>                        
                </Col>
            </Row>
          </Col>
          <Col md="2">
            <div
              className="text-right"
              style={{fontSize: '16px', color: '#525f7f'}}>
              <div>
                #Tickets:&nbsp;
                <span style={{fontWeight: 'bold'}}>{props.metals.countTickets}</span>
              </div>
              <div>
                Amount:&nbsp;
                <span style={{fontWeight: 'bold'}}>${props.metals.totalTicketAmount}</span>
              </div>
              <div>
                Profit:&nbsp;
                <span style={{fontWeight: 'bold'}}>${props.metals.totalProfitAmount}</span>
              </div>
            </div>
          </Col>
        </Row>
        {renderMetalData()}
          </CardBody>
        </Card>
      </Container>
      {viewMetalItemsModal && (
        <ViewMetalItemsModal
          {...props}
          filter={filter}
          show={viewMetalItemsModal}
          metal={metal}
          toggle={viewMetalItemsModalToggle}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    metals: state.metalsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMetalsListing: (data) => dispatch(fetchMetalsListing(data)),
    fetchMetalItemsListing: (metal) => dispatch(fetchMetalItemsListing(metal)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Metals);
