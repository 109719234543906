import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { fetchTaxesListing } from "../../redux/Taxes/TaxesAction";
import { DropdownList } from "react-widgets";
import {
  receiptTypeTaxOptions,
  defaultReceiptTypeTaxOption
} from "../../constants/ticketConstants";
import Unauthorized from "views/Pages/Unauthorized";
import Moment from "react-moment";

function Taxes(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [receiptType, setReceiptType] = useState('CR');

  useEffect(() => {
    let d = new Date();
    let end = formatDate(d);
    d.setMonth(d.getMonth() - 1);
    let start = formatDate(d);
    setStartDate(start);
    setEndDate(end);
    setReceiptType('CR')
    props.fetchTaxesListing(start, end, defaultReceiptTypeTaxOption);
  }, []);

  if (props.taxError && !unauthorized) {
    if (props.taxError.status === 403) {
      setUnauthorized(true);
    } else if (props.taxError.status === 440) {
      props.resetState();
      toast.error(props.taxError.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  //for formating date acc. to input type date
  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const renderTaxesTableData = () => {
    return props.taxData.map((tax, i) => {
      return (
        <tr key={i}>
          <td>{tax.id}</td>
          <td>
            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
              {tax.ticketTime.toString().length === 10
                ? new Date(tax.ticketTime * 1000)
                : new Date(tax.ticketTime)}
            </Moment>
          </td>
          <td>${tax.tax ? tax.tax.toFixed(2).replace('.00', '') : "0"}</td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Taxes</h1>
            </div>
          </CardHeader>
          <CardBody className="taxes-main-content">
            <Row>
              <Col md="10" lg="10">
                <Row>
                  <Col className="d-flex align-items-center mt-2">
                    <Label className="form-control-label mb-0">Start:</Label>
                    <Input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                  <Col className="d-flex align-items-center mt-2">
                    <Label className="form-control-label mb-0">End:</Label>
                    <Input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                  <Col className="mt-2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={(e) => {
                        props.fetchTaxesListing(startDate, endDate, receiptType);
                      }}
                    >
                      Update Date Range
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col className="d-flex align-items-center mt-2">
                <DropdownList
                  id="receiptType"
                  data={receiptTypeTaxOptions}
                  containerClassName="receiptType-drop"
                  disabled={[""]}
                  valueField="id"
                  textField="label"
                  value={receiptType}
                  style={{width: '100%'}}
                  onChange={(e) => {
                    setReceiptType(e);
                    props.fetchTaxesListing(startDate, endDate, e);
                  }}
              />
              </Col>
            </Row>
            <Table
              className="ticket-table table-listing mt-2"
              responsive
              bordered
            >
              <thead className="thead-light">
                <tr>
                  <th scope="col">Receipt #</th>
                  <th scope="col">Time</th>
                  <th scope="col">Tax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="2" className="text-right mr-4">
                    <strong className="mr-4">Total:</strong>
                  </td>
                  <td colSpan="2">${props.totalTax.toString().replace('.00', '')}</td>
                </tr>
                {renderTaxesTableData()}
                <tr>
                  <td colSpan="2" className="text-right mr-4">
                    <strong className="mr-4">Total:</strong>
                  </td>
                  <td colSpan="2">${props.totalTax.toString().replace('.00', '')}</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    taxData: state.TaxesReducer.taxesData,
    totalTax: state.TaxesReducer.totalTax,
    taxError: state.TaxesReducer.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTaxesListing: (startDate, endDate, receiptType) =>
      dispatch(fetchTaxesListing(startDate, endDate, receiptType)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Taxes);
