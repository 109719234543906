import React from "react";

// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import ReactPhoneInput from "react-phone-input-2";
import { updateUserDetails } from "../../redux/loginUser/loginUserAction";
import { connect } from "react-redux";
import {
  getErrorMessages,
  getallErrors,
  formValid,
} from "../../constantFunctions/formValidations";
// import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import ChangePassword from "views/Modal/ChangePassword";
import SetTwoFactorAuthentication from "views/Modal/SetTwoFactorAuthentication";
import '../../assets/css/profile.css';
import {
  GetApiActionWithAuthorization
} from "../../constantFunctions/apiActions";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      updatePermission: false,
      showChangePassword: false,
      showSetTwoFactorAuthentication: false,
      hideTwoFactorBtn: false,
      twoFactorUrl: "",
      phone: "",
      formValue: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
      },
      formErrors: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
      },
    };
  }
  componentDidMount() {
    const {
      first_name,
      last_name,
      username,
      phone,
      group,
      email,
    } = this.props.loginUserDetails.userDetails;
    this.setState({
      formValue: {
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        username: username ? username : "",
        email: email ? email : "",
      },
      phone: phone ? phone : "",
      updatePermission: group ? (group.name === "Admin" ? true : false) : false,
    });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {
      first_name,
      last_name,
      username,
      phone,
      group,
      email,
    } = props.loginUserDetails.userDetails;
    this.setState({
      formValue: {
        first_name: first_name ? first_name : "",
        last_name: last_name ? last_name : "",
        username: username ? username : "",
        email: email ? email : "",
      },
      phone: phone ? phone : "",
      updatePermission: group ? (group.name === "Admin" ? true : false) : false,
    });
  }

  //Handle input changes
  handleChange = (e) => {
    const { name, value } = e.target;
    let formValues = this.state.formValue;
    formValues[name] = value;
    this.setState(
      {
        formValue: formValues,
      },
      () => {
        let formErrors = { ...this.state.formErrors };
        formErrors = getErrorMessages(formErrors, name, value);
        this.setState({ formErrors: formErrors });
      }
    );
  };

  //Handle Contact Change
  handleContactChange = (e) => {
    this.setState({
      phone: e.toString(),
    });
  };

  //On Submit update details
  submitHandler = (e) => {
    e.preventDefault();
    if (formValid(this.state)) {
      let body = {
        first_name: this.state.formValue.first_name.trim(),
        last_name: this.state.formValue.last_name.trim(),
        username: this.state.formValue.username.trim(),
        phone: this.state.phone,
      };
      this.props.updateUserDetails(body);
    } else {
      let errors = getallErrors(this.state);
      this.setState({ formErrors: errors });
    }
  };

  /*_________________________Create User Modal Functions________________________ */
  twoFactorAuthenticationToggle = () => {
    this.setState({
      showSetTwoFactorAuthentication: !this.state.showSetTwoFactorAuthentication,
    });
  };
  //
  hideTwoFactorAuthenticationBtn = () => {
    this.setState({
      hideTwoFactorBtn: true
    });
  };
  //Toggle two factor authentication modal
  twoFactorAuthenticationLoad = (e) => {
    GetApiActionWithAuthorization(
      "users/get-authenticator-code"
    ).then((res) => {
      this.setState({
        twoFactorUrl: res.data
      });
      this.twoFactorAuthenticationToggle();
    });
  };

  //Toggle create group modal
  changePasswordToggle = (e) => {
    this.setState({
      showChangePassword: !this.state.showChangePassword,
    });
  };
  /*_____________________________________________________________________________*/

  render() {
    const { group } = this.props.loginUserDetails.userDetails;
    const { formErrors } = this.state;
    console.log(this.props.loginUserDetails.userDetails)
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--9 main-content" fluid>
          <Row>
            <Col xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="12" md="7">
                      <h3 className="mb-0">My Account</h3>
                    </Col>
                    <Col md="5" className="text-right">
                      {(!this.props.loginUserDetails.userDetails.two_factor_code_verified && !this.state.hideTwoFactorBtn) && (
                        <Button
                          style={{ cursor: "pointer" }}
                          color="warning"
                          onClick={this.twoFactorAuthenticationLoad}
                        >
                          <i class="fas fa-qrcode"></i> Two Factor Authentication
                        </Button>
                      )}
                      {this.state.updatePermission && (
                        <>
                          <Button
                            type="button"
                            color="primary"
                            onClick={this.changePasswordToggle}
                          >
                          <i class="fas fa-cog"></i>  Change Password
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="page-main-content">
                  <Form onSubmit={this.submitHandler}>
                    <h6 className="heading-small text-muted mb-4">
                      User information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              First name
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              name="first_name"
                              placeholder="First name"
                              onChange={(e) => {
                                if (this.state.updatePermission) {
                                  this.handleChange(e);
                                }
                              }}
                              value={this.state.formValue.first_name}
                              autoComplete="off"
                              type="text"
                              disabled={!this.state.updatePermission}
                            />
                            {formErrors.first_name.length > 0 && (
                              <span className="text-danger">
                                <small>{formErrors.first_name}</small>
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Last name
                            </label>
                            <Input
                              className="form-control-alternative"
                              name="last_name"
                              id="input-last-name"
                              value={this.state.formValue.last_name}
                              onChange={(e) => {
                                if (this.state.updatePermission) {
                                  this.handleChange(e);
                                }
                              }}
                              autoComplete="off"
                              type="text"
                              disabled={!this.state.updatePermission}
                            />
                            {formErrors.last_name.length > 0 && (
                              <span className="text-danger">
                                <small>{formErrors.last_name}</small>
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Username
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={this.state.formValue.username}
                              name="username"
                              id="input-username"
                              onChange={(e) => {
                                if (this.state.updatePermission) {
                                  this.handleChange(e);
                                }
                              }}
                              autoComplete="off"
                              type="text"
                              disabled={!this.state.updatePermission}
                            />
                            {formErrors.username.length > 0 && (
                              <span className="text-danger">
                                <small>{formErrors.username}</small>
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative"
                              value={this.state.formValue.email}
                              id="input-email"
                              name="email"
                              type="email"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-group-name"
                            >
                              Group
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-group-name"
                              name="group"
                              value={group ? group.name : ""}
                              type="text"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup className="profile-contact">
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Contact No.
                            </label>
                            <ReactPhoneInput
                              inputExtraProps={{
                                name: "phone",
                                type: "text",
                                id: "phone",
                                required: true,
                                autoFocus: true,
                              }}
                              country={"us"}
                              value={this.state.phone}
                              onChange={(e) => {
                                if (this.state.updatePermission) {
                                  this.handleContactChange(e);
                                }
                              }}
                              disabled={!this.state.updatePermission}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {this.state.updatePermission && (
                      <>
                        <div className="text-center my-3">
                          <Button type="submit" color="primary">
                            Update details
                          </Button>
                        </div>
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <ChangePassword
          {...this.props}
          show={this.state.showChangePassword}
          toggle={this.changePasswordToggle}
        />

        <SetTwoFactorAuthentication
          {...this.props}
          show={this.state.showSetTwoFactorAuthentication}
          toggle={this.twoFactorAuthenticationToggle}
          auth_barcode_url={this.state.twoFactorUrl}
          hideTwoFactorAuthenticationBtn={this.hideTwoFactorAuthenticationBtn}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUserDetails: state.loginUserReducer,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserDetails: (body) => dispatch(updateUserDetails(body)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
