import { setLoaderRequestFalse } from "redux/common/commonAction";
import {
  FETCH_DAILY_TABLE_LISTING_SUCCESS,
  FETCH_DAILY_TABLE_LISTING_FAILURE,
} from "./dailyTypes";

import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";
import { setLoaderRequestTrue } from "redux/common/commonAction";
import qs from "query-string";


//-------------------Daily table data listing--------------------------------------
export const fetchDailyTableData = (type, timeStamp, filterByWeeks, year, storeId) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    let params = {
      type,
      timeStamp,
      filterByWeeks,
      year,
      storeId,
      dataType: qs.parse(window.location.search, { ignoreQueryPrefix: true }).dataType || "all"
    };
    GetApiActionWithAuthorization("common/daily-data", params)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchDailyListingSuccess(res.data, params));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchDailyListingFailure(err));
      });
  };
};

//Daily Success save Payload data to store
export const fetchDailyListingSuccess = (data, params) => {
  return {
    type: FETCH_DAILY_TABLE_LISTING_SUCCESS,
    payload: {
      data,
      params
    },
  };
};

//Daily Failure save Payload
export const fetchDailyListingFailure = (error) => {
  return {
    type: FETCH_DAILY_TABLE_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
