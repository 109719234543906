import {
  FETCH_PAYEES_LISTING_SUCCESS,
  FETCH_PAYEES_LISTING_FAILURE
} from "./payeesTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________User Listing __________________________________________________________________ */

//Api Call Function for user listing
export const fetchPayeesListing = (page = 0) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("payees/get-all-data?page=" + page)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchPayeesListingSuccess(res));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchPayeesListingFailure(err));
      });
  };
};

export const fetchPayeesListingSuccess = (data) => {
  return {
    type: FETCH_PAYEES_LISTING_SUCCESS,
    payload: data,
  };
};

export const fetchPayeesListingFailure = (error) => {
  return {
    type: FETCH_PAYEES_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
