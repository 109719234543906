import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from "reactstrap";

function AddNewBoothModal(props) {
  const [b_name, setBname] = useState("");

  const addBoothHandler = (e) => {
    let body = {
      name: b_name.trim(),
    };
    PostApiWithAuthorizationAction("common/add-new-booth", body)
      .then((res) => {
        //toast.success(res.message);
        props.fetchBoothListing();
        props.toggle();
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } 
      });
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Add new Booth</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="form-control-label" for="b_name">
            Booth Name
          </Label>
          <Input
          id="b_name"
            type="text"
            placeholder="Booth Name"
            value={b_name}
            onChange={(e) => setBname(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
        id="add-btn"
          color="primary"
          onClick={addBoothHandler}
          disabled={b_name.length === 0}
        >
          Add
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddNewBoothModal;
