import {
  FETCH_COIN_ITEMS_P_LISTING_SUCCESS,
  FETCH_METRO_ITEMS_P_LISTING_SUCCESS,
  FETCH_COIN_ITEMS_P_LISTING_FAILURE,
  FETCH_METRO_ITEMS_P_LISTING_FAILURE,
  FETCH_ITEMS_CATEGORY_LISTING_SUCCESS,
  FETCH_ITEMS_CATEGORY_LISTING_FAILURE,
  RESET,
} from "./coinItemsTypes";

import { initialState } from "./initialState";

const reducer = (state = initialState, action) => {
  //console.log(action.type)
  switch (action.type) {
    case FETCH_COIN_ITEMS_P_LISTING_SUCCESS:
      return {
        ...state,
        coinItems: {
          allItems: action.payload,
          error: null,
        },
      };
    case FETCH_COIN_ITEMS_P_LISTING_FAILURE:
      return {
        ...state,
        coinItems: {
          allItems: [],
          error: action.payload,
        },
      };
      case FETCH_METRO_ITEMS_P_LISTING_SUCCESS:
        return {
          ...state,
          metroItems: {
            allItems: action.payload,
            error: null,
          },
        };
      case FETCH_METRO_ITEMS_P_LISTING_FAILURE:
        return {
          ...state,
          metroItems: {
            allItems: [],
            error: action.payload,
          },
        };
    case FETCH_ITEMS_CATEGORY_LISTING_SUCCESS:
      return {
        ...state,
        categories: {
          allCategories: action.payload,
          error: null,
        },
      };
    case FETCH_ITEMS_CATEGORY_LISTING_FAILURE:
      return {
        ...state,
        categories: {
          allCategories: [],
          error: action.payload,
        },
      };
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
