import Header from "components/Headers/Header";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import Card from "reactstrap/lib/Card";
import CardBody from "reactstrap/lib/CardBody";
import CardHeader from "reactstrap/lib/CardHeader";
import Container from "reactstrap/lib/Container";
import Form from "reactstrap/lib/Form";
import Input from "reactstrap/lib/Input";
import InputGroup from "reactstrap/lib/InputGroup";
import Table from "reactstrap/lib/Table";
import Unauthorized from "views/Pages/Unauthorized";

function AdvanceSearch(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [keys, setKeys] = useState([]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const getData = (pageNumber) => {
    PostApiWithAuthorizationAction("common/get-data-advance-search", {
      query: search.trim(),
      page: pageNumber,
    })
      .then((res) => {
        setData(res.data);
        setCount(res.count[0].count);
        if (res.data.length > 0) {
          setKeys(Object.keys(res.data[0]));
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error(err.err, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader className="py-3">
            <div className="text-center">
              <h3>Advance Search</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Form
              className="d-flex"
              onSubmit={(e) => {
                e.preventDefault();
                setPage(0);
                getData(0);
              }}
            >
              <InputGroup className="input-group-alternative border">
                <Input
                  type="text"
                  className="mr-2"
                  placeholder="Enter Query"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
              <Button type="submit" className="mx-2" color="primary">
                Search
              </Button>
            </Form>
            {data.length > 0 && (
              <>
                <Table className="query-result my-4" bordered responsive>
                  <thead>
                    <tr>
                      {keys.map((k,i) => {
                        return <th key={i}>{k}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((d,idT) => {
                      return (
                        <tr key={idT}>
                          {keys.map((k,i) => {
                            return <td key={i}>{d[k]}</td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left"></i>}
                  nextLabel={<i className="fas fa-chevron-right"></i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  initialPage={page}
                  pageCount={count / 200}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={(e) => {
                    setPage(e.selected);
                    getData(e.selected);
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default AdvanceSearch;
