import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import { Card, CardBody, CardHeader, Container } from "reactstrap";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import RenderSignatures from "./RenderSignatures";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Button from "reactstrap/lib/Button";
// reactstrap components

function SignatureDashboard(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [booth, setBooth] = useState([]);

  useEffect(() => {
    GetApiActionWithAuthorization("common/get-all-booths")
      .then((res) => {
        setBooth(
          res.data.map((b) => {
            return { name: b.name, enable: false };
          })
        );
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  }, []);

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow ">
          <CardHeader className="">
            <div className="text-center">
              <h1>Signature Dashboard</h1>
            </div>
          </CardHeader>
          <CardBody className="">
            <Row>
              {booth.map((b, i) => {
                return (
                  <Col md="6" lg="4" sm="6" className="mt-1 px-1 text-right" key={i}>
                    {b.enable ? (
                      <Button size="sm" color="warning" disabled>
                        Enabled
                      </Button>
                    ) : (
                      <Button
                        size="sm"
                        color="warning"
                        onClick={(e) => {
                          let booths = booth.map((b, j) => {
                            if (j === i) {
                              return { name: b.name, enable: true };
                            } else {
                              return { name: b.name, enable: false };
                            }
                          });
                          setBooth([...booths]);
                        }}
                      >
                        Enable
                      </Button>
                    )}
                    <RenderSignatures {...props} booth={b.name} enabled={b.enable} />
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default SignatureDashboard;
