import React, { useState } from "react";
import Viewer from 'react-viewer';

function ViewInvoiceItemAttachment(props) {
    const [ visible, setVisible ] = React.useState(false);
    const [ images, setImages ] = React.useState(false);

    const renderFiles = () => {
        return props.files.map((file, i) => {
            return (
                <>
                    <li class="fileuploader-item file-type-image file-ext-jpg file-has-popup" key={i}>
                        <div class="fileuploader-item-inner">
                            <div class="actions-holder">
                                <button type="button" class="fileuploader-action fileuploader-action-remove" title="Delete">
                                    <i class="fileuploader-icon-remove" onClick={() => props.removePictureInItem(props.index)}></i>
                                </button>
                            </div>
                            <div class="thumbnail-holder">
                                <div class="fileuploader-item-image">
                                    <img src={file.imageUrl} draggable="false"/>
                                </div>
                                <span class="fileuploader-action-popup" onClick={() => {
                                        setVisible(true);
                                        setImages([{src: file.imageUrl, alt: ''}])
                                    }}></span>
                            </div>
                        </div>
                    </li>
                </>
            )
        })
    };

    return (
        <>
            <Viewer
                visible={visible}
                onClose={() => { setVisible(false); } }
                images={images}
                rotatable={false}
                scalable={false}
                drag={false}
                loop={false}
                showTotal={false}
                />
            <div class="fileuploader fileuploader-theme-thumbnails">
                <div class="fileuploader-items">
                    <ul class="fileuploader-items-list">
                        { renderFiles() }
                    </ul>
                </div>
            </div>
        </>
    );
}

export default ViewInvoiceItemAttachment;
