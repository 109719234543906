import React from "react";
import {
  Container,
  Card,
  CardBody,
  Button,
  Table,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
import Unauthorized from "../Pages/Unauthorized";
import Header from "../../components/Headers/Header";
import CreateNewGroupModal from "../Modal/UserGroups/CreateNewGroupModal";
import { fetchUserGroupListing } from "../../redux/userManagement/userManagementAction";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import UpdateGroupModal from "views/Modal/UserGroups/UpdateGroupModal";

class UserGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allgroups: [],
      editProps: {},
      showCreateGroup: false,
      unauthorized: false,
      showUpdateGroup: false,
    };
  }
  componentDidMount() {
    this.props.fetchUserGroupListing();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { error } = props.userGroupManage;
    if (error) {
      if (error.status === 403) {
        this.setState({ unauthorized: true });
      } else if (error.status === 440) {
        this.props.resetState();
        toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
        this.props.history.push("/auth/login");
      }
    }
  }

  /*_________________________Create Group Modal Functions________________________ */

  //Toggle create group modal
  createGroupToggle = (e) => {
    this.setState({
      showCreateGroup: !this.state.showCreateGroup,
    });
  };
  /*_____________________________________________________________________________*/

  /*_________________________Update User Modal Functions________________________ */

  //Toggle create group modal
  updateGroupToggle = (e) => {
    this.setState({
      showUpdateGroup: !this.state.showUpdateGroup,
      editProps: {},
    });
  };
  renderEditData(props) {
    this.setState({
      showUpdateGroup: !this.state.showUpdateGroup,
      editProps: {
        selectedPermissions: props.group_permissions.map((permission) => {
          return permission.permission.id;
        }),
        name: props.name,
        description: props.description,
        id: props.id,
      },
    });
  }
  /*_____________________________________________________________________________*/

  //Render Group Permissions
  renderGroupPermissions(permissions) {
    return permissions.map((permission, i) => {
      return <li key={i}>{permission.permission.name}</li>;
    });
  }

  //Render table Data into the <td>
  renderTableData() {
    return this.props.userGroupManage.allUsersGroups.map((group, i) => {
      const { id, name, description, group_permissions } = group;
      return (
        <tr key={i}>
          <td>{id}</td>
          <td>{name}</td>
          <td>{description}</td>
          <td>
            <ul>{this.renderGroupPermissions(group_permissions)}</ul>
          </td>
          <td style={{ whiteSpace: "normal" }}>
            {name === "Admin" ? (
              <h4>Can not edit,Admin have all access and permissions.</h4>
            ) : (
              <span
                style={{ cursor: "pointer" }}
                title="Edit Details"
                id={"edit"+i}
                onClick={() => {
                  this.renderEditData(group);
                }}
              >
                <i className="fas fa-edit mr-1"></i>Edit
              </span>
            )}
          </td>
        </tr>
      );
    });
  }

  render() {
    return this.state.unauthorized ? (
      <Unauthorized />
    ) : (
      <>
        <Header />
        <Container className="mt--9 main-content" fluid>
          <Card className="card-height">
            <CardHeader className="bg-white d-flex justify-content-between">
              <h1>User Groups</h1>
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  this.props.history.goBack();
                }}
              >
                <i className="fas fa-chevron-left"></i> Back
              </Button>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <Button color="primary" id="create-group" onClick={this.createGroupToggle}>
                    Create New Group
                  </Button>
                </Col>
              </Row>
              <Table
                className="align-items-center mt-5 table-listing"
                responsive
                bordered
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Group Name</th>
                    <th scope="col">Group Description</th>
                    <th scope="col">Permission</th>
                    <th scope="col">Edit</th>
                  </tr>
                </thead>
                <tbody>{this.renderTableData()}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>
        {this.state.showCreateGroup && (
          <CreateNewGroupModal
            {...this.props}
            show={this.state.showCreateGroup}
            toggle={this.createGroupToggle}
          />
        )}
        {this.state.showUpdateGroup && (
          <UpdateGroupModal
            {...this.props}
            show={this.state.showUpdateGroup}
            toggle={this.updateGroupToggle}
            groupProps={this.state.editProps}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userGroupManage: state.userManagementReducer.usersGroupListing,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserGroupListing: () => dispatch(fetchUserGroupListing()),
    resetState: () => dispatch(resetState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserGroup);
