import {
  FETCH_PCREPORT_LISTING_FAILURE,
  FETCH_PCREPORT_LISTING_SUCCESS
} from "./pettyCashReportsTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________User Listing __________________________________________________________________ */

//Api Call Function for user listing
export const fetchPettyCashReportListing = (filter = {}) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-petty-cash-report", filter)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchPettyCashReportListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchPettyCashReportListingFailure(err));
      });
  };
};

//PettyCashReportlisting Success save Payload data to store
export const fetchPettyCashReportListingSuccess = (data) => {
  return {
    type: FETCH_PCREPORT_LISTING_SUCCESS,
    payload: data,
  };
};

//PettyCashReportlisting Failure save Payload
export const fetchPettyCashReportListingFailure = (error) => {
  return {
    type: FETCH_PCREPORT_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
