import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

function EditClerkModal(props) {
  const [c_name, setCname] = useState(props.editData.name);

  const updateClerkHandler = (e) => {
    let body = {
      id:props.editData.id,
      name: c_name.trim()
    };
    PostApiWithAuthorizationAction("common/edit-clerk-render-data", body)
      .then((res) => {
        //toast.success(res.message);
        props.addUpdatedData(res.data);
        props.toggle();
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } 
      });
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Update Clerk Details</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="form-control-label" for="c_name">
          Clerk Name
          </Label>
          <Input
          id="c_name"
            type="text"
            placeholder="Clerk Name"
            value={c_name}
            onChange={(e) => setCname(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
        id="update-btn"
          color="primary"
          onClick={updateClerkHandler}
          disabled={c_name.length === 0}
        >
          Update
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditClerkModal;
