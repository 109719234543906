import {
  FETCH_STORES_LISTING_SUCCESS,
  FETCH_STORES_LISTING_FAILURE,
  FETCH_CLERK_LISTING_SUCCESS,
  FETCH_CLERK_LISTING_FAILURE,
  FETCH_TICKETS_LISTING_SUCCESS,
  FETCH_TICKETS_LISTING_FAILURE,
  SAVE_TICKETS_FORM_DETAILS,
  SAVE_TICKETS_ITEMS_DETAILS,
  RESET_SAVE_TICKETS_DETAILS,
  FETCH_TICKET_ITEMS__LISTING_FAILURE,
  FETCH_TICKET_ITEMS_LISTING_SUCCESS,
  UPDATE_TICKET_DATA_AFTER_UPDATE,
  COPY_TICKETS_DETAILS_IN_METRO,
} from "./ticketTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________Store Listing __________________________________________________________________ */

//Api Call Function for store listing
export const fetchAllStoresListing = () => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-stores-listing")
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(
          saveTicketFormDetails({ name: "storeId", value: res.data[0] })
        );
        dispatch(fetchStoreListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchStoreListingFailure(err));
      });
  };
};

//Userlisting Success save Payload data to store
export const fetchStoreListingSuccess = (store) => {
  return {
    type: FETCH_STORES_LISTING_SUCCESS,
    payload: store,
  };
};

//Userlisting Failure save Payload
export const fetchStoreListingFailure = (error) => {
  return {
    type: FETCH_STORES_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */

/*_________________________________________________________________Clerk Listing __________________________________________________________________ */

//Api Call Function for clerk listing
export const fetchAllClerkListing = () => {
  return (dispatch) => {
    GetApiActionWithAuthorization("common/get-clerk-listing")
      .then((res) => {
        dispatch(fetchClerkListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchClerkListingFailure(err));
      });
  };
};

//Userlisting Success save Payload data to store
export const fetchClerkListingSuccess = (store) => {
  return {
    type: FETCH_CLERK_LISTING_SUCCESS,
    payload: store,
  };
};

//Userlisting Failure save Payload
export const fetchClerkListingFailure = (error) => {
  return {
    type: FETCH_CLERK_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */

/*_________________________________________________________________Ticket Listing __________________________________________________________________ */

//Api Call Function for ticket listing
export const fetchTicketsListing = (
  page,
  search,
  sortBy,
  sortOrder,
  filterBy,
  todayOnly = true,
  emptyProfit = false,
  dateFilter
) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("metro-tickets/get-all", {
      page,
      sortBy,
      sortOrder,
      search,
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      filterBy,
      todayOnly: todayOnly,
      emptyProfit: emptyProfit,
      dateFilter
    })
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchTicketsListingSuccess(res.data, page, res.count));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchTicketsListingFailure(err));
      });
  };
};

//Ticketlisting Success save Payload data to store
export const fetchTicketsListingSuccess = (tickets, page, count) => {
  return {
    type: FETCH_TICKETS_LISTING_SUCCESS,
    payload: { tickets, page, count },
  };
};

//Ticketlisting Failure save Payload
export const fetchTicketsListingFailure = (error) => {
  return {
    type: FETCH_TICKETS_LISTING_FAILURE,
    payload: error,
  };
};

//Ticketlisting Failure save Payload
export const updateTicketTableAfterUpdate = (tickets) => {
  return {
    type: UPDATE_TICKET_DATA_AFTER_UPDATE,
    payload: tickets,
  };
};

/*__________________________________________________________________________________________________________________________________________ */

/*_________________________________________________________________Ticket Items Listing __________________________________________________________________ */

//Api Call Function for ticket items listing
export const fetchTicketItemsListing = (id) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("metro-tickets/get-ticket-items/" + id)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(
          fetchTicketItemsListingSuccess({
            data: res.data,
            ticketDetails: res.ticketDetails,
          })
        );
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchTicketItemsListingFailure(err));
      });
  };
};

//Ticket Items listing Success save Payload data to store
export const fetchTicketItemsListingSuccess = (data) => {
  return {
    type: FETCH_TICKET_ITEMS_LISTING_SUCCESS,
    payload: data,
  };
};

//Ticket Items listing Failure save Payload
export const fetchTicketItemsListingFailure = (error) => {
  return {
    type: FETCH_TICKET_ITEMS__LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */

/*_________________________________________________________________Save ticket form details to __________________________________________________________________ */
export const saveTicketFormDetails = ({ name, value }) => {
  return {
    type: SAVE_TICKETS_FORM_DETAILS,
    payload: { name, value },
  };
};

export const saveTicketItemDetails = (items) => {
  return {
    type: SAVE_TICKETS_ITEMS_DETAILS,
    payload: items,
  };
};

export const copyTicketDetailsToMetro = (data) => {
  return {
    type: COPY_TICKETS_DETAILS_IN_METRO,
    payload: data,
  };
};

export const resetAddTicketForm = () => {
  return {
    type: RESET_SAVE_TICKETS_DETAILS,
  };
};
