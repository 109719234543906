import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import React, { useState, useEffect } from "react";
import { DropdownList } from "react-widgets";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button, Row, Col, FormGroup, Label
} from "reactstrap";

function SetVoidReasonModal(props) {
    const [reason, setReason] = useState("");
    const [reasons, setReasons] = useState([]);

    useEffect(() => {
        loadReasons();
    }, []);

    const loadReasons = () => {
        GetApiActionWithAuthorization("common/get-all-void-reasons").then(res =>{
            setReasons(res.data)
        }).catch(err =>{
            console.log(err);
        });
    };

  return (
    <Modal isOpen={props.show} toggle={props.toggle} autoFocus={false}>
      <ModalHeader toggle={props.toggle}>Choose Void Reason</ModalHeader>
      <ModalBody>
        <Row>
            <Col md ="12">
                <FormGroup className="mb-3">
                  <Label className="form-control-label" for="reason">{props.message}</Label>
                  <DropdownList
                    autoFocus={true}
                    data={reasons}
                    containerClassName="reason-drop"
                    valueField="id"
                    textField="reason"
                    value={reason}
                    placeholder="Select A Reason"
                    onChange={(e) => setReason(e)}
                  />
                </FormGroup>
            </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={e => props.confirmed(reason.reason)}>
          Proceed Void
        </Button>{" "}
        <Button color="secondary" onClick={e =>props.toggle()}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

export default SetVoidReasonModal;
