import React from "react";
import moment from "moment-timezone";
import { paymentCashOptions } from "constants/ticketConstants";
import { paymentCreditCardOptions } from "constants/ticketConstants";

const emailRegex = RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
const regularExpression = RegExp(
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/
);

//For get error all messages on chane fields
function getErrorMessages(formErrors, name, value) {
  switch (name) {
    case "first_name":
      formErrors.first_name =
        value.length < 3 ? "*minimum 3 characters required" : "";
      break;
    case "last_name":
      formErrors.last_name =
        value.length < 3 ? "*minimum 3 characters required" : "";
      break;
    case "email":
      formErrors.email = emailRegex.test(value) ? "" : "*invalid email address";
      break;
    case "name":
      formErrors.name = value.length === 0 ? "*field is required" : "";
      break;
    case "phone":
      formErrors.phone =
        value.length < 10 ? "*minimum 10 characters required" : "";
      break;
    case "username":
      formErrors.username =
        value.length < 3 || value.length > 50
          ? "*length must between 3-50"
          : "";
      break;
    case "group_id":
      formErrors.group_id = value.length === 0 ? "*field is required" : "";
      break;
    case "description":
      formErrors.description = value.length === 0 ? "*field is required" : "";
      break;
    case "password":
      if (value.length < 8) {
        formErrors.password = "*minimum 8 characters required";
      } else
        formErrors.password = regularExpression.test(value)
          ? ""
          : "*required symbol, upper and lower case letters and a number ";
      break;
    case "old_password":
      if (value.length < 8) {
        formErrors.old_password = "*minimum 8 characters required";
      } else
        formErrors.old_password = regularExpression.test(value)
          ? ""
          : "*required symbol, upper and lower case letters and a number ";
      break;
    case "new_password":
      if (value.length < 8) {
        formErrors.new_password = "*minimum 8 characters required";
      } else
        formErrors.new_password = regularExpression.test(value)
          ? ""
          : "*required symbol, upper and lower case letters and a number ";
      break;
    case "material":
      formErrors.material = value.length === 0 ? "*field is required" : "";
      break;
    case "itemType":
      formErrors.itemType = value.length === 0 ? "*field is required" : "";
      break;
    case "itemDescription":
      formErrors.itemDescription =
        value.length === 0 ? "*field is required" : "";
      break;
    default:
      break;
  }
  return formErrors;
}

//For check form valid or not
function formValid({ formErrors, formValue }) {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach((val) => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(formValue).forEach((val) => {
    val === "" && (valid = false);
  });
  return valid;
}

//For get error all messages on click button not on change fields
function getallErrors(state) {
  let errors = state.formErrors;
  if (errors.first_name === "" && state.formValue.first_name.length <= 3) {
    errors.first_name = "*minimum 3 characters required";
  }
  if (errors.name === "" && state.formValue.name.length === 0) {
    errors.name = "*field is required";
  }
  if (errors.description === "" && state.formValue.description.length === 0) {
    errors.description = "*field is required";
  }
  if (errors.last_name === "" && state.formValue.last_name.length <= 3) {
    errors.last_name = "*minimum 3 characters required";
  }
  if (errors.email === "" && !emailRegex.test(state.formValue.email)) {
    errors.email = "*invalid email address";
  }
  if (errors.password === "" && state.formValue.password.length <= 8) {
    errors.password = "*minimum 8 characters required";
  }
  if (errors.new_password === "" && state.formValue.new_password.length <= 8) {
    errors.new_password = "*minimum 8 characters required";
  }
  if (errors.old_password === "" && state.formValue.old_password.length <= 8) {
    errors.old_password = "*minimum 8 characters required";
  }
  if (errors.group_id === "" && state.formValue.group_id === "") {
    errors.group_id = "*field is required";
  }
  if (errors.material === "" && state.formValue.material === "") {
    errors.material = "*field is required";
  }
  if (errors.itemType === "" && state.formValue.itemType === "") {
    errors.itemType = "*field is required";
  }
  if (errors.itemDescription === "" && state.formValue.itemDescription === "") {
    errors.itemDescription = "*field is required";
  }
  if (
    errors.username === "" &&
    (state.formValue.username.length < 3 ||
      state.formValue.username.length > 50)
  ) {
    errors.username = "*length must between 3-50";
  }
  if (errors.phone === "" && state.formValue.phone.length < 10) {
    errors.phone = "*minimum 10 characters required";
  }

  //________Special cases for Transaction modals______________
  if (state.formValue.dest && state.formValue.dest.length === 0) {
    errors.dest = "*field is required";
  } else {
    errors.dest = "";
  }
  if (state.formValue.comment && state.formValue.comment.length === 0) {
    errors.comment = "*field is required";
  } else {
    errors.comment = "";
  }
  if (state.formValue.totalTransAmount >= 600) {
    /*if (!emailRegex.test(state.formValue.eMail)) {
      errors.eMail = "*invalid email address";
    } else {
      errors.eMail = "";
    }*/
    if (state.formValue.SSN && state.formValue.SSN.length < 11) {
      errors.SSN = "*invalid SSN address";
    } else {
      errors.SSN = "";
    }
  }

  return errors;
}

/*----------------------------------Function used to validate items form-------*/
function validateItemForm(formDetails) {
  let toValidate = {
    name: "Name",
    category: "Category",
    itemType: "Item Type"
  };
  let errors = [];
  for (const val in toValidate) {
    if (formDetails[val] === "" || !formDetails[val]) {
      errors.push(`${toValidate[val]} is Blank`);
    }
  }
  //
  if (errors.length > 0) {
    let err = errors.map((e, i) => {
      return (
        <li key={i}>
          <h5 className="text-white">{e}</h5>
        </li>
      );
    });
    return { success: false, err: <ul>{err}</ul> };
  }
  return { success: true };
}
/*-----------------------------------------Function used to validate void reasons form------*/
function validateVoidReasonForm(formDetails) {
  let toValidate = {
    reason: "Reason"
  };
  let errors = [];
  for (const val in toValidate) {
    if (formDetails[val] === "" || !formDetails[val]) {
      errors.push(`${toValidate[val]} is Blank`);
    }
  }
  //
  if (errors.length > 0) {
    let err = errors.map((e, i) => {
      return (
        <li key={i}>
          <h5 className="text-white">{e}</h5>
        </li>
      );
    });
    return { success: false, err: <ul>{err}</ul> };
  }
  return { success: true };
}

/*-----------------------------------Function used to validate tickets Forms and send Error messages------*/
function validateTicketForm(formDetails, items = false) {
  let toValidate = {
    ticketAmount: "Ticket Amount",
    firstName: "First Name",
    lastName: "Last Name",
    address: "Address",
    city: "City",
    state: "State",
    zip: "Zip",
    dOB: "Date of Birth",
    height: "Height",
    weight: "Weight",
    race: "Race",
    fromx: "From",
    clerk: "Clerk",
    payment: "payment",
    booth: "Booth",
  };
  let errors = [];
  for (const val in toValidate) {
    if (formDetails[val] === "") {
      errors.push(`${toValidate[val]} is Blank`);
    } else if (val === "ticketAmount"
                && parseFloat(formDetails[val]) < 1
                && formDetails["payment"].value !== 'barter'
                && formDetails.userType != "admin") {
      errors.push(`Ticket Amount must greater than 0`);
    } else if (
      (val === "race" || val === "booth") &&
      formDetails[val].value === ""
    ) {
      errors.push(`${toValidate[val]} is Blank`);
    } else if (val === "fromx" && formDetails[val].name === "") {
      errors.push(`${toValidate[val]} is Blank`);
    }
  }
  //
  if (!moment(formDetails["dOB"], 'MMDDYYYY').isValid()) {
    errors.push(`Invalid Date of Birth: MMDDYYYY format expected.`);
  }
  let dlIsValid = formDetails["dlState"] !== "" && formDetails["dlNo"] !== "",
    otherIsValid =
      formDetails["otherIdType"] !== "" && formDetails["otherIdType"] !== "";
  if (!dlIsValid || !otherIsValid) {
    errors.push("Please fill out both 'DL' fields OR both 'Other ID' fields");
  }

  if (formDetails["payment"].value === "check") {
    if (
      formDetails["paymentCheckAmount"] <= 0 ||
      formDetails["paymentCheckAmount"] === ""
    ) {
      errors.push("Check Amount must be a number greater than zero");
    }
    if (formDetails["checkNumber"] === "") {
      errors.push("Check Number must be included");
    }
    if (formDetails["checkNumber"].toString().length < 4) {
      errors.push("Check Number length is incorrect");
    }
    if (isNaN(formDetails["paymentCheckAmount"])) {
      errors.push("Check Payment Amount must be a number");
    }
    if (
      parseFloat(formDetails["paymentCheckAmount"]) >
      parseFloat(formDetails["ticketAmount"])
    ) {
      errors.push("Check Amount is higher than Ticket Amount");
    }
  }
  if(formDetails["ticketAmount"] > 900000) {
    errors.push("Ticket Amount can not be greater than 900000")
  }
  if (
    formDetails["clerk"].first_name === "" ||
    formDetails["clerk"].first_name === "None"
  ) {
    errors.push("Clerk name not selected");
  }
  if (formDetails.booth.name === "") {
    errors.push(`Booth is Blank`);
  }
  if (
    formDetails["fromx"].name === "internet google" &&
    formDetails["keyword"] === ""
  ) {
    errors.push("Customer came from Google. Please fill in a Keyword");
  }
  if (
    parseInt(formDetails["weight"]) < 50 ||
    parseInt(formDetails["weight"]) > 500
  ) {
    errors.push("Weight must be above 50 and below 500");
  }

  //validate when items set
  if (items) {
    if (items.length == 0 || 
      items.find((e) => {
        if (e.qty === "" || e.name === "" || e.cost === "") {
          return true;
        } else {
          return false;
        }
      })
    ) {
      errors.push("Some Items values are not valid");
    }
  }

  if (errors.length > 0) {
    let err = errors.map((e, i) => {
      return (
        <li key={i}>
          <h5 className="text-white">{e}</h5>
        </li>
      );
    });
    return { success: false, err: <ul>{err}</ul> };
  }
  return { success: true };
}

/*-----------------------------------Function used to validate daily count form ----*/
function validateDailyCountForm(form) {
  let items = form.items;
  let denoms = form.denoms;
  let errors = [];
  if (
    items.find((e) => {
      let hasError = false
      for(const denom of denoms) {
        if (!hasError) {
          hasError = (!isNaN(items['denom' + denom]));
        }
      }
      return hasError;
    })
  ) {
    errors.push("Some Items values are not valid");
  }
  if (errors.length > 0) {
    let err = errors.map((e, i) => {
      return (
        <li key={i}>
          <h5 className="text-white">{e}</h5>
        </li>
      );
    });
    return { success: false, err: <ul>{err}</ul> };
  }
  return { success: true };
}

/*-----------------------------------Function used to validate quote Forms --------*/
function validateQuoteForm(formDetails, items) {
  let errors = [];
  if (formDetails.clerk === "") {
    errors.push(`Clerk is Blank`);
  }
  if (formDetails.customerName === "") {
    errors.push(`Customer Name is Blank`);
  }
  if (
    items.find((e) => {
      if (e.itemDescription === "" || e.price === "") {
        return true;
      } else {
        return false;
      }
    })
  ) {
    errors.push("Some Items values are not valid");
  }
  if (errors.length > 0) {
    let err = errors.map((e, i) => {
      return (
        <li key={i}>
          <h5 className="text-white">{e}</h5>
        </li>
      );
    });
    return { success: false, err: <ul>{err}</ul> };
  }
  return { success: true };
}

/*-----------------------------------Function used to validate invoice Forms ------*/
function validateInvoiceForm(formDetails, items) {
  let errors = [];
  if (formDetails.billedTo === "") {
    errors.push(`Billed To is Blank`);
  }
  if (formDetails.deliveryDateDay === "") {
    errors.push(`Delivery Date Day To is Blank`);
  }
  if (formDetails.deliveryDateMonth === "") {
    errors.push(`Delivery Date Month To is Blank`);
  }
  if (formDetails.deliveryDateYear === "") {
    errors.push(`Delivery Date Year To is Blank`);
  }
  if (
    items.find((e) => {
      if (e.dateTime === "" || e.itemDescription === "" || e.price === "") {
        return true;
      } else {
        return false;
      }
    })
  ) {
    errors.push("Some Items values are not valid");
  }
  if (errors.length > 0) {
    let err = errors.map((e, i) => {
      return (
        <li key={i}>
          <h5 className="text-white">{e}</h5>
        </li>
      );
    });
    return { success: false, err: <ul>{err}</ul> };
  }
  return { success: true };
}

/*-----------------------------------Function used to validate coin invoice Forms ------*/
function validateCoinInvoiceForm(formDetails, items) {
  let errors = [];
  if (formDetails.billedTo === "") {
    errors.push(`Billed To is Blank`);
  }
  if (formDetails.deliveryDateDay === "") {
    errors.push(`Delivery Date Day To is Blank`);
  }
  if (formDetails.deliveryDateMonth === "") {
    errors.push(`Delivery Date Month To is Blank`);
  }
  if (formDetails.deliveryDateYear === "") {
    errors.push(`Delivery Date Year To is Blank`);
  }
  //
  if (errors.length > 0) {
    let err = errors.map((e, i) => {
      return (
        <li key={i}>
          <h5 className="text-white">{e}</h5>
        </li>
      );
    });
    return { success: false, err: <ul>{err}</ul> };
  }
  return { success: true };
}

/*-----------------------------------Function used to validate receipt payments items form */
function validateReceiptPaymentForm(items) {
  let errors = [];
  if (
    items.find((e) => {
      let description = e.hasOwnProperty('item') ? e.item.name : e.itemDescription || "";
      if (e.qty === "" || description === "" || !description || e.price === "") {
        return true;
      } else {
        return false;
      }
    })
  ) {
    errors.push("Some Items values are not valid");
  }
  //
  if (errors.length > 0) {
    let err = errors.map((e, i) => {
      return (
        <li key={i}>
          <h5 className="text-white">{e}</h5>
        </li>
      );
    });
    return { success: false, err: <ul>{err}</ul> };
  }
  return { success: true };
}

/*-----------------------------------Function used to validate receipt Forms and send Error messages------*/
function validateReceiptForm(formDetails, items) {
  let errors = [],
      totalItemAmount = items.reduce((prev, curr) => {
        if (curr.price === "") {
          return prev + 0.0;
        } else {
          return prev + parseFloat(curr.price);
        }
      }, 0);
  if (
    formDetails.tendered === "" &&
    formDetails.paidBy.value === "cash"
  ) {
    errors.push(`Cash Tendered is Blank`);
  }
  if (
    formDetails.creditCardNumber === "" &&
    formDetails.paidBy.value === "creditcard"
  ) {
    errors.push(`Card Number is Blank`);
  }
  if (
    ((formDetails.paidBy.value === "creditcard" || totalItemAmount >= 3000) && formDetails.firstName === "") ||
    (typeof formDetails.paymentType !=='undefined' && formDetails.paymentType.value === "partial" && formDetails.firstName === "")
  ) {
    errors.push(`First Name is Blank`);
  }
  if (formDetails.paidBy.value === "check" && (formDetails.checkAmount == "" || formDetails.checkAmount < 0)) {
    errors.push(`Check Amount is Blank or not valid`);
  }
  if (formDetails.paidBy.value === "check" && formDetails.checkNumber === "") {
    errors.push(`Check Number is Blank`);
  }
  if (
    ((formDetails.paidBy.value === "creditcard" || totalItemAmount >= 3000) && formDetails.lastName === "") ||
    (typeof formDetails.paymentType !=='undefined' && formDetails.paymentType.value === "partial" && formDetails.lastName === "")
  ) {
    errors.push(`Last Name is Blank`);
  }
  if (typeof formDetails.paymentType !=='undefined'
      && formDetails.paymentType.value === "partial"
      && formDetails.phone === "") {
    errors.push(`Phone is Blank`);
  }
  /*if (formDetails.fromx.name === "") {
    errors.push(`From is Blank`);
  }*/
  if (formDetails.printer.value === "") {
    errors.push(`Station is Blank`);
  }
  if (formDetails.booth.name === "") {
    errors.push(`Booth is Blank`);
  }

  /*if (
    formDetails["fromx"].name === "internet google" &&
    formDetails["keyword"] === ""
  ) {
    errors.push("Customer came from Google. Please fill in a Keyword");
  }*/
  if (
    items.find((e) => {
      let description = e.hasOwnProperty('item') ? e.item.name : e.itemDescription || "";
      if (e.qty === "" || description === "" || !description || e.price === "") {
        return true;
      } else {
        return false;
      }
    })
  ) {
    errors.push("Some Items values are not valid");
  }
  if (
    formDetails["clerk"].first_name === "" ||
    formDetails["clerk"].first_name === "None"
  ) {
    errors.push("Clerk name not selected");
  }
  //
  if(formDetails.paidBy.value === "cash" && totalItemAmount > formDetails.tendered) {
    errors.push("Cash Tendered amount can not be lower than Total amount");
  }
  //
  if(formDetails.paidBy.value === "check" && totalItemAmount > formDetails.checkAmount) {
    errors.push("Check amount can not be lower than Total amount");
  }
  //
  if (errors.length > 0) {
    let err = errors.map((e, i) => {
      return (
        <li key={i}>
          <h5 className="text-white">{e}</h5>
        </li>
      );
    });
    return { success: false, err: <ul>{err}</ul> };
  }
  return { success: true };
}

/*-----------------------------------Function used to validate Jewelry receipt Forms and send Error messages------*/
function validateJewelryReceiptForm(formDetails, items) {
  let errors = [];
  if (
    formDetails.tendered === "" &&
    paymentCashOptions.includes(formDetails.paidBy.value)
  ) {
    errors.push(`Cash Tendered is Blank`);
  }
  if (
    formDetails.checkAmount === "" &&
    formDetails.paidBy.value == "check"
  ) {
    errors.push(`Check Amount is Blank`);
  }
  if (
    formDetails.checkNumber === "" &&
    formDetails.paidBy.value == "check"
  ) {
    errors.push(`Check Number is Blank`);
  }
  if (formDetails.paidBy.value == "cash" && formDetails.tendered < items
    .reduce((prev, curr) => {
      if (curr.price === "") {
        return prev + 0.0;
      } else {
        return prev + parseFloat(curr.price);
      }
    }, 0)) {
      errors.push("Cash Tendered amount can not be lower than Total amount");
    }
  if (
    formDetails.creditCardNumber === "" &&
    paymentCreditCardOptions.includes(formDetails.paidBy.value)
  ) {
    errors.push(`Card Number is Blank`);
  }
  if (
    ((formDetails.paidBy.value === "creditcard" || formDetails.tendered >= 3000) && formDetails.firstName === "") ||
    (typeof formDetails.paymentType !=='undefined' && formDetails.paymentType.value === "partial" && formDetails.firstName === "") ||
    (formDetails.requireShipmentFreeTax && formDetails.firstName === "")
  ) {
    errors.push(`First Name is Blank`);
  }
  if (
    ((formDetails.paidBy.value === "creditcard" || formDetails.tendered >= 3000) && formDetails.lastName === "") ||
    (typeof formDetails.paymentType !=='undefined' && formDetails.paymentType.value === "partial" && formDetails.lastName === "") ||
    (formDetails.requireShipmentFreeTax && formDetails.lastName === "")
  ) {
    errors.push(`Last Name is Blank`);
  }
  if (typeof formDetails.paymentType !=='undefined'
      && formDetails.paymentType.value === "partial"
      && formDetails.phone === "") {
    errors.push(`Phone is Blank`);
  }
  /*if (formDetails.fromx.name === "") {
    errors.push(`From is Blank`);
  }*/
  if (formDetails.printer.value === "") {
    errors.push(`Station is Blank`);
  }
  if (formDetails.booth.name === "") {
    errors.push(`Booth is Blank`);
  }

  /*if (
    formDetails["fromx"].name === "internet google" &&
    formDetails["keyword"] === ""
  ) {
    errors.push("Customer came from Google. Please fill in a Keyword");
  }*/
  if (
    items.find((e) => {
      let description = e.hasOwnProperty('item') ? e.item.name : e.itemDescription || "";
      if (e.qty === "" || description === "" || !description || e.price === "") {
        return true;
      } else {
        return false;
      }
    })
  ) {
    errors.push("Some Items values are not valid");
  }
  if (
    formDetails["clerk"].first_name === "" ||
    formDetails["clerk"].first_name === "None"
  ) {
    errors.push("Clerk name not selected");
  }
  //shipping details validation
  if(formDetails.requireShipmentFreeTax && formDetails.shippingAddress === "") {
    errors.push("Shipping Address field is Blank");
  }
  if(formDetails.requireShipmentFreeTax && formDetails.shippingState === "") {
    errors.push("Shipping State field is Blank");
  }
  if(formDetails.requireShipmentFreeTax && formDetails.shippingCity === "") {
    errors.push("Shipping City field is Blank");
  }
  if(formDetails.requireShipmentFreeTax && formDetails.shippingPostalCode === "") {
    errors.push("Shipping Postal Code field is Blank");
  }
  if(formDetails.requireShipmentFreeTax && formDetails.shippingPhone === "") {
    errors.push("Shipping Phone field is Blank");
  }
  if (errors.length > 0) {
    let err = errors.map((e, i) => {
      return (
        <li key={i}>
          <h5 className="text-white">{e}</h5>
        </li>
      );
    });
    return { success: false, err: <ul>{err}</ul> };
  }
  return { success: true };
}

export {
  getErrorMessages,
  formValid,
  getallErrors,
  validateTicketForm,
  validateCoinInvoiceForm,
  validateItemForm,
  validateVoidReasonForm,
  validateReceiptForm,
  validateReceiptPaymentForm,
  validateJewelryReceiptForm,
  validateInvoiceForm,
  validateDailyCountForm,
  validateQuoteForm,
  emailRegex
};
