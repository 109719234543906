import {
  FETCH_PCUSER_LISTING_FAILURE,
  FETCH_PCUSER_LISTING_SUCCESS
} from "./pettyCashUsersTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________User Listing __________________________________________________________________ */

//Api Call Function for user listing
export const fetchPettyCashUsersListing = () => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-all-petty-cash-user")
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchPettyCashUsersListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchPettyCashUsersListingFailure(err));
      });
  };
};

//PettyCashUserslisting Success save Payload data to store
export const fetchPettyCashUsersListingSuccess = (data) => {
  return {
    type: FETCH_PCUSER_LISTING_SUCCESS,
    payload: data,
  };
};

//PettyCashUserslisting Failure save Payload
export const fetchPettyCashUsersListingFailure = (error) => {
  return {
    type: FETCH_PCUSER_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
