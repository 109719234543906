import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  CustomInput,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import {
  fetchBuyerListing,
  fetchBuyerListingSuccess,
} from "../../redux/Buyers/buyersAction";
import AddNewBuyerModal from "views/Modal/Buyers/AddNewBuyerModal";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import EditBuyerModal from "views/Modal/Buyers/EditBuyerModal";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import { DropdownList } from "react-widgets";

function Buyers(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    props.fetchBuyerListing({
      all: true
    });
  }, []);

  if (props.buyers.error && !unauthorized) {
    if (props.buyers.error.status === 403) {
      setUnauthorized(true);
    } else if (props.buyers.error.status === 440) {
      props.resetState();
      toast.error(props.buyers.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleAdd = (e) => {
    setAddModal(!addModal);
  };

  const toggleEdit = (id = "", name = "") => {
    setEditData({ id, name });
    setEditModal(!editModal);
  };

  const setBuyerType = (id, type) => {
    PostApiWithAuthorizationAction("common/edit-new-render-data", {
      id,
      type
    })
      .then((res) => {
        props.fetchBuyerListing({
          all: true
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const changeOrder = (decrease, id, order) => {
    let body = { decrease, id, order, type:"buyers" };
    PostApiWithAuthorizationAction("common/change-order", body)
      .then((res) => {
        props.fetchBuyerListing({
          all: true
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const updateEnableStatus = (e, id) => {
    let body = {
      enabled: e.target.checked,
      id: id,
    };
    PostApiWithAuthorizationAction("common/edit-new-render-data", body)
      .then((res) => {
        props.addUpdatedData(res.data);
      })
      .catch((err) => {
        
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderTicketsTableData = (e) => {
    return props.buyers.allBuyers.map((buyer, i) => {
      return (
        <tr key={i}>
          <td>{(i+1)}</td>
          <td>{buyer.name}</td>
          <td>
            <CustomInput
              type="switch"
              id={"showEnableBuyerCustomSwitch" + i}
              name="enabled"
              checked={buyer.enabled}
              onChange={(e) => updateEnableStatus(e, buyer.id)}
            />
          </td>
          <td>
            <DropdownList
              data={[{ value: 2, text: 'Coin' }, { value: 1, text: 'Metro' }]}
              value={buyer.type}
              placeholder="Type"
              valueField="value"
              textField="text"
              onChange={(e) => {
                setBuyerType(buyer.id, e.value);
              }}
            />
          </td>
          <td>
            <span
            id={"update-buyer"+i}
              className="delete-item"
              onClick={(e) => {
                toggleEdit(buyer.id, buyer.name);
              }}
            >
              Edit
            </span>
          </td>
          <td className="display-order">
            <Button
            className="px-2 py-2"
              onClick={(e) => {
                if (i !== 0) {
                  changeOrder(true, buyer.id, buyer.displayOrder);
                }
              }}
            >
              <i className="fas fa-arrow-up"></i> Up
            </Button>
            <Button
            className="px-2 py-2"
            onClick={(e) => {
                if (i !== props.buyers.allBuyers.length - 1) {
                  changeOrder(false, buyer.id, buyer.displayOrder);
                }
              }}
            >
              {" "}
              <i className="fas fa-arrow-down"></i> Down
            </Button>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Buyers List</h1>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="6" className="search-left-btn">
                <Button id ="add-new" color="primary" onClick={toggleAdd}>
                  Add New+
                </Button>
              </Col>
            </Row>
            <Table className="ticket-table table-listing mb-5" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Name</th>
                  <th scope="col">Enabled</th>
                  <th scope="col">Type</th>
                  <th scope="col">Edit</th>
                  <th scope="col">Order</th>
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
      {addModal && (
        <AddNewBuyerModal {...props} show={addModal} toggle={toggleAdd} />
      )}
      {editModal && (
        <EditBuyerModal
          {...props}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    buyers: state.buyersReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchBuyerListing: (params) => dispatch(fetchBuyerListing(params)),
    addUpdatedData: (data) => dispatch(fetchBuyerListingSuccess(data)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Buyers);
