import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";
import Moment from "react-moment";

function DailyTally(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const { match: { params } } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        load();
    }, []);

    if (data.error && !unauthorized) {
        if (data.error.status === 403) {
          setUnauthorized(true);
        } else if (data.error.status === 440) {
          toast.error(data.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

  const load = () => {
    GetApiActionWithAuthorization("common/daily-tally-data", params)
    .then((res) => {
        setData(res.data)
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

    const renderDailyTallyTableData = () => {
        return data.map((_data, i) => {
            return (
                <>
                    <tr>
                        <td scope="col">{_data.type[0] + _data.id}</td>
                        <td scope="col">${_data.ticketAmount}</td>
                        <td scope="col">${_data.profit}</td>
                        <td scope="col">
                            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                                {_data.ticketTime.toString().length === 10
                                ? _data.ticketTime * 1000
                                : _data.ticketTime}
                            </Moment>
                        </td>
                        <td scope="col">{_data.soldTo}</td>
                        <td scope="col">{_data.soldToAmount>0 ? _data.soldToAmount : ''}</td>
                        <td scope="col">{_data.comment}</td>
                    </tr>
                </>
            )
        }); 
    };

    const renderDailyTallyFooterTableData = () => {
        let totalProfit = data.map(d=>d.profit).reduce((a, b) => a + b, 0);
        let totalAmount = data.map(d=>d.ticketAmount).reduce((a, b) => a + b, 0);
        let totalSoldToAmount = data.map(d=>d.soldToAmount).reduce((a, b) => (a ? a : 0) + (b ? b : 0), 0);
        return data.length > 0 ? (
            <>
                <tr>
                    <td scope="col">Total</td>
                    <td scope="col">${totalAmount}</td>
                    <td scope="col">${totalProfit}</td>
                    <td scope="col"></td>
                    <td scope="col"></td>
                    <td scope="col">${totalSoldToAmount}</td>
                    <td scope="col"></td>
                </tr>
            </>
        ) : (<></>)
    }

    //
    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container
            className="mt--9 main-content profit-main-content"
            fluid>
            <Card className="card-height shadow">
              <CardBody className="checks-main-content">
                <Table
                    className="ticket-table table-listing items-list-table"
                    responsive
                    bordered>
                    <thead>
                        <tr>
                            <th scope="col">Ticket</th>
                            <th scope="col">Ticket Amount</th>
                            <th scope="col">Profit</th>
                            <th scope="col">Date</th>
                            <th scope="col">Sold To</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderDailyTallyTableData()}
                        {renderDailyTallyFooterTableData()}
                    </tbody>
                </Table>
              </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(DailyTally);