import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Card,
  CardBody,
  Button,
  Table,
  Row,
  Col,
  CardHeader,
  Label,
} from "reactstrap";
import Unauthorized from "../Pages/Unauthorized";
import Header from "../../components/Headers/Header";
import Moment from "react-moment";
import CreateNewUserModal from "../Modal/Users/CreateNewUserModal";
import { connect } from "react-redux";
import { fetchUserListing } from "../../redux/userManagement/userManagementAction";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import UpdateUserModal from "views/Modal/Users/UpdateUserModal";
import { DropdownList } from "react-widgets";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unauthorized: false,
      showUpdateUser: false,
      showCreateUser: false,
      dropLoading:false,
      groups:[],
      filterGroupId:"",
      editProps: {},
    };
  }
  componentDidMount() {
    this.props.fetchUserListing("all");
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { error } = props.userManage;
    if (error) {
      if (error.status === 403) {
        this.setState({ unauthorized: true });
      } else if (error.status === 440) {
        this.props.resetState();
        toast.error(error.message, { position: toast.POSITION.TOP_CENTER });
        this.props.history.push("/auth/login");
      }
    }
  }
  /*_________________________Create User Modal Functions________________________ */

  //Toggle create group modal
  createUserToggle = (e) => {
    this.setState({
      showCreateUser: !this.state.showCreateUser,
    });
  };
  /*_____________________________________________________________________________*/

  /*_________________________Update User Modal Functions________________________ */

  //Toggle create group modal
  updateUserToggle = (e) => {
    this.setState({
      showUpdateUser: !this.state.showUpdateUser,
      editProps: {},
    });
  };
  renderEditData(props) {
    this.setState({
      showUpdateUser: !this.state.showUpdateUser,
      editProps: props,
    });
  }
  /*_____________________________________________________________________________*/

  getGroups = e =>{
    if (e && this.state.groups.length<1) {
      document.getElementById("")
      this.setState({ dropLoading: true });
      GetApiActionWithAuthorization("users/all-users-groups")
        .then((res) => {
          let defaultGroup = [{id:"all",name:"ALL"}]
          this.setState({
            groups: defaultGroup.concat(res.data),
            dropLoading: false,
          });
        })
        .catch((err) => {
          if (err.status === 440) {
            this.props.history.push("/auth/login");
          }
        });
    }
  }

  changeFilter = e => {
    this.props.fetchUserListing(e.id);
  }
  //Render table Data into the <td>
  renderTableData() {
    return this.props.userManage.allUsers.map((user, i) => {
      const {
        first_name,
        last_name,
        email,
        group,
        created_at,
        last_login,
        username,
        phone,
        id,
      } = user;
      return (
        <tr key={i}>
          <td>{username}</td>
          <td>{email}</td>
          <td>{group.name}</td>
          <td style={{ whiteSpace: "normal" }}>
            {first_name + " " + last_name}
          </td>
          <td style={{ whiteSpace: "normal" }}>
            {last_login ? (
              <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                {last_login}
              </Moment>
            ) : (
              "Null"
            )}
          </td>
          <td style={{ whiteSpace: "normal" }}>
            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
              {created_at}
            </Moment>
          </td>
          <td>
            <span
              style={{ cursor: "pointer" }}
              title="Edit Details"
              onClick={() => {
                this.renderEditData({
                  first_name,
                  last_name,
                  email,
                  group,
                  username,
                  phone,
                  id,
                });
              }}
            >
              <i className="fas fa-edit mr-1"></i>Edit
            </span>
          </td>
        </tr>
      );
    });
  }

  render() {
    return this.state.unauthorized ? (
      <Unauthorized />
    ) : (
      <>
        <Header />
        <Container className="mt--9 main-content" fluid>
          <Card className="card-height shadow">
            <CardHeader>
              <div className="text-center">
                <h1>All Users</h1>
              </div>
            </CardHeader>
            <CardBody>
              <Row className="align-items-center">
                <Col sm="7">
                  <Button
                    id="create-user"
                    color="primary"
                    className="my-2"
                    onClick={this.createUserToggle}
                  >
                    Create New User
                  </Button>
                  <a href="/portal/user-management/groups">
                    <Button color="primary" id="navigate-group">User Groups or Role</Button>
                  </a>
                </Col>
                <Col sm="5">
                  <div className="d-flex align-items-center justify-content-end mr-3 filter">
                    <Label className="m-0">Filter By:</Label>
                    <DropdownList
                      data={this.state.groups}
                      textField="name"
                      containerClassName="filterBy"
                      placeholder="Select Group"
                      onChange={this.changeFilter}
                      onToggle={this.getGroups}
                      busy={this.state.dropLoading}
                    />
                  </div>
                </Col>
              </Row>
              <Table className="align-items-center mt-3 table-listing" responsive bordered>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">User Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Group or Role</th>
                    <th scope="col">Full Name</th>
                    <th scope="col">Last Login</th>
                    <th scope="col">Created On</th>
                    <th scope="col">Edit</th>
                  </tr>
                </thead>
                <tbody>{this.renderTableData()}</tbody>
              </Table>
            </CardBody>
          </Card>
        </Container>

        {/* Modals Used_________________________ */}
        {this.state.showCreateUser && (
          <CreateNewUserModal
            {...this.props}
            show={this.state.showCreateUser}
            toggle={this.createUserToggle}
          />
        )}
        {this.state.showUpdateUser && (
          <UpdateUserModal
            {...this.props}
            show={this.state.showUpdateUser}
            userProps={this.state.editProps}
            toggle={this.updateUserToggle}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userManage: state.userManagementReducer.usersListing,
    loading: state.commonReducer.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserListing: (body) => dispatch(fetchUserListing(body)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);

