import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from "reactstrap";

function AddNewClerkModal(props) {
  const [c_name, setCname] = useState("");
  const [loading, setLoading] = useState(false);

  const addClerkHandler = (e) => {
    let body = {
      name: c_name.trim(),
    };
    setLoading(true);
    PostApiWithAuthorizationAction("common/add-new-clerk", body)
      .then((res) => {
        setLoading(false);
        //toast.success(res.message);
        props.fetchClerksListing();
        props.toggle();
      })
      .catch((err) => {
        setLoading(false);
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Add new Clerk</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="form-control-label" for="c_name">
            Clerk Name
          </Label>
          <Input
          id="c_name"
            type="text"
            placeholder="Clerk Name"
            value={c_name}
            onChange={(e) => setCname(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {loading ? (
          <Button color="primary" disabled>
            Adding <i className="fas fa-spinner fa-spin"></i>
          </Button>
        ) : (
          <>
            <Button
            id="add-btn"
              color="primary"
              onClick={addClerkHandler}
              disabled={c_name.length === 0}
            >
              Add
            </Button>{" "}
            <Button color="secondary" onClick={props.toggle}>
              Cancel
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default AddNewClerkModal;
