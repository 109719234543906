import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  CustomInput,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardTitle,
  CardText
} from "reactstrap";
import classnames from 'classnames';
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import {
  fetchAllItemsListing,
  fetchItemsListingSuccess
} from "../../redux/CoinItems/coinItemsAction";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { Link } from "react-router-dom";
import AddItemsModal from "views/Modal/CoinItems/AddItemsModal";
import EditItemModal from "views/Modal/CoinItems/EditItemModal";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import { itemTypeOptions } from "constants/ticketConstants";
import qs from "query-string";

function CoinItems(props) {
  let view = qs.parse(props.location.search, { ignoreQueryPrefix: true }).view;
  view = (itemTypeOptions.map(i=>i.value).includes(view)) ? view : 'coin';
  const [unauthorized, setUnauthorized] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});

  const [activeTab, setActiveTab] = useState((itemTypeOptions.findIndex(i=>i.value===view)+1).toString() || '1');
  const [activeTabLabel, setActiveTabLabel] = useState(view);

  const toggleTab = tab => {
    if(activeTab !== tab) {
      setActiveTab(tab);
      let label = itemTypeOptions[tab-1].value;
      setActiveTabLabel(label);
      props.fetchAllItemsListing(label);
      props.history.push("/portal/items?view=" + label);
    }
  }

  useEffect(() => {
    //coin load
    props.fetchAllItemsListing('coin');
    //metro load
    props.fetchAllItemsListing('metro');
  }, []);

  if (props.coinItems.error && !unauthorized) {
    if (props.coinItems.error.status === 403) {
      setUnauthorized(true);
    } else if (props.coinItems.error.status === 440) {
      props.resetState();
      toast.error(props.coinItems.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleAdd = (e) => {
    setAddModal(!addModal);
  };

  const toggleEdit = (id = "", name = "", category = "", itemType) => {
    setEditData({ id, name, category, itemType });
    setEditModal(!editModal);
  };

  const updateMetalStatus = (e, id) => {
    let body = {
      isMetal: e.target.checked,
      id: id,
      itemType: activeTabLabel
    };
    PostApiWithAuthorizationAction("coin-tickets/update-coin-item", body)
      .then((res) => {
        props.addUpdatedData(res.data, activeTabLabel);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const updateEnableStatus = (e, id) => {
    let body = {
      enabled: e.target.checked,
      id: id,
      itemType: activeTabLabel
    };
    PostApiWithAuthorizationAction("coin-tickets/update-coin-item", body)
      .then((res) => {
        props.addUpdatedData(res.data, activeTabLabel);
      })
      .catch((err) => {
        
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const updateTaxableStatus = (e, id) => {
    let body = {
      taxable: e.target.checked,
      id: id,
      itemType: activeTabLabel
    };
    PostApiWithAuthorizationAction("coin-tickets/update-coin-item", body)
      .then((res) => {
        props.addUpdatedData(res.data, activeTabLabel);
      })
      .catch((err) => {
        
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const changeOrder = (decrease, id, order) => {
    let body = { decrease, id, order,type:"items", itemType: activeTabLabel };
    PostApiWithAuthorizationAction("common/change-order", body)
      .then((res) => {
        props.addUpdatedData(res.data, activeTabLabel);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderMetroItemsTableData = (e) => {
    return props.metroItems.allItems.map((item, i) => {
      return (
        <tr key={i}>
          <td>{item.name}</td>
          <td>{item.item_category ? item.item_category.categoryName : '-'}</td>
          <td>
            <CustomInput
              type="switch"
              id={"showEnableMetroItemCustomSwitch" + i}
              name="enabled"
              checked={item.enabled}
              onChange={(e) => updateEnableStatus(e, item.id)}
            />
          </td>
          <td>
            <CustomInput
              type="switch"
              id={"showMetalMetroItemCustomSwitch" + i}
              name="isMetal"
              checked={item.isMetal}
              onChange={(e) => updateMetalStatus(e, item.id)}
            />
          </td>
          <td>
            <span
            id={"update-item"+i}
              className="delete-item"
              onClick={(e) => {
                toggleEdit(item.id, item.name, item.category, item.itemType);
              }}
            >
              Edit
            </span>
          </td>
          <td className="display-order">
            <Button
            className="px-2 py-2"
            onClick={(e) => {
                if (i !== 0) {
                  changeOrder(true, item.id, item.displayOrder);
                }
              }}
            >
              <i className="fas fa-arrow-up"></i> Up
            </Button>
            <Button
            className="px-2 py-2"
            onClick={(e) => {
                if (i !== props.coinItems.allItems.length - 1) {
                  changeOrder(false, item.id, item.displayOrder);
                }
              }}
            >
              {" "}
              <i className="fas fa-arrow-down"></i> Down
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderTicketsTableData = (e) => {
    return props.coinItems.allItems.map((item, i) => {
      return (
        <tr key={i}>
          <td>{item.name}</td>
          <td>{item.item_category ? item.item_category.categoryName : '-'}</td>
          <td>
            <CustomInput
              type="switch"
              id={"showEnableItemCustomSwitch" + i}
              name="enabled"
              checked={item.enabled}
              onChange={(e) => updateEnableStatus(e, item.id)}
            />
          </td>
          <td>
            <CustomInput
              type="switch"
              id={"showEnableBuyerCustomSwitch" + i}
              name="enabled"
              checked={item.taxable}
              onChange={(e) => updateTaxableStatus(e, item.id)}
            />
          </td>
          <td>
            <span
            id={"update-item"+i}
              className="delete-item"
              onClick={(e) => {
                toggleEdit(item.id, item.name, item.category, item.itemType);
              }}
            >
              Edit
            </span>
          </td>
          <td className="display-order">
            <Button
            className="px-2 py-2"
            onClick={(e) => {
                if (i !== 0) {
                  changeOrder(true, item.id, item.displayOrder);
                }
              }}
            >
              <i className="fas fa-arrow-up"></i> Up
            </Button>
            <Button
            className="px-2 py-2"
            onClick={(e) => {
                if (i !== props.coinItems.allItems.length - 1) {
                  changeOrder(false, item.id, item.displayOrder);
                }
              }}
            >
              {" "}
              <i className="fas fa-arrow-down"></i> Down
            </Button>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Items</h1>
            </div>
          </CardHeader>
          <CardBody>
            <Nav tabs className="nav-tabs-c">
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1', 'py-2 px-4 mr-3 font-weight-bold box-shadow-none': true, 'btn-primary': activeTab === '1'})}
                  onClick={() => { toggleTab('1'); }}
                >
                  Coin
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2', 'py-2 px-4 font-weight-bold box-shadow-none': true, 'btn-primary': activeTab === '2'})}
                  onClick={() => { toggleTab('2'); }}
                >
                  Metro
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row className="my-1">
                  <Col md="12" className="search-left-btn text-right mb-0">
                    <Button id="add-new" color="primary" onClick={toggleAdd}>
                      Add New+
                    </Button>
                    <a id="category" href="/portal/coin-items/categories">
                      <Button color="primary">Categories</Button>
                    </a>
                  </Col>
                </Row>
                <Table className="coin-table table-listing border-top-none" responsive bordered>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Category</th>
                        <th scope="col">Enabled</th>
                        <th scope="col">Taxable</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Order</th>
                      </tr>
                    </thead>
                    <tbody>{renderTicketsTableData()}</tbody>
                  </Table>
              </TabPane>
              <TabPane tabId="2">
                <Row className="my-1">
                  <Col md="12" className="search-left-btn text-right mb-0">
                  <Button id="add-new" color="primary" onClick={toggleAdd}>
                      Add New+
                    </Button>
                    <a id="category" href="/portal/coin-items/categories">
                      <Button color="primary">Categories</Button>
                    </a>
                  </Col>
                </Row>
                <Table className="coin-table table-listing border-top-none" responsive bordered>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Category</th>
                        <th scope="col">Enabled</th>
                        <th scope="col">Metal</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Order</th>
                      </tr>
                    </thead>
                    <tbody>{renderMetroItemsTableData()}</tbody>
                  </Table>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
      {addModal && (
        <AddItemsModal
          {...props}
          show={addModal}
          toggle={toggleAdd}
          activeTabLabel={activeTabLabel} />
      )}
      {editModal && (
        <EditItemModal
          {...props}
          show={editModal}
          editData={editData}
          activeTabLabel={activeTabLabel}
          toggle={toggleEdit}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    coinItems: state.coinItemsReducer.coinItems,
    metroItems: state.coinItemsReducer.metroItems
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllItemsListing: (itemType) => dispatch(fetchAllItemsListing(itemType)),
    addUpdatedData: (data, activeTabLabel) => dispatch(fetchItemsListingSuccess(data, activeTabLabel)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoinItems);
