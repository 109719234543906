import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

function EditFromOptionModal(props) {
  const [name, setName] = useState(props.editData.name);

  const updateOption = (e) => {
    let body = {
      id: props.editData.id,
      name: name,
    };
    PostApiWithAuthorizationAction("common/edit-from-option-render-data", body)
      .then((res) => {
        //toast.success(res.message);
        props.addUpdatedData(res.data);
        props.toggle();
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Update Option</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="form-control-label" for="name">
            Name
          </Label>
          <Input
            id={"option_name"}
            type="text"
            placeholder="Option Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          id="update-btn"
          color="primary"
          onClick={updateOption}
          disabled={name.length === 0}
        >
          Update
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditFromOptionModal;
