import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FormGroup, Input, Table } from "reactstrap";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";

function ViewOnlyItems(props) {
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");

  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  //----- After delete modal confirm successfully -------
  const confirmed = (e) => {
    GetApiActionWithAuthorization(
      "metro-tickets/delete-item-from-ticket/" +
        delId +
        "/" +
        props.match.params.id
    )
      .then((res) => {
        props.fetchTicketItemsListingSuccess({
          data: res.data,
          ticketDetails: res.ticketDetails,
        });
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        setConfirm(false);
      })
      .catch((err) => {
        if (err.status === 403) {
          props.setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  //-------- Sold to check box handler of items-----------------
  const checkBoxChangeHandler = (e, name, id) => {
    const { checked } = e.target;
    let body = {
      ticketId: props.match.params.id,
      itemId: id,
    };
    if (checked) {
      body.soldTo = name;
    } else {
      body.soldTo = "";
    }

    PostApiWithAuthorizationAction("metro-tickets/edit-items-send-items", body)
      .then((res) => {
        props.fetchTicketItemsListingSuccess({
          data: res.data,
          ticketDetails: res.ticketDetails,
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          props.setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const selectAllOfSoldTo = (value) => {
    let body = {
      ticketId: props.match.params.id,
      selectAllOnly: true,
      soldTo: value,
    };
    PostApiWithAuthorizationAction("metro-tickets/edit-items-send-items", body)
      .then((res) => {
        props.fetchTicketItemsListingSuccess({
          data: res.data,
          ticketDetails: res.ticketDetails,
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          props.setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

const getSearchableContent = (description)=>{
    return description.split(". ")[1]
    ? description.split(". ")[1].trim()
    : "";
 
}

  return (
    <>
      <Table className="metro-items-listing mb-5" bordered responsive="sm">
        <thead>
          <tr>
            <th style={{ width: "60px" }}> Type</th>
            <th style={{ minWidth: "300px" }}>Item Description</th>
            <th style={{ width: "80px" }}>Model</th>
            <th style={{ width: "80px" }}>Serial</th>
            <th
              style={{ width: "60px" }}
              className="pointer"
              onClick={(e) => selectAllOfSoldTo("A")}
            >
              Sold to A
            </th>
            <th
              style={{ width: "60px" }}
              className="pointer"
              onClick={(e) => selectAllOfSoldTo("B")}
            >
              Sold to B
            </th>
            {/* <th style={{ width: "50px" }}>Edit</th> */}
            <th style={{ width: "60px" }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {props.items.items.map((item, i) => {
            if (props.search) {
              if (getSearchableContent(item.description).includes(props.search.trim())) {
                return (
                  <tr key={i} style={{background: props.highlightId == item.id ? '#eee' : '#fff'}} id={"row-" + item.id}>
                    <td>{item.propertyCode === "3" ? "Jewelry" : "Misc"}</td>
                    <td style={{ whiteSpace: "normal" }}>
                    {item.description.replace(/.\s\(Weight:[\s\S]*?\)/g, '')}
                    </td>
                    <td>{item.model}</td>
                    <td>{item.serial}</td>
                    <td>
                      <input
                        type="checkbox"
                        name="a"
                        checked={item.soldTo.toLowerCase() === "a"}
                        onChange={(e) => checkBoxChangeHandler(e, "A", item.id)}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        name="b"
                        checked={item.soldTo.toLowerCase() === "b"}
                        onChange={(e) => checkBoxChangeHandler(e, "B", item.id)}
                      />
                    </td>
                    <td>
                      <a href="#" onClick={(e) => toggleConfirm(item.id)}>
                        Delete
                      </a>
                    </td>
                  </tr>
                );
              } else {
                return null;
              }
            } else {
              return (
                <tr key={i} style={{background: props.highlightId == item.id ? '#eee' : '#fff'}} id={"row-" + item.id}>
                  <td>{item.propertyCode === "3" ? "Jewelry" : "Misc"}</td>
                  <td style={{ whiteSpace: "normal" }}>
                    {item.description.replace(/.\s\(Weight:[\s\S]*?\)/g, '')}
                  </td>
                  <td>{item.model}</td>
                  <td>{item.serial}</td>
                  <td>
                    <input
                      type="checkbox"
                      name="a"
                      checked={item.soldTo.toLowerCase() === "a"}
                      onChange={(e) => checkBoxChangeHandler(e, "A", item.id)}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="b"
                      checked={item.soldTo.toLowerCase() === "b"}
                      onChange={(e) => checkBoxChangeHandler(e, "B", item.id)}
                    />
                  </td>
                  <td>
                    <a href="#" onClick={(e) => toggleConfirm(item.id)}>
                      Delete
                    </a>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
    </>
  );
}

export default ViewOnlyItems;
