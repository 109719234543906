//Items Listing
export const FETCH_COIN_ITEMS_P_LISTING_SUCCESS = "FETCH_COIN_ITEMS_P_LISTING_SUCCESS";
export const FETCH_METRO_ITEMS_P_LISTING_SUCCESS = "FETCH_METRO_ITEMS_P_LISTING_SUCCESS";
export const FETCH_COIN_ITEMS_P_LISTING_FAILURE = "FETCH_COIN_ITEMS_P_LISTING_FAILURE";
export const FETCH_METRO_ITEMS_P_LISTING_FAILURE = "FETCH_METRO_ITEMS_P_LISTING_FAILURE";
export const ITEM_TYPE_COIN = "coin";
export const ITEM_TYPE_METRO = "metro";

//Items Categories Listing
export const FETCH_ITEMS_CATEGORY_LISTING_SUCCESS =
  "FETCH_ITEMS_CATEGORY_LISTING_SUCCESS";
export const FETCH_ITEMS_CATEGORY_LISTING_FAILURE =
  "FETCH_ITEMS_CATEGORY_LISTING_FAILURE";

export const RESET = "RESET";
