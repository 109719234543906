import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from "reactstrap";

function AddNewPettyCashUserModal(props) {
  const [u_name, setUname] = useState("");
  const [loading, setLoading] = useState(false);

  const addPettyCashUserHandler = (e) => {
    let body = {
      name: u_name.trim(),
    };
    setLoading(true);
    PostApiWithAuthorizationAction("common/add-new-petty-cash-user", body)
      .then((res) => {
        setLoading(false);
        //toast.success(res.message);
        props.fetchPettyCashUsersListing();
        props.toggle();
      })
      .catch((err) => {
        setLoading(false);
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Add new Petty Cash User</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="form-control-label" for="u_name">
            User Name
          </Label>
          <Input
          id="u_name"
            type="text"
            placeholder="User Name"
            value={u_name}
            onChange={(e) => setUname(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {loading ? (
          <Button color="primary" disabled>
            Adding <i className="fas fa-spinner fa-spin"></i>
          </Button>
        ) : (
          <>
            <Button
            id="add-btn"
              color="primary"
              onClick={addPettyCashUserHandler}
              disabled={u_name.length === 0}
            >
              Add
            </Button>{" "}
            <Button color="secondary" onClick={props.toggle}>
              Cancel
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default AddNewPettyCashUserModal;
