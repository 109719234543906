import React from "react";
import {
  Modal,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Button,
} from "reactstrap";
import {
  PostApiWithAuthorizationAction,
} from "../../constantFunctions/apiActions";
import {
  getErrorMessages,
  formValid,
  getallErrors,
} from "../../constantFunctions/formValidations";
import { toast } from "react-toastify";

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      formValue: {
        new_password:"",
        old_password: "",
        confirm_password: ""
      },
      formErrors: {
        new_password:"",
        old_password: "",
        confirm_password: ""
      },
      loading: false,
      dropLoading: false,
    };
  }


  //Handle input changes
  handleChange = (e) => {
    const { name, value } = e.target;
    let formValues = this.state.formValue;
    formValues[name] = value;
    this.setState(
      {
        formValue: formValues,
      },
      () => {
        let formErrors = { ...this.state.formErrors };
        formErrors = getErrorMessages(formErrors, name, value);
        this.setState({ formErrors: formErrors });
        if (
          this.state.formValue.confirm_password !==
          this.state.formValue.new_password
        ) {
          formErrors.confirm_password = "*Passwords didn't match";
          this.setState({ formErrors: formErrors });
        } else {
          formErrors.confirm_password = "";
          this.setState({ formErrors: formErrors });
        }
      }
    );
  };

  //Reset form
  resetForm() {
    this.setState({
      formValue: {
        new_password:"",
        old_password: "",
        confirm_password: ""
      },
      formErrors: {
        new_password:"",
        old_password: "",
        confirm_password: ""
      },
    });
  }

  //Change password handle function
  changePasswordHandler = (e) => {
    e.preventDefault();
    if (formValid(this.state)) {
      let body = {
        newPassword:this.state.formValue.new_password,
        password: this.state.formValue.old_password
      };
      this.setState({ loading: true });
      PostApiWithAuthorizationAction("users/change-password", body)
        .then((res) => {
          this.setState({ loading: false });
          //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
          this.resetForm();
          this.props.toggle();
          localStorage.removeItem("token");
          this.props.history.push("/auth/login");
        //   this.props.fetchUserListing();
        })
        .catch((err) => {
          this.setState({ loading: false });
          console.log(err);
        });
    } else {
      let errors = getallErrors(this.state);
      this.setState({ formErrors: errors });
    }
  };

  render() {
    const { formErrors } = this.state;
    return (
      <Modal isOpen={this.props.show} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Change Password</ModalHeader>
        <ModalBody>
          <Form role="form" onSubmit={this.handleSubmit}>
            <Row>
            <Col md="12">
                <FormGroup className="mb-3">
                  <Label className="form-control-label" for="old_password">
                    Old Password
                  </Label>
                  <Input
                    id="old_password"
                    placeholder="Old Password"
                    type="password"
                    name="old_password"
                    value={this.state.formValue.old_password}
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  {formErrors.old_password.length > 0 && (
                    <span className="text-danger">
                      <small>{formErrors.old_password}</small>
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label className="form-control-label" for="new_password">
                    New Password
                  </Label>
                  <Input
                    id="new_password"
                    placeholder="New Password"
                    type="password"
                    name="new_password"
                    value={this.state.formValue.new_password}
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  {formErrors.new_password.length > 0 && (
                    <span className="text-danger">
                      <small>{formErrors.new_password}</small>
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup className="mb-3">
                  <Label className="form-control-label" for="confirm_password">
                    Confirm Password
                  </Label>
                  <Input
                    id="confirm_password"
                    placeholder="Confirm Password"
                    type="password"
                    name="confirm_password"
                    value={this.state.formValue.confirm_password}
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  {formErrors.confirm_password.length > 0 && (
                    <span className="text-danger">
                      <small>{formErrors.confirm_password}</small>
                    </span>
                  )}
                </FormGroup>
              </Col>
             
             </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          {this.state.loading ? (
            <Button color="primary" disabled>
              loading <i className="fas fa-spinner fa-spin"></i>
            </Button>
          ) : (
            <Button color="primary" onClick={this.changePasswordHandler}>
              Change
            </Button>
          )}{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.resetForm();
              this.props.toggle();
            }}
            disabled={this.state.loading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ChangePassword;
