import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  CustomInput,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import UpdateFullTransactionModal from "../Modal/Tranactions/UpdateFullTransactionModal";
import { fetchPettyCashReportListing } from "../../redux/PettyCashReports/pettyCashReportsAction";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import Moment from "react-moment";
import { DropdownList } from "react-widgets";
import { monthOptions, dayOptions } from "../../constants/ticketConstants";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import moment from "moment";

function PettyCashReport(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [years, setYears] = useState([]);
  const [updatePCTransactionModal, setPCTransactionUpdateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [filterData, setFilterData] = useState({
    startMonth: monthOptions.find(m=>m.value==(moment().subtract(1, 'months').format('M'))),
    startDay: (new Date()).getDate(),
    startYear: (new Date()).getFullYear(),
    endMonth: monthOptions.find(m=>m.value==((new Date()).getMonth()+1)),
    endDay: (new Date()).getDate(),
    endYear: (new Date).getFullYear(),
    type: "All"
  });

  useEffect(() => {
    GetApiActionWithAuthorization("invoices/get-all-years").then((res) => {
      setYears(res.data)
    });
    props.fetchPettyCashReportListing(getPageFilter());
  }, []);

  if (props.reports.error && !unauthorized) {
    if (props.reports.error.status === 403) {
      setUnauthorized(true);
    } else if (props.reports.error.status === 440) {
      props.resetState();
      toast.error(props.reports.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleEdit = (id = "") => {
    setEditData({ id });
    setEditModal(!editModal);
  };

  const removePrefix = function (string, prefix) {
    const hasPrefix = string.indexOf(prefix) === 0;
    return hasPrefix ? string.substr(prefix.length) : string.toString();
  }

  const toggleUpdatePCTransaction = (id = "") => {
    setEditData({ id });
    setPCTransactionUpdateModal(!updatePCTransactionModal);
  };

  const getPageFilter = () => {
    return {
      start: filterData.startMonth.value + "/" + filterData.startDay + "/" + filterData.startYear,
      end: filterData.endMonth.value + "/" + filterData.endDay + "/" + filterData.endYear,
      type: filterData.type
    };
  };

  const renderTicketsTableData = (e) => {
    return props.reports.allTransactions.map((tr, i) => {
      return (
        <tr key={i}>
          <td>{removePrefix(tr.comment.trim(), 'For:')}</td>
          <td>{tr.pettyWho}</td>
          <td>
            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
              {tr.time.toString().length === 10 ? tr.time * 1000 : tr.time}
            </Moment>
          </td>
          <td>
            $
            {tr.denom100 * 100 +
              tr.denom50 * 50 +
              tr.denom20 * 20 +
              tr.denom10 * 10 +
              tr.denom5 * 5 +
              tr.denom1 * 1}
          </td>
          <td>
            <span
              id={"update-transaction" + i}
              className="delete-item"
              onClick={(e) => {
                toggleEdit(tr.id);
              }}
            >
              View/Edit
            </span>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Petty Cash Reports</h1>
            </div>
          </CardHeader>
          <CardBody>
          <Row>
            <Col md="4" className="d-flex d-flex-full mb-2">
              <span class="mx-1" style={{fontSize: "22px"}}>Start: </span>
              <DropdownList
                data={monthOptions}
                value={filterData.startMonth}
                placeholder="Month"
                textField="label"
                onChange={(e) => {
                  setFilterData({
                    ... filterData,
                    startMonth: e
                  })
                }}
              />
              <div class="mx-1" style={{fontSize: "24px"}}> / </div>
              <DropdownList
                data={dayOptions}
                value={filterData.startDay}
                placeholder="Day"
                onChange={(e) => {
                  setFilterData({
                    ... filterData,
                    startDay: e
                  })
                }}
              />
            <span class="mx-1" style={{fontSize: "24px"}}> / </span>
            <DropdownList
              data={years}
              value={filterData.startYear}
              placeholder="Year"
              onChange={(e) => {
                setFilterData({
                  ... filterData,
                  startYear: e
                })
              }}
            />
            </Col>
            <Col md="4" className="d-flex d-flex-full mb-2">
              <span class="mx-1" style={{fontSize: "22px"}}>End: </span>
              <DropdownList
                data={monthOptions}
                value={filterData.endMonth}
                placeholder="Month"
                textField="label"
                onChange={(e) => {
                  setFilterData({
                    ... filterData,
                    endMonth: e
                  })
                }}
              />
            <div class="mx-1" style={{fontSize: "24px"}}> / </div>
            <DropdownList
              data={dayOptions}
              value={filterData.endDay}
              placeholder="Day"
              onChange={(e) => {
                setFilterData({
                  ... filterData,
                  endDay: e
                })
              }}
            />
            <span class="mx-1" style={{fontSize: "24px"}}> / </span>
            <DropdownList
              data={years}
              value={filterData.endYear}
              placeholder="Year"
              onChange={(e) => {
                setFilterData({
                  ... filterData,
                  endYear: e
                })
              }}
            />
              </Col>
              <Col md="2" className="d-flex d-flex-full mb-2">
              <span class="mx-1" style={{fontSize: "24px"}}>Type: </span>
                <DropdownList
                  data={["All", "Addition", "Substraction"]}
                  value={filterData.type}
                  placeholder="Type"
                  onChange={(e) => {
                    setFilterData({
                      ... filterData,
                      type: e
                    })
                  }}
                />
              </Col>
              <Col md="2" className="pt-1 mb-2">
                <Button
                  id="btn-get-worksheet"
                  size="sm"
                  className="ml-2"
                  onClick={(e) => {
                    props.fetchPettyCashReportListing(getPageFilter());
                  }}
                  color="primary">Get Reports</Button>                
              </Col>
            </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Comment</th>
                  <th scope="col">For Who</th>
                  <th scope="col">Transaction Date</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
      {editModal && (
        <UpdateFullTransactionModal
          {...props}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
          pettyCash={true}
          pettyCashFilter={getPageFilter}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    reports: state.pettyCashReportsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPettyCashReportListing: (filter) =>
      dispatch(fetchPettyCashReportListing(filter)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PettyCashReport);
