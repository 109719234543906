import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

function ViewInvoicePrint(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [loading, setLoading] = useState(false);
  const printPDF = () => {
    setLoading(true);
    PostApiWithAuthorizationAction(props.printButtonUrl, {
      station: localStorage.getItem('lastUsedPrinter') || 'station1'
    })
    .then((res) => {
      setLoading(false);
      props.toggle();
    })
    .catch((err) => {
      setLoading(false);
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  }
  //
  return (
      <Modal isOpen={props.show} toggle={props.toggle} size="lg">
        <ModalHeader toggle={props.toggle}>Invoice</ModalHeader>
        <ModalBody className="py-0" style={{height:'70vh'}}>
            {console.log(props.invoiceUrl)}
          <embed
            src={props.invoiceUrl}
            style={{width: "100%", height: "100%"}}
            type="application/pdf"/>
        </ModalBody>
        <ModalFooter className="py-2">
          <Button color="warning" onClick={printPDF}>
          <i class="fas fa-print"></i> Print
          </Button>
      </ModalFooter>
      </Modal>
    );
}
    
export default ViewInvoicePrint;
