import {
  FETCH_RECEIPT_LISTING_FAILURE,
  FETCH_RECEIPT_LISTING_SUCCESS,
  SAVE_RECEIPT_FORM_DETAILS,
  SAVE_ITEMS_DETAILS_IN_FORM,
  UPDATE_RECEIPT_DATA_AFTER_UPDATE,
  RESET_SAVE_RECEIPT_DETAILS
} from "./ReceiptTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________Receipt Listing __________________________________________________________________ */

//Api Call Function for Receipt listing
export const fetchReceiptListing = (page,search,sortBy,sortOrder,filterBy,todayOnly=true,dateFilter) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());

    GetApiActionWithAuthorization("receipts/get-all", {page,sortBy,sortOrder,search,filterByItem: filterBy ==="Receipt" ? 0:1,todayOnly,dateFilter})
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchReceiptListingSuccess({
          receipts: res.data,
          page, 
          count: res.count,
          totalReceiptCount: res.totalReceiptCount,
          totalDailyReceiptCount: res.totalDailyReceiptCount,
          totalReceiptCheckAmount: res.totalReceiptCheckAmount,
          totalReceiptCashAmount: res.totalReceiptCashAmount
        }));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchReceiptsListingFailure(err));
      });
  };
};

//Ticketlisting Success save Payload data to store
export const fetchReceiptListingSuccess = (payload) => {
  return {
    type: FETCH_RECEIPT_LISTING_SUCCESS,
    payload
  };
};

//Ticketlisting Failure save Payload
export const fetchReceiptsListingFailure = (error) => {
  return {
    type: FETCH_RECEIPT_LISTING_FAILURE,
    payload: error,
  };
};

//Coinlisting Failure save Payload
export const updateReceiptTableAfterUpdate = (receipts) => {
  return {
    type: UPDATE_RECEIPT_DATA_AFTER_UPDATE,
    payload: receipts,
  };
};
/*__________________________________________________________________________________________________________________________________________ */


export const saveReceiptFormDetails = ({ name, value }) => {
  return {
    type: SAVE_RECEIPT_FORM_DETAILS,
    payload: { name, value },
  };
};

export const saveItemsDetails = (items) => {
  return {
    type: SAVE_ITEMS_DETAILS_IN_FORM,
    payload: items,
  };
};


export const resetAddReceiptForm = () => {
  return {
    type: RESET_SAVE_RECEIPT_DETAILS,
  };
};

