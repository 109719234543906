//Stores Listing
export const FETCH_STORES_LISTING_SUCCESS = 'FETCH_STORES_LISTING_SUCCESS';
export const FETCH_STORES_LISTING_FAILURE = 'FETCH_STORES_LISTING_FAILURE';

//Clerk Listing
export const FETCH_CLERK_LISTING_SUCCESS = 'FETCH_CLERK_LISTING_SUCCESS';
export const FETCH_CLERK_LISTING_FAILURE = 'FETCH_CLERK_LISTING_FAILURE';

//COINs Listing
export const FETCH_COINS_LISTING_SUCCESS = 'FETCH_COINS_LISTING_SUCCESS';
export const FETCH_COINS_LISTING_FAILURE = 'FETCH_COINS_LISTING_FAILURE';
export const UPDATE_COIN_DATA_AFTER_UPDATE = 'UPDATE_COIN_DATA_AFTER_UPDATE';

//COIN Items

export const FETCH_COIN_ITEMS__LISTING_FAILURE = 'FETCH_COIN_ITEMS__LISTING_FAILURE';
export const FETCH_COIN_ITEMS_LISTING_SUCCESS = 'FETCH_COIN_ITEMS_LISTING_SUCCESS';

//COIN form Details
export const SAVE_COINS_FORM_DETAILS = 'SAVE_COINS_FORM_DETAILS';
export const SAVE_COINS_ITEMS_DETAILS = 'SAVE_COINS_ITEMS_DETAILS';
export const SAVE_COINS_BARTER_ITEMS_DETAILS = 'SAVE_COINS_BARTER_ITEMS_DETAILS';
export const RESET_SAVE_COINS_DETAILS = 'RESET_SAVE_COINS_DETAILS';
export const COPY_TICKETS_DETAILS_IN_COIN = 'COPY_TICKETS_DETAILS_IN_COIN';

//Coin Barter Items
export const FETCH_COIN_BARTER_ITEMS_LISTING_SUCCESS = 'FETCH_COIN_BARTER_ITEMS_LISTING_SUCCESS';
export const FETCH_COIN_BARTER_ITEMS_LISTING_FAILURE = 'FETCH_COIN_BARTER_ITEMS_LISTING_FAILURE';

export const RESET = 'RESET';
