import {
  FETCH_METALS_LISTING_SUCCESS,
  FETCH_METALS_LISTING_FAILURE,
  RESET,
} from "./metalsTypes";

const initialState = {
  allMetals: [],
  countTickets: 0,
  totalTicketAmount: 0,
  totalProfitAmount: 0,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_METALS_LISTING_SUCCESS:
      return {
        allMetals: action.payload.data,
        countTickets: action.payload.countTickets,
        totalTicketAmount: action.payload.totalTicketAmount,
        totalProfitAmount: action.payload.totalProfitAmount,
        error: null,
      };
    case FETCH_METALS_LISTING_FAILURE:
      return {
        allMetals: [],
        error: action.payload.data,
        countTickets: 0,
        totalTicketAmount: 0,
        totalProfitAmount: 0,
      };
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
