import {
  REQUEST_LOADER,
  RESET
} from "./commonTypes";

const initialState = {
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_LOADER:
      return {
        loading: action.payload.set,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
