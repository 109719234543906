import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { validateVoidReasonForm } from "../../../constantFunctions/formValidations";

function AddVoidReasonModal(props) {
  const [name, setName] = useState("");
  const addItemsHandler = (e) => {
    let body = {
        reason: name.trim()
    };
    let validation = validateVoidReasonForm(body);
    if (validation.success) {
      PostApiWithAuthorizationAction("common/create-void-reason", body)
      .then((res) => {
        if (res.err) {
          toast.error(res.err, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          props.fetchAllVoidReasons();
          props.toggle();
        }
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } else {
      toast.error(validation.err, {
        position: toast.POSITION.TOP_CENTER,
        closeOnClick: true,
        autoClose: 8000,
      });
    }
  };

  return (
    <Modal isOpen={props.show} toggle={props.toggle} autoFocus={false}>
      <ModalHeader toggle={props.toggle}>Add new Reason</ModalHeader>
      <ModalBody>
        <FormGroup className="mb-0">
          <Label className="form-control-label" for="b_name">
            Reason
          </Label>
          <Input
            autoComplete="off"
            autoFocus={true}
            id="name"
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
        id="add-btn"
          color="primary"
          onClick={addItemsHandler}
          disabled={name.length === 0}
        >
          Add
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddVoidReasonModal;
