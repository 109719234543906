import { stationOptions } from "constants/ticketConstants";
import React, { useEffect, useState } from "react";
import { DropdownList } from "react-widgets";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import routes from "../../routes";

function ChangeStationValue(props) {
  const [station, setStation] = useState("");

  useEffect(() => {
      setStation(
        stationOptions.find((e) => e.value === localStorage.getItem("lastUsedPrinter"))
      );
  }, []);
  
  return (
    <Modal isOpen={props.show} toggle={props.toggle} backdrop={false}>
      <ModalHeader>Select Station!</ModalHeader>
      <ModalBody>
      <DropdownList
          data={stationOptions}
          textField="label"
          value={station}
          placeholder="Select Station"
          onChange={(e) => {
            setStation(e);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={e => {
          localStorage.setItem("lastUsedPrinter", station.value);
          props.toggle();
          if (props.permissions.length > 0) {
            if (props.permissions.includes("dashboard")) {
              props.history.push("/portal/dashboard");
            } else {
              let rout = routes.find(
                (r) => r.accessCode === props.permissions[0]
              );
              props.history.push(rout.layout + rout.path);
            }
          } else {
            props.history.push("/portal/not-any-permissions");
          }
          }}>
          Save
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ChangeStationValue;
