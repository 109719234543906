import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { FormGroup, Input } from "reactstrap";
import Label from "reactstrap/lib/Label";
function UpdateIpModal(props) {
  const [ip, setIp] = useState("");
  const [comment, setComment] = useState("");
  // add-ip-to-whitelist
useEffect(()=>{
  setIp(props.editData.ip);
  setComment(props.editData.comment);
},[])
  const submitHandler = (e) => {
    if (ip) {
      PostApiWithAuthorizationAction("common/update-ip-in-whitelist", {
        ip:ip.trim(),
        id:props.editData.id,
        comment: comment.trim()
      })
        .then((res) => {
          //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
          props.toggle();
          props.fetchIpAddressListing();
        })
        .catch((err) => {
          if (err.status === 440) {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    } else {
      toast.error("Ip Address Field is Blank!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Update Ip Address!</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="form-control-label" for="ip">
            Ip Address*:
          </Label>
          <Input
            type="text"
            id="ip_add"
            placeholder="Ip Address"
            value={ip}
            onChange={(e) => setIp(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label" for="ip">
            Comment:
          </Label>
          <Input
            type="textarea"
            id="comment"
            placeholder="Owned By"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button id="update-btn" color="primary" onClick={submitHandler}>
          Update
        </Button>{" "}
        <Button color="secondary" onClick={(e) => props.toggle()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default UpdateIpModal;
