import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Input
} from "reactstrap";
import Collapsible from 'react-collapsible';
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import {
  fetchTransactionDailyCount,
  fetchTransactionDailyCountSuccess,
  saveItemsDetails,
  resetDailyCountForm
} from "../../redux/Transactions/transactionsAction";
import { validateDailyCountForm } from '../../constantFunctions/formValidations';
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";

function TransactionDaily(props) {
    const [unauthorized, setUnauthorized] = useState(false);

    useEffect(() => {
      props.resetDailyCountForm();
      props.fetchTransactionDailyCount();
    }, []);
  
    if (props.transactionDailyCount.error && !unauthorized) {
      if (props.transactionDailyCount.error.status === 403) {
        setUnauthorized(true);
      } else if (props.transactionDailyCount.error.status === 440) {
        props.resetState();
        toast.error(props.transactionDailyCount.error.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        props.history.push("/auth/login");
      }
    }

    const changeBalanceValue = (name, value, index) => {
      let temp = props.transactionDailyCount.transactionOptions;
      let itemObj = temp[index]["balances"];
      itemObj[name] = value;
      temp[index]["balances"] = itemObj;
      props.saveItemsDetails({
        transactionOptions: temp,
        meta: props.transactionDailyCount.meta
      });
    };

    const formSubmitHandler = (e) => {
      let items = props.transactionDailyCount.transactionOptions.map(o=>o.balances);
      let validation = validateDailyCountForm({
        items,
        denoms: props.transactionDailyCount.meta.denoms
      });
      if (validation.success) {
        PostApiWithAuthorizationAction("transactions/save-daily-count", {
          items: props.transactionDailyCount.transactionOptions
        })
          .then((res) => {
            //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
            props.resetDailyCountForm();
            props.history.push("/portal/transactions");
          })
          .catch((err) => {
            if (err.status === 403) {
              setUnauthorized(true);
            } else if (err.status === 440) {
              props.resetState();
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            }
          });
      } else {
        toast.error(validation.err, {
          position: toast.POSITION.TOP_CENTER,
          closeOnClick: true,
        });
      }
    };

    const renderDailyCount = (e) => {
      return props.transactionDailyCount.transactionOptions.map((transactionOption, i) => {
        return (
          <Collapsible trigger={transactionOption.name} open={true}>
            <Table className="create-invoice-table add-receipt-item-table items-list-table" bordered responsive="sm">
              <tbody>
              {(
                Object.keys(transactionOption.balances).map(function(denom, index) {
                  if (denom != 'denom50') {
                  return <tr>
                            <td className="font-weight-600" style={{fontSize: '16px', verticalAlign: 'middle'}}>
                              ${denom.replace('denom', '')}:
                            </td>
                            <td style={{verticalAlign: 'middle'}}>
                              {(props.userType !== 'drawer') && (
                                <Input
                                  id={"price-" + i + "-" + index}
                                  type="number"
                                  value={transactionOption.balances[denom]}
                                  onChange={(e) => {
                                    changeBalanceValue("denom" + denom.replace('denom', ''), e.target.value, i);
                                  }}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              )}
                              {(props.userType === 'drawer') && (
                                <h3 className="mb-0">{transactionOption.balances[denom]}</h3>
                              )}
                            </td>
                            <td className="font-weight-600" style={{fontSize: '16px', verticalAlign: 'middle'}}>
                              ${parseInt(denom.replace('denom', '')) * parseInt(transactionOption.balances[denom])}
                            </td>
                            {(transactionOption.slug.includes('vault') && (
                              <td className="font-weight-600" style={{fontSize: '16px', verticalAlign: 'middle'}}>
                                ${props.transactionDailyCount.meta.vault[index] - (parseInt(denom.replace('denom', '')) * parseInt(transactionOption.balances[denom]))}
                              </td>
                            ))}
                          </tr>
                }
            }))}
              <tr>
                <td className="font-weight-600" style={{fontSize: '16px', verticalAlign: 'middle'}} colSpan="2">Total:</td>
                <td className="font-weight-600" style={{fontSize: '16px', verticalAlign: 'middle'}}>
                ${Object.values(Object.keys(transactionOption.balances)
                                .map((denom, i)=>(parseInt(denom.replace('denom', '')) * parseInt(transactionOption.balances[denom]))))
                                .reduce((t, n) => t + n, 0)}
                </td>
                {(transactionOption.slug.includes('vault') && (<td></td>))}
              </tr>
              </tbody>
            </Table>
          </Collapsible>
        );
      })
    };

    return unauthorized ? (
        <Unauthorized />
    ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid id="daily-count-page">
            <Card className="card-height shadow">
                <CardHeader className="bg-white d-flex justify-content-between">
                    <h1>Daily Count</h1>
                    <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                        props.history.goBack();
                    }}
                    >
                    <i className="fas fa-chevron-left"></i> Back
                    </Button>
                </CardHeader>
                <CardBody>
                  <div className="px-lg-6">
                    <Row>
                      <Col md="4" className="mb-2">
                        {(props.userType !== 'drawer') && (
                          <>
                            <a href="/portal/transactions-petty" id="transaction-daily">
                              <Button
                                color="primary"
                                onClick={(e) => {}}
                              >
                                Petty Cash
                              </Button>
                            </a>
                          </>
                        )}
                      </Col>
                      <Col md="8">
                        {(props.transactionDailyCount.transactionOptions.length > 0 && (
                          <div className="daily-count-total">
                            {(props.userType !== 'drawer') && (
                              <>
                            <div className="text-right" style={{fontSize: "16px"}}>
                              Metro Total:&nbsp;
                              <span className="font-weight-600">
                                ${props.transactionDailyCount.meta.metroTotal}
                              </span>
                            </div>
                            <div className="text-right" style={{fontSize: "16px"}}>
                              Coin Total:&nbsp;
                              <span className="font-weight-600">
                                ${props.transactionDailyCount.meta.coinTotal}
                              </span>
                            </div>
                            <div className="text-right" style={{fontSize: "16px"}}>
                              Coin Dep:&nbsp;
                              <span className="font-weight-600">
                                ${props.transactionDailyCount.meta.coinDeposit}
                              </span>
                            </div>
                            <div className="text-right" style={{fontSize: "16px"}}>
                              Coin Daily:&nbsp;
                              <span className="font-weight-600">
                                ${props.transactionDailyCount.meta.coinDailyTotal}
                              </span>
                            </div>
                            </>
                            )}
                            <div className="text-right" style={{fontSize: "16px"}}>
                              Coin Change:&nbsp;
                              <span className="font-weight-600">
                                ${props.transactionDailyCount.meta.coinChangeTotal}
                              </span>
                            </div>
                            {(props.userType !== 'drawer') && (
                              <>
                                <div className="text-right" style={{fontSize: "16px"}}>
                                  Jewelry Dep:&nbsp;
                                  <span className="font-weight-600">
                                    ${props.transactionDailyCount.meta.jewelryDeposit}
                                  </span>
                                </div>
                                <div className="text-right" style={{fontSize: "16px"}}>
                                  Jewelry Change:&nbsp;
                                  <span className="font-weight-600">
                                    ${props.transactionDailyCount.meta.jewelryChangeTotal}
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <div className="">
                      {renderDailyCount()}
                    </div>
                    {(props.transactionDailyCount.transactionOptions.length > 0
                      && props.userType !== 'drawer' && (
                      <div className="mt-2 text-right">
                        <Button
                          id="confirm-daily-count"
                          color="primary"
                          title="Confirm"
                          onClick={formSubmitHandler}
                        >Confirm</Button>
                      </div>
                    ))}
                  </div>
                </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
      transactionDailyCount: state.transactionDailyCountReducer.transactionDailyCount,
      userType: state.loginUserReducer.userType
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      fetchTransactionDailyCount: () => dispatch(fetchTransactionDailyCount()),
      addUpdatedData: (data) => dispatch(fetchTransactionDailyCountSuccess(data)),
      saveItemsDetails: (body) => dispatch(saveItemsDetails(body)),
      resetState: () => dispatch(resetState()),
      resetDailyCountForm: () => dispatch(resetDailyCountForm()),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(TransactionDaily);