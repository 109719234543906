import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from "reactstrap";

function AddNewBuyerModal(props) {
  const [b_name, setBname] = useState("");
  const [enabled, setEnabled] = useState(true);

  const addBuyerHandler = (e) => {
    let body = {
      name: b_name,
      enabled: enabled,
    };
    PostApiWithAuthorizationAction("common/add-new-buyers", body)
      .then((res) => {
        //toast.success(res.message);
        props.fetchBuyerListing();
        props.toggle();
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } 
      });
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Add new Buyer</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="form-control-label" for="b_name">
            Buyer Name
          </Label>
          <Input
          id="b_name"
            type="text"
            placeholder="Buyer Name"
            value={b_name}
            onChange={(e) => setBname(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>

        <FormGroup>
          <Label className="form-control-label" for="b_name">
            {" "}
            Enabled
          </Label>
          <CustomInput
            type="switch"
            id="enableBuyerCustomSwitch"
            name="enabled"
            checked={enabled}
            onChange={(e) => {
              setEnabled(e.target.checked);
            }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
        id="add-btn"
          color="primary"
          onClick={addBuyerHandler}
          disabled={b_name.length === 0}
        >
          Add
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddNewBuyerModal;
