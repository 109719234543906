import {
    FETCH_IP_ADDRESS_LISTING_FAILURE,
    FETCH_IP_ADDRESS_LISTING_SUCCESS,
    RESET,
  } from "./ipAddressTypes";
  
  const initialState = {
    allIpAddresses: [],
    error: null,
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_IP_ADDRESS_LISTING_SUCCESS:
        return {
          allIpAddresses: action.payload,
          error: null,
        };
      case FETCH_IP_ADDRESS_LISTING_FAILURE:
        return {
          allIpAddresses: [],
          error: action.payload,
        };
      case RESET: {
        return initialState;
      }
      default:
        return state;
    }
  };
  
  export default reducer;
  