import {
  FETCH_CRYPTO_LISTING_FAILURE,
  FETCH_CRYPTO_LISTING_SUCCESS,
} from "./CryptoTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________Crypto Listing __________________________________________________________________ */

//Api Call Function for Crypto listing
export const fetchCryptoListing = (startDate,endDate) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());

    GetApiActionWithAuthorization("common/get-all-crypto-details", {startDate,endDate})
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchCryptoListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchCryptoListingFailure(err));
      });
  };
};

//Check listing Success save Payload data to store
export const fetchCryptoListingSuccess = (cryptoData) => {
  return {
    type: FETCH_CRYPTO_LISTING_SUCCESS,
    payload: cryptoData,
  };
};

//Check listing Failure save Payload
export const fetchCryptoListingFailure = (error) => {
  return {
    type: FETCH_CRYPTO_LISTING_FAILURE,
    payload: error,
  };
};

/*__________________________________________________________________________________________________________________________________________ */



