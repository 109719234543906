import React, { useEffect, useRef, useState } from "react";
import Header from "components/Headers/Header";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import SignatureCanvas from "react-signature-canvas";
import ChangeBoothForSigPad from "views/Modal/ChangeBoothForSigPad";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import btc_qr from "../../assets/img/btc_qr.png";
import eth_qr from "../../assets/img/eth_qr.png";
// reactstrap components

function SignaturePad(props) {
  const sigCanvas = useRef({});
  const [changeBooth, setChangeBooth] = useState(false);
  const [changeInterval, setChangeInterval] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isThank, setIsThank] = useState(false);
  const [exist, setExist] = useState(false);
  const [id, setId] = useState("");
  const [ticket, setTicket] = useState({});
  const [unauthorized, setUnauthorized] = useState(false);
  const [type, setType] = useState("");
  const [autoSave, setAutosave] = useState(false);
  const [cryptoExist, setCryptoExist] = useState(false);
  const [showQR, setShowQR] = useState({});
  const [showTerms, setShowTerms] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("booth")) {
      setChangeBooth(true);
    }
  }, []);

  useEffect(() => {
    let inter;
    try {
      inter = setInterval(async () => {
        PostApiWithAuthorizationAction("common/get-signature-info", {
          booth: localStorage.getItem("booth"),
          id: id,
          exist: exist,
        })
          .then((res) => {
            console.log(res.data)
            if (!exist) {
              setId(res.data.id);
              setType(res.data.type);
              setExist(res.data.exist);
              setTicket(res.data.data)
            } else {
              if (res.status === 400) {
                setId("");
                setType("");
                setExist(false);
                setTicket({})
              }
            }
          })
          .catch((err) => {
            if (err.status === 403) {
              setUnauthorized(true);
            } else if (err.status === 440) {
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            }
          });
      }, 30000);
    } catch (e) {
      console.log(e);
    }
    return () => {
      clearInterval(inter);
    };
  }, [changeInterval]);

  //for Count down
  useEffect(() => {
    count > 0 && setTimeout(() => setCount(count - 1), 1000);
  }, [count]);
  const clear = (e) => sigCanvas.current.clear();

  const save = (e) => {
    //check signature canvas empty or not
    if (!sigCanvas.current.isEmpty()) {
      //If signature canvas exist
      if (exist) {
        let body = {
          id: id,
          type: type,
          station: localStorage.getItem("lastUsedPrinter"),
          signature: sigCanvas.current
            .getTrimmedCanvas()
            .toDataURL("image/png"),
        };
        setLoading(true);
        PostApiWithAuthorizationAction("common/save-signature", body)
          .then((res) => {
            setLoading(false);
            setExist(false);
            setId("");
            setType("");
            setChangeInterval(!changeInterval);

            if (res.cryptoExist.btcExist || res.cryptoExist.ethExist) {
              setCryptoExist(true);
              setShowQR(res.cryptoExist);
              setTimeout(() => {
                  setCryptoExist(false);
                  setShowQR({});
              }, 1000 * 60 * 10);
            }

            setIsThank(true);
            //on countdown
            setCount(10);

            setTimeout(() => {
              setIsThank(false);
            }, 10000);

            setAutosave(false);

            //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
          })
          .catch((err) => {
            setLoading(false);
            if (err.status === 403) {
              setUnauthorized(true);
            } else if (err.status === 440) {
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            } else {
              console.log(err);
              toast.error("Something went wrong! /n Please clear and retry", {
                position: toast.POSITION.TOP_CENTER,
              });
            }
          });
      }
    } else {
      toast.error("Signature Canvas is Empty!Please add Signature", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const toggleTerms = () => setShowTerms(!showTerms);

  const toggleBooth = (e) => {
    setChangeBooth(!changeBooth);
  };
  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      <Container className="mt--10 main-content" fluid>
        <Card className="card-height shadow back-grey">
          <CardHeader className="back-grey">
            <div className="text-center">
              <h1>Signature Pad</h1>
            </div>
          </CardHeader>
          <CardBody className="align-items-center d-flex justify-content-center">
            {exist ? (
              <div className="text-center w-100">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  backgroundColor="white"
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigPad",
                  }}
                  onBegin={(e) => {
                    if (!autoSave) {
                      setAutosave(true);
                      setTimeout(() => {
                        var pagebutton = document.getElementById("saveBtn");
                        if (pagebutton) {
                          pagebutton.click();
                        }
                      }, 3000);
                    }
                  }}
                />
                <br />
                {loading ? (
                  <Button color="primary" className="px-4 py-2 m-0" disabled>
                    Saving <i className="fas fa-spinner fa-spin"></i>
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    id="saveBtn"
                    className="px-5 py-4 m-0"
                    style={{ fontSize: "24px" }}
                    onClick={save}
                  >
                    Save
                  </Button>
                )}
                <br />
                <Button
                  color="secondary"
                  className="px-3 py-2 mt-3"
                  onClick={clear}
                  disabled={loading}
                >
                  Clear
                </Button>
                <div style={{marginTop: '25px', marginBottom: '10px'}}>
                <a href="javascript:;" style={{textDecoration: 'underline'}} onClick={toggleTerms}>Terms</a>
                {showTerms ? (
                  <div>
                  By signing below I, {ticket.firstName} {ticket.lastName}, hereby affirm that I am the owner of, or have legal right to sell, the
                    items for which I am receiving a payment of ${ticket.ticketAmount}.<br/>
                    By signing this
            agreement, the seller acknowledges that the above listed items have become
            the property of Nevada Coin Mart Inc. and that this transaction is non-cancelable
            and cannot be reversed. <b>Therefore, all transactions are final and cannot
                be voided. </b>
                  </div>
                ) : ""}
                </div>
              </div>
            ) : cryptoExist ? (
              <div className="text-center w-100">
                <h2 className="mb-4">Crypto wallet QR Codes and Address</h2>
                {showQR.btcExist && (
                  <>
                    <img src={btc_qr} width="150" />
                    <h4 className="mb-7">
                      BTC wallet Address: 5rfhgcftr5fhfgd5rffhggh
                    </h4>
                  </>
                )}
                {showQR.ethExist && (
                  <>
                    <img src={eth_qr} width="150" />
                    <h4>ETH wallet Address: 5rfhgcftr5fhfgd5rffhggh</h4>
                  </>
                )}

                <Button
                  className="mt-4"
                  color="primary"
                  onClick={(e) => {
                    setExist(false);
                    setId("");
                    setType("");
                    setIsThank(false);
                    setCryptoExist(false);
                    setShowQR({});
                  }}
                >
                  Close
                </Button>
              </div>
            ) : isThank ? (
              <div className="text-center">
                <h1>Thank you!</h1>
                <p>Your submission has been received.</p>
                <span id="timer">
                  This page will redirect in {count} seconds.
                </span>
              </div>
            ) : (
              <div className="text-center">
                <h1>Please Wait!</h1>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      {changeBooth && (
        <ChangeBoothForSigPad show={changeBooth} toggle={toggleBooth} />
      )}
    </>
  );
}

export default SignaturePad;
