import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import defaultUser from "../../assets/img/default-user.png";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";
import Cookies from "js-cookie";

class PortalNavbar extends React.Component {
  logout = (e) => {
    this.props.resetState();
    //toast.success("Successfully logout");
    localStorage.removeItem("token");
    Cookies.remove("jwtToken", { path: "/", domain: ".dmhq.com" });
    this.props.history.push("/auth/login");
  };

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark p-1" id="navbar-main">
          <Container fluid>
            <a
              id="menu-toggle"
              className={
                "navbar-brand pointer togglerButton mr-0" +
                (this.props.location.pathname === "/portal/signature-pad" ||
                this.props.location.pathname === "/portal/notices"
                  ? " invisible"
                  : "")
              }
            >
              <span
                className="navbar-toggler-icon  p-3"
                onClick={() => {
                  this.props.toggleSideBar();
                }}
              ></span>
            </a>
            <div className={
                "header-body" +
                (this.props.location.pathname === "/portal/signature-pad" ||
                this.props.location.pathname === "/portal/notices"
                  ? " invisible"
                  : "")
              }>
              <nav className="text-center header-links pb-1">
                {this.props.loginUserDetails.userPermissions.includes(
                  "ticket"
                ) && <a href="/portal/metro-tickets" id="ticket">Metro</a>}
                {this.props.loginUserDetails.userPermissions.includes(
                  "new_coin"
                ) && <a id="new_coin" href="/portal/coin-tickets">Coins</a>}
                {this.props.loginUserDetails.userPermissions.includes(
                  "receipt"
                ) && <a id="receipt" href="/portal/receipts">Coin Receipts</a>}
                {this.props.loginUserDetails.userPermissions.includes(
                  "receipt"
                ) && <a id="jewelry_receipt" href="/portal/jewelry-receipts">Jewelry Receipts</a>}
              </nav>
            </div>
            {/* <span className="h4 mb-0 ml-6 text-white text-uppercase " >
              <img
                alt={logo}
                className="navbar-brand-img"
                width="200px"
                src={logo}
              />
            </span> */}

            <Nav
              className={
                "align-items-center" +
                (this.props.location.pathname === "/portal/signature-pad" ||
                this.props.location.pathname === "/portal/notices"
                  ? " invisible"
                  : "")
              }
              navbar
            >
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img alt="..." src={defaultUser} />
                    </span>
                    <Media className="ml-2 d-none d-sm-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome!</h6>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      this.props.history.push("/portal/my-profile")
                    }
                  >
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem onClick={this.logout}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginUserDetails: state.loginUserReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalNavbar);
