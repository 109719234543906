import {
  FETCH_USERS_LISTING_SUCCESS,
  FETCH_USERS_LISTING_FAILURE,
  RESET,
  FETCH_USERS_GROUP_LISTING_SUCCESS,
  FETCH_USERS_GROUP_LISTING_FAILURE,
} from "./userManagementTypes";

const initialState = {
  usersListing: {
    allUsers: [],
    error: null,
  },
  usersGroupListing: {
    allUsersGroups: [],
    error: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_LISTING_SUCCESS:
      return {
        ...state,
        usersListing: {
          allUsers: action.payload,
          error: null,
        },
      };
    case FETCH_USERS_LISTING_FAILURE:
      return {
        ...state,
        usersListing: {
          allUsers: [],
          error: action.payload,
        },
      };
    case FETCH_USERS_GROUP_LISTING_SUCCESS:
      return {
        ...state,
        usersGroupListing: {
          allUsersGroups: action.payload,
          error: null,
        },
      };
    case FETCH_USERS_GROUP_LISTING_FAILURE:
      return {
        ...state,
        usersGroupListing: {
          allUsersGroups: [],
          error: action.payload,
        },
      };
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
