import {
    FETCH_IP_ADDRESS_LISTING_FAILURE,
    FETCH_IP_ADDRESS_LISTING_SUCCESS
  } from "./ipAddressTypes";
  import {
    setLoaderRequestTrue,
    setLoaderRequestFalse,
  } from "../common/commonAction";
  import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";
  
  /*_________________________________________________________________From Option Listing __________________________________________________________________ */
  
  //Api Call Function for from listing
  export const fetchIpAddressListing = () => {
    return (dispatch) => {
      dispatch(setLoaderRequestTrue());
      GetApiActionWithAuthorization("common/get-all-ips")
        .then((res) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchIpAddressListingSuccess(res.data));
        })
        .catch((err) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchIpAddressListingFailure(err));
        });
    };
  };

  //From Op. Success save Payload data to store
export const fetchIpAddressListingSuccess = (data) => {
    return {
      type: FETCH_IP_ADDRESS_LISTING_SUCCESS,
      payload: data,
    };
  };
  
  //From Op. Failure save Payload
  export const fetchIpAddressListingFailure = (error) => {
    return {
      type: FETCH_IP_ADDRESS_LISTING_FAILURE,
      payload: error,
    };
  };