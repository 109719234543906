import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
import Header from "components/Headers/Header.js";
import Unauthorized from "views/Pages/Unauthorized";
import { PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import queryString from 'query-string';

function TransactionDrawerSnapshot(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const params = queryString.parse(props.location.search);
    const [data, setData] = useState([]);
    //
    useEffect(() => {
        PostApiWithAuthorizationAction("transactions/get-snapshot-data", params).then((res) => {
            setData(res.data)
        });
    }, []);

    const renderSnapshotData = () => {
        return data.map((_data, i) => {
            return (
                <div className="mb-2">
                    <span className="option-name font-weight-bold">{_data.name}: </span>
                    <span className="option-total-amount">${_data.total}</span>
                </div>
            )
        });
    };

    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader className="bg-white d-flex justify-content-between">
                <h1>Drawer Snapshot for: {params.date} - {params.time}</h1>
                <Button
                type="button"
                color="primary"
                onClick={() => {
                    props.history.goBack();
                }}
                >
                <i className="fas fa-chevron-left"></i> Back
                </Button>
              </CardHeader>
              <CardBody>
                  <div style={{fontSize: '18px'}}>
                    {renderSnapshotData()}
                  </div>
              </CardBody>
            </Card>
          </Container>
        </>
    );

}

export default TransactionDrawerSnapshot;
