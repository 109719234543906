import {
  FETCH_VOID_TICKET_LISTING_FAILURE,
  FETCH_VOID_TICKET_LISTING_SUCCESS,
} from "./voidTicketsTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________User Listing __________________________________________________________________ */

//Api Call Function for from listing
export const fetchVoidTicketListing = (page, search, sortBy, sortOrder) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-all-void-tickets", {
      page,
      search,
      sortBy,
      sortOrder,
    })
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchVoidTicketListingSuccess(res.data, page, res.count));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchVoidTicketListingFailure(err));
      });
  };
};

//From Op. Success save Payload data to store
export const fetchVoidTicketListingSuccess = (voids, page, count) => {
  return {
    type: FETCH_VOID_TICKET_LISTING_SUCCESS,
    payload: { voids, page, count },
  };
};

//From Op. Failure save Payload
export const fetchVoidTicketListingFailure = (error) => {
  return {
    type: FETCH_VOID_TICKET_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
