import React from "react";
import { connect } from "react-redux";
import loaderSpinner from "../../../assets/img/loader.gif";

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return '';
    /*return (
      <>
        {this.props.loading && (
          <div className="loaderSpinner">
            <img src={loaderSpinner} alt="" />
          </div>
        )}
      </>
    );*/
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
  };
};
export default connect(mapStateToProps, null)(Loader);
