import {
  FETCH_BUYERS_LISTING_FAILURE,
  FETCH_BUYERS_LISTING_SUCCESS
} from "./buyersTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________User Listing __________________________________________________________________ */

//Api Call Function for user listing
export const fetchBuyerListing = (params) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-all-buyers/false", params)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchBuyerListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchBuyerListingFailure(err));
      });
  };
};

//Buyerlisting Success save Payload data to store
export const fetchBuyerListingSuccess = (data) => {
  return {
    type: FETCH_BUYERS_LISTING_SUCCESS,
    payload: data,
  };
};

//Buyerlisting Failure save Payload
export const fetchBuyerListingFailure = (error) => {
  return {
    type: FETCH_BUYERS_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
