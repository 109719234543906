import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { monthList } from "constants/ticketConstants";
import { toast } from "react-toastify";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";

class DailyTicket extends React.Component {
    state = {
        isMonthPage: monthList.includes(this.props.timeStamp),
        day: "",
        confirm: false
    }
    //
    render () {
      const {
        props: {
            data,
            year,
            timeStamp,
            _props,
            settings
        }
      } = this
      const toggleConfirm = (day) => {
        this.setState({
            ... this.state,
            confirm: (!this.state.confirm),
            day
        })
      };
      //
      const sendToLeadsOnline = () => {
        PostApiWithAuthorizationAction("common/post-day-tickets-lead-online/", {
            day:_props.location.pathname.replace('/portal/daily/', '') + '/' + this.state.day
        }).then((res) => {
            toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        }).catch((err) => {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        }).finally(() => {
            this.setState({
                ... this.state,
                confirm: false
            })
        })
      };
      //
      return data.length > 0 ? (
        <>
            <div>
                <h4 style={{display:'inline'}}>{settings.title}</h4> [<a href={settings.historicalChart}>Historical Chart</a>]
            </div>
            <Table className="daily-table mb-2" bordered striped>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>#</th>
                        <th>Amount</th>
                        {/*<th>Profit</th>*/}
                        {this.state.isMonthPage && (
                            <th></th>
                        )}
                    </tr>
                </thead>
                <tbody>
                {data.map((tic, i) => {
                    let ticTimestamp = tic.timeStamp;
                    if (tic.type === "month") {
                        ticTimestamp = monthList.indexOf(ticTimestamp);
                    }
                    return (
                    <tr key={i}>
                        <td>
                        <a
                            href={
                            (tic.type === "year")
                            ? `/portal/daily/${tic.timeStamp}?dataType=${settings.dataType}`
                            : (tic.type === "month")
                                ? `${_props.location.pathname}/${monthList.indexOf(tic.timeStamp)}?dataType=${settings.dataType}`
                                : `/portal/${settings.singleViewLink}?date=${year}/${monthList.indexOf(timeStamp)}/${tic.timeStamp}`}
                            className="delete-item">{ticTimestamp}</a>
                        </td>
                        <td>{tic.totalCount}</td>
                        <td className="text-center">${tic.amount.toFixed(2).replace('.00', '')}</td>
                        {/*<td className="text-center">${tic.profit.toFixed(2).replace('.00', '')}</td>*/}
                        {this.state.isMonthPage && (
                            <td className="text-center">
                                <a
                                    target="_blank"
                                    href={`/portal/profit/${year}/${monthList.indexOf(timeStamp)}/${tic.timeStamp}`}>Profit</a> |&nbsp;
                                <a
                                    target="_blank"
                                    href={`/portal/tally/${year}/${monthList.indexOf(timeStamp)}/${tic.timeStamp}`}>Tally</a> |&nbsp;
                                <a
                                    href={'javascript:;'}
                                    onClick={() => {
                                        toggleConfirm(ticTimestamp)
                                    }}>LeadOnline</a>
                            </td>
                        )}
                    </tr>
                    );
                })}
                </tbody>
            </Table>
            {this.state.confirm && (
                <ConfirmationDeleteModal
                    show={this.state.confirm}
                    toggle={toggleConfirm}
                    confirmed={sendToLeadsOnline}
                    message={"WARNING: this will overwrite any submissions already on LeadsOnline, ONLY click OK, if you have a 0-entry day on LeadsOnline"}
                    class="striped-modal"
                />
            )}
        </>
      ) : '';
    }
}
//
export default DailyTicket;
