import {
  FETCH_CHECKS_LISTING_SUCCESS,
  FETCH_CHECKS_LISTING_FAILURE,
  
  RESET
} from "./ChecksTypes";

import { initialState } from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CHECKS_LISTING_SUCCESS:
      return {
        ...state,
        checks: {
          ticketChecks: action.payload.ticketChecks,
          coinChecks: action.payload.coinChecks,
          checks: action.payload.checks,
          error: null,
        },
      };
    case FETCH_CHECKS_LISTING_FAILURE:
      return {
        ...state,
        checks: {
          ticketChecks: [],
          coinChecks: [],
          checks: [],
          error: action.payload,
        },
      };
   

    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
