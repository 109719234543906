import {
  FETCH_TRANSACTION_LISTING_SUCCESS,
  FETCH_TRANSACTION_LISTING_FAILURE,
  RESET,
} from "./transactionsTypes";

const initialState = {
  allTransactions: [],
  count: {
    metro: 0,
    coin: 0,
    coinDeposit: 0,
    jewelryDeposit: 0,
    coinChangeTotal: 0,
    jewelryChangeTotal: 0,
    coinDailyTotal: 0,
    page: 0
  },
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTION_LISTING_SUCCESS:
      return {
        allTransactions: action.payload.transactions,
        count: action.payload.count,
        error: null,
      };
    case FETCH_TRANSACTION_LISTING_FAILURE:
      return {
        allTransactions: [],
        count: {
          metro: 0,
          coin: 0,
          page: 0
        },
        error: action.payload,
      };
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
