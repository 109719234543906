import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { fetchChecksListing } from "../../redux/Checks/ChecksAction";
import Unauthorized from "views/Pages/Unauthorized";
import { DropdownList } from "react-widgets";
import Moment from "react-moment";
import PrintStandaloneCheck from "views/Modal/PrintStandaloneCheck";
import EditCheckModal from "views/Modal/Checks/EditCheckModal";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import moment from "moment";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";

function Checks(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [search, setSearch] = useState("");
  const [filterBy, setFilterBy] = useState("Payee");
  const [printNewModal, setPrintNewModal] = useState(false);
  const [editCheckModal, setEditCheckModal] = useState(false);
  const [checkData, setCheckData] = useState({});
  const [onlyVoid, setOnlyVoid] = useState(false);
  const [onlyCleared, setOnlyCleared] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [displayMetroContent, setDisplayMetroContent] = useState(false);
  const [displayCoinContent, setDisplayCoinContent] = useState(false);
  const [displayCheckContent, setDisplayCheckContent] = useState(false);

  useEffect(() => {
    let _moment = moment().tz('US/Pacific');
    let end = _moment.format('YYYY-MM-DD'),
      startTimestamp = _moment.subtract(6, 'months'),
      start = startTimestamp.format('YYYY-MM-DD');
    setStartDate(start);
    setEndDate(end);
    props.fetchChecksListing(
      search,
      filterBy.split(" ").join("").toLowerCase(),
      onlyCleared,
      onlyVoid,
      start,
      end
    );
  }, []);

  //for formating date acc. to input type date
  const formatDate = (d) => {
    let month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const checkboxHandler = (e, id, type, name) => {
    const { checked } = e.target;
    let body = {
      id,
      type,
      name,
      value: checked,
    };
    PostApiWithAuthorizationAction("common/edit-checks-details", body)
      .then((res) => {
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        props.fetchChecksListing(
          search,
          filterBy.split(" ").join("").toLowerCase(),
          onlyCleared,
          onlyVoid,
          startDate,
          endDate
        );
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  if (props.checks.error && !unauthorized) {
    if (props.checks.error.status === 403) {
      setUnauthorized(true);
    } else if (props.checks.error.status === 440) {
      props.resetState();
      toast.error(props.checks.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }
  //For standalone Modal
  const toggleNewCheckPrint = (e) => {
    setPrintNewModal(!printNewModal);
  };

  const toggleEditCheckModal = (e) => {
    setEditCheckModal(!editCheckModal);
  };

  const loadCheckModal = (id) => {
    GetApiActionWithAuthorization("common/get-particular-check-detail/" + id)
      .then((res) => {
        console.log(res)
        setCheckData(res.data)
        setEditCheckModal(true)
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  }

  const refreshList = (e) => {
    props.fetchChecksListing(
      search,
      filterBy.split(" ").join("").toLowerCase(),
      onlyCleared,
      onlyVoid,
      startDate,
      endDate
    );
    setPrintNewModal(false);
  };

  const formatCheckAmount = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount).replace('.00', '');
  };

  const printCheckHandler = (id) => {
    PostApiWithAuthorizationAction("common/print-checks", {
      id
    }).then((res) => {
      if(res.status == "success") {
        toast.success("PRINTED", { position: toast.POSITION.TOP_CENTER });
      }
    })
    .catch((e) => props.toggle());
  };

  const renderChecksTableData = (type, hideColumns = []) => {
    return props.checks[type].map((c, i) => {
      let amountNumber = formatCheckAmount(parseInt(c.amountAsNumber));
      return (
        <tr key={i}>
          <td>
            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
              {c.dateAdded.toString().length === 10
                ? new Date(c.dateAdded * 1000)
                : new Date(c.dateAdded)}
            </Moment>
          </td>
          {(!hideColumns.includes('ticket')) && (
            <td>{c.id}</td>
          )}
          <td>{c.checkNumber}</td>
          <td>{amountNumber}</td>
          <td>{c.payee}</td>
          <td style={{verticalAlign: 'middle'}}>
            <input
              id={"isCleared" + i}
              type="checkbox"
              checked={c.isCleared}
              className="db-sized-checkbox"
              onChange={(e) => checkboxHandler(e, c.id, type, "isCleared")}
            />
            <small className="ml-2"></small>
          </td>
          <td style={{verticalAlign: 'middle'}}>
            <input
              id={"isVoided" + i}
              type="checkbox"
              checked={c.isCheckVoid}
              className="db-sized-checkbox"
              onChange={(e) => checkboxHandler(e, c.id, type, "isCheckVoid")}
            />
            <small className="ml-2"></small>
          </td>
          {(type=='checks' && (
            <td style={{verticalAlign: 'middle'}}>
              [<a
                  id={'edit-check-'+c.id}
                  href='javascript:;'
                  onClick={(e) => {
                    e.preventDefault();
                    loadCheckModal(c.id)
                  }}>Edit</a>
              ]
              [<a
                  id={'print-check-'+c.id}
                  href='javascript:;'
                  onClick={(e) => {
                    e.preventDefault();
                    printCheckHandler(c.id)
                  }}>Print</a>
              ]
            </td>
          ))}
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Checks</h1>
            </div>
          </CardHeader>
          <CardBody className="checks-main-content page-main-content">
            <Row className="align-items-center search-container">
              <Col md="8" lg="6" sm="8" className="mt-2">
                <Form
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.fetchChecksListing(
                      search,
                      filterBy.split(" ").join("").toLowerCase(),
                      onlyCleared,
                      onlyVoid
                    );
                  }}
                >
                  <Input
                    type={filterBy === "Payee" ? "text" : "number"}
                    className="mr-2"
                    placeholder={
                      filterBy === "Payee"
                        ? "Search by Payee"
                        : filterBy === "Check Amount"
                        ? "Search by Check Amount"
                        : filterBy === "Ticket Number"
                        ? "Search by Ticket Number"
                        : "Search by Check Number"
                    }
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <DropdownList
                    data={["Payee", "Check Amount", "Check Number", "Ticket Number"]}
                    containerClassName="filter-By"
                    value={filterBy}
                    placeholder="Select"
                    onChange={(e) => setFilterBy(e)}
                  />
                  <Button className="mx-2 custom-btn-info" color="primary" type="submit">
                    Search
                  </Button>
                </Form>
              </Col>
              <Col md="10" lg="8">
                <Row>
                  <Col className="d-flex align-items-center mt-2">
                    <Label className="form-control-label mb-0">Start:</Label>
                    <Input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                  <Col className="d-flex align-items-center mt-2">
                    <Label className="form-control-label mb-0">End:</Label>
                    <Input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                  <Col className="mt-2 d-none d-lg-block">
                    <Button
                      color="primary"
                      className="custom-btn-info"
                      size="sm"
                      onClick={(e) => {
                        props.fetchChecksListing(
                          search,
                          filterBy.split(" ").join("").toLowerCase(),
                          onlyCleared,
                          onlyVoid,
                          startDate,
                          endDate
                        );
                      }}
                    >
                      Update Range
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="align-items-center pt-2" md="6" lg="3">
              <Button
                  color="primary"
                  className="custom-btn-info d-lg-none d-sm-inline"
                  size="sm"
                  onClick={(e) => {
                    props.fetchChecksListing(
                      search,
                      filterBy.split(" ").join("").toLowerCase(),
                      onlyCleared,
                      onlyVoid,
                      startDate,
                      endDate
                    );
                  }}
                >
                      Update Range
                </Button>
                <Button
                  id="print_check"
                  color="info"
                  size="sm"
                  className="custom-btn-info"
                  onClick={toggleNewCheckPrint}>
                    Print New
                  </Button>
                <Button
                  color="success"
                  size="sm"
                  style={{padding: "6px 12px", background: '#5cb85c', borderColor: '#269226'}}>
                    Download CSV
                </Button>
              </Col>
              <Col md="6" className="pt-2 pt-lg-3 text-center text-lg-left">
                  <Label className="form-control-label mr-5" check>
                    <Input
                      type="checkbox"
                      id="onlyCleared"
                      className="db-sized-checkbox"
                      checked={onlyCleared}
                      onChange={(e) => {
                        setOnlyCleared(e.target.checked);
                        props.fetchChecksListing(
                          search,
                          filterBy.split(" ").join("").toLowerCase(),
                          e.target.checked,
                          onlyVoid,
                          startDate,
                          endDate
                        );
                      }}
                    />{" "}
                    Show Only Cleared
                  </Label>
                {/* </Col>
              <Col className="ml-1 mr-0"> */}
                  <Label className="form-control-label" check>
                    <Input
                      type="checkbox"
                      id="onlyVoid"
                      className="db-sized-checkbox"
                      checked={onlyVoid}
                      onChange={(e) => {
                        setOnlyVoid(e.target.checked);
                        props.fetchChecksListing(
                          search,
                          filterBy.split(" ").join("").toLowerCase(),
                          onlyCleared,
                          e.target.checked,
                          startDate,
                          endDate
                        );
                      }}
                    />{" "}
                    Show Only Voided
                  </Label>
              </Col>
            </Row>
            <Row>
              <Col md="6" lg="6" className="pr-0 pr-lg-1">
                <div className="mt-1 check-table-labels">
                  <p className="mb-0">Checks for : Metro Ticket&nbsp;
                  {displayMetroContent ? (
                    <Button
                      id="add-btn"
                      color="primary"
                      size="sm"
                      style={{fontSize: '15px', padding: '0 7px'}}
                      onClick={(e) => {
                        setDisplayMetroContent(!displayMetroContent)
                      }}
                    >-</Button>
                  ) : (
                    <Button
                      id="add-btn"
                      color="primary"
                      size="sm"
                      style={{fontSize: '15px', padding: '0 7px'}}
                      onClick={(e) => {
                        setDisplayMetroContent(!displayMetroContent)
                      }}
                    >+</Button>                  
                  )}
                  </p>
                  {displayMetroContent && (
                    <p>
                    Outstanding amount: <span class="check-total-amount">
                    {formatCheckAmount(props.checks.ticketChecks
                      .reduce((prev, curr) => {
                        if (curr.amountAsNumber === "") {
                          return prev + 0.0;
                        } else {
                          return prev + parseFloat(curr.amountAsNumber);
                        }
                      }, 0)
                      .toFixed(2).replace('.00', ''))}</span>
                  </p>
                  )}
                </div>
                {displayMetroContent && (                
                <Table className="check-table table-listing" responsive bordered striped>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Time</th>
                      <th scope="col">Ticket</th>
                      <th scope="col">Check</th>
                      <th scope="col">Amt.</th>
                      <th scope="col">Payee</th>
                      <th scope="col">Cleared</th>
                      <th scope="col">Voided</th>
                    </tr>
                  </thead>
                  <tbody>{renderChecksTableData("ticketChecks")}</tbody>
                </Table>
                )}
              </Col>
              <Col md="6" lg="6" className="pr-0 pr-lg-2">
                <div className="mt-1 check-table-labels">
                  <p className="mb-0">Checks for : Coin Tickets&nbsp;
                  {displayCoinContent ? (
                    <Button
                    id="add-btn"
                    color="primary"
                    size="sm"
                    style={{fontSize: '15px', padding: '0 7px'}}
                    onClick={(e) => {
                      setDisplayCoinContent(!displayCoinContent)
                    }}
                  >-</Button>
                  ) : (
                    <Button
                      id="add-btn"
                      color="primary"
                      size="sm"
                      style={{fontSize: '15px', padding: '0 7px'}}
                      onClick={(e) => {
                        setDisplayCoinContent(!displayCoinContent)
                      }}
                    >+</Button>                  
                  )}</p>
                  {displayCoinContent && (             
                  <p>
                    Outstanding amount: <span class="check-total-amount">
                    {formatCheckAmount(props.checks.coinChecks
                      .reduce((prev, curr) => {
                        if (curr.amountAsNumber === "") {
                          return prev + 0.0;
                        } else {
                          return prev + parseFloat(curr.amountAsNumber);
                        }
                      }, 0)
                      .toFixed(2).replace('.00', ''))}
                    </span>
                  </p>
                  )}
                </div>
                {displayCoinContent && ( 
                <Table className="check-table table-listing" responsive bordered striped>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Time</th>
                      <th scope="col">Ticket</th>
                      <th scope="col">Check</th>
                      <th scope="col">Amt.</th>
                      <th scope="col">Payee</th>
                      <th scope="col">Cleared</th>
                      <th scope="col">Voided</th>
                    </tr>
                  </thead>
                  <tbody>{renderChecksTableData("coinChecks")}</tbody>
                </Table>
                )}
                <div className="mt-3 check-table-labels">
              <p className="mb-0">Checks for : checks&nbsp;
              {displayCheckContent ? (
                    <Button
                    id="add-btn"
                    color="primary"
                    size="sm"
                    style={{fontSize: '15px', padding: '0 7px'}}
                    onClick={(e) => {
                      setDisplayCheckContent(!displayCheckContent)
                    }}
                  >-</Button>
              ) : (
                <Button
                id="add-btn"
                color="primary"
                size="sm"
                style={{fontSize: '15px', padding: '0 7px'}}
                onClick={(e) => {
                  setDisplayCheckContent(!displayCheckContent)
                }}
              >+</Button>                    
              )}</p>
              {displayCheckContent && (   
              <p>
                Outstanding amount: <span class="check-total-amount">
                {formatCheckAmount(props.checks.checks
                  .reduce((prev, curr) => {
                    if (curr.amountAsNumber === "") {
                      return prev + 0.0;
                    } else {
                      return prev + parseFloat(curr.amountAsNumber);
                    }
                  }, 0)
                  .toFixed(2).replace('.00', ''))}
                </span>
              </p>
              )}
            </div>
            {displayCheckContent && ( 
            <Table className="check-table table-listing" responsive bordered striped>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Time</th>
                  <th scope="col">Check</th>
                  <th scope="col">Amt.</th>
                  <th scope="col">Payee</th>
                  <th scope="col">Cleared</th>
                  <th scope="col">Voided</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>{renderChecksTableData("checks", [
                'ticket'
              ])}</tbody>
            </Table>
            )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      {printNewModal && (
        <PrintStandaloneCheck
          {...props}
          show={printNewModal}
          refreshList={refreshList}
          toggle={toggleNewCheckPrint}
        />
      )}
      {editCheckModal && (
        <EditCheckModal
          {...props}
          show={editCheckModal}
          data={checkData}
          refreshList={refreshList}
          toggle={toggleEditCheckModal}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    checks: state.ChecksReducer.checks,
    // receipts: state.ReceiptReducer.receipts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchChecksListing: (
      search,
      filterBy,
      onlyCleared,
      onlyVoided,
      startDate,
      endDate
    ) =>
      dispatch(
        fetchChecksListing(
          search,
          filterBy,
          onlyCleared,
          onlyVoided,
          startDate,
          endDate
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checks);
