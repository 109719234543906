import {
  FETCH_JEWELRY_RECEIPT_LISTING_FAILURE,
  FETCH_JEWELRY_RECEIPT_LISTING_SUCCESS,
  SAVE_JEWELRY_RECEIPT_FORM_DETAILS,
  SAVE_ITEMS_DETAILS_IN_JEWELRY_FORM,
  UPDATE_JEWELRY_RECEIPT_DATA_AFTER_UPDATE,
  RESET_SAVE_JEWELRY_RECEIPT_DETAILS,
  RESET
} from "./JewelryReceiptTypes";

import { initialState } from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JEWELRY_RECEIPT_LISTING_SUCCESS :
      return {
        ...state,
        receipts: {
          allReceipts: action.payload.receipts,
          page: action.payload.page,
          count: action.payload.count,
          error: null,
        },
      };
    case FETCH_JEWELRY_RECEIPT_LISTING_FAILURE :
      return {
        ...state,
        receipts: {
          page: 0,
          count: 0,
          allReceipts: [],
          error: action.payload,
        },
      };
    case SAVE_JEWELRY_RECEIPT_FORM_DETAILS:
      return {
        ...state,
        addReceiptFormdetails: {
          ...state.addReceiptFormdetails,
          [action.payload.name]: action.payload.value,
        },
      };

    case SAVE_ITEMS_DETAILS_IN_JEWELRY_FORM:
      return {
        ...state,
        receiptItemdetails: {
          items: action.payload,
          count: action.payload.length,
        },
      };

      
      case UPDATE_JEWELRY_RECEIPT_DATA_AFTER_UPDATE:
      return {
        ...state,
        receipts: {
          ...state.receipts,
          allReceipts: action.payload,
          error: null,
        },
      };
    case RESET_SAVE_JEWELRY_RECEIPT_DETAILS:
      return {
        ...state,
        addReceiptFormdetails: initialState.addReceiptFormdetails,
        receiptItemdetails: {
          items: [],
          count: 0,
        },
      };

    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
