import {
  FETCH_STORES_LISTING_SUCCESS,
  FETCH_STORES_LISTING_FAILURE,
  FETCH_CLERK_LISTING_SUCCESS,
  FETCH_CLERK_LISTING_FAILURE,
  FETCH_COINS_LISTING_SUCCESS,
  FETCH_COINS_LISTING_FAILURE,
  SAVE_COINS_ITEMS_DETAILS,
  SAVE_COINS_BARTER_ITEMS_DETAILS,
  RESET_SAVE_COINS_DETAILS,
  FETCH_COIN_ITEMS__LISTING_FAILURE,
  FETCH_COIN_ITEMS_LISTING_SUCCESS,
  FETCH_COIN_BARTER_ITEMS_LISTING_SUCCESS,
  FETCH_COIN_BARTER_ITEMS_LISTING_FAILURE,
  UPDATE_COIN_DATA_AFTER_UPDATE,
  COPY_TICKETS_DETAILS_IN_COIN,
  SAVE_COINS_FORM_DETAILS,
  RESET,
} from "./coinTypes";

import { initialState } from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORES_LISTING_SUCCESS:
      return {
        ...state,
        stores: {
          allStores: action.payload,
          error: null,
        },
      };
    case FETCH_STORES_LISTING_FAILURE:
      return {
        ...state,
        stores: {
          allStores: [],
          error: action.payload,
        },
      };
    case FETCH_CLERK_LISTING_SUCCESS:
      return {
        ...state,
        clerks: {
          allClerks: action.payload,
          error: null,
        },
      };
    case FETCH_CLERK_LISTING_FAILURE:
      return {
        ...state,
        clerks: {
          allClerks: initialState.clerks.allClerks,
          error: action.payload,
        },
      };
    case FETCH_COINS_LISTING_SUCCESS:
      return {
        ...state,
        coin_tickets: {
          allTickets: action.payload.coins,
          page: action.payload.page,
          count: action.payload.count,
          error: null,
        },
      };
    case UPDATE_COIN_DATA_AFTER_UPDATE:
      return {
        ...state,
        coin_tickets: {
          ...state.coin_tickets,
          allTickets: action.payload,
          error: null,
        },
      };
    case FETCH_COINS_LISTING_FAILURE:
      return {
        ...state,
        coin_tickets: {
          page: 0,
          count: 0,
          allTickets: [],
          error: action.payload,
        },
      };
      case FETCH_COIN_BARTER_ITEMS_LISTING_SUCCESS:
        return {
          ...state,
          coinBarterItemdetails: {
            items: action.payload.data,
            ticketDetails: action.payload.ticketDetails ? action.payload.ticketDetails:state.coinItemdetails.ticketDetails,
            count: action.payload.data.length,
            error: null,
          },
        };
        case FETCH_COIN_BARTER_ITEMS_LISTING_FAILURE:
          return {
            ...state,
            coinBarterItemdetails: {
              items: [],
              ticketDetails: [],
              count: 0,
              error: action.payload
            },
          };
    case FETCH_COIN_ITEMS_LISTING_SUCCESS:
      return {
        ...state,
        coinItemdetails: {
          items: action.payload.data,
          ticketDetails: action.payload.ticketDetails ? action.payload.ticketDetails:state.coinItemdetails.ticketDetails,
          count: action.payload.data.length,
          error: null,
        },
      };
    case FETCH_COIN_ITEMS__LISTING_FAILURE:
      return {
        ...state,
        tickets_items: {
          items: [],
          count:0,
          error: action.payload,
        },
      };

    case SAVE_COINS_FORM_DETAILS:
      return {
        ...state,
        addCoinFormdetails: {
          ...state.addCoinFormdetails,
          [action.payload.name]: action.payload.value,
        },
      };

    case SAVE_COINS_ITEMS_DETAILS:
      return {
        ...state,
        coinItemdetails: {
          items: action.payload,
          count: action.payload.length,
        },
      };
    case SAVE_COINS_BARTER_ITEMS_DETAILS:
      return {
        ...state,
        coinBarterItemdetails: {
          items: action.payload,
          count: action.payload.length,
        },
      };
    case COPY_TICKETS_DETAILS_IN_COIN:
      return {
        ...state,
        addCoinFormdetails: {
          ...initialState.addCoinFormdetails,
          ...action.payload,
        },
      };
    case RESET_SAVE_COINS_DETAILS:
      return {
        ...state,
        addCoinFormdetails: initialState.addCoinFormdetails,
        coinItemdetails: {
          items: [],
          count: 0,
        },
      };

    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
