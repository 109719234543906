import {
  FETCH_VOID_TICKET_LISTING_FAILURE,
  FETCH_VOID_TICKET_LISTING_SUCCESS,
  RESET,
} from "./voidTicketsTypes";

const initialState = {
  allVoids: [],
  page: 0,
  count: 0,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VOID_TICKET_LISTING_SUCCESS:
      return {
        allVoids: action.payload.voids,
        page: action.payload.page,
        count: action.payload.count,
        error: null,
      };
    case FETCH_VOID_TICKET_LISTING_FAILURE:
      return {
        allVoids: [],
        page: 0,
        count: 0,
        error: action.payload,
      };
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
