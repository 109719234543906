import {
  FETCH_PAYEES_LISTING_SUCCESS,
  FETCH_PAYEES_LISTING_FAILURE,
  RESET,
} from "./payeesTypes";

const initialState = {
  allPayees: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PAYEES_LISTING_SUCCESS:
      return {
        allPayees: action.payload.data,
        count:action.payload.count,
        page: action.payload.page,
        error: null,
      };
    case FETCH_PAYEES_LISTING_FAILURE:
      return {
        allPayees: [],
        count: 0,
        page: 1,
        error: action.payload,
      };
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
