import {
  FETCH_PCUSER_LISTING_SUCCESS,
  FETCH_PCUSER_LISTING_FAILURE,
  RESET,
} from "./pettyCashUsersTypes";

const initialState = {
  allUsers: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PCUSER_LISTING_SUCCESS:
      return {
        allUsers: action.payload,
        error: null,
      };
    case FETCH_PCUSER_LISTING_FAILURE:
      return {
        allUsers: [],
        error: action.payload,
      };
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
