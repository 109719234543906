import {
  FETCH_CRYPTO_LISTING_FAILURE,
  FETCH_CRYPTO_LISTING_SUCCESS,
  RESET,
} from "./CryptoTypes";

import { initialState } from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CRYPTO_LISTING_SUCCESS:
      return {
        cryptoData: action.payload.cryptoData,
        total: action.payload.total,
        error: null,
      };
    case FETCH_CRYPTO_LISTING_FAILURE:
      return {
        cryptoData: [],
        total: 0,
        error: action.payload,
      };

    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
