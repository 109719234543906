import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import PortalNavbar from "../components/Navbars/PortalNavbar";
import PortalFooter from "../components/Footers/PortalFooter";
import Sidebar from "../components/Sidebar/Sidebar";
import { toast } from "react-toastify";

import routes from "../routes";
import { connect } from "react-redux";
import { fetchLoginUserSuccess } from "../redux/loginUser/loginUserAction";
import { resetState } from "../redux/common/commonAction";
import classnames from "classnames";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../redux/common/commonAction";
class CustomerPortal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
    };
  }

  toggleSide = (e) => {
    this.setState({
      toggled: !this.state.toggled,
    });
  };
  componentDidMount() {
    if (localStorage.getItem("token")) {
      //Fetch users listing from redux
      this.refs.mainContent.addEventListener("click", this.sideBarHandler);
      this.props.setLoaderRequestTrue();
      GetApiActionWithAuthorization("users/particular-user-permissions")
        .then((res) => {
            if (typeof res.data.userdetails.group !== 'undefined') {
                localStorage.setItem("group", res.data.userdetails.group.name.toLowerCase());
            }
          this.props.fetchLoginUserSuccess(res.data);
          this.props.setLoaderRequestFalse();
          if (res.data.permissions.length > 0) {
            if (this.props.location.pathname === "/customer-portal") {
              if (res.data.permissions.includes("dashboard")) {
                this.props.history.push("/customer-portal/dashboard");
              } else {
                let rout = routes.find(
                  (r) => r.accessCode === res.data.permissions[0]
                );
                if (rout) {
                  this.props.history.push(rout.layout + rout.path);
                }
              }
            }
          } else {
            this.props.history.push("/portal/not-any-permissions");
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.status === 440) {
            this.props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            //redirection not work properly unless
            this.props.history.push("/auth/login?r=" + this.props.location.pathname);
            window.location.href = "/auth/login?r=" + this.props.location.pathname;
          }
        });
    } else {
      toast.error("Please Login First", {
        position: toast.POSITION.TOP_CENTER,
      });
      this.props.history.push("/auth/login?r=" + this.props.location.pathname);
      window.location.href = "/auth/login?r=" + this.props.location.pathname;
    }
  }

  componentWillUnmount() {
    this.refs.mainContent.removeEventListener("click", this.sideBarHandler);
  }
  sideBarHandler = () => {
    if (this.state.toggled) {
      this.setState({ toggled: false });
    }
  };

  scrollTop = (e) => {
    this.refs.mainContent.scrollTop = 0;
  };

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/portal") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            exact={prop.exact}
            key={key}
          />
        );
      } else if (prop.layout === "/customer-portal") {
        console.log(prop.layout)
        return (
            <Route
              path={prop.path}
              component={prop.component}
              exact={prop.exact}
              key={key}
            />
          );
      }
      else {
        return null;
      }
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  render() {
    const { error } = this.props.loginUserDetails;
    return (
      error === null && (
        <div
          id="wrapper"
          className={classnames({ toggled: this.state.toggled})}
        >
          <div
            className="main-content"
            id="page-content-wrapper"
            ref="mainContent"
          >
            <Switch>{this.getRoutes(routes)}</Switch>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUserDetails: state.loginUserReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchLoginUserSuccess: (body) => dispatch(fetchLoginUserSuccess(body)),

    setLoaderRequestTrue: () => dispatch(setLoaderRequestTrue()),
    setLoaderRequestFalse: () => dispatch(setLoaderRequestFalse()),
    resetState: () => dispatch(resetState()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPortal);
