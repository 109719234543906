import {
  FETCH_CHECKS_LISTING_FAILURE,
  FETCH_CHECKS_LISTING_SUCCESS,
} from "./ChecksTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________Checks Listing __________________________________________________________________ */

//Api Call Function for Checks listing
export const fetchChecksListing = (search,filterBy,onlyCleared=false,onlyVoided=false,startDate,endDate) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());

    GetApiActionWithAuthorization("common/get-all-checks", {search:search?search.trim():null,startDate,endDate,onlyCleared,onlyVoided,filterBy})
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchChecksListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchChecksListingFailure(err));
      });
  };
};

//Check listing Success save Payload data to store
export const fetchChecksListingSuccess = (checksData) => {
  return {
    type: FETCH_CHECKS_LISTING_SUCCESS,
    payload: checksData,
  };
};

//Check listing Failure save Payload
export const fetchChecksListingFailure = (error) => {
  return {
    type: FETCH_CHECKS_LISTING_FAILURE,
    payload: error,
  };
};

/*__________________________________________________________________________________________________________________________________________ */



