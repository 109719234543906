import React, { useEffect, useRef, useState } from "react";
import Header from "components/Headers/Header";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import SignaturePad from 'react-signature-pad-wrapper';
import ChangeBoothForSigPad from "views/Modal/ChangeBoothForSigPad";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import btc_qr from "../../assets/img/btc_qr.png";
import eth_qr from "../../assets/img/eth_qr.png";

function SignaturePad2(props) {
  const sigCanvas = useRef({});
  const [changeBooth, setChangeBooth] = useState(false);
  const [changeInterval, setChangeInterval] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isThank, setIsThank] = useState(false);
  const [exist, setExist] = useState(false);
  const [id, setId] = useState("");
  const [unauthorized, setUnauthorized] = useState(false);
  const [type, setType] = useState("");
  const [cryptoExist, setCryptoExist] = useState(false);
  const [showQR, setShowQR] = useState({});
  const [autoSave, setAutosave] = useState(false);

  //functions
  const getTicketInfoForSignature = () => {
    PostApiWithAuthorizationAction("common/get-signature-info", {
      booth: localStorage.getItem("booth"),
      id,
      exist
    }).then((res) => {
      if (!exist) {
        setId(res.data.id);
        setType(res.data.type);
        setExist(res.data.exist);
      } else {
        if (res.status === 400) {
          setId("");
          setType("");
          setExist(false);
        }
      }
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const saveSignatureForTicket = () => {
    setLoading(true);
    PostApiWithAuthorizationAction("common/save-signature", {
      id: id,
      type: type,
      station: localStorage.getItem("lastUsedPrinter"),
      signature: sigCanvas.current.toDataURL()
    }).then((res) => {
      setLoading(false);
      setExist(false);
      setId("");
      setType("");
      setChangeInterval(!changeInterval);
      //
      if (res.cryptoExist.btcExist || res.cryptoExist.ethExist) {
        setCryptoExist(true);
        setShowQR(res.cryptoExist);
        setTimeout(() => {
            setCryptoExist(false);
            setShowQR({});
        }, 1000 * 60 * 10);
      }
      setIsThank(true);
      window.location.reload()
    }).catch((err) => {
      setLoading(false);
      saveSignatureForTicket();
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      } else {
        console.log(err);
        toast.error(JSON.stringify(err.err), {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const handleAutoSave = () => {
    console.log('event target')
    if (!autoSave) {
      setAutosave(true);
      setTimeout(() => {
        var pagebutton = document.getElementById("saveBtn");
        if (pagebutton) {
          pagebutton.click();
        }
      }, 3000);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("booth")) {
      setChangeBooth(true);
    } else {
      getTicketInfoForSignature();
    }
    //
    let interval = setInterval(async () => {
      let canvasSelector = window.document.querySelector('canvas');
      if (canvasSelector) {
        canvasSelector.addEventListener("click", handleAutoSave);
        canvasSelector.addEventListener("touchstart", handleAutoSave);
        clearInterval(interval);
      }
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      getTicketInfoForSignature();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [changeInterval]);

  //for Count down
  useEffect(() => {
    count > 0 && setTimeout(() => setCount(count - 1), 1000);
  }, [count]);

  const clear = (e) => sigCanvas.current.clear();

  const save = (e) => {
    //check signature canvas empty or not
    if (!sigCanvas.current.isEmpty()) {
      //If signature canvas exist
      if (exist) {
        saveSignatureForTicket();
      }
    } else {
      toast.error("Signature Canvas is Empty!Please add Signature", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const toggleBooth = (e) => {
    setChangeBooth(!changeBooth);
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      <Container className="mt--10 main-content" fluid>
        <Card className="card-height shadow back-gray">
          <CardHeader className="back-gray">
            <div className="text-center">
              <h1>Signature Pad V2</h1>
            </div>
          </CardHeader>
          <CardBody className="align-items-center d-flex justify-content-center">
            {exist ? (
              <div className="text-center w-100">
                <Button
                  color="danger"
                  className="px-3 py-2 mt-3 mb-2"
                  onClick={clear}
                  disabled={loading}
                >
                  Clear
                </Button>
                <div className="signature-pad-box">
                  <SignaturePad
                    ref={sigCanvas}
                    options={{
                      backgroundColor: 'rgb(255,255,255)'
                    }}/>
                </div>
                <br />
                {loading ? (
                  <Button color="primary" className="px-4 py-2 m-0" disabled>
                    Saving <i className="fas fa-spinner fa-spin"></i>
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    id="saveBtn"
                    className="px-5 py-4 m-0"
                    style={{ fontSize: "24px" }}
                    onClick={save}
                  >
                    Save
                  </Button>
                )}
              </div>
            ) : cryptoExist ? (
              <div className="text-center w-100">
                <h2 className="mb-4">Crypto wallet QR Codes and Address</h2>
                {showQR.btcExist && (
                  <>
                    <img src={btc_qr} width="150" />
                    <h4 className="mb-7">
                      BTC wallet Address: 5rfhgcftr5fhfgd5rffhggh
                    </h4>
                  </>
                )}
                {showQR.ethExist && (
                  <>
                    <img src={eth_qr} width="150" />
                    <h4>ETH wallet Address: 5rfhgcftr5fhfgd5rffhggh</h4>
                  </>
                )}
                <Button
                  className="mt-4"
                  color="primary"
                  onClick={(e) => {
                    setExist(false);
                    setId("");
                    setType("");
                    setIsThank(false);
                    setCryptoExist(false);
                    setShowQR({});
                  }}
                >
                  Close
                </Button>
              </div>
            ) : isThank ? (
              <div className="text-center">
                <h1>Thank you!</h1>
                <p>Your submission has been received.</p>
                <span id="timer">
                  This page will redirect in {count} seconds.
                </span>
              </div>
            ) : (
              <div className="text-center">
                <h1>Wait for incoming requests...</h1>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
      {changeBooth && (
        <ChangeBoothForSigPad show={changeBooth} toggle={toggleBooth} />
      )}
    </>
  );
}

export default SignaturePad2;
