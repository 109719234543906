
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import queryString from 'query-string';

class Login extends React.Component {
  render() {
    let params = queryString.parse(window.location.search);
    return (
      <>
        <footer
          className="py-5"
          style={{display: (typeof params['secret'] !== "undefined") ? 'none' : 'block'}}>
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;
