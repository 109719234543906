import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  Label,
  Input
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import {
  fetchTransactionListing,
  fetchTransactionListingSuccess,
} from "../../redux/Transactions/transactionsAction";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import Moment from "react-moment";
import CreateNewTransaction from "views/Modal/Tranactions/CreateNewTransaction";
import UpdateFullTransactionModal from "views/Modal/Tranactions/UpdateFullTransactionModal";
import AddPettyCashTransaction from "views/Modal/Tranactions/AddPettyCashTransaction";
import UpdatePettyCashTransactionModal from "views/Modal/Tranactions/UpdatePettyCashTransactionModal";
import { Link } from "react-router-dom";
import { Multiselect } from "react-widgets";
import { monthOptions, dayOptions, hourOptions, minuteOptions } from "../../constants/ticketConstants";
import { DropdownList } from "react-widgets";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import moment from "moment";

function Transactions(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [addTransactionModal, setTransactionAddModal] = useState(false);
  const [addPCTransactionModal, setPCTransactionAddModal] = useState(false);
  const [updatePCTransactionModal, setPCTransactionUpdateModal] = useState(false);
  const [years, setYears] = useState([]);
  const [transactionOptions, setTransactionOptions] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [getTransactionStatus, setGetTransactionStatus] = useState(false);
  const [editData, setEditData] = useState({});
  const [transactionStartDate, setTransactionStartDate] = useState({});
  const [transactionEndDate, setTransactionEndDate] = useState({});
  const [transactionFilterOption, setTransactionFilterOption] = useState("");
  const [snapshotData, setSnapshotData] = useState({
    month: monthOptions.find(m=>m.value==1),
    day: 1,
    year: (new Date).getFullYear(),
    hour: '00',
    min: '00'
  });
  const [worksheetData, setWorksheetData] = useState({
    startMonth: monthOptions.find(m=>m.value==((new Date()).getMonth()+1)),
    startDay: (new Date()).getDate(),
    startYear: (new Date()).getFullYear(),
    endMonth: monthOptions.find(m=>m.value==((new Date()).getMonth()+1)),
    endDay: (new Date()).getDate(),
    endYear: (new Date).getFullYear()
  });

  useEffect(() => {
    setTransactionStartDate(moment().subtract(1, 'month').format("YYYY-MM-DD"));
    setTransactionEndDate(moment().format("YYYY-MM-DD"));
    props.fetchTransactionListing({
      returnCount: true
    });
    //
    GetApiActionWithAuthorization("invoices/get-all-years").then((res) => {
      setYears(res.data)
    });
    //
    GetApiActionWithAuthorization("transactions/get-all-options").then((res) => {
      setTransactionOptions(res.data)
    });
  }, []);

  if (props.transaction.error && !unauthorized) {
    if (props.transaction.error.status === 403) {
      setUnauthorized(true);
    } else if (props.transaction.error.status === 440) {
      props.resetState();
      toast.error(props.transaction.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleAddTransaction = (e) => {
    setTransactionAddModal(!addTransactionModal);
  };

  const toggleAddPCTransaction = (e) => {
    setPCTransactionAddModal(!addPCTransactionModal);
  };

  const toggleUpdatePCTransaction = (id = "") => {
    setEditData({ id });
    setPCTransactionUpdateModal(!updatePCTransactionModal);
  };

  const toggleEdit = (id = "") => {
    setEditData({ id });
    setEditModal(!editModal);
  };

    //for formating date acc. to input type date
    const formatDate = (d) => {
      let month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
  
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
  
      return [year, month, day].join("-");
    };

  const renderTicketsTableData = (e) => {
    return props.transaction.allTransactions.map((transaction, i) => {
      return (
        <tr key={i}>
          <td>
            {transaction.comment.split(":").length > 1
              ? transaction.comment.split(":")[1].trim()
              : transaction.comment}
          </td>
          <td>
            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
              {transaction.time.toString().length === 10
                ? new Date(transaction.time * 1000)
                : new Date(transaction.time)}
            </Moment>
          </td>
          <td>{transaction.dest === 'retail' ? transaction.dest.replace('retail', 'coinretail') : transaction.dest}</td>
          <td>{transaction.src  === 'retail' ? transaction.src.replace('retail', 'coinretail') : transaction.src}</td>
          <td>
            $
            {transaction.denom100 * 100 +
              transaction.denom50 * 50 +
              transaction.denom20 * 20 +
              transaction.denom10 * 10 +
              transaction.denom5 * 5 +
              transaction.denom1 * 1}
          </td>
          <td>
            <span
              id={"update-transaction" + i}
              className="delete-item"
              onClick={(e) => {
                  if(transaction.dest.split("-").length > 1){
                    toggleUpdatePCTransaction(transaction.id);

                  }
                  else{
                    toggleEdit(transaction.id);
                  }
              }}
            >
              View/Edit
            </span>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content transaction-page" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Transactions</h1>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="8" className="search-left-btn">
                <Button
                  id="add-new"
                  color="primary"
                  onClick={toggleAddTransaction}
                >
                  Create Transaction
                </Button>
                <a href="/portal/transactions/reconcile" id="transaction-reconcile">
                  <Button
                    id="btn-transaction-reconcile"
                    className="mr-2"
                    color="primary">
                    Reconcile
                  </Button>
                </a>
                <a href="/portal/transactions/daily" id="transaction-daily">
                  <Button
                    id="btn-transaction-daily"
                    className="mr-2"
                    color="primary">
                    Daily Count
                  </Button>
                </a>
                <a href="/portal/transactions/drawer-transfer" id="transaction-drawer-transfer">
                  <Button
                    id="btn-transaction-drawer-transfer"
                    className="mr-2"
                    color="primary">
                    Drawer Transfer
                  </Button>
                </a>
                <Button
                  id="btn-petty-cash"
                  color="primary"
                  onClick={toggleAddPCTransaction}
                >
                  Petty Cash
                </Button>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="6" className="pr-0">
                <div className="d-flex">
                  <DropdownList
                    data={monthOptions}
                    value={snapshotData.month}
                    placeholder="Month"
                    textField="label"
                    onChange={(e) => {
                      setSnapshotData({
                        ... snapshotData,
                        month: e
                      })
                    }}
                  />
                  <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                  <DropdownList
                    data={dayOptions}
                    value={snapshotData.day}
                    placeholder="Day"
                    onChange={(e) => {
                      setSnapshotData({
                        ... snapshotData,
                        day: e
                      })
                    }}
                  />
                  <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                  <DropdownList
                    data={years}
                    value={snapshotData.year}
                    placeholder="Year"
                    onChange={(e) => {
                      setSnapshotData({
                        ... snapshotData,
                        year: e
                      })
                    }}
                  />
                  <DropdownList
                    data={hourOptions}
                    value={snapshotData.hour}
                    placeholder="Hour"
                    className="ml-2"
                    onChange={(e) => {
                      setSnapshotData({
                        ... snapshotData,
                        hour: e
                      })
                    }}
                  />
                  <span class="mx-1" style={{fontSize: "24px"}}> : </span>
                  <DropdownList
                    data={minuteOptions}
                    value={snapshotData.min}
                    placeholder="Min"
                    onChange={(e) => {
                      setSnapshotData({
                        ... snapshotData,
                        min: e
                      })
                    }}
                  />
                  <Button
                    id="btn-drawer-snapshot"
                    size="sm"
                    className="ml-2"
                    color="primary"
                    onClick={(e) => {
                      props.history.push("/portal/transactions/snapshot?date=" + snapshotData.month.value + "/" + snapshotData.day + "/" + snapshotData.year + "&time=" + snapshotData.hour + ":" + snapshotData.min);
                    }}>Get Drawer Snapshot</Button>
                  </div>
              </Col>
              <Col md="3"></Col>
              <Col md="3" className="pt-lg-2">
                <Multiselect
                  dataKey="name"
                  textField="value"
                  placeholder="Filter Out"
                  data={[
                    { name: 'metro', value: "Metro" },
                    { name: 'coin', value: "Coin" },
                    { name: 'retail', value: "Retail" },
                    { name: 'jewelryretail', value: "Jewelry Retail" }
                  ]}
                  onChange={(val) => {
                    props.fetchTransactionListing({
                      returnCount: true,
                      filter: val.map(v=>v.name)
                    });
                  }}
                />
              </Col>
            </Row>
            <Row>
            <Col md="8">
                <Row>
                  <Col md="6" className="d-flex">
                    <span class="mx-1" style={{fontSize: "22px"}}>Start: </span>
                    <DropdownList
                      data={monthOptions}
                      value={worksheetData.startMonth}
                      placeholder="Month"
                      textField="label"
                      onChange={(e) => {
                        setWorksheetData({
                          ... worksheetData,
                          startMonth: e
                        })
                      }}
                    />
                  <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                  <DropdownList
                    data={dayOptions}
                    value={worksheetData.startDay}
                    placeholder="Day"
                    onChange={(e) => {
                      setWorksheetData({
                        ... worksheetData,
                        startDay: e
                      })
                    }}
                  />
                  <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                  <DropdownList
                    data={years}
                    value={worksheetData.startYear}
                    placeholder="Year"
                    onChange={(e) => {
                      setWorksheetData({
                        ... worksheetData,
                        startYear: e
                      })
                    }}
                  />
                  </Col>
                  <Col md="6" className="d-flex pl-0">
                    <span class="mx-1" style={{fontSize: "22px"}}>End: </span>
                    <DropdownList
                      data={monthOptions}
                      value={worksheetData.endMonth}
                      placeholder="Month"
                      textField="label"
                      onChange={(e) => {
                        setWorksheetData({
                          ... worksheetData,
                          endMonth: e
                        })
                      }}
                    />
                  <div class="mx-1" style={{fontSize: "24px"}}> / </div>
                  <DropdownList
                    data={dayOptions}
                    value={worksheetData.endDay}
                    placeholder="Day"
                    onChange={(e) => {
                      setWorksheetData({
                        ... worksheetData,
                        endDay: e
                      })
                    }}
                  />
                  <span class="mx-1" style={{fontSize: "24px"}}> / </span>
                  <DropdownList
                    data={years}
                    value={worksheetData.endYear}
                    placeholder="Year"
                    onChange={(e) => {
                      setWorksheetData({
                        ... worksheetData,
                        endYear: e
                      })
                    }}
                  />
                  <Button
                    id="btn-get-worksheet"
                    size="sm"
                    className="ml-2"
                    onClick={(e) => {
                      props.history.push("/portal/transactions/worksheet?dateStart=" + worksheetData.startMonth.value + "/" + worksheetData.startDay + "/" + worksheetData.startYear + "&dateEnd=" + worksheetData.endMonth.value + "/" + worksheetData.endDay + "/" + worksheetData.endYear);
                    }}
                    color="primary">Get Worksheet</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="daily-count-total mb-2">
              <Col md="5">
                <Row>
                  <Col className="d-flex align-items-center mt-2">
                    <Label className="form-control-label mb-0">Start:</Label>
                    <Input
                      type="date"
                      value={transactionStartDate}
                      onChange={(e) => setTransactionStartDate(e.target.value)}
                    />
                  </Col>
                  <Col className="d-flex align-items-center mt-2">
                    <Label className="form-control-label mb-0">End:</Label>
                    <Input
                      type="date"
                      value={transactionEndDate}
                      onChange={(e) => setTransactionEndDate(e.target.value)}
                    />
                  </Col>
                </Row>
                </Col>
                  <Col md="3" style={{marginTop: '2px'}}>
                    <Row>
                    <Col className="d-flex align-items-center mt-2">
                      <DropdownList
                        data={transactionOptions}
                        value={transactionFilterOption}
                        placeholder="To / From"
                        textField="slug"
                        onChange={(e) => setTransactionFilterOption(e)}
                      />
                    </Col>
                      <Col className="d-flex align-items-center mt-2">
                        <Button
                          color="primary"
                          className="custom-btn-info"
                          size="sm"
                          onClick={(e) => {
                            setGetTransactionStatus(true);
                            props.fetchTransactionListing({
                              returnCount: true,
                              startDate: transactionStartDate,
                              endDate: transactionEndDate,
                              toFrom: transactionFilterOption.slug,
                              page: 0
                            });
                          }}
                        >
                          Get Transactions
                        </Button>
                      </Col>
                    </Row>
                  </Col>
              <Col md="4">
                <div className="text-right" style={{fontSize: "16px"}}>
                  Metro Total:&nbsp;
                  <span className="font-weight-600">
                    ${props.transaction.count.metro}
                  </span>
                </div>
                <div className="text-right" style={{fontSize: "16px"}}>
                  Coin Total:&nbsp;
                  <span className="font-weight-600">
                    ${props.transaction.count.coin}
                  </span>
                </div>
                <div className="text-right" style={{fontSize: "16px"}}>
                  Coin Dep:&nbsp;
                  <span className="font-weight-600">
                    ${props.transaction.count.coinDeposit}
                  </span>
                </div>
                <div className="text-right" style={{fontSize: "16px"}}>
                  Coin Daily:&nbsp;
                  <span className="font-weight-600">
                    ${props.transaction.count.coinDailyTotal}
                  </span>
                </div>
                <div className="text-right" style={{fontSize: "16px"}}>
                  Coin Change:&nbsp;
                  <span className="font-weight-600">
                    ${props.transaction.count.coinChangeTotal}
                  </span>
                </div>
                <div className="text-right" style={{fontSize: "16px"}}>
                  Jewelry Dep:&nbsp;
                  <span className="font-weight-600">
                    ${props.transaction.count.jewelryDeposit}
                  </span>
                </div>
                <div className="text-right" style={{fontSize: "16px"}}>
                  Jewelry Change:&nbsp;
                  <span className="font-weight-600">
                    ${props.transaction.count.jewelryChangeTotal}
                  </span>
                </div>
              </Col>
            </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Ticket #</th>
                  <th scope="col">Date</th>
                  <th scope="col">To</th>
                  <th scope="col">From</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
            {props.transaction.allTransactions.length > 0 && (
                <ReactPaginate
                  previousLabel={
                    <i className="fas fa-chevron-left text-white"></i>
                  }
                  nextLabel={
                    <i className="fas fa-chevron-right text-white"></i>
                  }
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  initialPage={props.transaction.count.page}
                  pageCount={props.transaction.count.allTransactions / 50}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={(e) => {
                    if (props.transaction.count.page !== e.selected) {
                      let params = {
                        returnCount: true,
                        page: e.selected
                      };
                      //
                      params.startDate = transactionStartDate;
                      params.endDate = transactionEndDate;
                      params.toFrom = transactionFilterOption.slug;
                      props.fetchTransactionListing(params);
                    }
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              )}
          </CardBody>
        </Card>
      </Container>
      {addTransactionModal && (
        <CreateNewTransaction
          {...props}
          show={addTransactionModal}
          toggle={toggleAddTransaction}
        />
      )}
      {addPCTransactionModal && (
        <AddPettyCashTransaction
          {...props}
          show={addPCTransactionModal}
          toggle={toggleAddPCTransaction}
        />
      )}

      {updatePCTransactionModal && (
        <UpdatePettyCashTransactionModal
          {...props}
          show={updatePCTransactionModal}
          toggle={toggleUpdatePCTransaction}
          editData={editData}
        />
      )}
      {editModal && (
        <UpdateFullTransactionModal
          {...props}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    transaction: state.transactionsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTransactionListing: (params) => dispatch(fetchTransactionListing(params)),
    addUpdatedData: (data) => dispatch(fetchTransactionListingSuccess(data)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
