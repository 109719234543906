import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import { drawerTransferOptions } from "../../constants/ticketConstants";
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Button,
  Table,
  Input
} from "reactstrap";
import Unauthorized from "views/Pages/Unauthorized";
import Header from "components/Headers/Header.js";

function TransactionDrawerTransfer(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [balanceBetweenCoinAndMetro, setBalanceBetweenCoinAndMetro] = useState(0);
    const [transactionData, setTransactionData] = useState({
        src: "",
        dest: "",
        denom100: 0,
        //denom50: 0,
        denom20: 0,
        denom10: 0,
        denom5: 0,
        denom1: 0,
        comment: ""
    });
    //
    useEffect(() => {
        PostApiWithAuthorizationAction('transactions/get-balance-between-accounts', {
            src: 'metrodrawer',
            dest: 'coindrawer'
        }).then((res) => {
            setBalanceBetweenCoinAndMetro(res.data);
        });
    }, []);
    //
    const submitHandler = (e) => {
        if (transactionData.dest === "") {
          toast.error("To field is required*", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (transactionData.src === "") {
            toast.error("From field is required*", {
              position: toast.POSITION.TOP_CENTER,
            });
        } else if (transactionData.denom1 < 0) {
            toast.error("$1 field should be greater or equal to 0 *", {
                position: toast.POSITION.TOP_CENTER,
            });
        } else if (transactionData.denom5 < 0) {
            toast.error("$5 field should be greater or equal to 0 *", {
                position: toast.POSITION.TOP_CENTER,
            });
        } else if (transactionData.denom10 < 0) {
            toast.error("$10 field should be greater or equal to 0 *", {
                position: toast.POSITION.TOP_CENTER,
            });
        } else if (transactionData.denom20 < 0) {
            toast.error("$20 field should be greater or equal to 0 *", {
                position: toast.POSITION.TOP_CENTER,
            });
        } /*else if (transactionData.denom50 < 0) {
            toast.error("$50 field should be greater or equal to 0 *", {
                position: toast.POSITION.TOP_CENTER,
            });
        }*/ else if (transactionData.denom100 < 0) {
            toast.error("$100 field should be greater or equal to 0 *", {
                position: toast.POSITION.TOP_CENTER,
            });
        } else {
          PostApiWithAuthorizationAction(
            "common/create-new-transaction",
            {
                ...transactionData,
                src: transactionData.src.value,
                dest: transactionData.dest.value,
                comment: transactionData.comment.length > 0 ? transactionData.comment : 'Drawer Transfer'
            }
          ).then((res) => {
            //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/portal/transactions");
          });
        }
    };

  return unauthorized ? (<Unauthorized />) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
            <CardHeader className="bg-white d-flex justify-content-between">
                <h1>Drawer Transfer</h1>
                <Button
                type="button"
                color="primary"
                onClick={() => {
                    props.history.goBack();
                }}
                >
                <i className="fas fa-chevron-left"></i> Back
                </Button>
            </CardHeader>
            <CardBody>
                <div className="px-4">
                    {(balanceBetweenCoinAndMetro != 0 && (
                        <span>Coin owes Metro: ${Math.abs(balanceBetweenCoinAndMetro)}</span>
                    ))}
                <Table className="transaction-table" striped bordered>
                <tbody>
                <tr>
                    <td style={{ width: "100px" }}>To:</td>
                    <td colSpan="2">
                        <div className="d-flex align-items-end">
                        <DropdownList
                            id="from"
                            data={drawerTransferOptions}
                            textField="label"
                            containerClassName="from-drop"
                            value={transactionData.dest}
                            onChange={(e) => {
                                if (e === transactionData.src) {
                                    toast.error("To and From must have different values", {
                                        position: toast.POSITION.TOP_CENTER,
                                    });
                                } else {
                                    if (transactionData.src === "") {
                                        setTransactionData({
                                            ...transactionData,
                                            src: drawerTransferOptions.find(w=>w.value != e.value),
                                            dest: e
                                        });
                                    } else {
                                        setTransactionData({
                                            ...transactionData,
                                            dest: e,
                                        });
                                    }
                                }
                            }}
                        />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "100px" }}>From:</td>
                    <td colSpan="2">
                        <div className="d-flex align-items-end">
                        <DropdownList
                            id="from"
                            data={drawerTransferOptions}
                            textField="label"
                            containerClassName="from-drop"
                            value={transactionData.src}
                            onChange={(e) => {
                                if (e === transactionData.dest) {
                                    toast.error("To and From must have different values", {
                                    position: toast.POSITION.TOP_CENTER,
                                    });
                                } else {
                                    if (transactionData.dest === "") {
                                        setTransactionData({
                                            ...transactionData,
                                            src: e,
                                            dest: drawerTransferOptions.find(w=>w.value != e.value)
                                        });
                                    } else {
                                        setTransactionData({
                                            ...transactionData,
                                            src: e,
                                        });
                                    }
                                }
                            }}
                        />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: "100px" }}>Comment:</td>
                    <td colSpan="2" className="comment">
                        <Input
                            type="text"
                            placeholder="Comment"
                            value={transactionData.comment}
                            onChange={(e) => {
                                setTransactionData({
                                ...transactionData,
                                comment: e.target.value,
                                });
                            }}
                        />
                    </td>
                    </tr>
                    <tr>
                    <td style={{ width: "100px" }}>$100</td>
                    <td>
                        <Input
                            type="number"
                            min="0"
                            value={transactionData.denom100}
                            onChange={(e) => {
                                setTransactionData({
                                ...transactionData,
                                denom100: e.target.value,
                                });
                            }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                            }}
                        />
                    </td>
                    <td> ${100 * transactionData.denom100}</td>
                    </tr>
                    {/*<tr>
                    <td style={{ width: "100px" }}>$50</td>
                    <td>
                        <Input
                            type="number"
                            value={transactionData.denom50}
                            min="0"
                            onChange={(e) => {
                                setTransactionData({
                                ...transactionData,
                                denom50: e.target.value,
                                });
                            }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                            }}
                        />
                    </td>
                    <td>${50 * transactionData.denom50} </td>
                    </tr>*/}
                    <tr>
                    <td style={{ width: "100px" }}>$20</td>
                    <td>
                        <Input
                            type="number"
                            value={transactionData.denom20}
                            min="0"
                            onChange={(e) => {
                                setTransactionData({
                                ...transactionData,
                                denom20: e.target.value,
                                });
                            }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                            }}
                        />
                    </td>
                    <td>${20 * transactionData.denom20} </td>
                    </tr>
                    <tr>
                    <td style={{ width: "100px" }}>$10</td>
                    <td>
                        <Input
                            type="number"
                            value={transactionData.denom10}
                            min="0"
                            onChange={(e) => {
                                setTransactionData({
                                ...transactionData,
                                denom10: e.target.value,
                                });
                            }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                            }}
                        />
                    </td>
                    <td>${10 * transactionData.denom10} </td>
                    </tr>
                    <tr>
                    <td style={{ width: "100px" }}>$5</td>
                    <td>
                        <Input
                            type="number"
                            value={transactionData.denom5}
                            min="0"
                            onChange={(e) => {
                                setTransactionData({
                                ...transactionData,
                                denom5: e.target.value,
                                });
                            }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                            }}
                        />
                    </td>
                    <td> ${5 * transactionData.denom5} </td>
                    </tr>
                    <tr>
                    <td style={{ width: "100px" }}>$1</td>
                    <td>
                        <Input
                            type="number"
                            value={transactionData.denom1}
                            min="0"
                            onChange={(e) => {
                                setTransactionData({
                                ...transactionData,
                                denom1: e.target.value,
                                });
                            }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                            }}
                        />
                    </td>
                    <td> ${1 * transactionData.denom1}</td>
                    </tr>
                    <tr>
                    <td colSpan="2">Total:</td>
                    <td>
                        $
                        {transactionData.denom100 * 100 +
                        //transactionData.denom50 * 50 +
                        transactionData.denom20 * 20 +
                        transactionData.denom10 * 10 +
                        transactionData.denom5 * 5 +
                        transactionData.denom1 * 1}{" "}
                    </td>
                    </tr>
                </tbody>
                </Table>
                <div className="text-left mt-2">
                    <Button color="primary" onClick={(e) => submitHandler()}>Create Transaction</Button>
                </div>
            </div>
        </CardBody>
        </Card>
    </Container>
    </>
  );
}
  
export default TransactionDrawerTransfer;