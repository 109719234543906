import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from "reactstrap";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import Moment from "react-moment";
import moment from "moment";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../constantFunctions/apiActions";
import ReactPaginate from "react-paginate";
import { monthOptions, dayOptions, paidOptions } from "../../constants/ticketConstants";
import { DropdownList } from "react-widgets";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import qs from "query-string";
import { metricFilterOptions } from "../../constants/ticketConstants"

function Metrics(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    let groupMetricBy = qs.parse(props.location.search, { ignoreQueryPrefix: true }).start;
    const [metrics, setMetrics] = useState({
        data: [],
        originalData: [],
        count: 0
    });
    const [hierachy, setHierachy] = useState([]);
    const [filter, setFilter] = useState({
        showRawProfit: 0,
        groupMetricBy: groupMetricBy || 'keyword',
        sortBy: "profitTotal",
        query: {
            groupMetricBy: groupMetricBy || 'keyword',
            value: ''
        }
    });

    useEffect(() => {
        load();
    }, []);

    if (metrics.error && !unauthorized) {
        if (metrics.error.status === 403) {
          setUnauthorized(true);
        } else if (metrics.error.status === 440) {
          toast.error(metrics.error.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.history.push("/auth/login");
        }
    }

    const filterLocale = query => {
        setMetrics({
            ... metrics,
            data: metrics.originalData.filter(d => d.key.includes(query))
        })
    };

    const resetFilter = () => {
        setFilter({
            ... filter,
            showRawProfit: 0,
            groupMetricBy: 'keyword',
            sortBy: "profitTotal",
            query: ''
        })
    };


const loadChildMetric = (params, hierachyData = []) => {
    let _metrics = metrics.data;
    if (!hierachyData.includes(filter.groupMetricBy)) {
        hierachyData = [filter.groupMetricBy].concat(hierachyData);
    }
    if (!hierachyData.includes(params.groupMetricBy)) {
        hierachyData.push(params.groupMetricBy);
    }
    if (!hierachyData.includes(params.query.value)) {
        hierachyData.push(params.query.value);
    }
    if (!hierachyData.includes(filter.groupMetricBy)) {
        hierachyData.push(filter.groupMetricBy);
    }
    //
    if (hierachyData.length - 2 > 0) {
        _metrics = _metrics.find(m=>m.key==hierachyData[1]);
        if (Object.keys(_metrics.childrens).length > 0) {
            let nestedHierachicData = hierachyData.filter(h=>(h!=hierachyData[0] && h !=hierachyData[1]));
            let columns = nestedHierachicData.filter((h, index) => (index%2)==0);
            let values = nestedHierachicData.filter((h, index) => (index%2)!=0);
            for(let i=0; i< columns.length; i++) {
                if (i==columns.length-1) {
                    _metrics = (typeof _metrics.childrens[columns[i]] !== 'undefined') ? _metrics.childrens[columns[i]].data : [];
                } else {
                    _metrics = _metrics.childrens[columns[i]].data.find(m=>m.key==values[i]);
                }
            }
        } else {
            _metrics = metrics.data;
        }
    }
    let _loadMetricIndex = _metrics.findIndex(m=>m.key==params.query.value);
    //
    if (typeof _metrics[_loadMetricIndex].childrens === 'undefined') {
        _metrics[_loadMetricIndex].childrens = {};
    }
    //
    if (!_metrics[_loadMetricIndex].childrens.hasOwnProperty(params.query.groupMetricBy)) {
        PostApiWithAuthorizationAction("metrics/get-all-data", {
            metricData: params,
            hierachyData
        })
        .then((res) => {
            let _obj = {};
            _obj[params.query.groupMetricBy] = {
                groupMetricBy: params.query.groupMetricBy,
                data: res.data
            };
            //set row childrens
            _metrics[_loadMetricIndex].childrens = Object.assign(_obj, _metrics[_loadMetricIndex].childrens);
            //clear focus
            window.document.getElementById(params.groupMetricBy + "_" + params.query.value.split(' ').join('_') + '_input').blur();
            //set hierachy
            setHierachy(hierachyData);
        });
    } else {
        delete _metrics[_loadMetricIndex].childrens[params.query.groupMetricBy];
        //force data reload
        setFilter({ ... filter, sortBy: "profitTotal"});
        //clear focus
        window.document.getElementById(params.groupMetricBy + "_" + params.query.value.split(' ').join('_') + '_input').blur();
    }
};

  const load = (params) => {
    params = Object.assign(filter, params);
    PostApiWithAuthorizationAction("metrics/get-all-data", {
        metricData: filter
    })
    .then((res) => {
        setMetrics(res)
    })
    .catch((err) => {
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const addToIgnoreList = (params) => {
    PostApiWithAuthorizationAction("metrics/metric-ignore-add", params)
      .then((res) => {
        load();
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          props.history.push("/auth/login");
        }
        toast.error(err.err, { position: toast.POSITION.TOP_CENTER });
      });
    };

    const renderMetricHeaderTable = (name) => {
        return (
            <>
                <thead className="thead-light">
                    <tr>
                        <th scope="col">{ name }</th>
                        <th scope="col">Total Profit</th>
                        <th scope="col">Num Tickets</th>
                        <th scope="col">avg/per</th>
                    </tr>
                </thead>
            </>
        );
    };

    const renderChildTable = (row) => {
        return renderMetrics(row.groupMetricBy, row);
    };

    const renderMetrics = (groupMetricBy, _metrics) => {
        return (
            <>
                <Table className="ticket-table table-listing my-2" responsive bordered>
                    { renderMetricHeaderTable(groupMetricBy) }
                    { renderMetricsBodyTable(groupMetricBy, _metrics) }
                </Table>
            </>
        );
    };

    const renderMetricsBodyTable = (groupMetricBy, _metrics) => {
        return (
            <tbody>
                {(_metrics.data.map((_data, i) => {
                let avgPer = Math.round((_data.value / _data.count * 100)) / 100;
                let localeFilterOptions = metricFilterOptions.filter(m=>m!=groupMetricBy && !_data.hierachyData.filter((d, index)=> (index%2) == 0).includes(m));
                return (
                    <>
                        <tr>
                            <td scope="col" className="text-left">
                                <Row>
                                    <Col md="6">{_data.key}</Col>
                                    <Col md="6" className="text-right">
                                        { !_data.ignored && (
                                        <Button
                                            id="add-ignore-list"
                                            style={{ fontSize: '9px'}}
                                            color="danger"
                                            size="sm"
                                            onClick={(e) => {
                                                if (window.confirm('[' + _data.key + '] has been ignored. It will no longer show up after tonight.')) {
                                                    addToIgnoreList({
                                                        value: _data.key,
                                                        type: groupMetricBy.toLowerCase()
                                                    });
                                                }
                                            }}>Add to Ignore list</Button>
                                        )}
                                    </Col>
                                </Row>
                            </td>
                            <td scope="col">{_data.value ? _data.value.toFixed(2).replace('.00', '') : 0}</td>
                            <td scope="col">{_data.count}</td>
                            <td scope="col">{avgPer}</td>
                            {localeFilterOptions.length > 0 && (
                                <td scope="col">
                                    <DropdownList
                                        data={localeFilterOptions}
                                        placeholder="Filter"
                                        className="mr-1"
                                        id={groupMetricBy + '_' + _data.key.split(' ').join('_')}
                                        value=""
                                        style={{ fontSize: '12px'}}
                                        onChange={(e) => {
                                            loadChildMetric({
                                                query: {
                                                    groupMetricBy: e,
                                                    value: _data.key
                                                },
                                                sortBy: 'profitTotal',
                                                groupMetricBy
                                            }, _data.hierachyData)
                                        }}
                                    />
                                </td>
                            )}
                        </tr>
                        <tr style={{border: '1px solid #e9ecef', display: Object.keys(_data.childrens).length > 0 ? undefined: 'none'}}>
                            <td colSpan="3">
                            {(//key = "race" || "keyword" || "zip" || "age"
                                Object.keys(_data.childrens).map(function(key, index) {
                                    return renderChildTable(_data.childrens[key]);
                                })
                            )}
                            </td>
                        </tr>
                    </>
                )
            })
        )}
        </tbody>
        )
    };

    //
    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader>
                <div className="text-center">
                    <h1>Metrics</h1>
                </div>
              </CardHeader>
              <CardBody className="checks-main-content" id="metrics-content">
                <Row className="mb-2">
                    <Col md="3">
                        <span className="">Start With:</span>
                        <a
                            href={"/portal/metrics?start=keyword"}
                            className={classnames({'font-weight-bold': filter.groupMetricBy === "keyword"})}>Keyword</a>&nbsp;|&nbsp;
                        <a
                            href={"/portal/metrics?start=age"}
                            className={classnames({'font-weight-bold': filter.groupMetricBy === "age"})}>Age</a>&nbsp;|&nbsp;
                        <a
                            href={"/portal/metrics?start=race"}
                            className={classnames({'font-weight-bold': filter.groupMetricBy === "race"})}>Race</a>&nbsp;|&nbsp;
                        <a
                            href={"/portal/metrics?start=zip"}
                            className={classnames({'font-weight-bold': filter.groupMetricBy === "zip"})}>Zip</a>
                    </Col>
                    <Col md="5" className="text-center">
                        <Label className="form-control-label" check>
                            <Input
                                type="checkbox"
                                id="showRawProfit"
                                checked={filter.showRawProfit}
                                onChange={(e) => {
                                    load({
                                        ...filter,
                                        showRawProfit: e.target.checked
                                    });
                                }}
                                />{" "}
                            Show Raw Profit (no adwords calculation)
                        </Label>&nbsp;|&nbsp;
                        <a href={"/portal/metrics/ignore"}>Edit Ignore List</a>   
                    </Col>
                    <Col md="4">
                        <div className="d-flex d-flex-full mt-lg-n2">
                            <Input
                                type="text"
                                placeholder="Filter Table"
                                style={{height: '35px'}}
                                value={filter.query.value}
                                onChange={(e) => {
                                    filterLocale(e.target.value)
                                    setFilter({
                                        ... filter,
                                        query: {
                                            ...filter.query,
                                            value: e.target.value
                                        }
                                    })
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                { renderMetrics(filter.groupMetricBy, metrics) }
              </CardBody>
            </Card>
          </Container>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(Metrics);