import React, { useState, useEffect } from "react";
import { Input, Table, FormGroup, Button } from "reactstrap";

import { DropdownList } from "react-widgets";
import {
  PostApiWithAuthorizationAction,
  GetApiActionWithAuthorization
} from "constantFunctions/apiActions";
import { toast } from "react-toastify";
import { formValid } from "constantFunctions/formValidations";
import { itemType, itemMaterial } from "../../../constants/ticketConstants";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

function AddMetroItems(props) {
  const defaultVal = {
    itemType: "3 Jewelry",
    description: "",
    serial: "",
    model: "",
  };
  const [newItems, setNewItems] = useState([defaultVal]);
  const [maxRows, setMaxRows] = useState(10);
  const [metroItems, setMetroItems] = useState([]);
  const [requiredItemDescriptions, setRequiredItemDescriptions] = useState([]);
  // const [serial, setSerial] = useState("");
  // const [model, setModel] = useState("");

  useEffect(() => {
    GetApiActionWithAuthorization("coin-tickets/get-all-coin-items", {
      itemType: 'metro'
    })
    .then((res) => {
      setMetroItems(res.data.map(i=>i.name));
      return  GetApiActionWithAuthorization("item-descriptions/get-all-data")
    })
    .then((res) => {
      let descriptions = [];
      res.data.forEach(function(item) {
        descriptions.push(item.keyword);
      });
      setRequiredItemDescriptions(descriptions)
    })
    .catch((err) => {
      if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      } else {
        console.log(err);
        toast.error("Something went wrong! /n Please clear and retry", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  }, []);

  const handleAutoFormSubmit = (e, i) => {
    //apply logic only when ENTER hit or TAB hit
    if ((e.key === 'Enter' || e.key === "Tab") && !document.getElementById("add-all-items").disabled) {
      e.preventDefault()
      handleSubmit()
    }
  };

  const handleKeyDown = event => {
    let _key = event.key
    //apply logic only when ENTER hit
    if (_key === 'Enter') {
      let index = event.target.id.replace(/\D/g, ""),
          findItem = newItems[index],
          items = [...newItems],
          material = findItem.description.split(". ")[0].trim(),
          description = findItem.description.split(". ")[1].trim();
      /**
       * index is last item
       * fields filled
       * then add new row
       */
      if (((newItems.length-1) == index) && material.length > 0 && description.length > 0) {
        items.push({
          itemType: findItem.itemType,
          description: findItem.description.split(". ")[0].trim(),
          model: '',
          serial: ''
        });
        setNewItems([... items]);
      }
    }
    //
    setTimeout(function() {
      if ((_key === 'Enter' || _key === "Tab")
          && !document.getElementById("add-all-items").disabled) {
        event.preventDefault()
        handleSubmit()
      }
    }, 10)
  };

  const addRowBelow = (i) => {
    if (newItems.length < maxRows) {
      let lastItem = newItems[i];
      let items = [...newItems];
      let maxRows = 10;
      //items.splice(i + 1, 0, defaultVal);
      for(i=0; i<(maxRows-newItems.length); i++) {
        items.push({
          itemType: lastItem.itemType,
          description: lastItem.description.split(". ")[0].trim(),
          model: '',
          serial: ''
        });
      }
      setNewItems([... items]);
    } else {
      toast.error("*Maximum limit(10) of add items exceeded", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const removeRow = (i) => {
    if (newItems.length > 1) {
      let items = [...newItems];
      items.splice(i, 1);
      setNewItems(items);
    } else {
      toast.error("*Can't remove, Atleast 1 item required", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  const getDescriptionValue = (val, _description = "") => {
    let descriptionItem = val
      .split(". ")
      .map((t) => t.trim())
      .join(".");
    let desc =
      (_description.split(". ")[0]
        ? _description.split(". ")[0].trim()
        : " ") +
      ". " +
      descriptionItem;

    let weightProps = [
      "lb",
      "lbs",
      "gram",
      "grams",
      "pound",
      "pounds",
      "lb.",
      "lbs.",
      "gram.",
      "grams.",
      "pound.",
      "pounds.",
    ];
    function endsWithAny(suffixes, string) {
      return suffixes.some(function (suffix) {
        return string.endsWith(suffix);
      });
    }

    if (!endsWithAny(weightProps, desc)) {
      if (
        _description.split(". ")[0].trim() === "Costume Jewelry" ||
        _description.split(". ")[0].trim() === "Silver Plate"
      ) {
        desc = desc + " " + "lbs";
      } else if (_description.split(". ")[0].trim() !== "Other") {
        desc = desc + " " + "grams";
      }
    }

    if (_description.split(". ")[2]) {
      desc = desc + ". " + _description.split(". ")[2].trim();
    }
    return desc
  }
  const changeDesctriptionValue = (val, item, i) => {
    let descriptionItem = val
      .split(". ")
      .map((t) => t.trim())
      .join(".");
    let desc =
      (item.description.split(". ")[0]
        ? item.description.split(". ")[0].trim()
        : " ") +
      ". " +
      descriptionItem;

    let weightProps = [
      "lb",
      "lbs",
      "gram",
      "grams",
      "pound",
      "pounds",
      "lb.",
      "lbs.",
      "gram.",
      "grams.",
      "pound.",
      "pounds.",
    ];
    function endsWithAny(suffixes, string) {
      return suffixes.some(function (suffix) {
        return string.endsWith(suffix);
      });
    }

    if (!endsWithAny(weightProps, desc)) {
      if (
        item.description.split(". ")[0].trim() === "Costume Jewelry" ||
        item.description.split(". ")[0].trim() === "Silver Plate"
      ) {
        desc = desc + " " + "lbs";
      } else if (item.description.split(". ")[0].trim() !== "Other") {
        desc = desc + " " + "grams";
      }
    }

    if (item.description.split(". ")[2]) {
      desc = desc + ". " + item.description.split(". ")[2].trim();
    }
    let items = [...newItems];
    items[i] = { ...items[i], description: desc };
    setNewItems(items);
  };

  //-----------return weight value from item object ----------
  const getWeightFromString = (item) => {
    return item.description.split(". ")[2]
      ? item.description.split(". ")[2].split(":")[1].split(")")[0].trim()
      : "";
  };

  const setCursorPosition = (inputElem, position) => {
    if (inputElem.setSelectionRange) {
      inputElem.focus();
      inputElem.setSelectionRange(position, position);
    }
  }

  const handleSubmit = (i = 0) => {
    let availableItems = newItems.filter((it) => {
      return it.description.split(". ").map(s => s.trim()).filter(p => p !== "" && p !== "grams" && p !=="lbs").length > 1
    });
    let requiredModelSerial = [];
    if (availableItems.length > 0) {
      for(let index=0; index < newItems.length; index++) {
        let containKeyword = requiredItemDescriptions.findIndex(t=>newItems[index].description.includes(t));
        if(containKeyword >= 0 && (newItems[index].model.length === 0 || newItems[index].serial.length === 0)) {
          requiredModelSerial.push('Line ' + (index+1) + ': Model & Serial fields are required.');
        }
      }
    }
    if (availableItems.length === 0) {
      let html = (
        <ul>
          <li>
            <h5 className="text-white">
              *Item Material and Description is required for all items.
            </h5>
          </li>
        </ul>
      );
      toast.error(html, {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (requiredModelSerial.length > 0) {
      toast.error(requiredModelSerial.join('\n'), {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      document.getElementById("add-all-items").disabled = true;
      PostApiWithAuthorizationAction("metro-tickets/add-new-items-in-edit", {
        ticketId: props.match.params.id,
        allItems: availableItems.map((it) => {
          return {
            ...it,
            propertyCode: it.itemType.split(" ")[0].trim(),
            ticketId: props.match.params.id,
            soldTo: "",
            description: getDescriptionValue(it.description.split(". ")[1]
            ? it.description.split(". ")[1].trim().replace('grams', '').replace('lbs', '')
            : " ", it.description)
          };
        }),
      })
        .then((res) => {
          document.getElementById("description"+i).focus()
          document.getElementById("add-all-items").disabled = false;
          //toast.success("Successfully Added");
          props.fetchTicketItemsListingSuccess({
            data: res.data,
            ticketDetails: res.ticketDetails,
          });
          setNewItems([
            {
              ... defaultVal,
              description: newItems[0].description.split(". ")[0].trim(),
              itemType: newItems[0].itemType
            }
          ]);
          setTimeout(function() {
            setCursorPosition(document.getElementById('description' + i), 0)
          }, 50)
        })
        .catch((err) => {
          document.getElementById("add-all-items").disabled = false;
          if (err.status === 440) {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          } else {
            console.log(err);
            toast.error("Something went wrong! /n Please clear and retry", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    }
  };
  return (
    <>
      <Table className="metro-add-item" borderless responsive="sm">
        <tbody>
          <tr>
            <th style={{ minWidth: "90px" }}>Type</th>
            <th style={{ minWidth: "120px" }}></th>
            <th style={{ minWidth: "300px" }}>Item Description</th>
            <th style={{ width: "80px" }}>Model</th>
            <th style={{ width: "80px" }}>Serial</th>
            {(newItems.length < 10) && (
            <th style={{ width: "100px" }}></th>
            )}
          </tr>
          {newItems.map((it, i) => {
            let itemDescription = it.description.split(". ").filter((v, i)=>i>0).join('. ')
            return (
              <tr key={i} id={"row"+i}>
                <td>
                  <FormGroup className="m-0">
                    <DropdownList
                      className="text-left"
                      placeholder="Select"
                      containerClassName="metro-type-drop"
                      data={itemType}
                      placeholder="Select"
                      value={it.itemType}
                      onChange={(e) => {
                        let items = [...newItems];
                        items[i] = { ...items[i], itemType: e };
                        setNewItems(items);
                      }}
                    />
                  </FormGroup>
                </td>
                <td>
                <DropdownList
                      id={"material"+i}
                      className="text-left"
                      data={metroItems}
                      value={it.description.split(". ")[0].trim()}
                      containerClassName="metro-item-drop"
                      placeholder="Select"
                      onChange={(e) => {
                        let desc =
                          e +
                          ". " +
                          (it.description.split(". ")[1]
                            ? it.description.split(". ")[1].trim()
                            : "");
                        if (it.description.split(". ")[2]) {
                          desc =
                            desc + ". " + it.description.split(". ")[2].trim();
                        }
                        let items = [...newItems];
                        items[i] = { ...items[i], description: desc };
                        setNewItems(items);
                        setTimeout(function() {
                          setCursorPosition(document.getElementById('description' + i), 0)
                        }, 50)
                      }}
                    />
                </td>
                <td>
                  <div className="d-flex align-items-center w-100">
                    <Input
                      id={"description"+i}
                      tabIndex={it.description.split(". ")[0].trim() != 'Other' ? i+1 : 0}
                      type="text"
                      className="form-control mr-1"
                      placeholder="Description"
                      value={ itemDescription }
                      onBlur={(e) =>
                        changeDesctriptionValue(e.target.value, it, i)
                      }
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        let desc = it.description.split(". ")[0].trim() + ". " + newValue;
                        let items = [...newItems];
                        items[i] = { ...items[i], description: desc };
                        setNewItems(items);
                      }}
                    />
                    {/*<Input
                      id={"weight"+i}
                      type="text"
                      tabIndex={it.description.split(". ")[0].trim() != 'Other' ? i+2 : 0}
                      style={{ width: "60px" }}
                      placeholder="Weight"
                      value={getWeightFromString(it)}
                      onKeyDown={(e) => {
                        handleAutoFormSubmit(e, i)
                      }}
                      onChange={(e) => {
                        let desc =
                          (it.description.split(". ")[0]
                            ? it.description.split(". ")[0].trim()
                            : "") +
                          ". " +
                          (it.description.split(". ")[1]
                            ? it.description.split(". ")[1].trim()
                            : "") +
                          ". " +
                          "(Weight:" +
                          e.target.value +
                          ")";
                        let items = [...newItems];
                        items[i] = { ...items[i], description: desc };
                        setNewItems(items);
                      }}
                    />*/}
                  </div>
                </td>
                <td>
                  <FormGroup className="m-0">
                    <Input
                      id={"model"+i}
                      type="text"
                      placeholder="Model"
                      tabIndex={it.description.split(". ")[0].trim() == 'Other' ? i+1 : 0}
                      value={it.model}
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        let items = [...newItems];
                        items[i] = { ...items[i], model: e.target.value };
                        setNewItems(items);
                      }}
                    />
                  </FormGroup>
                </td>
                <td>
                  <FormGroup className="m-0">
                    <Input
                      id={"serial"+i}
                      type="text"
                      value={it.serial}
                      tabIndex={it.description.split(". ")[0].trim() == 'Other' ? i+2 : 0}
                      placeholder="Serial"
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        let items = [...newItems];
                        items[i] = { ...items[i], serial: e.target.value };
                        setNewItems(items);
                      }}
                    />
                  </FormGroup>
                </td>
                {(newItems.length < 10) && (i==newItems.length-1) && (
                <td className="text-left">
                  <div className="ml-2">
                    <Button
                      id={"add-item"+i}
                      className="add-btn"
                      color="primary"
                      onClick={(e) => addRowBelow(i)}
                    >
                      + Add Rows
                    </Button>
                    {/*
                    <Button
                      id={"remove-item"+i}
                      className="add-btn"
                      color="primary"
                      onClick={(e) => removeRow(i)}
                    >
                      - Remove Row
                    </Button>*/}
                  </div>
                </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="text-center my-2">
        <Button id="add-all-items" className="px-3 py-1" color="primary" onClick={(e) => {
          e.preventDefault();
          handleSubmit()
        }}>
          Add all Items
        </Button>
      </div>
    </>
  );
}

export default AddMetroItems;