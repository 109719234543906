import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  Label,
  FormGroup,
  Row,
  Col,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import {
  jewelryPaymentOptionsReceipt,
  stationOptions,
  jewelryReceiptPaymentTypes,
  paymentCashOptions
} from "../../constants/ticketConstants";
import { resetState } from "redux/common/commonAction";
import {
  saveReceiptFormDetails,
  saveItemsDetails,
  resetAddReceiptForm,
} from "../../redux/JewelryReceipt/JewelryReceiptAction";
import Unauthorized from "views/Pages/Unauthorized";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { validateJewelryReceiptForm } from "../../constantFunctions/formValidations";
import Autocomplete from "components/AutoComplete/Autocomplete";
import PhoneInput from "react-phone-input-2";
import { enableFileUploader } from "libs/common";
import { jsonToFormData } from "libs/common";

function CreateNewReceipt(props) {
  const [keywords, setKeywords] = useState([]);
  const [items, setitems] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [keyLoad, setKeyLoad] = useState(false);
  const [allClerks, setAllClerks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromOptions, setFromOption] = useState([]);
  const [boothOptions, setBoothOptions] = useState([]);
  const [checkNumberPrefix, setCheckNumberPrefix] = useState('');

  useEffect(() => {
    if (props.receiptItemsDetails.items.length === 0) {
      addRowInItem();
    }
    GetApiActionWithAuthorization("common/get-items-select-options")
      .then((res) => {
        setitems(res.data);
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
      GetApiActionWithAuthorization("common/get-check-setting").then((res) => {
        props.saveReceiptFormDetails({
          name: "checkNumber",
          value: res.data.jewelryreceiptCheckNumberPrefix,
        });
        //
        setCheckNumberPrefix(res.data.jewelryreceiptCheckNumberPrefix.toString());
      });
    //load fileuploader with plain jquery
    window.jQuery(document).ready(function() {
      enableFileUploader();
    });
  }, []);

  // const getKeywords = (e) => {
  //   if (keywords.length === 0) {
  //     GetApiActionWithAuthorization("common/get-keywords-for-form")
  //       .then((res) => {
  //         setKeyLoad(false);
  //         setKeywords(res.data);
  //       })
  //       .catch((err) => setKeyLoad(false));
  //   }
  // };

  const getFromOptions = (e) => {
    if (e && fromOptions.length === 0) {
      setKeyLoad(true);
      GetApiActionWithAuthorization("common/get-from-select-options")
        .then((res) => {
          setKeyLoad(false);
          setFromOption(res.data);
        })
        .catch((err) => setKeyLoad(false));
    }
  };

  const fetchAllClerks = (e) => {
    if (e && allClerks.length <= 0) {
      GetApiActionWithAuthorization("common/get-clerk-listing")
        .then((res) => {
          setAllClerks(res.data);
        })
        .catch((err) => {
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    }
  };

  const fetchAllBooth = (e) => {
    if (e) {
      GetApiActionWithAuthorization("common/get-all-booths?displayOrderType=JewelryReceipt").then((res) => {
        setBoothOptions(res.data);
      });
    }
  };

  const addRowInItem = (e) => {
    let obj = {
      itemDescription: "",
      unitPrice: "",
      price: "",
      qty: "",
      tax: "",
    };
    let olderItems = props.receiptItemsDetails.items;
    olderItems.push(obj);
    props.saveItemsDetails(olderItems);
  };

  const removeRowInItem = (index) => {
    let olderItems = props.receiptItemsDetails.items;
    olderItems.splice(index, 1);
    props.saveItemsDetails(olderItems);
  };

  const changeItemValues = (name, value, index) => {
    let temp = props.receiptItemsDetails.items;
    let itemObj = temp[index];
    itemObj[name] = value;
    temp[index] = itemObj;
    if (name === "qty") {
      itemObj["unitPrice"] = (
        parseFloat(itemObj.price) / parseInt(value)
      ).toFixed(2);
    }

    if (name === "price") {
      if (itemObj.qty) {
        itemObj["unitPrice"] = (
          parseFloat(value) / parseInt(itemObj.qty)
        ).toFixed(2);
      } else {
        itemObj["unitPrice"] = parseFloat(value).toFixed(2);
      }

      //tax amount
      itemObj["tax"] = ((parseFloat(value) * 8.375) / 100).toFixed(2);
    }
    props.saveItemsDetails(temp);
  };

  const formSubmitHandler = (e) => {
    let validation = validateJewelryReceiptForm(
      { ...props.receiptFormDetails, paidBy: props.receiptFormDetails.payment },
      props.receiptItemsDetails.items
    );
    if (validation.success) {
      let data = jsonToFormData({
        formDetails: {
          ...props.receiptFormDetails,
          fromx: props.receiptFormDetails.fromx.name,
          printer: props.receiptFormDetails.printer.value,
          paymentType: props.receiptFormDetails.paymentType.value,
          booth: props.receiptFormDetails.booth.name,
          payment: props.receiptFormDetails.payment.value,
          clerk: props.receiptFormDetails.clerk.first_name,
          ticketAmount: props.receiptItemsDetails.items
            .reduce((prev, curr) => {
              if (curr.price === "") {
                return prev + 0.0;
              } else {
                return prev + parseFloat(curr.price);
              }
            }, 0)
            .toFixed(2),
          tax: props.receiptItemsDetails.items
            .reduce((prev, curr) => {
              if (curr.tax === "") {
                return prev + 0.0;
              } else {
                return prev + parseFloat(curr.tax);
              }
            }, 0)
            .toFixed(2),
        },
        items: props.receiptItemsDetails.items,
      });
      //
      window.jQuery('[name="files[]"]').each(function( index ) {
        let files = window.jQuery('[name="files[]"]')[index].files;
        for (const file of files) {
          data.append('files[]', file, file.name);
        }
      });
      setLoading(true);
      PostApiWithAuthorizationAction("jewelry-receipts/create-new", data, {
        'Content-Type': 'multipart/form-data'
      })
        .then((res) => {
          setLoading(false);
          //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
          props.resetAddReceiptForm();
          props.history.push("/portal/jewelry-receipts");
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    } else {
      toast.error(validation.err, {
        position: toast.POSITION.TOP_CENTER,
        closeOnClick: true,
      });
    }
  };

  const getTotal = () => {
    let amount = props.receiptItemsDetails.items.reduce((prev, curr) => {
        if (curr.price === "") {
          return prev + 0.0;
        } else {
          return prev + parseFloat(curr.price);
        }
    }, 0)
    return amount;
  }

  const getCreditCardRemainder = () => {
      let amount = getTotal() - props.receiptFormDetails.tendered
      return (amount <= 0) ? 0 : amount.toFixed(2)
  }

  const renderAllItemsHTML = (e) => {
    return props.receiptItemsDetails.items.map((item, i) => {
      return (
        <tr key={i} id={"receipt-items-row" + i}>
          <td className="input-td">
            <Input
              id="qty"
              type="number"
              value={item.qty}
              className={classnames({ "input-null": item.qty === "" })}
              onChange={(e) => {
                changeItemValues("qty", e.target.value, i);
              }}
            />
          </td>
          <td className="input-td">
            <Input
              id="description"
              type="text"
              value={item.itemDescription}
              className={classnames({
                "input-null": item.itemDescription === "",
              })}
              onChange={(e) => {
                changeItemValues("itemDescription", e.target.value, i);
              }}
            />
          </td>
          <td className="input-td">
            <Input type="number" value={item.unitPrice} disabled />
          </td>
          <td className="input-td">
            <Input
              id="price"
              type="number"
              className={classnames({ "input-null": item.price === "" })}
              value={item.price}
              onChange={(e) => {
                changeItemValues("price", e.target.value, i);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </td>
          <td className="action-col">
            <Button
              id="add-more"
              className="icon-cross"
              color="primary"
              title="Add more"
              onClick={() => {
                if (item.price !== "" && item.itemDescription !== "") {
                  addRowInItem();
                }
              }}
            >
              <i className="fas fa-check-square"></i>
            </Button>
            <Button
              id="remove"
              className="icon-cross"
              color="danger"
              title="remove"
              onClick={() => {
                removeRowInItem(i);
              }}
            >
              <i className="fas fa-times"></i>
            </Button>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader className="bg-white d-flex justify-content-between">
            <h2>Create New Jewelry Receipt</h2>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <i className="fas fa-chevron-left"></i> Back
            </Button>
          </CardHeader>
          <CardBody>
            <Table className="add-receipt-table" bordered responsive="sm">
              <tbody>
                <tr className={props.receiptFormDetails.requireShipmentFreeTax ? "back-red" : ""}>
                  <th>First Name:</th>
                  <td className="input-td">
                    <Input
                      id="firstName"
                      type="text"
                      placeholder="First Name"
                      autoComplete="off"
                      name="firstName"
                      value={props.receiptFormDetails.firstName}
                      onChange={(e) =>
                        props.saveReceiptFormDetails({
                          name: "firstName",
                          value: e.target.value,
                        })
                      }
                      autoFocus={true}
                    />
                  </td>
                  <th>Last Name:</th>
                  <td className="input-td">
                    <Input
                      id="lastName"
                      type="text"
                      placeholder="Last Name"
                      autoComplete="off"
                      name="lastName"
                      value={props.receiptFormDetails.lastName}
                      onChange={(e) =>
                        props.saveReceiptFormDetails({
                          name: "lastName",
                          value: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th>Payment Type:</th>
                  <td>
                    <DropdownList
                      id="paymentType"
                      data={jewelryReceiptPaymentTypes}
                      textField="label"
                      value={props.receiptFormDetails.paymentType}
                      onChange={(e) => {
                        props.saveReceiptFormDetails({
                          name: "paymentType",
                          value: e,
                        });
                      }}
                    />
                  </td>
                  {props.receiptFormDetails.paymentType.value === "partial" && (
                    <>
                      <th>Phone:</th>
                      <td>
                      <PhoneInput
                        country={'us'}
                        value={props.receiptFormDetails.phone}
                        onChange={phone => props.saveReceiptFormDetails({
                          name: "phone",
                          value: phone
                        })}
                      />
                      </td>
                    </>
                  )}
                </tr>
                <tr className="back-red">
                  <th>Paid By:</th>
                  <td>
                    <DropdownList
                      id="payment"
                      data={jewelryPaymentOptionsReceipt}
                      textField="label"
                      value={props.receiptFormDetails.payment}
                      onChange={(e) => {
                        if (e.value === "creditcard") {
                          props.saveReceiptFormDetails({
                            name: "tendered",
                            value: 0,
                          });
                        }
                        props.saveReceiptFormDetails({
                          name: "payment",
                          value: e,
                        });
                      }}
                    />
                  </td>
                  {props.receiptFormDetails.payment.value === "creditcard" && (
                    <>
                      <th>Card Last 4 digits:</th>

                      <td className="input-td">
                        <Input
                          id="cardNumber"
                          type="number"
                          className={classnames({
                            "input-null":
                              props.receiptFormDetails.creditCardNumber === "",
                          })}
                          step="0.01"
                          placeholder="Card Last 4 digits"
                          autoComplete="off"
                          name="creditCardNumber"
                          onInput={(e) => {
                            e.target.value = e.target.value
                              .toString()
                              .slice(0, 4);
                          }}
                          value={props.receiptFormDetails.creditCardNumber}
                          onChange={(e) => {
                            props.saveReceiptFormDetails({
                              name: "creditCardNumber",
                              value: e.target.value,
                            });
                          }}
                        />
                      </td>
                    </>
                  )}
                  {paymentCashOptions.includes(props.receiptFormDetails.payment.value) && (
                    <>
                      <th>Cash Tendered ($):</th>
                      <td className="input-td">
                        <div className="d-flex">
                          <Input
                            id="tendered"
                            type="number"
                            step="1"
                            placeholder="Cash Tendered ($)"
                            autoComplete="off"
                            name="tendered"
                            value={props.receiptFormDetails.tendered}
                            onChange={(e) =>
                              props.saveReceiptFormDetails({
                                name: "tendered",
                                value: e.target.value,
                              })
                            }
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                          {props.receiptFormDetails.payment.value === "cash_creditcard" && (
                            <>
                              <span className="pl-4 pr-1 pt-2 font-weight-bold">Card Last 4 digits:</span>
                              <Input
                                id="cardNumber"
                                type="number"
                                className={classnames({
                                  "input-null":
                                    props.receiptFormDetails.creditCardNumber === "",
                                })}
                                step="0.01"
                                placeholder="Card Last 4 digits"
                                autoComplete="off"
                                name="creditCardNumber"
                                onInput={(e) => {
                                  e.target.value = e.target.value
                                    .toString()
                                    .slice(0, 4);
                                }}
                                value={props.receiptFormDetails.creditCardNumber}
                                onChange={(e) => {
                                  props.saveReceiptFormDetails({
                                    name: "creditCardNumber",
                                    value: e.target.value,
                                  });
                                }}
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </>
                  )}
                  {props.receiptFormDetails.payment.value === "check" && (
                    <>
                      <th>Check Amount:</th>
                      <td className="input-td">
                        <div className="d-flex">
                          <Input
                            type="number"
                            id="checkAmount"
                            placeholder="Check Amount"
                            autoComplete="off"
                            name="checkAmount"
                            value={props.receiptFormDetails.checkAmount}
                            onChange={(e) =>
                              props.saveReceiptFormDetails({
                                name: e.target.name,
                                value: e.target.value,
                              })
                            }
                          />
                            <span className="pl-4 pr-1 pt-2 font-weight-bold">Check #:</span>
                            <InputGroup>
                              {(props.userType === 'admin') && (
                              <InputGroupAddon addonType="prepend">
                                <Button
                                  size="sm"
                                  color="danger"
                                  title="Set Check Number Prefix"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    //
                                    props.history.push("/portal/setting-checks");
                                  }}>
                                  <i class="fas fa-money-check-alt"></i>
                                </Button>
                              </InputGroupAddon>
                              )}
                              <Input
                                id="checkNumber"
                                type="text"
                                placeholder="Check Number"
                                autoComplete="off"
                                name="checkNumber"
                                value={props.receiptFormDetails.checkNumber}
                                onChange={(e) => {
                                  let characterLength = e.target.value.length
                                  let checkNumberPrefixLength = checkNumberPrefix.length
                                  if (props.userType.toLowerCase() !='admin') {
                                    if (characterLength >= checkNumberPrefixLength) {
                                      props.saveReceiptFormDetails({
                                        name: e.target.name,
                                        value: e.target.value,
                                      });
                                    }
                                  } else {
                                    props.saveReceiptFormDetails({
                                      name: e.target.name,
                                      value: e.target.value,
                                    });
                                  }
                                }}
                                />
                          </InputGroup>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
                {/*
                <tr className="back-red">
                  <th>From:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="fromx"
                        data={fromOptions}
                        containerClassName="from-drop"
                        textField="name"
                        onToggle={getFromOptions}
                        value={props.receiptFormDetails.fromx}
                        onChange={(e) =>
                          props.saveReceiptFormDetails({
                            name: "fromx",
                            value: e,
                          })
                        }
                      />
                    </div>
                  </td>
                      </tr>*/}
                <tr className="back-red">
                  <th>Booth:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="booth"
                        data={boothOptions}
                        containerClassName="booth-drop"
                        textField="name"
                        value={props.receiptFormDetails.booth}
                        onChange={(e) => {
                          props.saveReceiptFormDetails({
                            name: "booth",
                            value: e,
                          });
                        }}
                        onToggle={fetchAllBooth}
                      />
                    </div>
                  </td>
                </tr>
                {props.userType &&
                  !["employee", "employees", "employe"].includes(
                    props.userType.toLowerCase()
                  ) && (
                    <tr>
                      <th>Station:</th>
                      <td colSpan="3">
                        <div className="d-flex align-items-end">
                          <DropdownList
                            id="station"
                            data={stationOptions}
                            containerClassName="station-drop"
                            textField="label"
                            value={props.receiptFormDetails.printer}
                            onChange={(e) => {
                              props.saveReceiptFormDetails({
                                name: "printer",
                                value: e,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                {/*<tr className="back-grey">
                  <th>Keyword:</th>
                  <td colSpan="3" className="autocomplete input-td">
                    <Input
                      id="keyword"
                      type="text"
                      placeholder="Keyword"
                      name="keyword"
                      value={props.receiptFormDetails.keyword}
                      onChange={(e) =>
                        props.saveReceiptFormDetails({
                          name: "keyword",
                          value: e.target.value,
                        })
                      }
                      autoComplete="off"
                    />
                  </td>
                    </tr>*/}
                <tr className="back-red">
                  <th>Clerk Name:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="clerk"
                        data={allClerks}
                        containerClassName="booth-drop"
                        textField="first_name"
                        onToggle={fetchAllClerks}
                        value={props.receiptFormDetails.clerk}
                        onChange={(e) =>
                          props.saveReceiptFormDetails({
                            name: "clerk",
                            value: e,
                          })
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr className="back-grey">
                  <th>Note:</th>
                  <td colSpan="3" className="input-td">
                    <Input
                      id="note"
                      className="w-75"
                      type="text"
                      placeholder="Note"
                      autoComplete="off"
                      name="note"
                      value={props.receiptFormDetails.note}
                      onChange={(e) =>
                        props.saveReceiptFormDetails({
                          name: "note",
                          value: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr className="back-grey">
                  <th></th>
                  <td
                    colSpan="3"
                    className="input-td input-shipping-check">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          style={{height: 'auto', marginTop: '2px'}}
                          onChange={(e) => props.saveReceiptFormDetails({
                              name: "requireShipmentFreeTax",
                              value: e.target.checked
                            })
                          }/>{' '} Allow Free Sales Tax
                      </Label>
                    </FormGroup>
                  </td>
                </tr>
                {props.receiptFormDetails.requireShipmentFreeTax && (
                  <tr className="back-gray">
                  <th>Shipping Details</th>
                  <td
                    colSpan="3"
                    className="input-td">
                    <Row>
                    <Col md="3">
                        <Input
                          id="shippingTrackingNumber"
                          type="text"
                          placeholder="Tracking #"
                          autoComplete="off"
                          name="shippingTrackingNumber"
                          value={props.receiptFormDetails.shippingTrackingNumber}
                          onChange={(e) =>
                            props.saveReceiptFormDetails({
                              name: "shippingTrackingNumber",
                              value: e.target.value
                            })
                          }
                        />
                      </Col>
                      <Col md="3">
                        <Input
                          id="shippingAddress"
                          type="text"
                          placeholder="Address"
                          autoComplete="off"
                          name="shippingAddress"
                          className={props.receiptFormDetails.shippingAddress.length === 0 ? "input-null" : ""}
                          value={props.receiptFormDetails.shippingAddress}
                          onChange={(e) =>
                            props.saveReceiptFormDetails({
                              name: "shippingAddress",
                              value: e.target.value
                            })
                          }
                        />
                      </Col>
                      <Col md="1">
                        <Input
                          id="shippingState"
                          type="text"
                          placeholder="State"
                          autoComplete="off"
                          name="shippingState"
                          className={props.receiptFormDetails.shippingState.length === 0 ? "input-null" : ""}
                          value={props.receiptFormDetails.shippingState}
                          onChange={(e) =>
                            props.saveReceiptFormDetails({
                              name: "shippingState",
                              value: e.target.value
                            })
                          }
                          />
                      </Col>
                      <Col md="2">
                        <Input
                            id="shippingCity"
                            type="text"
                            placeholder="City"
                            autoComplete="off"
                            name="shippingCity"
                            value={props.receiptFormDetails.shippingCity}
                            className={props.receiptFormDetails.shippingCity.length === 0 ? "input-null" : ""}
                            onChange={(e) =>
                              props.saveReceiptFormDetails({
                                name: "shippingCity",
                                value: e.target.value
                              })
                            }
                          />
                      </Col>
                      <Col md="1">
                        <Input
                            id="shippingPostalCode"
                            type="text"
                            placeholder="Postal"
                            autoComplete="off"
                            name="shippingPostalCode"
                            value={props.receiptFormDetails.shippingPostalCode}
                            className={props.receiptFormDetails.shippingPostalCode.length === 0 ? "input-null" : ""}
                            onChange={(e) =>
                              props.saveReceiptFormDetails({
                                name: "shippingPostalCode",
                                value: e.target.value
                              })
                            }
                          />
                      </Col>
                      <Col md="2">
                        <Input
                            id="shippingPhone"
                            type="text"
                            placeholder="Phone"
                            autoComplete="off"
                            name="shippingPhone"
                            value={props.receiptFormDetails.shippingPhone}
                            className={props.receiptFormDetails.shippingPhone.length === 0 ? "input-null" : ""}
                            onChange={(e) =>
                              props.saveReceiptFormDetails({
                                name: "shippingPhone",
                                value: e.target.value
                              })
                            }
                          />
                      </Col>
                    </Row>
                  </td>
                </tr>
                )}
                <tr>
                  <th>Attachments:</th>
                  <td className="width-middle" colSpan="3">
                    <input type="file" name="files[]" id="fileuploader"/>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table className="add-receipt-item-table" bordered striped>
              <thead>
                <tr>
                  <th className="qty-col">Qty</th>
                  <th className="desc-col">DESC</th>
                  <th className="unit-col">UNIT($)</th>
                  <th className="amount-col">Amount($)</th>
                </tr>
              </thead>
              <tbody>
                {props.receiptItemsDetails.items.length === 0 && (
                  <tr>
                    <td className="py-3 text-center" colSpan="4">
                      <Button
                        className="py-1"
                        color="primary"
                        onClick={addRowInItem}
                      >
                        Add item row+
                      </Button>
                    </td>
                  </tr>
                )}
                {renderAllItemsHTML()}
                {props.receiptFormDetails.payment.value === "cash_creditcard" && (
                  <>
                    <tr style={{color: 'red', fontWeight: 'bold'}}>
                      <td colSpan="3" style={{fontSize: '15px'}}>Credit Card Remainder:</td>
                      <td style={{fontSize: '15px'}}>
                        ${getCreditCardRemainder()}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td colSpan="3">Tax:</td>
                  <td>
                    $
                    {props.receiptFormDetails.requireShipmentFreeTax ? "0.00" : props.receiptItemsDetails.items
                      .reduce((prev, curr) => {
                        if (curr.tax === "") {
                          return prev + 0.0;
                        } else {
                          return prev + parseFloat(curr.tax);
                        }
                      }, 0)
                      .toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">Total:</td>
                  <td>
                    ${getTotal().toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
            {loading ? (
              <div className="text-center my-2">
                <Button color="primary" disabled>
                  Saving <i className="fas fa-spinner fa-spin"></i>
                </Button>
              </div>
            ) : (
              <div className="text-center my-2">
                <Button
                  id="save-receipt"
                  color="primary"
                  onClick={formSubmitHandler}
                >
                  Save Receipt
                </Button>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    receiptFormDetails: state.JewelryReceiptReducer.addReceiptFormdetails,
    receiptItemsDetails: state.JewelryReceiptReducer.receiptItemdetails,
    userType: state.loginUserReducer.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveReceiptFormDetails: (body) => dispatch(saveReceiptFormDetails(body)),
    saveItemsDetails: (body) => dispatch(saveItemsDetails(body)),
    resetAddReceiptForm: () => dispatch(resetAddReceiptForm()),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewReceipt);
