import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  CustomInput,
  FormGroup,
} from "reactstrap";
import { DropdownList } from "react-widgets";
import { Typeahead } from 'react-bootstrap-typeahead';

function PrintStandaloneCheck(props) {
  const [payee, setpayee] = useState("");
  const [memo, setMemo] = useState("");
  const [checkAmount, setCheckAmount] = useState("");
  const [checkNumber, setCheckNumber] = useState("");
  const [displayCustomPayee, setDisplayCustomPayee] = useState(false);
  const [isVoidedAfterDay, setIsVoidedAfterDay] = useState(true);
  const [payees, setPayees] = useState([]);

  const searchPayees = (payee) => {
    GetApiActionWithAuthorization("payees/search-payee/" + payee)
    .then((res) => {
      setPayees(res.data)
    })
    .catch((e) => {});
  };

  const loadPayeeSetting = (payee) => {
    GetApiActionWithAuthorization("payees/get-payee-last-data/" + payee)
    .then((res) => {
      setIsVoidedAfterDay(res.data.voidDay)
    })
    .catch((e) => {}); 
  };

  const printCheckHandler = (id, type) => {
    if (
      checkAmount !== "" &&
      parseInt(checkAmount) > 0 &&
      payee !== "" &&
      checkNumber !== "" &&
      memo !== ""
    ) {
      let body = {
        checkNumber,
        checkAmount,
        payee,
        memo,
        isVoidedAfterDay
      };
      PostApiWithAuthorizationAction("common/print-standalone-checks", body)
        .then((res) => {
          //toast.success(res.message);
          props.refreshList();
        })
        .catch((e) => props.toggle());
    } else {
      if (parseInt(checkAmount) <= 0 && checkAmount !== "") {
        toast.error("Invalid check amount");
      } else {
        toast.error("Please fill all the fields");
      }
    }
  };

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Print Standalone Check!</ModalHeader>
      <ModalBody className="py-0">
        <FormGroup>
          <Label className="form-control-label">Payee:</Label>
          <Typeahead
            id="payee"
            labelKey="name"
            emptyLabel=""
            onInputChange={(e) => {
              setpayee(e)
              searchPayees(e)
              loadPayeeSetting(e)
            }}
            onChange={(e) => {
              if (e.length > 0) {
                setpayee(e[0].name)
                loadPayeeSetting(e[0].name)
              }
            }}
            options={payees}
            placeholder="Payee"
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label">Check Amount:</Label>
          <Input
            id="checkAmount"
            type="number"
            className="ml-1"
            placeholder="Check Amount"
            autoComplete="off"
            value={checkAmount}
            onChange={(e) => setCheckAmount(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label">Memo:</Label>
          <Input
            id="memo"
            type="text"
            className="ml-1"
            placeholder="Memo"
            autoComplete="off"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label">Check Number:</Label>
          <Input
            id="checkNumber"
            type="number"
            className="ml-1"
            placeholder="Check Number"
            autoComplete="off"
            value={checkNumber}
            onChange={(e) => setCheckNumber(e.target.value)}
          />
        </FormGroup>
        <FormGroup className="mb-2">
          <Label className="form-control-label" for="b_name">
            {" "}
            Void after 15 days
          </Label>
          <CustomInput
            type="switch"
            id="isVoidedAfterDay"
            name="enabled"
            checked={isVoidedAfterDay}
            onChange={(e) => {
                setIsVoidedAfterDay(e.target.checked);
            }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="pt-0">
        <Button id="print" color="primary" onClick={(e) => printCheckHandler()}>
          Print
        </Button>{" "}
        <Button color="secondary" onClick={(e) => props.toggle()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default PrintStandaloneCheck;
