import {
  FETCH_DAILY_TABLE_LISTING_SUCCESS,
  FETCH_DAILY_TABLE_LISTING_FAILURE,
} from "./dailyTypes";

const initialState = {
  tableData: {
    ticket: [],
    coin: [],
    receipt: [],
    jewelReceipt: [],
    oldCoin: [],
    bullion: [],
    error: null,
    type: 0,
    count: {
      metroTotal: 0,
      coinTotal: 0,
      coinDepTotal: 0
    }
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DAILY_TABLE_LISTING_SUCCESS:
      return {
        tableData: action.payload.data,
        error: null,
      };
    case FETCH_DAILY_TABLE_LISTING_FAILURE:
      return {
        tableData: {...initialState.tableData,error: action.payload.data,},
      };
    // case RESET: {
    //   return initialState;
    // }
    default:
      return state;
  }
};

export default reducer;