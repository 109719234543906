import React, { useState } from "react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

// reactstrap components
import { Row, Col } from "reactstrap";
import { resetState } from "../../redux/common/commonAction";
import { connect } from "react-redux";

function Footer(props) {
  const [timerOn, setTimer] = useState(false);
  const [timerID, setTimerId] = useState("");
  const [count, setCount] = useState(0);

  const logout = (e) => {
    props.resetState();
    //toast.success("Successfully logout");
    localStorage.removeItem("token");
    localStorage.removeItem("booth");
    Cookies.remove("jwtToken", { path: "/", domain: ".dmhq.com" });
    props.history.push("/auth/login");
  };
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="12">
          <div className="copyright text-center text-muted non-selector">
            <span
              onClick={() => {
                if (props.location.pathname === "/portal/signature-pad" || props.location.pathname === "/portal/notices") {
                  setCount(count + 1);
                  if (count === 5) {
                    setCount(0);
                    setTimer(false);
                    logout();
                    clearTimeout(timerID);
                  }
                  if (!timerOn) {
                    setTimer(true);
                    let timer = setTimeout(() => {
                      setCount(0);
                      setTimer(false);
                    }, 5000);
                    setTimerId(timer);
                  }
                }
              }}
            >
              © {new Date().getFullYear()}{" "}
            </span>
            <a
              className="font-weight-bold ml-1"
              href="#"
              rel="noopener noreferrer"
              target="_blank"
            >
              Nevada Coin Mart
            </a>
          </div>
        </Col>
      </Row>
    </footer>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(resetState()),
  };
};

export default connect(null, mapDispatchToProps)(Footer);
