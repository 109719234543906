import {
  FETCH_STORES_LISTING_SUCCESS,
  FETCH_STORES_LISTING_FAILURE,
  FETCH_CLERK_LISTING_SUCCESS,
  FETCH_CLERK_LISTING_FAILURE,
  FETCH_COINS_LISTING_SUCCESS,
  FETCH_COINS_LISTING_FAILURE,
  SAVE_COINS_ITEMS_DETAILS,
  SAVE_COINS_BARTER_ITEMS_DETAILS,
  RESET_SAVE_COINS_DETAILS,
  FETCH_COIN_ITEMS__LISTING_FAILURE,
  FETCH_COIN_ITEMS_LISTING_SUCCESS,
  FETCH_COIN_BARTER_ITEMS_LISTING_SUCCESS,
  FETCH_COIN_BARTER_ITEMS_LISTING_FAILURE,
  UPDATE_COIN_DATA_AFTER_UPDATE,
  COPY_TICKETS_DETAILS_IN_COIN,
  SAVE_COINS_FORM_DETAILS,
  RESET,
} from "./coinTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________Store Listing __________________________________________________________________ */

//Api Call Function for store listing
export const fetchAllStoresListing = () => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-stores-listing")
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(saveCoinFormDetails({ name: "storeId", value: res.data[0] }));
        dispatch(fetchStoreListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchStoreListingFailure(err));
      });
  };
};

//Userlisting Success save Payload data to store
export const fetchStoreListingSuccess = (store) => {
  return {
    type: FETCH_STORES_LISTING_SUCCESS,
    payload: store,
  };
};

//Userlisting Failure save Payload
export const fetchStoreListingFailure = (error) => {
  return {
    type: FETCH_STORES_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */

/*_________________________________________________________________Clerk Listing __________________________________________________________________ */

//Api Call Function for clerk listing
export const fetchAllClerkListing = () => {
  return (dispatch) => {
    GetApiActionWithAuthorization("common/get-clerk-listing")
      .then((res) => {
        dispatch(fetchClerkListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchClerkListingFailure(err));
      });
  };
};

//Userlisting Success save Payload data to store
export const fetchClerkListingSuccess = (store) => {
  return {
    type: FETCH_CLERK_LISTING_SUCCESS,
    payload: store,
  };
};

//Userlisting Failure save Payload
export const fetchClerkListingFailure = (error) => {
  return {
    type: FETCH_CLERK_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */

/*_________________________________________________________________Coin Listing __________________________________________________________________ */

//Api Call Function for coin listing
export const fetchCoinsListing = (
  page,
  search,
  sortBy,
  sortOrder,
  filterBy,
  todayOnly = true,
  emptyProfit = false,
  dateFilter
) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());

    GetApiActionWithAuthorization("coin-tickets/get-all", {
      page,
      sortBy,
      sortOrder,
      search,
      filterByItem: filterBy === "Ticket" ? 0 : 1,
      todayOnly: todayOnly,
      emptyProfit: emptyProfit,
      dateFilter
    })
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchCoinsListingSuccess(res.data, page, res.count));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchCoinsListingFailure(err));
      });
  };
};

//Coinlisting Success save Payload data to store
export const fetchCoinsListingSuccess = (coins, page, count) => {
  return {
    type: FETCH_COINS_LISTING_SUCCESS,
    payload: { coins, page, count },
  };
};

//Coinlisting Failure save Payload
export const fetchCoinsListingFailure = (error) => {
  return {
    type: FETCH_COINS_LISTING_FAILURE,
    payload: error,
  };
};

//Coinlisting Failure save Payload
export const updateTicketTableAfterUpdate = (coins) => {
  return {
    type: UPDATE_COIN_DATA_AFTER_UPDATE,
    payload: coins,
  };
};

/*__________________________________________________________________________________________________________________________________________ */

/*_________________________________________________________________Ticket Barter Items Listing ______________________________________________*/
export const fetchCoinBarterItemsListing = (id) => {
  return (dispatch) => {
    GetApiActionWithAuthorization("coin-tickets/get-ticket-items/" + id + "?barter=true")
      .then((res) => {
        dispatch(
          fetchCoinBarterItemsListingSuccess({
            data: res.data,
            ticketDetails: res.ticketDetails,
          })
        );
      })
      .catch((err) => {
        dispatch(fetchCoinBarterItemsListingFailure(err));
      });
  };
};

//Ticket Items listing Success save Payload data to store
export const fetchCoinBarterItemsListingSuccess = (data) => {
  return {
    type: FETCH_COIN_BARTER_ITEMS_LISTING_SUCCESS,
    payload: data,
  };
};

//Ticket Items listing Failure save Payload
export const fetchCoinBarterItemsListingFailure = (error) => {
  return {
    type: FETCH_COIN_BARTER_ITEMS_LISTING_FAILURE,
    payload: error,
  };
};

/*_________________________________________________________________Ticket Items Listing __________________________________________________________________ */

//Api Call Function for ticket items listing
export const fetchCoinItemsListing = (id) => {
  return (dispatch) => {
    GetApiActionWithAuthorization("coin-tickets/get-ticket-items/" + id)
      .then((res) => {
        dispatch(
          fetchCoinItemsListingSuccess({
            data: res.data,
            ticketDetails: res.ticketDetails,
          })
        );
      })
      .catch((err) => {
        dispatch(fetchCoinItemsListingFailure(err));
      });
  };
};

//Ticket Items listing Success save Payload data to store
export const fetchCoinItemsListingSuccess = (data) => {
  return {
    type: FETCH_COIN_ITEMS_LISTING_SUCCESS,
    payload: data,
  };
};

//Ticket Items listing Failure save Payload
export const fetchCoinItemsListingFailure = (error) => {
  return {
    type: FETCH_COIN_ITEMS__LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */

export const saveCoinFormDetails = ({ name, value }) => {
  return {
    type: SAVE_COINS_FORM_DETAILS,
    payload: { name, value },
  };
};

export const saveCoinItemDetails = (items) => {
  return {
    type: SAVE_COINS_ITEMS_DETAILS,
    payload: items,
  };
};

export const saveCoinBarterItemDetails = (items) => {
  return {
    type: SAVE_COINS_BARTER_ITEMS_DETAILS,
    payload: items,
  };
};

export const copyTicketDetailsToCoin = (data) => {
  return {
    type: COPY_TICKETS_DETAILS_IN_COIN,
    payload: data,
  };
};

export const resetAddCoinForm = () => {
  return {
    type: RESET_SAVE_COINS_DETAILS,
  };
};
