import {
  FETCH_FROM_OP_LISTING_FAILURE,
  FETCH_FROM_OP_LISTING_SUCCESS
} from "./fromOptionsTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________From Option Listing __________________________________________________________________ */

//Api Call Function for from listing
export const fetchFromOptionsListing = () => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-all-from-options")
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchFromOptionsListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchFromOptionsListingFailure(err));
      });
  };
};

//From Op. Success save Payload data to store
export const fetchFromOptionsListingSuccess = (data) => {
  return {
    type: FETCH_FROM_OP_LISTING_SUCCESS,
    payload: data,
  };
};

//From Op. Failure save Payload
export const fetchFromOptionsListingFailure = (error) => {
  return {
    type: FETCH_FROM_OP_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
