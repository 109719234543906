import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "./commonCSS.scss";
import "moment-timezone";
import "react-toastify/dist/ReactToastify.css";
import "react-widgets/dist/css/react-widgets.css";
import "react-phone-input-2/lib/style.css";

import PortalLayout from "layouts/Portal.js";
import CustomerPortal from "layouts/CustomerPortal.js";
import MetroInvoice from "./components/Invoices/MetroInvoice";
import Loader from "./views/Pages/Loader/Loader";
import AuthLayout from "layouts/Auth.js";
import { ToastContainer } from "react-toastify";

import { Provider } from "react-redux";
import store from "./redux/store";
import ReceiptInvoice from "components/Invoices/ReceiptInvoice";
import ViewInvoice from "views/Invoice/ViewInvoice";
import ViewCoinInvoice from "views/Invoice/ViewCoinInvoice";
import CustomerEditCoinInvoice from "views-customer/Invoice/EditInvoice"
import CustomerHome from "views-customer/CustomerHome";
import LiveViewInvoice from "views/Invoice/LiveViewInvoice";

const isCustomer = (localStorage.getItem("group") === 'customer')
if (isCustomer) {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/customer-portal" render={(props) => <CustomerPortal {...props} />} />
          <Route path="/coin-invoice/edit/:id" component={CustomerEditCoinInvoice} />
          <Route path="/home" component={CustomerHome} />
          <Redirect from="/" to="/home" />
        </Switch>
        <ToastContainer />
        <Loader />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path="/portal" render={(props) => <PortalLayout {...props} />} />
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/invoice/:type/:id" component={MetroInvoice} />
          <Route path="/invoice-receipt/:id" component={ReceiptInvoice} />
          <Route path="/invoices/v/:id" component={ViewInvoice} />
          <Route path="/coin-invoices/v/:id" component={ViewCoinInvoice} />
          <Route path="/coin-invoice/edit/:id" component={CustomerEditCoinInvoice} />
          <Route path="/coin-invoice/live-view/:id" component={LiveViewInvoice} />
          <Redirect from="/" to="/portal" />
        </Switch>
        <ToastContainer />
        <Loader />
      </BrowserRouter>
    </Provider>,
    document.getElementById("root")
  );
}
