import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  CustomInput,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import {
  fetchPayeesListing,
  fetchPayeesListingSuccess
} from "../../redux/Payees/payeesAction";
import EditPayeeModal from "views/Modal/Payees/EditPayeeModal";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import AddNewPayeeModal from "views/Modal/Payees/AddNewPayeeModal";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import ReactPaginate from "react-paginate";

function Payees(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");
  useEffect(() => {
    props.fetchPayeesListing();
  }, []);

  if (props.payees.error && !unauthorized) {
    if (props.payees.error.status === 403) {
      setUnauthorized(true);
    } else if (props.payees.error.status === 440) {
      props.resetState();
      toast.error(props.payees.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleAdd = (e) => {
    setAddModal(!addModal);
  };

  const toggleEdit = (id = "", name = "") => {
    setEditData({ id, name });
    setEditModal(!editModal);
  };

  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  const confirmed = (e) => {
    GetApiActionWithAuthorization("payees/delete-payee/" + delId)
      .then((res) => {
        props.fetchPayeesListing(props.payees.page);
        setConfirm(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderTableData = (e) => {
    return props.payees.allPayees.map((payee, i) => {
      return (
        <tr key={i}>
          <td>
            [
            <span
            id={"update-payee"+i}
              className="delete-item"
              onClick={(e) => {
                toggleEdit(payee.id, payee.name);
              }}
            >
              Edit
            </span>
            ] [
            <span
            id={"delete-payee"+i}
            className="delete-item"
              onClick={(e) => toggleConfirm(payee.id)}
            >
              Delete
            </span>
            ]{" "}
          </td>
          <td>{payee.name}</td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Payees</h1>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="6" className="search-left-btn">
                <Button id="add-new" color="primary" onClick={toggleAdd}>
                  New Payee
                </Button>
              </Col>
            </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Name</th>
                </tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </Table>
            {props.payees.allPayees.length > 0 && (
                <ReactPaginate
                  previousLabel={<i className="fas fa-chevron-left"></i>}
                  nextLabel={<i className="fas fa-chevron-right"></i>}
                  breakLabel={".."}
                  breakClassName={"break-me"}
                  initialPage={props.payees.page}
                  pageCount={props.payees.count / 50}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={(e) => {
                    console.log(e)
                    //run only when page changes
                    if (props.payees.page !== e.selected) {
                      props.fetchPayeesListing(e.selected);
                    }
                  }}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
            )}
          </CardBody>
        </Card>
      </Container>
      {addModal && (
        <AddNewPayeeModal {...props} show={addModal} toggle={toggleAdd} />
      )}
      {editModal && (
        <EditPayeeModal
          {...props}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
        />
      )}
      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    payees: state.payeesReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchPayeesListing: (page) => dispatch(fetchPayeesListing(page)),
    addUpdatedData: (data) => dispatch(fetchPayeesListingSuccess(data)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payees);
