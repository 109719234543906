import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Row,
  Col,
} from "reactstrap";

function AddNewCommentModal(props) {
  const [isClosedTicket, setIsClosedTicket] = useState(false);

  const addContactLogHandler = (e) => {
    PostApiWithAuthorizationAction("quotes/add-contact-log", {
        ... props.quoteLog,
        isClosedTicket
    }).then((res) => {
      //toast.success(res.message);
      props.fetchQuoteListing({
        page: props.quoteDetails.page
      });
      props.toggleComment();
    }).catch((err) => {
      if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      } 
    });
  };
  return (
    <Modal isOpen={props.showComment} toggle={props.toggleComment}>
      <ModalHeader toggle={props.toggleComment}>Add Comment</ModalHeader>
      <ModalBody>
        <Row>
            <Col md="12">
                <FormGroup className="mb-2">
                  <Input
                      id="comment"
                      type="text"
                      placeholder="Unable to contact customer due to ... / Customer contacted said they would... "
                      autoFocus={true}
                      value={props.quoteLog.comment}
                      onChange={(e) => props.setQuoteLog({
                          ... props.quoteLog,
                          comment: e.target.value
                      })}
                  />
                  <span className="text-danger">
                      <small>*required</small>
                  </span>
                </FormGroup>
            </Col>
        </Row>
        <FormGroup className="mb-2">
          <CustomInput
            type="switch"
            id="isClosedTicket"
            name="enabled"
            checked={isClosedTicket}
            onChange={(e) => {
              setIsClosedTicket(e.target.checked);
            }}
          />
          <Label className="form-control-label" for="b_name">Close Ticket</Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button id="add-btn" color="primary" onClick={addContactLogHandler} disabled={props.quoteLog.comment.length === 0}>Save Comment</Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddNewCommentModal;
