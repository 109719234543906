import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import {
  paymentOptionsCoinReceipt,
  stationOptions,
} from "../../constants/ticketConstants";
import { resetState } from "redux/common/commonAction";
import {
  saveReceiptFormDetails,
  saveItemsDetails,
  resetAddReceiptForm,
} from "../../redux/Receipt/ReceiptAction";
import Unauthorized from "views/Pages/Unauthorized";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { validateReceiptForm } from "../../constantFunctions/formValidations";
import Autocomplete from "components/AutoComplete/Autocomplete";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { enableFileUploader } from "libs/common";
import { jsonToFormData } from "libs/common";

function CreateNewReceipt(props) {
  const [keywords, setKeywords] = useState([]);
  const [items, setitems] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [keyLoad, setKeyLoad] = useState(false);
  const [allClerks, setAllClerks] = useState([]);
  const [checkNumberPrefix, setCheckNumberPrefix] = useState('');
  const [loading, setLoading] = useState(false);
  const [fromOptions, setFromOption] = useState([]);
  const [ethSpotPrice, setETHSpotPrice] = useState(0);
  const [btcSpotPrice, setBTCSpotPrice] = useState(0);
  const [resetTime, setResetTime] = useState(false);
  const [boothOptions, setBoothOptions] = useState([]);

  useEffect(() => {
    if (props.receiptItemsDetails.items.length === 0) {
      addRowInItem();
    }
    fetchAllBooth()
    fetchAllClerks()
    GetApiActionWithAuthorization("common/get-items-select-options")
      .then((res) => {
        setitems(res.data);
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });

    // ___________Get spot prices for ETH and BTC______________
    GetApiActionWithAuthorization("common/get-all-spot-prices").then((res) => {
      setBTCSpotPrice(res.data.find((s) => s.item === "btc").price);
      setETHSpotPrice(res.data.find((s) => s.item === "eth").price);
    });
    //load fileuploader with plain jquery
    window.jQuery(document).ready(function() {
      enableFileUploader();
    });
    GetApiActionWithAuthorization("common/get-check-setting").then((res) => {
      props.saveReceiptFormDetails({
        name: "checkNumber",
        value: res.data.receiptCheckNumberPrefix,
      });
      //
      setCheckNumberPrefix(res.data.receiptCheckNumberPrefix.toString());
    });
    document.getElementById('tendered').focus();
  }, []);

  // ___________Get spot prices for ETH and BTC(every hour changes)______________
  useEffect(() => {
    let timer = setTimeout(() => {
      if (new Date().getMinutes() === 2) {
        setResetTime(!resetTime);
        GetApiActionWithAuthorization("common/get-all-spot-prices").then(
          (res) => {
            setBTCSpotPrice(res.data.find((s) => s.item === "btc").price);
            setETHSpotPrice(res.data.find((s) => s.item === "eth").price);
          }
        );
      } else {
        setResetTime(!resetTime);
      }
    }, 30000);
    return () => {
      clearTimeout(timer);
    };
  }, [resetTime]);

  // const getKeywords = (e) => {
  //   if (keywords.length === 0) {
  //     GetApiActionWithAuthorization("common/get-keywords-for-form")
  //       .then((res) => {
  //         setKeyLoad(false);
  //         setKeywords(res.data);
  //       })
  //       .catch((err) => setKeyLoad(false));
  //   }
  // };

  const getFromOptions = (e) => {
    if (e && fromOptions.length === 0) {
      setKeyLoad(true);
      GetApiActionWithAuthorization("common/get-from-select-options")
        .then((res) => {
          setKeyLoad(false);
          setFromOption(res.data);
        })
        .catch((err) => setKeyLoad(false));
    }
  };

  const fetchAllClerks = () => {
    if (allClerks.length <= 0) {
      GetApiActionWithAuthorization("common/get-clerk-listing")
        .then((res) => {
          setAllClerks(res.data);
        })
        .catch((err) => {
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    }
  };

  const fetchAllBooth = () => {
    GetApiActionWithAuthorization("common/get-all-booths?displayOrderType=Receipt").then((res) => {
      setBoothOptions(res.data);
    });
  };

  const addRowInItem = (e) => {
    let obj = {
      item: "",
      unitPrice: "",
      price: "",
      qty: "1",
      tax: "",
    };
    let olderItems = props.receiptItemsDetails.items;
    olderItems.push(obj);
    props.saveItemsDetails(olderItems);
  };

  const removeRowInItem = (index) => {
    let olderItems = props.receiptItemsDetails.items;
    olderItems.splice(index, 1);
    props.saveItemsDetails(olderItems);
  };

  const changeItemValues = (name, value, index) => {
    let temp = props.receiptItemsDetails.items;
    let itemObj = temp[index];
    itemObj[name] = value;
    if (
      name === "qty" &&
      (itemObj.item === "" ||
        !["btc", "eth"].includes(itemObj.item.name.toLowerCase()))
    ) {
      itemObj["unitPrice"] = (
        parseFloat(itemObj.price) / parseInt(value)
      ).toFixed(2);
    }
    if (name === "item") {
      if (value.taxable) {
        itemObj["tax"] = ((parseFloat(itemObj.price) * 8.375) / 100).toFixed(2);
      } else {
        itemObj["tax"] = 0.0;
      }
    }

    //update unit price if not eth and btc
    if (
      name === "price" &&
      (itemObj.item === "" ||
        !["btc", "eth"].includes(itemObj.item.name.toLowerCase()))
    ) {
      if (itemObj.qty) {
        itemObj["unitPrice"] = (
          parseFloat(value) / parseInt(itemObj.qty)
        ).toFixed(2);
      } else {
        itemObj["unitPrice"] = parseFloat(value).toFixed(2);
      }
    }

    //___________update tax__________________
    if (name === "price") {
      if (itemObj.item && itemObj.item.taxable) {
        itemObj["tax"] = ((parseFloat(value) * 8.375) / 100).toFixed(2);
      } else {
        itemObj["tax"] = 0.0;
      }
    }
    temp[index] = itemObj;
    props.saveItemsDetails(temp);
  };

  const formSubmitHandler = (e) => {
    let validation = validateReceiptForm(
      { ...props.receiptFormDetails, paidBy: props.receiptFormDetails.payment },
      props.receiptItemsDetails.items
    );
    if (validation.success) {
      let formData = {
        formDetails: {
          ...props.receiptFormDetails,
          fromx: props.receiptFormDetails.fromx.name,
          printer: props.receiptFormDetails.printer.value,
          booth: props.receiptFormDetails.booth.name,
          payment: props.receiptFormDetails.payment.value,
          clerk: props.receiptFormDetails.clerk.first_name,
          ticketAmount: props.receiptItemsDetails.items
            .reduce((prev, curr) => {
              if (curr.price === "") {
                return prev + 0.0;
              } else {
                return prev + parseFloat(curr.price);
              }
            }, 0)
            .toFixed(2),
          tax: props.receiptItemsDetails.items
            .reduce((prev, curr) => {
              if (curr.tax === "") {
                return prev + 0.0;
              } else {
                return prev + parseFloat(curr.tax);
              }
            }, 0)
            .toFixed(2),
        },
        items: props.receiptItemsDetails.items,
      };
      let body = jsonToFormData(formData);
      //
      if ((props.receiptFormDetails.tendered - formData.formDetails.ticketAmount) > 40) {
        confirmAlert({
          title: 'Confirmation',
          message: 'Are you sure that all informations are correct ?',
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                setLoading(true);
                createNewReceiptApi(body);
              }
            },
            { label: 'No', onClick: () => {} }
          ]
        });
      } else {
        setLoading(true);
        createNewReceiptApi(body);
      }
    } else {
      toast.error(validation.err, {
        position: toast.POSITION.TOP_CENTER,
        closeOnClick: true,
      });
    }
  };

  const createNewReceiptApi = (data) => {
    window.jQuery('[name="files[]"]').each(function( index ) {
      let files = window.jQuery('[name="files[]"]')[index].files;
      for (const file of files) {
        data.append('files[]', file, file.name);
      }
    });
    return PostApiWithAuthorizationAction("receipts/create-new", data, {
      'Content-Type': 'multipart/form-data'
    })
    .then((res) => {
      setLoading(false);
      //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
      props.resetAddReceiptForm();
      props.history.push("/portal/receipts");
    })
    .catch((err) => {
      setLoading(false);
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const renderAllItemsHTML = (e) => {
    return props.receiptItemsDetails.items.map((item, i) => {
      return (
        <tr key={i} id={"receipt-items-row" + i}>
          <td className="input-td">
            <Input
              id="qty"
              type="number"
              value={item.qty}
              tabIndex={i+8}
              className={classnames({ "input-null": item.qty === "" })}
              onChange={(e) => {
                changeItemValues("qty", e.target.value, i);
              }}
            />
          </td>
          <td className="input-td">
            <Input
              id="price"
              type="number"
              className={classnames({ "input-null": item.price === "" })}
              value={item.price}
              tabIndex={i+9}
              onChange={(e) => {
                if (item.item && item.item.name.toLowerCase() === "btc") {
                  changeItemValues("price", e.target.value, i);
                  changeItemValues("unitPrice", btcSpotPrice, i);
                  changeItemValues(
                    "qty",
                    (
                      parseFloat(e.target.value) / parseFloat(btcSpotPrice)
                    ).toFixed(8),
                    i
                  );
                } else if (
                  item.item &&
                  item.item.name.toLowerCase() === "eth"
                ) {
                  changeItemValues("price", e.target.value, i);
                  changeItemValues("unitPrice", ethSpotPrice, i);
                  changeItemValues(
                    "qty",
                    (
                      parseFloat(e.target.value) / parseFloat(ethSpotPrice)
                    ).toFixed(8),
                    i
                  );
                } else {
                  changeItemValues("price", e.target.value, i);
                }
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </td>
          <td className="input-td">
            <Input type="number" value={item.unitPrice} disabled tabIndex={i+11}/>
          </td>
          <td>
            <DropdownList
              id="item"
              data={items}
              textField="name"
              containerClassName="item-drop"
              value={item.item}
              tabIndex={i+10}
              onChange={(e) => {
                if (e.name.toLowerCase() === "eth") {
                  changeItemValues("item", e, i);
                } else if (e.name.toLowerCase() === "btc") {
                  changeItemValues("item", e, i);
                } else {
                  changeItemValues("item", e, i);
                }
              }}
            />
          </td>
          <td className="action-col">
            <Button
              id="add-more"
              className="icon-cross"
              color="primary"
              title="Add more"
              onClick={() => {
                if (item.price !== "" && item.item !== "") {
                  addRowInItem();
                }
              }}
            >
              <i className="fas fa-check-square"></i>
            </Button>
            <Button
              id="remove"
              className="icon-cross"
              color="danger"
              title="remove"
              onClick={() => {
                removeRowInItem(i);
              }}
            >
              <i className="fas fa-times"></i>
            </Button>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader className="bg-white d-flex justify-content-between">
            <h2>Create New Coin Receipt</h2>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <i className="fas fa-chevron-left"></i> Back
            </Button>
          </CardHeader>
          <CardBody>
            <Table className="add-receipt-table" bordered responsive="sm">
              <tbody>
                <tr>
                  <th>First Name:</th>
                  <td className="input-td">
                    <Input
                      id="firstName"
                      type="text"
                      placeholder="First Name"
                      autoComplete="off"
                      tabIndex={1}
                      name="firstName"
                      value={props.receiptFormDetails.firstName}
                      onChange={(e) =>
                        props.saveReceiptFormDetails({
                          name: "firstName",
                          value: e.target.value,
                        })
                      }
                      autoFocus={true}
                    />
                  </td>
                  <th>Last Name:</th>
                  <td className="input-td">
                    <Input
                      id="lastName"
                      type="text"
                      placeholder="Last Name"
                      autoComplete="off"
                      name="lastName"
                      tabIndex={2}
                      value={props.receiptFormDetails.lastName}
                      onChange={(e) =>
                        props.saveReceiptFormDetails({
                          name: "lastName",
                          value: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th>Paid By:</th>
                  <td>
                    <DropdownList
                      id="payment"
                      data={paymentOptionsCoinReceipt}
                      textField="label"
                      value={props.receiptFormDetails.payment}
                      tabIndex={3}
                      onChange={(e) => {
                        if (e.value === "creditcard") {
                          props.saveReceiptFormDetails({
                            name: "tendered",
                            value: 0,
                          });
                        }
                        props.saveReceiptFormDetails({
                          name: "payment",
                          value: e,
                        });
                      }}
                    />
                  </td>

                  {props.receiptFormDetails.payment.value === "creditcard" && (
                    <>
                      <th>Card Last 4 digits:</th>

                      <td className="input-td">
                        <Input
                          id="cardNumber"
                          type="number"
                          className={classnames({
                            "input-null":
                              props.receiptFormDetails.creditCardNumber === "",
                          })}
                          step="0.01"
                          placeholder="Card Last 4 digits"
                          autoComplete="off"
                          name="creditCardNumber"
                          tabIndex={4}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 4);
                          }}
                          value={props.receiptFormDetails.creditCardNumber}
                          onChange={(e) =>
                            props.saveReceiptFormDetails({
                              name: "creditCardNumber",
                              value: e.target.value,
                            })
                          }
                        />
                      </td>
                    </>
                  )}
                  {props.receiptFormDetails.payment.value === "cash" && (
                    <>
                      <th>Cash Tendered ($):</th>
                      <td className="input-td">
                        <Input
                          id="tendered"
                          type="number"
                          className={classnames({
                            "input-null":
                              props.receiptFormDetails.tendered === "",
                          })}
                          step="1"
                          tabIndex={4}
                          placeholder="Cash Tendered ($)"
                          autoComplete="off"
                          name="tendered"
                          value={props.receiptFormDetails.tendered}
                          onChange={(e) =>
                            props.saveReceiptFormDetails({
                              name: "tendered",
                              value: e.target.value,
                            })
                          }
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </td>
                    </>
                  )}
                </tr>
                <tr>
                {props.receiptFormDetails.payment.value === "check" && (
                    <>
                      <th>Check Amount:</th>
                      <td className="input-td">
                        <Input
                          type="number"
                          id="checkAmount"
                          placeholder="Check Amount"
                          autoComplete="off"
                          name="checkAmount"
                          tabIndex={4}
                          value={props.receiptFormDetails.checkAmount}
                          onChange={(e) =>
                            props.saveReceiptFormDetails({
                              name: e.target.name,
                              value: e.target.value,
                            })
                          }
                        />
                      </td>
                      <th>Check #:</th>
                      <td className="input-td">
                          <InputGroup>
                            {(props.userType === 'admin') && (
                            <InputGroupAddon addonType="prepend">
                              <Button
                                size="sm"
                                color="danger"
                                title="Set Check Number Prefix"
                                onClick={(e) => {
                                  e.preventDefault();
                                  //
                                  props.history.push("/portal/setting-checks");
                                }}>
                                <i class="fas fa-money-check-alt"></i>
                              </Button>
                            </InputGroupAddon>
                            )}
                            <Input
                              id="checkNumber"
                              type="text"
                              placeholder="Check Number"
                              autoComplete="off"
                              name="checkNumber"
                              tabIndex={5}
                              value={props.receiptFormDetails.checkNumber}
                              onChange={(e) => {
                                let characterLength = e.target.value.length
                                let checkNumberPrefixLength = checkNumberPrefix.length
                                if (props.userType.toLowerCase() !='admin') {
                                  if (characterLength >= checkNumberPrefixLength) {
                                    props.saveReceiptFormDetails({
                                      name: e.target.name,
                                      value: e.target.value,
                                    });
                                  }
                                } else {
                                  props.saveReceiptFormDetails({
                                    name: e.target.name,
                                    value: e.target.value,
                                  });
                                }
                              }}
                              />
                        </InputGroup>
                      </td>
                    </>
                  )}
                </tr>
                {/*<tr className="back-red">
                  <th>From:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="fromx"
                        data={fromOptions}
                        containerClassName="from-drop"
                        textField="name"
                        onToggle={getFromOptions}
                        value={props.receiptFormDetails.fromx}
                        onChange={(e) =>
                          props.saveReceiptFormDetails({
                            name: "fromx",
                            value: e,
                          })
                        }
                      />
                    </div>
                  </td>
                      </tr>*/}
                <tr className="back-red">
                  <th>Booth:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="booth"
                        data={boothOptions}
                        containerClassName="booth-drop"
                        textField="name"
                        valueField="name"
                        value={props.receiptFormDetails.booth}
                        onChange={(e) => {
                          console.log(e)
                          props.saveReceiptFormDetails({
                            name: "booth",
                            value: e,
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
                {props.userType &&
                  !["employee", "employees", "employe"].includes(
                    props.userType.toLowerCase()
                  ) && (
                    <tr>
                      <th>Station:</th>
                      <td colSpan="3">
                        <div className="d-flex align-items-end">
                          <DropdownList
                            id="station"
                            data={stationOptions}
                            containerClassName="station-drop"
                            textField="label"
                            value={props.receiptFormDetails.printer}
                            onChange={(e) => {
                              props.saveReceiptFormDetails({
                                name: "printer",
                                value: e,
                              });
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                {/*<tr className="back-grey">
                  <th>Keyword:</th>
                  <td colSpan="3" className="autocomplete input-td">
                    <Input
                      id="keyword"
                      type="text"
                      placeholder="Keyword"
                      name="keyword"
                      value={props.receiptFormDetails.keyword}
                      onChange={(e) =>
                        props.saveReceiptFormDetails({
                          name: "keyword",
                          value: e.target.value,
                        })
                      }
                      autoComplete="off"
                    />
                  </td>
                    </tr>*/}
                <tr className="back-red">
                  <th>Clerk Name:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="clerk"
                        data={allClerks}
                        containerClassName="booth-drop"
                        tabIndex={6}
                        textField="first_name"
                        value={props.receiptFormDetails.clerk}
                        onChange={(e) =>
                          props.saveReceiptFormDetails({
                            name: "clerk",
                            value: e,
                          })
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr className="back-grey">
                  <th>Note:</th>
                  <td colSpan="3" className="input-td">
                    <Input
                      id="note"
                      className="w-75"
                      type="text"
                      placeholder="Note"
                      autoComplete="off"
                      name="note"
                      value={props.receiptFormDetails.note}
                      tabIndex={7}
                      onChange={(e) =>
                        props.saveReceiptFormDetails({
                          name: "note",
                          value: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th>Attachments:</th>
                  <td className="width-middle" colSpan="3">
                    <input type="file" name="files[]" id="fileuploader"/>
                  </td>
                </tr>
              </tbody>
            </Table>
            <Table className="add-receipt-item-table" bordered striped>
              <thead>
                <tr>
                  <th className="qty-col">Qty</th>
                  <th className="amount-col">Amount($)</th>
                  <th className="unit-col">UNIT($)</th>
                  <th className="desc-col">DESC</th>
                </tr>
              </thead>
              <tbody>
                {props.receiptItemsDetails.items.length === 0 && (
                  <tr>
                    <td className="py-3 text-center" colSpan="4">
                      <Button
                        className="py-1"
                        color="primary"
                        onClick={addRowInItem}
                      >
                        Add item row+
                      </Button>
                    </td>
                  </tr>
                )}

                {renderAllItemsHTML()}
                <tr>
                  <td colSpan="3">Tax:</td>
                  <td>
                    $
                    {props.receiptItemsDetails.items
                      .reduce((prev, curr) => {
                        if (curr.tax === "") {
                          return prev + 0.0;
                        } else {
                          return prev + parseFloat(curr.tax);
                        }
                      }, 0)
                      .toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">Total:</td>
                  <td>
                    $
                    {props.receiptItemsDetails.items
                      .reduce((prev, curr) => {
                        if (curr.price === "") {
                          return prev + 0.0;
                        } else {
                          return prev + parseFloat(curr.price);
                        }
                      }, 0)
                      .toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </Table>
            {loading ? (
              <div className="text-center my-2">
                <Button color="primary" disabled>
                  Saving <i className="fas fa-spinner fa-spin"></i>
                </Button>
              </div>
            ) : (
              <div className="text-center my-2">
                <Button
                  id="save-receipt"
                  color="primary"
                  onClick={formSubmitHandler}
                >
                  Save Receipt
                </Button>
              </div>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    receiptFormDetails: state.ReceiptReducer.addReceiptFormdetails,
    receiptItemsDetails: state.ReceiptReducer.receiptItemdetails,
    userType: state.loginUserReducer.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveReceiptFormDetails: (body) => dispatch(saveReceiptFormDetails(body)),
    saveItemsDetails: (body) => dispatch(saveItemsDetails(body)),
    resetAddReceiptForm: () => dispatch(resetAddReceiptForm()),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewReceipt);
