import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { toast } from "react-toastify";
import Unauthorized from "views/Pages/Unauthorized";
import { connect } from "react-redux";
import { fetchIpAddressListing } from "../../redux/IpAddresses/ipAddressAction";
import AddIpModal from "views/Modal/IpWhiteListModal/AddIpModal";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import Moment from "react-moment";
import UpdateIpModal from "views/Modal/IpWhiteListModal/UpdateIpModal";
import axios from "axios";

function IpWhitelist(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [id, setId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [editData, setEditData] = useState({});
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    props.fetchIpAddressListing();
  }, []);

  if (props.ips.error && !unauthorized) {
    if (props.ips.error.status === 403) {
      setUnauthorized(true);
    } else if (props.ips.error.status === 440) {
      props.resetState();
      toast.error(props.ips.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleAdd = (e) => {
    setAddModal(!addModal);
  };

  const toggleConfirm = (id = "") => {
    setId(id);
    setConfirm(!confirm);
  };

  const toggleEdit = (ip) => {
    setEditData(ip);
    setEditModal(!editModal);
  };

  const confirmed = (e) => {
    let token = localStorage.getItem("token"),
      header = { headers: { Authorization: `Bearer ${token}` } };
    axios
      .delete(process.env.REACT_APP_apiUrl+"common/delete-ip/" + id, header)
      .then((res) => {
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        props.fetchIpAddressListing();
        toggleConfirm();
      })
      .catch((err) => {
        let errRes = err.response.data;
        if (errRes.status === 403) {
          setUnauthorized(true);
        } else if (errRes.status === 440) {
          props.resetState();
          toast.error(errRes.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderTicketsTableData = (e) => {
    return props.ips.allIpAddresses.map((ip, i) => {
      return (
        <tr key={i}>
          <td>
            {ip.fromBot && (
              <>
              <i class="fas fa-robot"></i>&nbsp;
              </>
            )}
            {ip.ip}
          </td>
          <td>{ip.comment}</td>
          <td>
            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
              {ip.createdAt}
            </Moment>
          </td>
          <td>
          {!ip.fromBot && (
            <>
              [<span
              id={"update-ip"+i}
                className="delete-item"
                onClick={(e) => {
                  toggleEdit(ip);
                }}
              >
                Edit
              </span>]&nbsp;
            </>
          )}
            [<span id={"delete-ip"+i} className="delete-item" onClick={(e) => toggleConfirm(ip.id)}>
              Delete
            </span>]
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>IP Whitelisting</h1>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="6" className="search-left-btn">
                <Button id ="add-new" color="primary" onClick={toggleAdd}>
                  Add New+
                </Button>
              </Col>
            </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">IP</th>
                  <th scope="col">Comment</th>
                  <th scope="col">Added On</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
      {addModal && <AddIpModal {...props} show={addModal} toggle={toggleAdd} />}
      {editModal && (
        <UpdateIpModal
          {...props}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
        />
      )}
      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ips: state.ipAddressReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchIpAddressListing: () => dispatch(fetchIpAddressListing()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IpWhitelist);
