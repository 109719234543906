import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Row,
  Col,
} from "reactstrap";

function AddNewBlockModal(props) {
  const [isPulled, setIsPulled] = useState(props.block.isPulled);
  const [isPickedUp, setIsPickedUp] = useState(props.block.isPickedUp);

  const addBlockHandler = (e) => {
    PostApiWithAuthorizationAction("common/save-new-blocks", {
        ... props.block,
        isPickedUp,
        isPulled
    })
      .then((res) => {
        //toast.success(res.message);
        props.fetchBlockListing(props.blockDetails.page);
        props.toggle();
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } 
      });
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Save Block</ModalHeader>
      <ModalBody className="pt-1 pb-0">
        <Row>
            <Col md="6">
                <FormGroup className="mb-0">
                <Label className="form-control-label" for="b_name">
                    Fist Name
                </Label>
                <Input
                    id="firstName"
                    type="text"
                    placeholder="Fist Name"
                    value={props.block.firstName}
                    onChange={(e) => props.setBlock({
                        ... props.block,
                        firstName: e.target.value
                    })}
                />
                <span className="text-danger">
                    <small>*required</small>
                </span>
                </FormGroup>
            </Col>
            <Col md="6">
                <FormGroup className="mb-0">
                    <Label className="form-control-label" for="b_name">
                        Last Name
                    </Label>
                    <Input
                        id="lastName"
                        type="text"
                        placeholder="Last Name"
                        value={props.block.lastName}
                        onChange={(e) => props.setBlock({
                            ... props.block,
                            lastName: e.target.value
                        })}
                    />
                    <span className="text-danger">
                        <small>*required</small>
                    </span>
                </FormGroup>
            </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup className="mb-1">
              <Label className="form-control-label" for="badge">
                Badge
              </Label>
              <Input
                id="badge"
                placeholder="Badge"
                type="text"
                name="badge"
                value={props.block.badge}
                autoComplete="off"
                onChange={(e) => props.setBlock({
                  ... props.block,
                  badge: e.target.value
              })}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="mb-1">
            <Label
              className="form-control-label"
              for="detective">
              Detective
            </Label>
            <Input
              id="detective"
              placeholder="Detective"
              type="text"
              name="detective"
              value={props.block.detective}
              autoComplete="off"
              onChange={(e) => props.setBlock({
                ... props.block,
                detective: e.target.value
              })}
            />
          </FormGroup>
          </Col>
          </Row>
          <Row>
            <Col md="6">
              <FormGroup className="mb-2">
                <Label className="form-control-label" for="case_id">
                  Case 
                </Label>
                <Input
                  id="case_id"
                  placeholder="Case"
                  type="text"
                  name="case_id"
                  value={props.block.case_id}
                  autoComplete="off"
                  onChange={(e) => props.setBlock({
                    ... props.block,
                    case_id: e.target.value
                  })}
                />
              </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-2">
                  <Label className="form-control-label" for="b_name">
                    Video URL
                  </Label>
                  <Input
                    id="videoUrl"
                    type="text"
                    placeholder="Video URL"
                    value={props.block.videoUrl}
                    onChange={(e) => props.setBlock({
                        ... props.block,
                        videoUrl: e.target.value
                    })}
                  />
                </FormGroup>
              </Col>
          </Row>
        <FormGroup className="mb-2">
          <Input
              type="textarea"
              name="reason"
              id="reason"
              placeholder="Reason"
              style={{color: 'black'}}
              value={props.block.reason}
              onChange={(e) => props.setBlock({
                  ... props.block,
                  reason: e.target.value
              })}/>
        </FormGroup>
        <FormGroup className="mb-2">
          <Label className="form-control-label" for="b_name">
            {" "}
            Pulled
          </Label>
          <CustomInput
            type="switch"
            id="isPulled"
            name="enabled"
            checked={isPulled}
            onChange={(e) => {
                setIsPulled(e.target.checked);
            }}
          />
        </FormGroup>
        <FormGroup className="mb-1">
          <Label className="form-control-label" for="b_name">
            {" "}
            PickedUp
          </Label>
          <CustomInput
            type="switch"
            id="isPickedUp"
            name="enabled"
            checked={isPickedUp}
            onChange={(e) => {
                setIsPickedUp(e.target.checked);
            }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="pt-0">
        <Button
        id="add-btn"
          color="primary"
          onClick={addBlockHandler}
          disabled={props.block.firstName.length === 0 || props.block.lastName.length === 0}
        >
          Save
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddNewBlockModal;
