import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  CustomInput,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import {
  fetchAllItemsCategoriesListing,
  fetchItemsCategoriesListingSuccess,
} from "../../redux/CoinItems/coinItemsAction";
import AddCategoryModal from "views/Modal/CoinItems/Categories/AddCategoryModal";
import EditCategoryModal from "views/Modal/CoinItems/Categories/EditCategoryModal";
import { toast } from "react-toastify";

function ItemsCategories(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    props.fetchAllItemsCategoriesListing();
  }, []);

  if (props.categories.error && !unauthorized) {
    if (props.categories.error.status === 403) {
      setUnauthorized(true);
    } else if (props.categories.error.status === 440) {
      props.resetState();
      toast.error(props.categories.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleAdd = (e) => {
    setAddModal(!addModal);
  };

  const toggleEdit = (id = "", categoryName = "") => {
    setEditData({ id, categoryName });
    setEditModal(!editModal);
  };

  const renderTicketsTableData = (e) => {
    return props.categories.allCategories.map((category, i) => {
      return (
        <tr key={i}>
          <td>{category.categoryName}</td>
          <td>
            <span
            id={"update-cate"+i}
              className="delete-item"
              onClick={(e) => {
                toggleEdit(category.id, category.categoryName);
              }}
            >
              Edit
            </span>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader className="bg-white d-flex justify-content-between">
            <h1>Items Categories</h1>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <i className="fas fa-chevron-left"></i> Back
            </Button>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="6" className="search-left-btn">
                <Button id="add-new" color="primary" onClick={toggleAdd}>
                  Add New+
                </Button>
              </Col>
            </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Edit</th>
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
      {addModal && (
        <AddCategoryModal {...props} show={addModal} toggle={toggleAdd} />
      )}
      {editModal && (
        <EditCategoryModal
          {...props}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    categories: state.coinItemsReducer.categories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllItemsCategoriesListing: () =>
      dispatch(fetchAllItemsCategoriesListing()),
    fetchItemsCategoriesListingSuccess: (body) =>
      dispatch(fetchItemsCategoriesListingSuccess(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsCategories);
