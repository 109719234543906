import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

function EditNotificationEmailModal(props) {
  const [email, setEmail] = useState(props.editData.email);
  const [firstName, setFirstName] = useState(props.editData.firstName);
  const [lastName, setLastName] = useState(props.editData.lastName);

  const updateHandler = (e) => {
    let _email = email.trim();
    //
    if (_email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      let body = {
        id: props.editData.id,
        email: _email,
        firstName,
        lastName
      };
      PostApiWithAuthorizationAction("notification-emails/edit-email-data", body)
        .then((res) => {
          props.resetData(res.data);
          props.toggle(body);
        })
        .catch((err) => {
          if (err.status === 440) {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          } 
        });
    } else {
      toast.error('Invalid Email format', { position: toast.POSITION.TOP_CENTER });
    }
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Update Email</ModalHeader>
      <ModalBody>
      <FormGroup>
          <Label className="form-control-label" for="firstName">
            First Name
          </Label>
          <Input
            id="firstName"
            type="text"
            placeholder="First Name"
            autoComplete="off"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label" for="lastName">
            Last Name
          </Label>
          <Input
            id="lastName"
            type="text"
            placeholder="Last Name"
            autoComplete="off"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label" for="email">Email</Label>
          <Input
            id="email"
            type="text"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
        id="update-btn"
          color="primary"
          onClick={updateHandler}
          disabled={email.length === 0}
        >
          Update
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditNotificationEmailModal;
