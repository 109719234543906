import React from "react";
import Cookies from "js-cookie";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Label,
} from "reactstrap";
import { PostApiAction } from "../../constantFunctions/apiActions";
import { toast } from "react-toastify";
import routes from "../../routes";
import ChangeStationValue from "views/Modal/ChangeStationValue";
import { useLocation } from 'react-router';
import queryString from 'query-string';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: {
        username: "",
        password: "",
        verification_code: ""
      },
      enableTwoStepForm: false,
      loginServerData: {},
      keepLoggedIn: false,
      loading: false,
      stationModal: false,
      permissions:[]
    };
  }
  componentDidMount(){
    //CHECK AUTO LOGIN PURPOSE
    let params = queryString.parse(window.location.search);
    if (typeof params['secret'] !== "undefined") {
      this.handleAutoWhitelist(params['secret']);
    }
    if (typeof params['code'] !== "undefined") {
      this.handleAutoLogin(params['code']);
    }
    if(localStorage.getItem("token")){
      this.props.history.push("/portal")
    }
  }

  toggleStationModal = (e) => {
    this.setState({
      stationModal: !this.state.stationModal,
    });
  };

  //Handle input changes
  handleChange = (e) => {
    const { name, value } = e.target;
    let formValues = this.state.formValue;
    formValues[name] = value;
    this.setState({
      formValue: formValues,
    });
  };

  handleConnect = (data) => {
    let params = queryString.parse(window.location.search);
    let redirect = params.r || '';
    this.setState({
      loading: false,
    });
    //
    Cookies.set("jwtToken", data.token, {
      path: "/",
      domain: ".dmhq.com",
    });
    localStorage.setItem("token", data.token);
    localStorage.setItem("group", data.group.toLowerCase());
    //If employee than open station modal
    let g = data.group;
    if (g) {
      console.log(g.toLowerCase())
      if (g.toLowerCase().includes("employee")) {
        this.setState({ stationModal: true, permissions: data.permissions });
      }  else if (g.toLowerCase().includes("drawer")) {
        let loggedUrl = redirect.length > 0 ? redirect : "/transactions/daily";
        this.props.history.push(loggedUrl);
      } else if (g.toLowerCase().includes("customer")) {
        window.location.href = redirect.length > 0 ? redirect : "/home"
      } else {
        if (data.permissions.length > 0) {
          if (data.permissions.includes("dashboard")) {
            let loggedUrl = redirect.length > 0 ? redirect : "/portal/dashboard";
            this.props.history.push(loggedUrl);
          }
          else {
            let rout = routes.find(
              (r) => r.accessCode === data.permissions[0]
            );
            this.props.history.push(rout.layout + rout.path);
          }
        } else {
          this.props.history.push("/portal/not-any-permissions");
        }
      }
    }
  };

  sendLoginSecret = () => {
    let _vm = this;
    PostApiAction("users/build-login-secret")
    .then((res) => {
      if (res.status == 200) {
        alert('Please check your inbox')
      }
    })
    .catch((err) => {
      if(err.response.data.status === 440){
        toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER })
      }
    }).finally(() => {
      _vm.props.history.push("/auth/login");
    });
  }

  handleAutoLogin = (secret) => {
    let _vm = this;
    PostApiAction("users/log-with-secret", { secret })
    .then((res) => {
      if (res.data) {
        _vm.handleConnect(res.data);
        _vm.props.history.push("/portal")
      }
    })
    .catch((err) => {
      if(err.response.data.status === 440){
        toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER })
      }
    }).finally(() => {
      _vm.props.history.push("/auth/login");
    });
  }

  handleAutoWhitelist = (secret) => {
    let _vm = this;
    PostApiAction("users/login-user", { secret, username: "" })
    .then((res) => {
    })
    .catch((err) => {
      if(err.response.data.status === 440){
        toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER })
      }
    }).finally(() => {
      _vm.props.history.push("/auth/login");
    });
  }

  //submit Handler for verification code
  handleCodeVerificationSubmit = (e) => {
    e.preventDefault();
    let _vm = this;
    _vm.setState({
      loading: true,
    });
    PostApiAction("users/verify-authenticator-code", {
      verification_code: _vm.state.formValue.verification_code
    }, {
      'Authorization': 'Bearer ' + _vm.state.loginServerData.token
    })
      .then((res) => {
        if(res.verified) {
          _vm.handleConnect(_vm.state.loginServerData);
        } else {
          toast.error('Invalid Verification Code',{ position: toast.POSITION.TOP_CENTER })
        }
      })
      .catch((err) => {
        _vm.setState({
          loading: false,
        });
        if(err.response.data.status === 440){
          toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER })
        }
      });
  };

  //Submit Handler for form
  handleSubmit = (e) => {
    e.preventDefault();
    let _vm = this;
    let obj = {
      username: _vm.state.formValue.username,
      password: _vm.state.formValue.password,
      keepLoggedIn: _vm.state.keepLoggedIn,
    };
    _vm.setState({
      loading: true,
    });
    PostApiAction("users/login-user", obj)
      .then((res) => {
        if(!res.data.twoStepEnabled) {
          _vm.handleConnect(res.data);
        } else {
          _vm.setState({
            loginServerData: res.data
          });
          //
          _vm.setState({
            enableTwoStepForm: true
          });
        }
      })
      .catch((err) => {
        _vm.setState({
          loading: false,
        });
        if(err.response.data.status === 440){
          toast.error(err.response.data.message,{ position: toast.POSITION.TOP_CENTER })
        }
      });
  };

  render() {
    let params = queryString.parse(window.location.search);
    return (
      <>
        <Col lg="5" md="7" style={{display: (typeof params['secret'] !== "undefined" || typeof params['code'] !== "undefined") ? 'none' : 'block'}}>
          <Card className="bg-secondary shadow border-0">
            {(this.state.enableTwoStepForm) && (
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-2">
                  <h2>Enter your code</h2>
                </div>
                <div
                  className="text-center text-muted mb-3 font-weight-500">
                  <i>This extra step shows it's really you trying to sign in.</i>
                </div>
                <div style={{background: 'white', boxShadow: '0 0 2px #ddd', border: '1px solid #bbb'}} className="p-4">
                  <Form role="form" onSubmit={this.handleCodeVerificationSubmit}>
                    <FormGroup
                      className="mb-3"
                      style={{border: '1px solid #ddd', borderRadius: '8px'}}>
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i class="fas fa-qrcode"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoFocus
                          placeholder="Enter your code"
                          type="text"
                          id="verification_code"
                          name="verification_code"
                          value={this.state.formValue.verification_code}
                          maxLength="6"
                          autoComplete="off"
                          onChange={this.handleChange}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        className="btn-block"
                        color="primary"
                        type="submit"
                        style={{fontWeight: 'normal', fontSize: '18px'}}>Verify code</Button>
                    </div>
                  </Form>
                </div>
              </CardBody>         
            )}
            {(!this.state.enableTwoStepForm) && (
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Sign in with credentials</small>
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      autoFocus
                      placeholder="Username or Email"
                      type="text"
                      id="username"
                      name="username"
                      value={this.state.formValue.username}
                      autoComplete="new-email"
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Password"
                      id="password"
                      type="password"
                      name="password"
                      value={this.state.formValue.password}
                      autoComplete="new-password"
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup check>
                  <Label className="form-control-label" check>
                    <Input
                      type="checkbox"
                      checked={this.state.keepLoggedIn}
                      onChange={(e) => {
                        this.setState({ keepLoggedIn: e.target.checked })
                      }}
                    />{" "}
                    Keep me logged in
                  </Label>
                </FormGroup>
                {this.state.loading ? (
                  <div className="text-center">
                    <Button color="primary" disabled>
                      loading <i className="fas fa-spinner fa-spin"></i>
                    </Button>
                  </div>
                ) : (
                  <div className="text-center">
                    <Button className="mt-4" color="primary" type="submit">
                      Sign in
                    </Button>
                  </div>
                )}
                <Link
                  id={'recover-login'}
                  onClick={(e) => {
                  e.preventDefault();
                  this.sendLoginSecret()
                }}>Forgot Password ?</Link>               
              </Form>
            </CardBody>
            )}
          </Card>
          {/* <Row className="mt-3">
            <Col xs="6">
           
            </Col>
          </Row> */}
        </Col>
        {this.state.stationModal && (
          <ChangeStationValue
          {...this.props}
            show={this.state.stationModal}
            toggle={this.toggleStationModal}
            permissions={this.state.permissions}
          />
        )}
      </>
    );
  }
}

export default Login;
