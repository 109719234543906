import {
    FETCH_KEYWORDS_LISTING_SUCCESS,
    FETCH_KEYWORDS_LISTING_FAILURE
  } from "./keywordsTypes";
  import {
    setLoaderRequestTrue,
    setLoaderRequestFalse,
  } from "../common/commonAction";
  import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";
  
  /*_________________________________________________________________User Listing __________________________________________________________________ */
  
  //Api Call Function for user listing
  export const fetchKeywordsListing = (page = 0) => {
    return (dispatch) => {
      dispatch(setLoaderRequestTrue());
      GetApiActionWithAuthorization("leads-online/get-all-data?page=" + page)
        .then((res) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchKeywordsListingSuccess(res));
        })
        .catch((err) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchKeywordsListingFailure(err));
        });
    };
  };
  
  export const fetchKeywordsListingSuccess = (data) => {
    console.log(data)
    return {
      type: FETCH_KEYWORDS_LISTING_SUCCESS,
      payload: data,
    };
  };
  
  export const fetchKeywordsListingFailure = (error) => {
    return {
      type: FETCH_KEYWORDS_LISTING_FAILURE,
      payload: error,
    };
  };
  /*__________________________________________________________________________________________________________________________________________ */
  