import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button, Row, Col, FormGroup, Label, Input
} from "reactstrap";

function BlockModal(props) {
    const [badge,setBadge] = useState("");
    const [case_id,setCase] = useState("");
    const [detective,setDetective] = useState("");
    const [reason,setReason] = useState("");

    const addToBlockList = e =>{
        let body={
            badge:badge,
            case_id:case_id,
            reason:reason,
            detective:detective,
            firstName:props.data.firstName,
            lastName:props.data.lastName,
            ticketId:props.data.ticketId,
        }
        PostApiWithAuthorizationAction("metro-tickets/add-to-block-list",body).then(res =>{
          //toast.success(res.message);
          props.setBlock(true);
          props.toggle();
        }).catch(err =>{
            console.log(err);
        })
    }
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Add to Block List</ModalHeader>
      <ModalBody>
        <Row>
            <Col md ="6">
            <FormGroup className="mb-3">
                  <Label className="form-control-label" for="badge">
                    Badge
                  </Label>
                  <Input
                    id="badge"
                    placeholder="Badge"
                    type="text"
                    name="badge"
                    value={badge}
                    autoComplete="off"
                    onChange={e => setBadge(e.target.value)}
                  />
                </FormGroup>
            </Col>
            <Col md ="6">
            <FormGroup className="mb-3">
                  <Label className="form-control-label" for="detective">
                    Detective
                  </Label>
                  <Input
                    id="detective"
                    placeholder="Detective"
                    type="text"
                    name="detective"
                    value={detective}
                    autoComplete="off"
                    onChange={e => setDetective(e.target.value)}
                  />
                </FormGroup>
            </Col>
            <Col md ="12">
            <FormGroup className="mb-3">
                  <Label className="form-control-label" for="case_id">
                    Case 
                  </Label>
                  <Input
                    id="case_id"
                    placeholder="Case"
                    type="text"
                    name="case_id"
                    value={case_id}
                    autoComplete="off"
                    onChange={e => setCase(e.target.value)}
                  />
                </FormGroup>
            </Col>
            <Col md ="12">
            <FormGroup className="mb-3">
                  <Label className="form-control-label" for="reason">
                    Reason
                  </Label>
                  <textarea
                  id="reason"
                  className="form-control"
                  type="textarea"
                  placeholder="Reason"
                  rows="2"
                  style={{ resize: "none" }}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
                </FormGroup>
            </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={e => addToBlockList()}>
          Block
        </Button>{" "}
        <Button color="secondary" onClick={e =>props.toggle()}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

export default BlockModal;
