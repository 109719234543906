import {
  FETCH_CLERKS_LISTING_FAILURE,
  FETCH_CLERKS_LISTING_SUCCESS
} from "./clerksTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________User Listing __________________________________________________________________ */

//Api Call Function for user listing
export const fetchClerksListing = () => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-all-clerks")
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchClerksListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchClerksListingFailure(err));
      });
  };
};

//Clerkslisting Success save Payload data to store
export const fetchClerksListingSuccess = (data) => {
  return {
    type: FETCH_CLERKS_LISTING_SUCCESS,
    payload: data,
  };
};

//Clerkslisting Failure save Payload
export const fetchClerksListingFailure = (error) => {
  return {
    type: FETCH_CLERKS_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
