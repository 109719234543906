import React, { useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Button,
  Label,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import {
  fetchAllStoresListing,
  fetchAllClerkListing,
  saveTicketItemDetails,
  saveTicketFormDetails,
  resetAddTicketForm,
} from "../../../redux/Tickets/ticketAction";
import {
  copyTicketDetailsToCoin
} from "../../../redux/CoinTicket/coinAction";
import { DropdownList } from "react-widgets";
import Unauthorized from "views/Pages/Unauthorized";
import AddMetroTicketForm from "views/Forms/AddMetroTicket/AddMetroTicketForm";
import { validateTicketForm } from "constantFunctions/formValidations";
import { toast } from "react-toastify";
import { PostApiWithAuthorizationAction } from "../../../constantFunctions/apiActions";
import { jsonToFormData } from "libs/common";

function AddNewMetroTicket(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [copyTo, setCopyTo] = useState({ value: "coin", label: "NewCoin" });
  const [blocked, setBlocked] = useState(false);
  const [censored, setCensored] = useState(false);
  const [loading, setLoading] = useState(false);
  //
  const saveHandler = (e) => {
    let data = jsonToFormData({
      details: {
        ...props.metroFormDetails,
        payment: props.metroFormDetails.payment.value,
        sex: props.metroFormDetails.sex.value,
        eyes: props.metroFormDetails.eyes.value,
        hair: props.metroFormDetails.hair.value,
        booth: props.metroFormDetails.booth.name,
        station: props.metroFormDetails.station.value,
        copyTo: props.metroFormDetails.copyTo.value,
        race: props.metroFormDetails.race.value,
        storeId: props.metroFormDetails.storeId.id,
        clerk: props.metroFormDetails.clerk.first_name,
        fromx: props.metroFormDetails.fromx.name,
      },
      items: props.items.items
    });
    window.jQuery('[name="files[]"]').each(function( index ) {
      let files = window.jQuery('[name="files[]"]')[index].files;
      for (const file of files) {
        data.append('files[]', file, file.name);
      }
    });
    setLoading(true);
    PostApiWithAuthorizationAction("metro-tickets/create-new", data, {
      'Content-Type': 'multipart/form-data'
    })
      .then((res) => {
        setLoading(false);
        //toast.success(res.message);
        props.resetForm();
        props.history.push("/portal/metro-tickets");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error(err.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card>
          <CardHeader
            className={
              blocked
                ? ((censored ? "back-green" : "back-red") + " d-flex justify-content-between p-2")
                : "bg-white d-flex justify-content-between p-2"
            }
          >
            <h3>Add Metro Ticket</h3>
            {/* <div className="d-flex copy-drop align-items-center">
            <div className="d-flex copy-drop justify-content-end align-items-center">
              <Label className="m-0">Copy to:</Label>
              <DropdownList
                data={[{ value: "coin", label: "NewCoin" }]}
                containerClassName="race-drop"
                textField="label"
                value={copyTo}
                onChange={(e) => setCopyTo(e)}
              />
              <Button className="ml-1" color="info" onClick={e => copyToNew()}>
                Copy
              </Button>
            </div> */}
            <Button
              type="button"
              className="py-2 ml-2"
              color="primary"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <i className="fas fa-chevron-left"></i> Back
            </Button>
            {/* </div> */}
          </CardHeader>
          <CardBody className={blocked ? (censored ? "back-green" : "back-red") + " p-2" : "p-2"}>
            
            <AddMetroTicketForm {...props} blocked={setBlocked} censored={setCensored}/>
            <div className="text-center mt-3">
              {blocked ? (
                <Button type="submit" color="primary" disabled>
                  Blocked
                </Button>
              ) : loading ? (
                <Button color="primary" disabled>
                  loading <i className="fas fa-spinner fa-spin"></i>
                </Button>
              ) : (
                <Button
                id="submit-ticket"
                  type="submit"
                  color="primary"
                  onClick={(e) => {
                    let validation = validateTicketForm({
                      ...props.metroFormDetails,
                      userType: props.userType
                    });
                    if (validation.success) {
                      saveHandler();
                    } else {
                      toast.error(validation.err, {
                        position: toast.POSITION.TOP_CENTER,
                        closeOnClick: true,
                        autoClose: 8000,
                      });
                    }
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
// }

const mapStateToProps = (state) => {
  return {
    stores: state.ticketReducer.stores,
    clerks: state.ticketReducer.clerks,
    metroFormDetails: state.ticketReducer.addTicketFormdetails,
    items: state.ticketReducer.metroItemdetails,
    userType: state.loginUserReducer.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchAllStoresListing: () => dispatch(fetchAllStoresListing()),
    fetchAllClerkListing: () => dispatch(fetchAllClerkListing()),
    saveTicketFormDetails: (body) => dispatch(saveTicketFormDetails(body)),
    saveTicketItemDetails: (body) => dispatch(saveTicketItemDetails(body)),
    copyTicketDetailsToCoin:(body) => dispatch(copyTicketDetailsToCoin(body)),
    resetForm: () => dispatch(resetAddTicketForm()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewMetroTicket);
