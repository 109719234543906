import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";

function AddNotificationEmailModal(props) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);

  const addHandler = (e) => {
    let _email = email.trim();
    //
    if (_email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      let body = {
        email: _email,
        firstName,
        lastName
      };
      setLoading(true);
      PostApiWithAuthorizationAction("notification-emails/add-email", body)
        .then((res) => {
          setLoading(false);
          props.load();
          props.toggle();
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 440) {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
      } else {
        toast.error('Invalid Email format', { position: toast.POSITION.TOP_CENTER });
      }
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Add new Email</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="form-control-label" for="firstName">
            First Name
          </Label>
          <Input
            id="firstName"
            type="text"
            placeholder="First Name"
            autoComplete="off"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label" for="lastName">
            Last Name
          </Label>
          <Input
            id="lastName"
            type="text"
            placeholder="Last Name"
            autoComplete="off"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label" for="email">
            Email
          </Label>
          <Input
            id="email"
            type="email"
            placeholder="Email"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        {loading ? (
          <Button color="primary" disabled>
            Adding <i className="fas fa-spinner fa-spin"></i>
          </Button>
        ) : (
          <>
            <Button
              id="add-btn"
              color="primary"
              onClick={addHandler}
              disabled={email.length === 0}
            >
              Add
            </Button>{" "}
            <Button color="secondary" onClick={props.toggle}>
              Cancel
            </Button>
          </>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default AddNotificationEmailModal;
