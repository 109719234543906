import {
  FETCH_COIN_ITEMS_P_LISTING_SUCCESS,
  FETCH_METRO_ITEMS_P_LISTING_SUCCESS,
  FETCH_COIN_ITEMS_P_LISTING_FAILURE,
  FETCH_METRO_ITEMS_P_LISTING_FAILURE,
  FETCH_ITEMS_CATEGORY_LISTING_SUCCESS,
  FETCH_ITEMS_CATEGORY_LISTING_FAILURE
} from "./coinItemsTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________items category Listing __________________________________________________________________ */

//Api Call Function for items listing
export const fetchAllItemsCategoriesListing = () => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("coin-tickets/get-all-coin-items-categories")
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchItemsCategoriesListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchItemsCategoriesListingFailure(err));
      });
  };
};

//Userlisting Success save Payload data to store
export const fetchItemsCategoriesListingSuccess = (categories) => {
  return {
    type: FETCH_ITEMS_CATEGORY_LISTING_SUCCESS,
    payload: categories,
  };
};

//Userlisting Failure save Payload
export const fetchItemsCategoriesListingFailure = (error) => {
  return {
    type: FETCH_ITEMS_CATEGORY_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */


/*_________________________________________________________________items Listing __________________________________________________________________ */

//Api Call Function for items listing
export const fetchAllItemsListing = (itemType = '') => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("coin-tickets/get-all-coin-items", {
      itemType
    })
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        if (itemType == 'coin') {
          dispatch(fetchCoinItemsListingSuccess(res.data));
        } else {
          dispatch(fetchMetroItemsListingSuccess(res.data));
        }
      })
      .catch((err) => {
        //console.log(err)
        dispatch(setLoaderRequestFalse());
        if (itemType == 'coin') {
          dispatch(fetchCoinItemsListingFailure(err));
        } else {
          dispatch(fetchMetroItemsListingFailure(err));
        }
      });
  };
};

//-- Payload Coin data
//Userlisting Success save Payload data to store
export const fetchCoinItemsListingSuccess = (items) => {
  return {
    type: FETCH_COIN_ITEMS_P_LISTING_SUCCESS,
    payload:items
  };
};

//Userlisting Failure save Payload
export const fetchCoinItemsListingFailure = (error) => {
  return {
    type: FETCH_COIN_ITEMS_P_LISTING_FAILURE,
    payload: error
  };
};

export const fetchItemsListingSuccess = (items, itemType) => {
  return (itemType === 'coin') ? fetchCoinItemsListingSuccess(items) : fetchMetroItemsListingSuccess(items)
};
//-- Payload metro data
//Userlisting Success save Payload data to store
export const fetchMetroItemsListingSuccess = (items) => {
  return {
    type: FETCH_METRO_ITEMS_P_LISTING_SUCCESS,
    payload:items
  };
};

//Userlisting Failure save Payload
export const fetchMetroItemsListingFailure = (error) => {
  return {
    type: FETCH_METRO_ITEMS_P_LISTING_FAILURE,
    payload: error
  };
};
/*__________________________________________________________________________________________________________________________________________ */

    
