import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import { FormGroup, Input, Label, Table } from "reactstrap";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import { itemType, itemMaterial } from "../../../constants/ticketConstants";

function ItemsEditableTable(props) {
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");

  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  //----- After delete modal confirm successfully -------
  const confirmed = (e) => {
    GetApiActionWithAuthorization(
      "metro-tickets/delete-item-from-ticket/" +
        delId +
        "/" +
        props.match.params.id
    )
      .then((res) => {
        props.fetchTicketItemsListingSuccess({
          data: res.data,
          ticketDetails: res.ticketDetails,
        });
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        setConfirm(false);
      })
      .catch((err) => {
        if (err.status === 403) {
          props.setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  //-----------return weight value from item object ----------
  const getWeightFromString = (item) => {
    let itemWeight = "";
    let weightAsDecimal = item.description.split(". ")[2];

    if (weightAsDecimal){
      itemWeight = weightAsDecimal.split(":")[1].split(")")[0].trim();
    }

    return itemWeight;
  };

  //-------- Sold to check box handler of items-----------------
  const checkBoxChangeHandler = (e, name, id) => {
    const { checked } = e.target;
    let body = {
      ticketId: props.match.params.id,
      itemId: id,
    };
    if (checked) {
      body.soldTo = name;
    } else {
      body.soldTo = "";
    }

    PostApiWithAuthorizationAction("metro-tickets/edit-items-send-items", body)
      .then((res) => {
        props.fetchTicketItemsListingSuccess({
          data: res.data,
          ticketDetails: res.ticketDetails,
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          props.setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  //-----Update values in tables like material description.etc----------
  const updateValuesInTable = (name, value, id) => {
    let body = {
      ticketId: props.match.params.id,
      itemId: id,
      [name]: value,
    };
    PostApiWithAuthorizationAction("metro-tickets/edit-items-send-items", body)
      .then((res) => {
        props.fetchTicketItemsListingSuccess({
          data: res.data,
          ticketDetails: res.ticketDetails,
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          props.setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  //----Change items description input value-------
  const changeDesctriptionValue = (e, item) => {
    let newValue = e.target.value
      .split(". ")
      .map((t) => t.trim())
      .join(".");
    let desc = item.description.split(". ")[0].trim() + ". " + newValue;

    let weightProps = [
      "lb",
      "lbs",
      "gram",
      "grams",
      "pound",
      "pounds",
      "lb.",
      "lbs.",
      "gram.",
      "grams.",
      "pound.",
      "pounds.",
    ];
    function endsWithAny(suffixes, string) {
      return suffixes.some(function (suffix) {
        return string.endsWith(suffix);
      });
    }

    if (!endsWithAny(weightProps, desc)) {
      if (
        item.description.split(". ")[0].trim() === "Costume" ||
        item.description.split(". ")[0].trim() === "Silver Plate"
      ) {
        desc = desc + " " + "lbs";
      } else if(item.description.split(". ")[0].trim() !== "Other"){
        desc = desc + " " + "grams";
      }
    }
    if (item.description.split(". ")[2]) {
      desc = desc + ". " + item.description.split(". ")[2].trim();
    }
    updateValuesInTable("description", desc, item.id);
  };

  const selectAllOfSoldTo = (value) => {
    let body = {
      ticketId: props.match.params.id,
      selectAllOnly: true,
      soldTo: value,
    };
    PostApiWithAuthorizationAction("metro-tickets/edit-items-send-items", body)
      .then((res) => {
        props.fetchTicketItemsListingSuccess({
          data: res.data,
          ticketDetails: res.ticketDetails,
        });
      })
      .catch((err) => {
        if (err.status === 403) {
          props.setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const getSearchableContent = (description)=>{
      return description.split(". ")[1]
      ? description.split(". ")[1].trim()
      : "";
  }

  return (
    <>
      <Table className="metro-items-listing mb-5" bordered responsive="sm">
        <thead>
          <tr>
            <th style={{ width: "60px" }}> Type</th>
            <th style={{ minWidth: "300px" }}>Item Description</th>
            <th style={{ width: "80px" }}>Model</th>
            <th style={{ width: "80px" }}>Serial</th>
            <th
              style={{ width: "60px" }}
              className="pointer"
              onClick={(e) => selectAllOfSoldTo("A")}
            >
              Sold to A
            </th>
            <th
              style={{ width: "60px" }}
              className="pointer"
              onClick={(e) => selectAllOfSoldTo("B")}
            >
              Sold to B
            </th>
            {/* <th style={{ width: "50px" }}>Edit</th> */}
            <th style={{ width: "60px" }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {props.items.items.map((item, i) => {
            if (props.search) {
              if (getSearchableContent(item.description).includes(props.search.trim())) {
                return (
                  <tr key={i} id={"edit-row"+i}>
                    <td>
                      <FormGroup className="m-0">
                        <DropdownList
                        id="type"
                          className="text-left"
                          placeholder="Select"
                          containerClassName="metro-type-drop"
                          data={itemType}
                          placeholder="Select"
                          value={
                            item.propertyCode === "3" ? "3 Jewelry" : "10 Misc"
                          }
                          onChange={(e) =>
                            updateValuesInTable(
                              "propertyCode",
                              e.split(" ")[0],
                              item.id
                            )
                          }
                        />
                      </FormGroup>
                      {/* {item.propertyCode === "3" ? "Jewelry" : "Misc"} */}
                    </td>
                    <td style={{ whiteSpace: "normal" }}>
                      <div className="row m-0">
                        <div className="col-4 text-left p-0">
                          <Label className="m-0 text-left">Material:</Label>
                          <DropdownList
                      id={"material"+i}
                      className="text-left"
                            data={itemMaterial}
                            value={item.description.split(". ")[0].trim()}
                            containerClassName="metro-item-drop"
                            placeholder="Select"
                            onChange={(e) => {
                              let desc =
                                e +
                                ". " +
                                item.description.split(". ")[1].trim();
                              if (item.description.split(". ")[2]) {
                                desc =
                                  desc +
                                  ". " +
                                  item.description.split(". ")[2].trim();
                              }
                              updateValuesInTable("description", desc, item.id);
                            }}
                          />
                        </div>
                        <div className=" col-6 text-left p-0 pl-1">
                          <Label className="m-0">Description:</Label>
                          <Input
                      id={"description"+i}
                      type="text"
                            className="form-control"
                            placeholder="Description"
                            defaultValue={item.description
                              .split(". ")[1]
                              .trim()}
                            onBlur={(e) => changeDesctriptionValue(e, item)}
                          />
                        </div>

                        {/*<div className="col-2 text-left p-0 pl-1">
                          <Label className="m-0">Weight:</Label>
                          <Input
                      id={"weight"+i}
                      type="text"
                            // style={{ width: "60px" }}
                            placeholder="Weight"
                            defaultValue={getWeightFromString(item)}
                            onBlur={(e) => {
                              let desc =
                                item.description.split(". ")[0].trim() +
                                ". " +
                                item.description.split(". ")[1].trim() +
                                ". " +
                                "(Weight:" +
                                e.target.value +
                                ")";
                              updateValuesInTable("description", desc, item.id);
                            }}
                          />
                          </div>*/}
                      </div>

                      {/* {item.description} */}
                    </td>
                    <td>
                      <FormGroup className="m-0">
                        <Input
                      id={"model"+i}
                      type="text"
                          placeholder="Model"
                          defaultValue={item.model}
                          onBlur={(e) =>
                            updateValuesInTable(
                              "model",
                              e.target.value,
                              item.id
                            )
                          }
                        />
                      </FormGroup>
                      {/* {item.model} */}
                    </td>
                    <td>
                      <FormGroup className="m-0">
                        <Input
                      id={"serial"+i}
                      type="text"
                          defaultValue={item.serial}
                          placeholder="Serial"
                          onBlur={(e) =>
                            updateValuesInTable(
                              "serial",
                              e.target.value,
                              item.id
                            )
                          }
                        />
                      </FormGroup>
                      {/* {item.serial} */}
                    </td>
                    <td>
                      <input
                      id={"soldToA"+i}
                      type="checkbox"
                        name="a"
                        checked={item.soldTo.toLowerCase() === "a"}
                        onChange={(e) => checkBoxChangeHandler(e, "A", item.id)}
                      />
                    </td>
                    <td>
                      <input
                      id={"soldToB"+i}
                      type="checkbox"
                        name="b"
                        checked={item.soldTo.toLowerCase() === "b"}
                        onChange={(e) => checkBoxChangeHandler(e, "B", item.id)}
                      />
                    </td>
                    {/* <td>
                <a
                  href="#"
                  onClick={(e) =>
                    toggleEdit({ ...item, id: item.id })
                  }
                >
                  Edit
                </a>
              </td> */}
                    <td>
                      <a href="#" id={"delete"+i} onClick={(e) => toggleConfirm(item.id)}>
                        Delete
                      </a>
                    </td>
                  </tr>
                );
              } else {
                return null;
              }
            } else {
              return (
                <tr key={i} id={"edit-row"+i}>
                <td>
                  <FormGroup className="m-0">
                    <DropdownList
                    id={"type"+i}
                      className="text-left"
                      placeholder="Select"
                      containerClassName="metro-type-drop"
                      data={itemType}
                      placeholder="Select"
                      value={
                        item.propertyCode === "3" ? "3 Jewelry" : "10 Misc"
                      }
                      onChange={(e) =>
                        updateValuesInTable(
                          "propertyCode",
                          e.split(" ")[0],
                          item.id
                        )
                      }
                    />
                  </FormGroup>
                  {/* {item.propertyCode === "3" ? "Jewelry" : "Misc"} */}
                </td>
                <td style={{ whiteSpace: "normal" }}>
                  <div className="row m-0">
                    <div className="col-5 text-left p-0">
                      <Label className="m-0 text-left">Material:</Label>
                      <DropdownList
                  id={"material"+i}
                  className="text-left"
                        data={itemMaterial}
                        value={item.description.split(". ")[0].trim()}
                        containerClassName="metro-item-drop"
                        placeholder="Select"
                        onChange={(e) => {
                          let desc =
                            e +
                            ". " +
                            item.description.split(". ")[1].trim();
                          if (item.description.split(". ")[2]) {
                            desc =
                              desc +
                              ". " +
                              item.description.split(". ")[2].trim();
                          }
                          updateValuesInTable("description", desc, item.id);
                        }}
                      />
                    </div>
                    <div className=" col-7 text-left p-0 pl-1">
                      <Label className="m-0">Description:</Label>
                      <Input
                  id={"description"+i}
                  type="text"
                        className="form-control"
                        placeholder="Description"
                        defaultValue={item.description
                          .split(". ")[1]
                          .trim()}
                        onBlur={(e) => changeDesctriptionValue(e, item)}
                      />
                    </div>

                    {/*<div className="col-2 text-left p-0 pl-1">
                      <Label className="m-0">Weight:</Label>
                      <Input
                  id={"weight"+i}
                  type="text"
                        // style={{ width: "60px" }}
                        placeholder="Weight"
                        defaultValue={getWeightFromString(item)}
                        onBlur={(e) => {
                          let desc =
                            item.description.split(". ")[0].trim() +
                            ". " +
                            item.description.split(". ")[1].trim() +
                            ". " +
                            "(Weight:" +
                            e.target.value +
                            ")";
                          updateValuesInTable("description", desc, item.id);
                        }}
                      />
                      </div>*/}
                  </div>

                  {/* {item.description} */}
                </td>
                <td>
                  <FormGroup className="m-0">
                    <Input
                  id={"model"+i}
                  type="text"
                      placeholder="Model"
                      defaultValue={item.model}
                      onBlur={(e) =>
                        updateValuesInTable(
                          "model",
                          e.target.value,
                          item.id
                        )
                      }
                    />
                  </FormGroup>
                  {/* {item.model} */}
                </td>
                <td>
                  <FormGroup className="m-0">
                    <Input
                  id={"serial"+i}
                  type="text"
                      defaultValue={item.serial}
                      placeholder="Serial"
                      onBlur={(e) =>
                        updateValuesInTable(
                          "serial",
                          e.target.value,
                          item.id
                        )
                      }
                    />
                  </FormGroup>
                  {/* {item.serial} */}
                </td>
                <td>
                  <input
                  id={"soldToA"+i}
                  type="checkbox"
                    name="a"
                    checked={item.soldTo.toLowerCase() === "a"}
                    onChange={(e) => checkBoxChangeHandler(e, "A", item.id)}
                  />
                </td>
                <td>
                  <input
                  id={"soldToB"+i}
                  type="checkbox"
                    name="b"
                    checked={item.soldTo.toLowerCase() === "b"}
                    onChange={(e) => checkBoxChangeHandler(e, "B", item.id)}
                  />
                </td>
                {/* <td>
            <a
              href="#"
              onClick={(e) =>
                toggleEdit({ ...item, id: item.id })
              }
            >
              Edit
            </a>
          </td> */}
                <td>
                  <a href="#" id={"delete"+i} onClick={(e) => toggleConfirm(item.id)}>
                    Delete
                  </a>
                </td>
              </tr>
            );
            }
          })}
        </tbody>
      </Table>
      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
    </>
  );
}

export default ItemsEditableTable;



