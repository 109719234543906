//Stores Listing
export const FETCH_STORES_LISTING_SUCCESS = 'FETCH_STORES_LISTING_SUCCESS';
export const FETCH_STORES_LISTING_FAILURE = 'FETCH_STORES_LISTING_FAILURE';

//Clerk Listing
export const FETCH_CLERK_LISTING_SUCCESS = 'FETCH_CLERK_LISTING_SUCCESS';
export const FETCH_CLERK_LISTING_FAILURE = 'FETCH_CLERK_LISTING_FAILURE';

//Tickets Listing
export const FETCH_TICKETS_LISTING_SUCCESS = 'FETCH_TICKETS_LISTING_SUCCESS';
export const FETCH_TICKETS_LISTING_FAILURE = 'FETCH_TICKETS_LISTING_FAILURE';
export const UPDATE_TICKET_DATA_AFTER_UPDATE = 'UPDATE_TICKET_DATA_AFTER_UPDATE';

//Ticket Items

export const FETCH_TICKET_ITEMS__LISTING_FAILURE = 'FETCH_TICKET_ITEMS__LISTING_FAILURE';
export const FETCH_TICKET_ITEMS_LISTING_SUCCESS = 'FETCH_TICKET_ITEMS_LISTING_SUCCESS';

//Ticket form Details
export const SAVE_TICKETS_FORM_DETAILS = 'SAVE_TICKETS_FORM_DETAILS';
export const SAVE_TICKETS_ITEMS_DETAILS = 'SAVE_TICKETS_ITEMS_DETAILS';
export const RESET_SAVE_TICKETS_DETAILS = 'RESET_SAVE_TICKETS_DETAILS';
export const COPY_TICKETS_DETAILS_IN_METRO = 'COPY_TICKETS_DETAILS_IN_METRO';

export const RESET = 'RESET';
