import {
  FETCH_LOGIN_USER_SUCCESS,
  FETCH_LOGIN_USER_FAILURE,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE
} from "./loginUserTypes";
import {
  GetApiActionWithAuthorization,
  PostApiWithAuthorizationAction,
} from "../../constantFunctions/apiActions";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { toast } from "react-toastify";

/*_________________________________________________________________Get User Details and listing __________________________________________________________________ */
export const fetchLoginUser = () => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("users/particular-user-permissions")
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchLoginUserSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchLoginUserFailure(err));
      });
  };
};

export const fetchLoginUserSuccess = (details) => {
  return {
    type: FETCH_LOGIN_USER_SUCCESS,
    payload: details,
  };
};

export const fetchLoginUserFailure = (error) => {
  return {
    type: FETCH_LOGIN_USER_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */

/*_________________________________________________________________Update User Details__________________________________________________________________ */
export const updateUserDetails = (body) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    PostApiWithAuthorizationAction("users/update-login-user-details", body)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        dispatch(updateUserDetailsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(updateUserDetailsFailure(err));
      });
  };
};

export const updateUserDetailsSuccess = (details) => {
  return {
    type: UPDATE_USER_DETAILS_SUCCESS,
    payload: details,
  };
};

export const updateUserDetailsFailure = (error) => {
  return {
    type: UPDATE_USER_DETAILS_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
