import {
  FETCH_TAXES_LISTING_FAILURE,
  FETCH_TAXES_LISTING_SUCCESS,
} from "./TaxesTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________Taxes Listing __________________________________________________________________ */

//Api Call Function for Taxes listing
export const fetchTaxesListing = (startDate,endDate, receiptType) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-all-tax-details", {
      startDate,
      endDate, 
      receiptType: receiptType.id || 'CR'
    }).then((res) => {
      dispatch(setLoaderRequestFalse());
      dispatch(fetchTaxesListingSuccess(res.data));
    }).catch((err) => {
      dispatch(setLoaderRequestFalse());
      dispatch(fetchTaxesListingFailure(err));
    });
  };
};

//Check listing Success save Payload data to store
export const fetchTaxesListingSuccess = (taxesData) => {
  return {
    type: FETCH_TAXES_LISTING_SUCCESS,
    payload: taxesData,
  };
};

//Check listing Failure save Payload
export const fetchTaxesListingFailure = (error) => {
  return {
    type: FETCH_TAXES_LISTING_FAILURE,
    payload: error,
  };
};

/*__________________________________________________________________________________________________________________________________________ */



