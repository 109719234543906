import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
// reactstrap components
import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Collapse,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { resetState } from "../../redux/common/commonAction";
import classnames from "classnames";
class Sidebar extends React.Component {
  state = {
    toolsCollapse: false,
    cpaCollapse: false,
    settingCollapse_1: false,
    settingCollapse_2: false,
    settingCollapse_3: false
  };

  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  toggleToolsSub = () => {
    this.setState({
      toolsCollapse: !this.state.toolsCollapse
    });
  };

  toggleCpaSub = () => {
    this.setState({
      cpaCollapse: !this.state.cpaCollapse
    });
  };

  toggleSettingSub = (level) => {
    let obj = {};
    obj["settingCollapse_" + level] = !this.state["settingCollapse_" + level];
    this.setState(obj);
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes, group) => {
    return routes.map((prop, key) => {
      let canShowSideBarItem = (prop.group === group);
      if (prop.hideOnAdmin === true && this.props.userType == 'admin') {
        canShowSideBarItem = false;
      }
      //
      if (canShowSideBarItem) {
        let sideBarUrl = prop.layout + (prop.displayUrl ? prop.displayUrl : prop.path);
        let classNames = {};
        classNames[prop.name.split(" ").join("").toLowerCase()] = true;
        if (this.props.location.pathname == sideBarUrl) {
          classNames["active"] = true;
        }
        return (
          <NavItem key={key}>
            <NavLink
              id={prop.accessCode}
              className={classnames(classNames)}
              href={sideBarUrl}
              onClick={this.props.sideBarHandler}
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      } else {
        return null;
      }
    });
  };

  render() {
    const { routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank",
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="sm"
        id="sidebar-wrapper"
      >
        <Container fluid>
          {/* Brand */}
          <NavbarBrand className="pt-0 logo-side" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>

          {/* User */}
          {/* Collapse */}
          <Navbar className="side-links border-0">
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <a href={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
              </Row>
            </div>
            {/* Navigation */}
            <Nav navbar>{this.createLinks(routes, 'root')}</Nav>
            {/* Admin Tools Menu Items */}
            {routes.find((r) => r.group === 'cpa') && (
              <div>
                {/* Divider */}
                <hr className=" my-1 w-100" />
                <Nav navbar>
                  {" "}
                  <NavItem
                    className={classnames({
                      activeTools: this.state.cpaCollapse,
                    })}
                  >
                    <NavLink
                    id="admin-tools"
                      className="pointer d-flex justify-content-between"
                      onClick={this.toggleCpaSub}
                    >
                      <div className="text-left">
                        <i className="fas fa-cogs mr-4"></i>
                        CPA
                      </div>

                      <div>
                        {this.state.cpaCollapse ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
                <Collapse isOpen={this.state.cpaCollapse}>
                  <Nav navbar className="pl-3 activeTools">
                    {this.createLinks(routes, 'cpa')}
                  </Nav>
                </Collapse>
              </div>
            )}
            {/* Admin Tools Menu Items */}
            {routes.find((r) => r.group === 'adminTools1') && (
              <div>
                {/* Divider */}
                <hr className=" my-1 w-100" />
                <Nav navbar>
                  {" "}
                  <NavItem
                    className={classnames({
                      activeTools: this.state.settingCollapse_1,
                    })}
                  >
                    <NavLink
                    id="admin-tools"
                      className="pointer d-flex justify-content-between"
                      onClick={() => {
                        this.toggleSettingSub("1")
                      }}
                    >
                      <div className="text-left">
                        <i className="fas fa-cogs mr-4"></i>
                        SETTINGS 1
                      </div>

                      <div>
                        {this.state.settingCollapse_1 ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
                <Collapse isOpen={this.state.settingCollapse_1}>
                  <Nav navbar className="pl-3 activeTools">
                    {this.createLinks(routes, 'adminTools1')}
                  </Nav>
                </Collapse>
              </div>
            )}
            {routes.find((r) => r.group === 'adminTools2') && (
              <div>
                {/* Divider */}
                <hr className=" my-1 w-100" />
                <Nav navbar>
                  {" "}
                  <NavItem
                    className={classnames({
                      activeTools: this.state.settingCollapse_2,
                    })}
                  >
                    <NavLink
                    id="admin-tools"
                      className="pointer d-flex justify-content-between"
                      onClick={() => {
                        this.toggleSettingSub("2")
                      }}
                    >
                      <div className="text-left">
                        <i className="fas fa-cogs mr-4"></i>
                        SETTINGS 2
                      </div>

                      <div>
                        {this.state.settingCollapse_2 ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
                <Collapse isOpen={this.state.settingCollapse_2}>
                  <Nav navbar className="pl-3 activeTools">
                    {this.createLinks(routes, 'adminTools2')}
                  </Nav>
                </Collapse>
              </div>
            )}
            {routes.find((r) => r.group === 'adminTools3') && (
              <div>
                {/* Divider */}
                <hr className=" my-1 w-100" />
                <Nav navbar>
                  {" "}
                  <NavItem
                    className={classnames({
                      activeTools: this.state.settingCollapse_3,
                    })}
                  >
                    <NavLink
                    id="admin-tools"
                      className="pointer d-flex justify-content-between"
                      onClick={() => {
                        this.toggleSettingSub("3")
                      }}
                    >
                      <div className="text-left">
                        <i className="fas fa-cogs mr-4"></i>
                        SETTINGS 3
                      </div>

                      <div>
                        {this.state.settingCollapse_3 ? (
                          <i className="fas fa-chevron-up"></i>
                        ) : (
                          <i className="fas fa-chevron-down"></i>
                        )}
                      </div>
                    </NavLink>
                  </NavItem>
                </Nav>
                <Collapse isOpen={this.state.settingCollapse_3}>
                  <Nav navbar className="pl-3 activeTools">
                    {this.createLinks(routes, 'adminTools3')}
                  </Nav>
                </Collapse>
              </div>
            )}
          </Navbar>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapStateToProps = (state) => {
  return {
    userType: state.loginUserReducer.userType
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
