import React, { useEffect, useState } from "react";
import { render } from "react-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Unauthorized from "views/Pages/Unauthorized";
import Header from "components/Headers/Header";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  Row,
} from "reactstrap";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { DropdownList } from "react-widgets";
import { toast } from "react-toastify";
import Label from "reactstrap/lib/Label";
const options = {
  chart: {
    type: "spline",
  },
  title: {
    text: null,
  },
  yAxis: {
    type: "linear",
    title: {
      text: "Amount",
    },
    opposite: false,
  },
  xAxis: {
    type: "linear",
    title: {
      text: "Month",
    },
    labels: {
      format: "{value}",
    },
    opposite: false,
  },
  tooltip: {
    borderWidth: 1,
    borderCornerradius: 3,
    formatter: function () {
      let tooltip =
        '<span style="color:black">' +
        this.x +
        " - " +
        this.series.name +
        '</span><br><span style="color:black>$' +
        this.y +
        "</span>";
      return tooltip;
    },
  },
  plotOptions: {
    series: {
      pointStart: 1,
      pointInterval: 1,
    },
  },
  series: [
    {
      data: [],
    },
  ],
};

function HistoricalChartView(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const [ticketType, setTicketType] = useState("Ticket");
  const [amountType, setAmountType] = useState("Ticket Amount");
  const [aggrType, setAggrType] = useState("Sum");
  const [showDataBy, setShowDataBy] = useState("Month");
  const [groupBy, setGroupBy] = useState("Year");

  useEffect(() => {
    if(props.location.state && props.location.state.type){
      setTicketType(props.location.state.type);
      getChartData(props.location.state.type, amountType, aggrType, showDataBy, groupBy);
    }else{
      getChartData(ticketType, amountType, aggrType, showDataBy, groupBy);
    }
  }, []);

  const getChartData = (type, amount, aggregate, showBy, group_by) => {
    GetApiActionWithAuthorization("common/daily-chart-data", {
      type: type.toLowerCase(),
      amount: amount === "Ticket Amount" ? "ticketAmount" : "profit",
      aggregate: aggregate === "Average" ? "AVG" : aggregate.toLowerCase(),
      showBy:
        showBy === "Day of month"
          ? "day"
          : showBy.split(" ").join("").toLowerCase(),
      group_by:
        group_by === "Day of month"
          ? "day"
          : group_by.split(" ").join("").toLowerCase(),
    })
      .then((res) => {
        let option1 = { ...options };
        option1.series = res.data;
        setChartOptions(option1);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader className="py-3">
            <div className="text-center">
              <h3>Daily</h3>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="p-5 chart-option-row">
              <Col className="p-1"md ="2" sm="2">
              <Label  className="form-control-label mb-0" for="first_name">
                    Ticket Type:
                  </Label>
                <DropdownList
                  data={["Ticket", "Coin", "Receipt", "JewelryReceipt"]}
                  containerClassName="option-drop"
                  value={ticketType}
                  onChange={(e) => {
                    setTicketType(e);
                    getChartData(e, amountType, aggrType, showDataBy, groupBy);
                  }}
                />
              </Col>
              <Col className="p-1"md ="3" sm="3">
              <Label  className="form-control-label mb-0" for="first_name">
                    Amount Type:
                  </Label>
                <DropdownList
                  data={["Ticket Amount", "Profit"]}
                  value={amountType}
                  containerClassName="option-drop"
                  onChange={(e) => {
                    setAmountType(e);
                    getChartData(ticketType, e, aggrType, showDataBy, groupBy);
                  }}
                />
              </Col>
              <Col className="p-1"md ="2" sm="2">
              <Label  className="form-control-label mb-0" for="first_name">
                    Logic:
                  </Label>
                <DropdownList
                  data={["Sum", "Average", "Count", "Min", "Max"]}
                  value={aggrType}
                  containerClassName="option-drop"
                  onChange={(e) => {
                    setAggrType(e);
                    getChartData(
                      ticketType,
                      amountType,
                      e,
                      showDataBy,
                      groupBy
                    );
                  }}
                />
              </Col>
              <Col className="p-1"md ="2" sm="2">
              <Label  className="form-control-label mb-0" for="first_name">
                   By:
                  </Label>
                <DropdownList
                  data={[
                    "Year",
                    "Month",
                    "Week",
                    "Day of month",
                    "Day of Week",
                    "Hour",
                  ]}
                  value={showDataBy}
                  containerClassName="option-drop"
                  onChange={(e) => {
                    setShowDataBy(e);
                    let newOption = chartOptions;
                    newOption.xAxis.title.text = e;
                    setChartOptions(newOption);
                    getChartData(ticketType, amountType, aggrType, e, groupBy);
                  }}
                />
              </Col>
              <Col className="p-1" md ="3" sm="3">
              <Label  className="form-control-label mb-0" for="first_name">
                    Group By:
                  </Label>
                <DropdownList
                  data={[
                    "Year",
                    "Month",
                    "Week",
                    "Day of month",
                    "Day of Week",
                    "Hour",
                  ]}
                  value={groupBy}
                  containerClassName="option-drop"
                  onChange={(e) => {
                    setGroupBy(e);
                    getChartData(
                      ticketType,
                      amountType,
                      aggrType,
                      showDataBy,
                      e
                    );
                  }}
                />
              </Col>
            </Row>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default HistoricalChartView;
