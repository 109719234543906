import {
  FETCH_METALS_LISTING_FAILURE,
  FETCH_METALS_LISTING_SUCCESS,
  FETCH_METAL_ITEMS_LISTING_SUCCESS,
  FETCH_METAL_ITEMS_LISTING_FAILURE
} from "./metalsTypes";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { GetApiActionWithAuthorization } from "../../constantFunctions/apiActions";

/*_________________________________________________________________Metals Listing __________________________________________________________________ */
//Api Call Function for metal items listing
export const fetchMetalItemsListing = (metal) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-all-metal-items-data?metal=" + metal.name)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchMetalItemsListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchMetalItemsListingFailure(err));
      });
  };
};

//MetalItemslisting Success save Payload data to store
export const fetchMetalItemsListingSuccess = (data) => {
  return {
    type: FETCH_METAL_ITEMS_LISTING_SUCCESS,
    payload: data,
  };
};

//MetalItemslisting Failure save Payload
export const fetchMetalItemsListingFailure = (error) => {
  return {
    type: FETCH_METAL_ITEMS_LISTING_FAILURE,
    payload: error,
  };
};

//Api Call Function for metals listing
export const fetchMetalsListing = (data) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("metals/get-all-metals-data", data)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchMetalsListingSuccess(res));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchMetalsListingFailure(err));
      });
  };
};

//Metalslisting Success save Payload data to store
export const fetchMetalsListingSuccess = (data) => {
  return {
    type: FETCH_METALS_LISTING_SUCCESS,
    payload: data,
  };
};

//Metalslisting Failure save Payload
export const fetchMetalsListingFailure = (error) => {
  return {
    type: FETCH_METALS_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
