import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
} from "reactstrap";
import {
  emailRegex,
  getallErrors,
} from "../../../constantFunctions/formValidations";
import InputMask from "react-input-mask";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";

function UpdatePettyCashTransactionModal(props) {
  const [users, setUsers] = useState([]);
  const [delId, setDelId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [transactionData, setTransactionData] = useState({
    src: "pettycash",
    dest: "",
    denom100: 0,
    //denom50: 0,
    denom20: 0,
    denom10: 0,
    denom5: 0,
    denom1: 0,
    comment: "",
    eMail: "",
    SSN: "",
  });
  const [formErrors, setFormErrors] = useState({
    eMail: "",
    comment: "",
    SSN: "",
    dest: "",
  });

  useEffect(() => {
    GetApiActionWithAuthorization(
      "common/get-particular-transaction-data-with-id",
      {
        id: props.editData.id,
      }
    ).then((res) => {
      setTransactionData({
        ...res.data,
        src: "pettycash",
        dest: { name: res.data.dest.split("-")[1] },
        SSN:res.data.SSN?res.data.SSN:"",
        eMail:res.data.email?res.data.email:"",
      });
    });
  }, []);

  const fetchAllPCUsers = (e) => {
    if (e) {
      GetApiActionWithAuthorization("common/get-all-petty-cash-user").then(
        (res) => {
          setUsers(res.data);
        }
      );
    }
  };

  const submitHandler = (e) => {
    let total =
      transactionData.denom100 * 100 +
      //transactionData.denom50 * 50 +
      transactionData.denom20 * 20 +
      transactionData.denom10 * 10 +
      transactionData.denom5 * 5 +
      transactionData.denom1 * 1;
    var valid =
      transactionData.dest!=="" && transactionData.comment.length > 0;
    if (total >= 600) {
      valid =(transactionData.SSN.length === 11 &&
        transactionData.eMail.length > 0 &&
        emailRegex.test(transactionData.eMail));
    }
    if (valid) {
      let body = {
        ...transactionData,
        dest: "PettyCash-" + transactionData.dest.name,
        email: transactionData.eMail,
        SSN:total >= 600 ? transactionData.SSN:""
      };
      PostApiWithAuthorizationAction(
        "common/update-transaction-details",
        body
      ).then((res) => {
        props.fetchTransactionListing({
          returnCount: true
        });
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        props.toggle();
      });
    }
    else{
      let errors = getallErrors({
        formValue: {
          ...transactionData,
          totalTransAmount: total,
        },
        formErrors,
      });
      setFormErrors({ ...errors });
    }
  };

  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  const confirmed = (e) => {
    GetApiActionWithAuthorization("transactions/delete/" + delId)
      .then((res) => {
        props.fetchTransactionListing({
          returnCount: true
        });
        setConfirm(false);
        props.toggle();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    var cursorPosition = selection ? selection.start : null;

    // keep minus if entered by user
    if (
      value.endsWith("-") &&
      userInput !== "-" &&
      !transactionData.SSN.endsWith("-")
    ) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = { start: cursorPosition, end: cursorPosition };
      }
      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  };

  return (
    <>
    <Modal size="lg" isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        Update Petty Cash Transaction
      </ModalHeader>
      <ModalBody>
        <Table className="transaction-table" striped bordered>
          <tbody>
            <tr>
              <td style={{ width: "100px" }}>Comment:</td>
              <td colSpan="2" className="comment">
                <Input
                  type="text"
                  placeholder="Comment"
                  value={transactionData.comment}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      comment: e.target.value,
                    });
                  }}
                />
                {formErrors.comment.length > 0 && (
                  <span className="text-danger">
                    <small>{formErrors.comment}</small>
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>For Who:</td>
              <td colSpan="2">
                <div className="d-flex align-items-end">
                  <DropdownList
                    id="for-who"
                    data={users}
                    textField="name"
                    name="name"
                    containerClassName="from-drop"
                    onToggle={fetchAllPCUsers}
                    value={transactionData.dest}
                    onChange={(e) => {
                      setTransactionData({
                        ...transactionData,
                        dest: e,
                      });
                    }}
                  />
                </div>
                {formErrors.dest.length > 0 && (
                  <span className="text-danger ml-2">
                    <small>{formErrors.dest}</small>
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$100</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom100}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom100: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${100 * transactionData.denom100}</td>
            </tr>
            {/*<tr>
              <td style={{ width: "100px" }}>$50</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom50}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom50: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${50 * transactionData.denom50} </td>
                </tr>*/}
            <tr>
              <td style={{ width: "100px" }}>$20</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom20}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom20: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${20 * transactionData.denom20} </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$10</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom10}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom10: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${10 * transactionData.denom10} </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$5</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom5}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom5: e.target.value,
                    });
                  }}
                />
              </td>
              <td> ${5 * transactionData.denom5} </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$1</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom1}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom1: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${1 * transactionData.denom1}</td>
            </tr>
            <tr>
              <td colSpan="2">Total:</td>
              <td>
                $
                {transactionData.denom100 * 100 +
                  //transactionData.denom50 * 50 +
                  transactionData.denom20 * 20 +
                  transactionData.denom10 * 10 +
                  transactionData.denom5 * 5 +
                  transactionData.denom1 * 1}{" "}
              </td>
            </tr>
            {transactionData.denom100 * 100 +
              //transactionData.denom50 * 50 +
              transactionData.denom20 * 20 +
              transactionData.denom10 * 10 +
              transactionData.denom5 * 5 +
              transactionData.denom1 * 1 >=
              600 && (
              <>
                <tr>
                  <td>SSN:</td>
                  <td colSpan="2">
                    <InputMask
                      mask="999-99-9999"
                      maskChar={null}
                      value={transactionData.SSN}
                      onChange={(e) => {
                        setTransactionData({
                          ...transactionData,
                          SSN: e.target.value,
                        });
                      }}
                      beforeMaskedValueChange={beforeMaskedValueChange}
                    />
                    {formErrors.SSN.length > 0 && (
                      <span className="text-danger">
                        <small>{formErrors.SSN}</small>
                      </span>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td colSpan="2">
                    <Input
                      type="email"
                      placeholder="Email"
                      value={transactionData.eMail}
                      onChange={(e) => {
                        setTransactionData({
                          ...transactionData,
                          eMail: e.target.value,
                        });
                      }}
                    />
                    {formErrors.eMail.length > 0 && (
                      <span className="text-danger">
                        <small>{formErrors.eMail}</small>
                      </span>
                    )}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={(e) => toggleConfirm(transactionData.id)}
          style={{position: 'absolute', left: '20px'}}
          size="sm">
          Delete
        </Button>{" "}
        <Button color="primary" onClick={(e) => submitHandler()}>
          Save
        </Button>{" "}
        <Button color="secondary" onClick={(e) => props.toggle()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
    {confirm && (
      <ConfirmationDeleteModal
        show={confirm}
        toggle={toggleConfirm}
        confirmed={confirmed}
      />
    )}
    </>
  );
}

export default UpdatePettyCashTransactionModal;
