import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Form,
  Col,
  Input,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import {
  fetchVoidTicketListing,
  fetchVoidTicketListingSuccess,
} from "../../redux/VoidTickets/voidTicketsAction";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";

function VoidTickets(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [search, setSearch] = useState("");
  const [sortId, setSortId] = useState("dateAdded");
  const [sortOrder, setSortOrder] = useState("-1");
  const [confirm, setConfirm] = useState(false);
  const [confirmRestore, setConfirmRestore] = useState(false);
  const [recoverId, setRecoverId] = useState("");

  useEffect(() => {
    props.fetchVoidTicketListing(props.voids.page, search, sortId, sortOrder);
  }, []);

  if (props.voids.error && !unauthorized) {
    if (props.voids.error.status === 403) {
      setUnauthorized(true);
    } else if (props.voids.error.status === 440) {
      props.resetState();
      toast.error(props.voids.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const restored = (e) => {
    GetApiActionWithAuthorization("common/restore-void-ticket",{id:recoverId}).then(res =>{
      props.fetchVoidTicketListing(props.voids.page, search, sortId, sortOrder);
      //toast.success(res.message,{position:toast.POSITION.TOP_CENTER});
      setRecoverId("");
      setConfirmRestore(false);
    })
    .catch((err) => {
      setConfirm(false);
      if (err.status === 403) {
        setConfirmRestore(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const confirmed = (e) => {
    GetApiActionWithAuthorization("common/recover-void-ticket",{id:recoverId}).then(res =>{
      props.fetchVoidTicketListing(props.voids.page, search, sortId, sortOrder);
      //toast.success(res.message,{position:toast.POSITION.TOP_CENTER});
      setRecoverId("");
      setConfirm(false);
    })
    .catch((err) => {
      setConfirm(false);
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const toggleRestoreConfirm = (id = "") => {
    setRecoverId(id);
    setConfirmRestore(!confirmRestore);
  };

  const toggleConfirm = (id = "") => {
    setRecoverId(id);
    setConfirm(!confirm);
  };

  const renderTicketsTableData = (e) => {
    return props.voids.allVoids.map((vd, i) => {
      return (
        <tr key={i}>
          <td>{vd.ticketType}{vd.ticketId}</td>
          <td>${parseInt(vd.ticketAmount)}</td>
          <td>{vd.customer_name}</td>
          <td>
            {" "}
            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
              {vd.dateAdded.toString().length === 10
                ? vd.dateAdded * 1000
                : vd.dateAdded}
            </Moment>
          </td>
          <td>
            {vd.recovered_as.length >0 ?(
              vd.recovered_as.map(re =>{
                return re+", ";
              })
            ):"Null"}
          </td>
          <td>
            <a href="#" id={"restore-ticket"+i} onClick={(e) => toggleConfirm(vd.id)}>
              Copy to new Ticket
            </a>&nbsp;|&nbsp;
            <a href="#" id={"full-restore-ticket"+i} onClick={(e) => toggleRestoreConfirm(vd.id)} style={{ color: 'red'}}>
              Restore
            </a>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Void Tickets</h1>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="search-container mb-4">
              <Col sm={{ size: "7", offset: "5" }}>
                <Form
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.fetchVoidTicketListing(
                      0,
                      search.trim(),
                      sortId,
                      sortOrder
                    );
                  }}
                >
                  <Input
                    type="text"
                    className="mr-2"
                    placeholder="Search by Ticket id, Name.etc"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button type="submit" className="mx-2" color="primary">
                    Search
                  </Button>
                </Form>
              </Col>
            </Row>
            <a id="category" href="/portal/void-logger/reasons">
              <Button color="primary" className="mb-2">Void Reasons</Button>
            </a>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Ticket Id</th>
                  <th scope="col">Ticket Amount</th>
                  <th scope="col">Name</th>
                  <th scope="col">Voided At</th>
                  <th scope="col">Recoverd As</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
            {props.voids.allVoids.length > 0 && (
              <ReactPaginate
                previousLabel={<i className="fas fa-chevron-left"></i>}
                nextLabel={<i className="fas fa-chevron-right"></i>}
                breakLabel={".."}
                breakClassName={"break-me"}
                initialPage={props.voids.page}
                pageCount={props.voids.count / 50}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={(e) => {
                  props.fetchVoidTicketListing(
                    e.selected,
                    search,
                    sortId,
                    sortOrder
                  );
                }}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </CardBody>
        </Card>
      </Container>
      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          message={<h4>Are you sure you want to create new Ticket from this ?</h4>}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
      {confirmRestore && (
        <ConfirmationDeleteModal
          show={confirmRestore}
          message={<h4>Are you sure you want to restore Ticket?</h4>}
          toggle={toggleRestoreConfirm}
          confirmed={restored}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    voids: state.voidTicketsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchVoidTicketListing: (page, search, sortId, sortOrder) =>
      dispatch(fetchVoidTicketListing(page, search, sortId, sortOrder)),
    addUpdatedData: (data) => dispatch(fetchVoidTicketListingSuccess(data)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoidTickets);
