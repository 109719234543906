import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
  CustomInput,
} from "reactstrap";
import { itemTypeOptions } from "constants/ticketConstants";
import { validateItemForm } from "constantFunctions/formValidations";

function AddItemsModal(props) {
  const [name, setName] = useState("");
  const [taxable, setTaxable] = useState(false);
  const [dropLoad, setDropLoad] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedItemType, setSelectedItemType] = useState(false);

  const fetchAllCategories = (e) => {
    if (e && categories.length === 0) {
      setDropLoad(true);
      GetApiActionWithAuthorization(
        "coin-tickets/get-all-coin-items-categories"
      )
        .then((res) => {
          setDropLoad(false);
          setCategories(res.data);
        })
        .catch((err) => {
          setDropLoad(false);
          if (err.status === 440) {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          } else {
            toast.error("Something went wrong! /n Please clear and retry", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    }
  };

  const addItemsHandler = (e) => {
    let body = {
      name: name.trim(),
      taxable: taxable,
      category: selectedCategory.id,
      itemType: props.activeTabLabel
    };
    let validation = validateItemForm(body);
    if (validation.success) {
      PostApiWithAuthorizationAction("coin-tickets/create-new-coin-item", body)
      .then((res) => {
        if (res.err) {
          toast.error(res.err, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          //toast.success(res.message);
          props.addUpdatedData(res.data, props.activeTabLabel);
          props.toggle();
        }
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } else {
      toast.error(validation.err, {
        position: toast.POSITION.TOP_CENTER,
        closeOnClick: true,
        autoClose: 8000,
      });
    }
  };

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Add new item</ModalHeader>
      <ModalBody>
        <FormGroup className="mb-0">
          <Label className="form-control-label" for="b_name">
            Name
          </Label>
          <Input
          id="name"
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
        <FormGroup className="mb-0">
          <Label className="form-control-label" for="b_name">
            Category
          </Label>
          <DropdownList
          id="category"
            data={categories}
            busy={dropLoad}
            placeholder="Category Name"
            textField="categoryName"
            onToggle={fetchAllCategories}
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e)}
          />
          <span className="text-danger">
            <small>*required</small>
          </span>
        </FormGroup>
        <FormGroup className="mb-0">
          <Label className="form-control-label" for="b_name">
            {" "}
            taxable
          </Label>
          <CustomInput
            type="switch"
            id="taxableCustomSwitch"
            name="taxable"
            checked={taxable}
            onChange={(e) => {
              setTaxable(e.target.checked);
            }}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
        id="add-btn"
          color="primary"
          onClick={addItemsHandler}
          disabled={name.length === 0}
        >
          Add
        </Button>{" "}
        <Button color="secondary" onClick={props.toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddItemsModal;
