import {
  FETCH_RECEIPT_LISTING_FAILURE,
  FETCH_RECEIPT_LISTING_SUCCESS,
  SAVE_RECEIPT_FORM_DETAILS,
  SAVE_ITEMS_DETAILS_IN_FORM,
  RESET_SAVE_RECEIPT_DETAILS,
  UPDATE_RECEIPT_DATA_AFTER_UPDATE,
  RESET
} from "./ReceiptTypes";

import { initialState } from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECEIPT_LISTING_SUCCESS:
      return {
        ...state,
        receipts: {
          allReceipts: action.payload.receipts,
          page: action.payload.page,
          count: action.payload.count,
          totalReceiptCount: action.payload.totalReceiptCount,
          totalDailyReceiptCount: action.payload.totalDailyReceiptCount,
          totalReceiptCheckAmount: action.payload.totalReceiptCheckAmount,
          totalReceiptCashAmount: action.payload.totalReceiptCashAmount,
          error: null,
        },
      };
    case FETCH_RECEIPT_LISTING_FAILURE:
      return {
        ...state,
        receipts: {
          page: 0,
          count: 0,
          totalReceiptCount: 0,
          totalDailyReceiptCount: 0,
          totalReceiptCheckAmount: 0,
          totalReceiptCashAmount: 0,
          allReceipts: [],
          error: action.payload,
        },
      };
    case SAVE_RECEIPT_FORM_DETAILS:
      return {
        ...state,
        addReceiptFormdetails: {
          ...state.addReceiptFormdetails,
          [action.payload.name]: action.payload.value,
        },
      };

    case SAVE_ITEMS_DETAILS_IN_FORM:
      return {
        ...state,
        receiptItemdetails: {
          items: action.payload,
          count: action.payload.length,
        },
      };

      
      case UPDATE_RECEIPT_DATA_AFTER_UPDATE:
      return {
        ...state,
        receipts: {
          ...state.receipts,
          allReceipts: action.payload,
          error: null,
        },
      };
    case RESET_SAVE_RECEIPT_DETAILS:
      return {
        ...state,
        addReceiptFormdetails: initialState.addReceiptFormdetails,
        receiptItemdetails: {
          items: [],
          count: 0,
        },
      };

    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
