import {
  REQUEST_LOADER,
  RESET
} from "./commonTypes";



export const setLoaderRequestTrue = () => {
  return {
    type: REQUEST_LOADER,
    payload:{set:true}
  };
};
export const setLoaderRequestFalse = () => {
  return {
    type: REQUEST_LOADER,
    payload:{set:false}
  };
};
export const resetState = (error) => {
  return {
    type: RESET
  };
};
