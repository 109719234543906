import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

function ConfirmationDeleteModal(props) {
  return (
    <Modal isOpen={props.show} toggle={props.toggle} className={props.class ? props.class : ""}>
      <ModalHeader toggle={props.toggle}>Confirmation!</ModalHeader>
      <ModalBody>
      <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  {props.message?(
                    props.message
                  ):(

                  <h4>Are you sure you want to Delete?</h4>
                  )}
                </div>
              </div>
            </div>
      </ModalBody>
      <ModalFooter>
        <Button id="confirm-btn" color="primary" onClick={e => props.confirmed()}>
          Confirm
        </Button>{" "}
        <Button color="secondary" onClick={e =>props.toggle()}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmationDeleteModal;
