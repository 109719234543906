import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
} from "reactstrap";

function EditTransactionModal(props) {
  const [transactionData, setTransactionData] = useState({});
  useEffect(() => {
    GetApiActionWithAuthorization("common/get-particular-transaction-details", {
      id: props.id,
    }).then((res) => {
      console.log(res);
      setTransactionData(res.data);
    });
  }, []);

  const submitHandler = (e) => {
    let obj = {
      denom100: transactionData.denom100,
      //denom50: transactionData.denom50,
      denom20: transactionData.denom20,
      denom10: transactionData.denom10,
      denom5: transactionData.denom5,
      denom1: transactionData.denom1,
      id: transactionData.id,
    };
    PostApiWithAuthorizationAction(
      "common/update-transaction-details",
      obj
    ).then((res) => {
      //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
      props.toggle();
    });
  };

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        Edit Transaction for :{props.id}
      </ModalHeader>
      <ModalBody>
        <Table className="transaction-table" striped bordered>
          <tbody>
            <tr>
              <td>$100</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom100}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom100: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${100 * transactionData.denom100}</td>
            </tr>
            {/*<tr>
              <td>$50</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom50}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom50: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${50 * transactionData.denom50} </td>
                </tr>*/}
            <tr>
              <td>$20</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom20}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom20: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${20 * transactionData.denom20} </td>
            </tr>
            <tr>
              <td>$10</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom10}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom10: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${10 * transactionData.denom10} </td>
            </tr>
            <tr>
              <td>$5</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom5}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom5: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${5 * transactionData.denom5} </td>
            </tr>
            <tr>
              <td>$1</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom1}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom1: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${1 * transactionData.denom1}</td>
            </tr>
            <tr>
              <td colSpan="2">Total:</td>
              <td>
                $
                {transactionData.denom100 * 100 +
                  //transactionData.denom50 * 50 +
                  transactionData.denom20 * 20 +
                  transactionData.denom10 * 10 +
                  transactionData.denom5 * 5 +
                  transactionData.denom1 * 1}{" "}
              </td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={(e) => submitHandler()}>
          Save
        </Button>{" "}
        <Button color="secondary" onClick={(e) => props.toggle()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditTransactionModal;
