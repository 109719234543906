import React, { useEffect, useState } from "react";
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from "../../../constantFunctions/apiActions";
import { validateReceiptPaymentForm } from "../../../constantFunctions/formValidations";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  Label,
} from "reactstrap";
import { toast } from "react-toastify";
import classnames from "classnames";

function ViewAndEditReceiptPaymentsModal(props) {
  const [items, setItems] = useState([]);
  //
  useEffect(() => {
    fetchPaymentItems();
  }, []);

  const fetchPaymentItems = () => {
    let paymentId = props.partialPayments[props.paymentIndex].id;
    if (paymentId > 0) {
        GetApiActionWithAuthorization(`jewelry-receipts/get-particular-receipt-payment-data/${paymentId}`)
        .then((res) => {
            setItems(res.items);
        })
        .catch((err) => {
            if (err.status === 440) {
                toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
                props.history.push("/auth/login");
            } else {
                toast.error("Something went wrong! /n Please clear and retry", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }
   };

   const formSubmitHandler = (e) => {
    //validation
    let validation = validateReceiptPaymentForm(items);
    if (validation.success) {
        let payments = props.partialPayments;
        let _payment = payments[props.paymentIndex];
        PostApiWithAuthorizationAction("jewelry-receipts/save-receipt-payment-data", {
            items,
            payment: _payment
        })
        .then((res) => {
            _payment.totalAmount = items.reduce((prev, curr) => {
                if (curr.price === "") {
                  return prev + 0.0;
                } else {
                  return prev + parseFloat(curr.price);
                }
            }, 0);
            _payment.tax = ((parseFloat(_payment.totalAmount) * 8.375) / 100);
            if (_payment.id == 0) {
                _payment.id = res.data.payment.id;
            }
            payments[props.paymentIndex] = _payment;
            props.setPartialPayments([... payments]);
            props.loadNewItems();
            props.toggle();
        })
        .catch((err) => {
            if (err.status === 440) {
                toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
                props.history.push("/auth/login");
            } else {
                toast.error("Something went wrong! /n Please clear and retry", {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    } else {
        toast.error(validation.err, {
          position: toast.POSITION.TOP_CENTER,
          closeOnClick: true,
        });
    }
  };

  const changeItemValues = (name, value, index) => {
    let temp = items;
    let itemObj = temp[index];
    itemObj[name] = value;
    temp[index] = itemObj;
    if (name === "qty") {
      itemObj["unitPrice"] = (
        parseFloat(itemObj.price) / parseInt(value)
      ).toFixed(2);
    }

    if (name === "price") {
      if (itemObj.qty) {
        itemObj["unitPrice"] = (
          parseFloat(value) / parseInt(itemObj.qty)
        ).toFixed(2);
      } else {
        itemObj["unitPrice"] = parseFloat(value).toFixed(2);
      }

      //tax amount
      itemObj["tax"] = ((parseFloat(value) * 8.375) / 100).toFixed(2);
    }
    setItems([...temp]);
  };

  const addRowInItem = (e) => {
    let obj = {
      paymentId: props.id,
      receiptId: props.receiptId,
      itemDescription: "",
      unitPrice: "",
      price: "",
      qty: "",
      tax: "",
    };
    let olderItems = items;
    olderItems.push(obj);
    setItems([...olderItems]);
  };

  const removeRowInItem = (index) => {
    let olderItems = items;
    olderItems.splice(index, 1);
    setItems([...olderItems]);
  };

  const renderItems = (e) => {
    return items.map((item, i) => {
      return (
        <tr key={i} id={"receipt-items-row" + i}>
          <td className="input-td">
            <Input
              id={"qty" + i}
              type="number"
              value={item.qty}
              className={classnames({ "input-null": item.qty === "" })}
              onChange={(e) => {
                changeItemValues("qty", e.target.value, i);
              }}
            />
          </td>
          <td className="input-td">
            <Input
              id={"description-" + i}
              type="text"
              value={item.itemDescription}
              className={classnames({
                "input-null": item.itemDescription === "",
              })}
              onChange={(e) => {
                changeItemValues("itemDescription", e.target.value, i);
              }}
            />
          </td>
          <td className="input-td">
            <Input type="number" value={item.unitPrice} disabled />
          </td>
          <td className="input-td">
            <Input
              id={"price" + i}
              type="number"
              className={classnames({ "input-null": item.price === "" })}
              value={item.price}
              onChange={(e) => {
                changeItemValues("price", e.target.value, i);
              }}
            />
          </td>
          <td className="action-col">
            <Button
              id="add-more"
              className="icon-cross"
              color="primary"
              title="Add more"
              onClick={() => {
                if (item.price !== "" && item.itemDescription !== "") {
                  addRowInItem();
                }
              }}
            >
              <i className="fas fa-check-square"></i>
            </Button>
            <Button
              id="remove"
              className="icon-cross"
              color="danger"
              title="remove"
              onClick={() => {
                removeRowInItem(i);
              }}
            >
              <i className="fas fa-times"></i>
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Modal size="lg" isOpen={props.show} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>
          View And Edit Payment Items
        </ModalHeader>
        <ModalBody className="p-2">
        <Table className="add-receipt-item-table" bordered striped>
            <thead>
                <tr>
                  <th className="qty-col">Qty</th>
                  <th className="desc-col">DESC</th>
                  <th className="unit-col">UNIT($)</th>
                  <th className="amount-col">Amount($)</th>
                </tr>
            </thead>
            <tbody>
                {items.length === 0 && (
                  <tr>
                    <td className="py-3 text-center" colSpan="4">
                      <Button
                        className="py-1"
                        color="primary"
                        onClick={addRowInItem()}
                      >
                        Add item row+
                      </Button>
                    </td>
                  </tr>
                )}
                { renderItems() }
                <tr>
                  <td colSpan="3">Tax:</td>
                  <td>
                    $
                    {items
                      .reduce((prev, curr) => {
                        if (curr.tax === "") {
                          return prev + 0.0;
                        } else {
                          return prev + parseFloat(curr.tax);
                        }
                      }, 0)
                      .toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3">Total:</td>
                  <td>
                    $
                    {items
                      .reduce((prev, curr) => {
                        if (curr.price === "") {
                          return prev + 0.0;
                        } else {
                          return prev + parseFloat(curr.price);
                        }
                      }, 0)
                      .toFixed(2)}
                  </td>
                </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button id="save-payment-items" color="warning" onClick={formSubmitHandler}>Add Items</Button>
          <Button id="close" color="secondary" onClick={(e) => props.toggle()}>Close</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ViewAndEditReceiptPaymentsModal;