//TRANSACTION Listing
export const FETCH_TRANSACTION_LISTING_FAILURE = 'FETCH_TRANSACTION_LISTING_FAILURE';
export const FETCH_TRANSACTION_LISTING_SUCCESS = 'FETCH_TRANSACTION_LISTING_SUCCESS';
export const FETCH_TRANSACTION_DAILY_COUNT_SUCCESS = 'FETCH_TRANSACTION_DAILY_COUNT_SUCCESS';
export const FETCH_TRANSACTION_DAILY_COUNT_FAILURE = 'FETCH_TRANSACTION_DAILY_COUNT_FAILURE';
export const SAVE_DC_DETAILS_IN_FORM = 'SAVE_DC_DETAILS_IN_FORM';
export const RESET_DC_FORM = 'RESET_DC_FORM';
export const RESET_RECONCILE_FORM = 'RESET_RECONCILE_FORM';

export const RESET = 'RESET';
