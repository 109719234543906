import {
  FETCH_BLOCK_LISTING_SUCCESS,
  FETCH_BLOCK_LISTING_FAILURE,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE
} from "./BlockListTypes";
import {
  GetApiActionWithAuthorization,
} from "../../constantFunctions/apiActions";
import {
  setLoaderRequestTrue,
  setLoaderRequestFalse,
} from "../common/commonAction";
import { toast } from "react-toastify";

/*_________________________________________________________________Get Block Customer listing __________________________________________________________________ */
export const fetchBlockListing = (params) => {
  return (dispatch) => {
    dispatch(setLoaderRequestTrue());
    GetApiActionWithAuthorization("common/get-all-block-listing", params)
      .then((res) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchBlockListingSuccess({data:res.data,page: params.page,count:res.count}));
      })
      .catch((err) => {
        dispatch(setLoaderRequestFalse());
        dispatch(fetchBlockListingFailure(err));
      });
  };
};

export const fetchBlockListingSuccess = (data) => {
  return {
    type: FETCH_BLOCK_LISTING_SUCCESS,
    payload: data,
  };
};

export const fetchBlockListingFailure = (error) => {
  return {
    type: FETCH_BLOCK_LISTING_FAILURE,
    payload: error,
  };
};
/*__________________________________________________________________________________________________________________________________________ */
