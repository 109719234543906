import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import loaderSpinner from "../../assets/img/loader.gif";

function ReceiptInvoice(props) {
  const [receiptInfo, setReceiptInfo] = useState({});
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(false);

  useEffect(() => {
    let id = props.match.params.id;
    setId(id)
    let url;
    if(id.startsWith("JR")){
      url = `jewelry-receipts/get-particular-receipt-data/${id.substring(2)}`;
    }
    else{
      url = `receipts/get-particular-receipt-data/${id.substring(2)}`;
    }
    setLoading(true);
    GetApiActionWithAuthorization(url).then((res) => {
      setLoading(false);
      setReceiptInfo(res.details);
      setItems(res.items);
    });
  }, []);
  return (
    <div>
      <table
        border="0"
        cellPadding="0"
        cellSpacing="0"
        width="100%"
        style={{ borderCollapse: "collapse" }}
      >
        <tbody>
          <tr>
            <td>
              <table
                border="0"
                cellPadding="0"
                cellSpacing="0"
                width="100%"
                style={{ borderCollapse: "collapse", padding: "5px" }}
              >
                <tbody>
                  <tr>
                    <td>
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Nevada Coin Mart Inc. &#174;
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      4065 S. Jones Blvd.
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Las Vegas, NV 89103
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "10px",
                                        color: "black",
                                      }}
                                    >
                                      702-998-4000
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "10px",
                                        color: "black",
                                      }}
                                    >
                                      Receipt No: {id}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "10px",
                                        color: "black",
                                      }}
                                    >
                                      Date:
                                      <Moment
                                        format="MM/DD/YY HH:mm"
                                        tz="America/Los_Angeles"
                                      >
                                        {receiptInfo.ticketTime &&
                                          (receiptInfo.ticketTime.toString()
                                            .length === 10
                                            ? new Date(
                                                receiptInfo.ticketTime * 1000
                                              )
                                            : new Date(receiptInfo.ticketTime))}
                                      </Moment>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "10px",
                                        color: "black",
                                      }}
                                    >
                                      Clerk:
                                      {receiptInfo.clerk}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Paid By: {receiptInfo.paidBy}
                                      {(receiptInfo.paidBy == 'check') && (
                                        <>
                                          &nbsp;#{receiptInfo.checkNumber}
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Total: $
                                      {receiptInfo.ticketAmount &&
                                        receiptInfo.ticketAmount.toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Tendered: $
                                      {receiptInfo.tendered &&
                                        receiptInfo.tendered.toFixed(2)}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="right"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Change: $
                                      {parseFloat(receiptInfo.tendered) >
                                      parseFloat(receiptInfo.ticketAmount)
                                        ? (
                                            parseFloat(receiptInfo.tendered) -
                                            parseFloat(receiptInfo.ticketAmount)
                                          ).toFixed(2)
                                        : "0.00"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                border="0"
                                cellPadding="0"
                                cellSpacing="0"
                                width="100%"
                                style={{ borderCollapse: "collapse" }}
                              >
                                <tbody>
                                  <tr>
                                    <td
                                      align="left"
                                      style={{
                                        fontSize: "16px",
                                        paddingBottom: "40px",
                                        color: "black",
                                      }}
                                    >
                                      <h2>
                                        Buyer: &nbsp;
                                        <small>
                                          {" "}
                                          {receiptInfo.firstName +
                                            " " +
                                            receiptInfo.lastName}
                                        </small>
                                      </h2>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        border="0"
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%"
                        style={{ borderCollapse: "collapse" }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <table
                                className="table-bordered table-striped"
                                width="100%"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        fontSize: "16px",
                                        padding: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Qty
                                    </th>
                                    <th
                                      style={{
                                        fontSize: "16px",
                                        padding: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Desc
                                    </th>
                                    <th
                                      style={{
                                        fontSize: "16px",
                                        padding: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Unit
                                    </th>
                                    <th
                                      style={{
                                        fontSize: "16px",
                                        padding: "5px",
                                        color: "black",
                                      }}
                                    >
                                      Amount
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {items.map((it, i) => {
                                    return (
                                      <tr key={i}>
                                        <td
                                          align="left"
                                          style={{
                                            fontSize: "16px",
                                            padding: "5px",
                                            color: "black",
                                          }}
                                        >
                                          {it.qty}
                                        </td>
                                        <td
                                          align="left"
                                          style={{
                                            padding: "5px",
                                            fontSize: "16px",
                                            color: "black",
                                            width: "40%",
                                          }}
                                        >
                                          {id.startsWith("JR") ?(
                                          it.itemDescription
                                          ):(
                                          it.item.name

                                          )}
                                        </td>
                                        <td
                                          align="left"
                                          style={{
                                            padding: "5px",
                                            fontSize: "16px",
                                            color: "black",
                                          }}
                                        >
                                          ${parseFloat(it.unitPrice).toFixed(2)}
                                        </td>
                                        <td
                                          align="left"
                                          style={{
                                            padding: "5px",
                                            fontSize: "16px",
                                            color: "black",
                                          }}
                                        >
                                          ${parseFloat(it.price).toFixed(2)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  <tr>
                                    <td
                                      align="left"
                                      colSpan="3"
                                      style={{
                                        padding: "5px",
                                        fontSize: "16px",
                                        color: "black",
                                      }}
                                    >
                                      Tax:
                                    </td>
                                    <td
                                      align="left"
                                      style={{
                                        padding: "5px",
                                        fontSize: "16px",
                                        color: "black",
                                      }}
                                    >
                                      {!receiptInfo.requireShipmentFreeTax && (
                                        "$" + parseFloat(receiptInfo.tax).toFixed(2)
                                      )}
                                      {receiptInfo.requireShipmentFreeTax && (
                                        "$0.00"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td
                                      align="left"
                                      colSpan="3"
                                      style={{
                                        padding: "5px",
                                        fontSize: "16px",
                                        color: "black",
                                      }}
                                    >
                                      Total:
                                    </td>
                                    <td
                                      align="left"
                                      style={{
                                        padding: "5px",
                                        fontSize: "16px",
                                        color: "black",
                                      }}
                                    >
                                      $
                                      {parseFloat(
                                        receiptInfo.ticketAmount
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      {loading && (
        <div className="loaderSpinner">
          <img src={loaderSpinner} alt="" />
        </div>
      )}
    </div>
  );
}

export default ReceiptInvoice;
