export const initialState = {
  items: {
    allItems: [],
    error: null,
  },
  coinItems: {
    allItems: [],
    error: null,
  },
  metroItems: {
    allItems: [],
    error: null,
  },
  categories: {
    allCategories: [],
    error: null,
  },
};
