import React from "react";
import { Container, Card, CardBody } from "reactstrap";

import unauth from "../../assets/img/unauthorised-156169_1280.png";

import Header from "../../components/Headers/Header";

class NotAnyPermissions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Header />
        <Container className="mt--9 main-content" fluid>
          <Card className="card-height">
            <CardBody className="align-items-center d-flex justify-content-center">
              <div className="text-center mt-5">
                <img src={unauth} height="70" width="70" alt=""/>
                <h1>User do not have any permissions to access the Portal!</h1>
                <h3>Please contact with ADMIN for access and permissions!</h3>
              </div>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default NotAnyPermissions;
