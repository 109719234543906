import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import DateTimePicker from 'react-datetime-picker';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  Row,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import Unauthorized from "views/Pages/Unauthorized";
import {
  monthOptions,
  dayOptions,
  coinInvoiceTypes,
  bagNumbers
} from "../../constants/ticketConstants";
import { resetState } from "redux/common/commonAction";
import {
    saveInvoiceFormDetails,
    saveItemsDetails,
    resetAddInvoiceForm,
} from "../../redux/Invoices/InvoicesAction";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { validateCoinInvoiceForm } from "../../constantFunctions/formValidations";
import moment from 'moment';
import NumberFormat from 'react-number-format';
import ViewInvoicePrint from "views/Modal/ViewInvoicePrint";
import { enableFileUploader } from "libs/common";
import { jsonToFormData } from "libs/common";
import ViewInvoiceItemAttachment from "components/Invoices/ViewInvoiceItemAttachment";


function CreateNewCoinInvoice(props) {
    const [unauthorized, setUnauthorized] = useState(false);
    const [years, setYears] = useState([]);
    const [buyers, setBuyers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const { match: { params } } = props;
    const [printUrl, setPrintUrl] = useState("");
    const [viewPrintModal, setViewPrintModal] = useState(false);
    const [printButtonUrl, setPrintButtonUrl] = useState("");
    const [attachments, setAttachments] = useState([]);

    useEffect(() => {
        props.resetAddInvoiceForm();
        if (!params.id) {
          //load fileuploader with plain jquery
          window.jQuery(document).ready(function() {
            enableFileUploader();
          });
          props.saveItemsDetails([{
            id: 0,
            itemDescription: '',
            dateTime: moment().format('MM-DD-YYYY HH:mm:ss'),
            price: '0.00',
            cost: '0.00',
            qty: 1,
            estimate: '0.00',
            typeId: 'bag',
            typeValue: '1',
            amountPer: '0',
            numbers: bagNumbers,
            Offers: []
          }]);
        } else {
          loadForm()
        }
        GetApiActionWithAuthorization("invoices/get-all-years").then((res) => {
            setYears(res.data)
        });
    }, []);

    const loadForm = () => {
      GetApiActionWithAuthorization("invoices/get-single-invoice-data/" + params.id + "?type=2").then((res) => {
        props.saveInvoiceFormDetails({
          name: "billedTo",
          value: res.data.billedTo
        })
        props.saveInvoiceFormDetails({
          name: "checkNumber",
          value: res.data.checkNumber
        })
        props.saveInvoiceFormDetails({
          name: "applyDiscount",
          value: res.data.applyDiscount
        })
        //delivery date
        let deliveryDate = moment(res.data.deliveryDate*1000);
        //items
        let items = res.data.coin_invoices_items ? res.data.coin_invoices_items.map((item, index) => {
          if(item.createdAt == 0) {
            item.createdAt = moment().format('MM-DD-YYYY HH:mm:ss');
          }
          //
          item.attachments = item.coin_invoice_items_attachments.length > 0 ? item.coin_invoice_items_attachments.map(f => {
            return {
              ... f,
              imageUrl: f.url
            }
          })[0] : { imageUrl: '' }
          item.numbers = bagNumbers
          //                  
          return item;
        }) : [];
        setAttachments(items.map(v=>v.attachments))
        //
        console.log(items)
        props.saveItemsDetails(items);
        props.saveInvoiceFormDetails({
          name: "checkNumber",
          value: res.data.checkNumber
        })
        props.saveInvoiceFormDetails({
          name: "deliveryDateDay",
          value: deliveryDate.format('D')
        })
        props.saveInvoiceFormDetails({
          name: "deliveryDateMonth",
          value: monthOptions.find(i=>i.value==deliveryDate.format('M'))
        })
        props.saveInvoiceFormDetails({
          name: "deliveryDateYear",
          value: deliveryDate.format('Y')
        })
        //load fileuploader with plain jquery
        window.jQuery(document).ready(function() {
          enableFileUploader();
        });
      });
    }

    const togglePrintView = () => {
      setViewPrintModal(!viewPrintModal);
    };

    const getNextLetter = (char) => {
      if (char === 'z') {
        return 'a';
      }
      //
      if (char === 'Z') {
        return 'A';
      }
      //
      return String.fromCharCode(char.charCodeAt(0) + 1);
    }

    const addRowInItem = (e) => {
      const [lastItem] = props.invoiceItemsDetails.items.slice(-1)
      let numbers = bagNumbers
      /*let typeValueO = lastItem.typeValue.substring(0, lastItem.typeValue.length-1)
      let defaultTypeValue = ""
      let lastCharacter = lastItem.typeValue
      if (lastItem.typeValue.includes("Z")) {
        defaultTypeValue = "A".repeat(lastItem.typeValue.length)
        lastCharacter = lastItem.typeValue.slice(-1)
        numbers = new Array(26).fill(1).map(( _, i ) => String.fromCharCode(65 + i)).map(m => ("A".repeat(lastItem.typeValue.length)) + m)
      } else if (typeValueO.length > 0) {
        defaultTypeValue = lastItem.typeValue.substring(0, lastItem.typeValue.length-1)
        lastCharacter = lastItem.typeValue.slice(-1)
        numbers = new Array(26).fill(1).map(( _, i ) => String.fromCharCode(65 + i)).map(m => ("A".repeat(typeValueO.length)) + m)
      }*/
      let typeValue = (parseInt(lastItem.typeValue) + 1).toString()
      //
        let obj = {
          id: 0,
          itemDescription: "",
          price: "0.00",
          dateTime: moment().format('MM-DD-YYYY HH:mm:ss'),
          cost: '0.00',
          qty: 1,
          estimate: '0.00',
          typeId: 'bag',
          //typeValue: defaultTypeValue + getNextLetter(lastCharacter),
          typeValue,
          amountPer: '0',
          numbers,
          Offers: []
        };
        let olderItems = props.invoiceItemsDetails.items;
        olderItems.push(obj);
        console.log(olderItems)
        props.saveItemsDetails(olderItems);
      };
    
      const removePictureInItem = (index) => {
        let item = props.invoiceItemsDetails.items[index]
        attachments[index] = { imageUrl: '' }
        props.invoiceItemsDetails.items[index] = {
          ... item,
          coin_invoice_items_attachments: null,
          attachments: null
        }
        props.saveItemsDetails(props.invoiceItemsDetails.items);
        setAttachments([... attachments])
      };

      const removeRowInItem = (index) => {
        let olderItems = props.invoiceItemsDetails.items;
        olderItems.splice(index, 1);
        props.saveItemsDetails(olderItems);
      };
    
      const changeItemValues = (name, value, index) => {
        let temp = props.invoiceItemsDetails.items;
        let itemObj = temp[index];
        itemObj[name] = value;
        temp[index] = itemObj;

        if (name === "price") {
            itemObj["price"] = parseFloat(value).toFixed(2);
        }
        if (name === "cost") {
          itemObj["cost"] = parseFloat(value).toFixed(2);
      }
        props.saveItemsDetails(temp);
      };    

    const acceptOffer = (params) => {
      if(window.confirm('Are you sure that you want accept this offer ?')) {
        setLoading(true);
        PostApiWithAuthorizationAction("invoices/accept-offer", params)
        .then((res) => {
          setLoading(false);
          loadForm()
        })
        .catch((err) => {
          setLoading(false);
          if (err.status === 440) {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
      }
    }

    const printHandler = (item) => {
      setLoading(true);
      PostApiWithAuthorizationAction("invoices/print-label/", {
        ...item,
        station: localStorage.getItem('lastUsedPrinter') || 'station1',
        invoiceId: params.id
      }).then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
    };

    const formSubmitHandler = (e) => {
        let validation = validateCoinInvoiceForm(
            { ...props.invoiceFormDetails },
            props.invoiceItemsDetails.items
        );
        if (validation.success) {
            let data = jsonToFormData({
              formDetails: {
                ...props.invoiceFormDetails,
                deliveryDateMonth: props.invoiceFormDetails.deliveryDateMonth.value,
                type: 2
              },
              items: props.invoiceItemsDetails.items,
            });
            /*if (params.id) {
              let existingFiles = window.jQuery('[name="fileuploader-list-files"]').val();
              data.append('existingFiles', existingFiles);
            }*/
            console.log(attachments)
            for (const index in attachments) {
              if (attachments.hasOwnProperty(index)) {
                const file = attachments[index]
                if (file.imageUrl != '' && !file.id) {
                  data.append('files[' + index + '][]', file, file.name);
                }
              }
            }
            setSubmitting(true);
            PostApiWithAuthorizationAction("invoices/save/" + (params.id ? params.id : ""), data, {
              'Content-Type': 'multipart/form-data'
            })
              .then((res) => {
                setSubmitting(false);
                props.resetAddInvoiceForm();
                props.history.push("/portal/coin-invoices");
              })
              .catch((err) => {
                setSubmitting(false);
                if (err.status === 403) {
                  setUnauthorized(true);
                } else if (err.status === 440) {
                  props.resetState();
                  toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
                  props.history.push("/auth/login");
                }
              });
        } else {
            toast.error(validation.err, {
              position: toast.POSITION.TOP_CENTER,
              closeOnClick: true,
            });
        }
    };

    const formatAmount = (amount) => {
      var parts = amount.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
    }

    const onChooseFile = (i) => {
      document.getElementById('input-file-upload-' + i).click()
    }

    const hasAttachment = (i) => {
      let files = attachments
      return (typeof files[i] !== 'undefined' && files[i].imageUrl != '')
    }

    const onFilePicked = (event, index) => {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        files[0].imageUrl = fileReader.result
        attachments[index] = files[0]
        setAttachments({
          ... attachments
        })
      })
      fileReader.readAsDataURL(files[0])
    }

    const getTotalAmount = () => {
      return props.invoiceItemsDetails.items
      .reduce((prev, curr) => {
        let price = !isNaN(curr.price) ? parseFloat(curr.price) : 0.0
        return prev + price;
      }, 0)
    }

    const getDiscountAmount = () => {
      return props.invoiceFormDetails.applyDiscount ? getTotalAmount() * 0.1 : 0
    }

    const renderAllItemsHTML = (e) => {
        return props.invoiceItemsDetails.items.map((item, i) => {
          // let itemQty = parseInt(item.qty);
          // let itemCost = parseFloat(item.cost);
          let amountPer = item.amountPer ? item.amountPer : "",
              offers = item.Offers ? item.Offers : [],
              offer = offers.length > 0 ? offers[0] : { id: 0 },
              offerAmount = offers.length > 0 ? offer.amount : "0",
              amountOfferDiff = parseFloat(item.estimate) - parseFloat(offerAmount)
            return (
              <tr key={i} id={"invoice-item-row" + i}>
                <td className="input-td">
                  <Input
                    type="number"
                    className={classnames({ "input-null": item.qty === "", 'form-control': true })}
                    value={item.qty}
                    onChange={(e) => {
                      changeItemValues("qty", e.target.value, i);
                      changeItemValues("price", e.target.value * item.amountPer, i);
                    }}/>
                </td>
                <td className="input-td">
                    <Input
                        id="description"
                        type="text"
                        value={item.itemDescription}
                        className={classnames({
                            "input-null": item.itemDescription === "",
                        })}
                        onChange={(e) => {
                            changeItemValues("itemDescription", e.target.value, i);
                        }}
                    />
                </td>
                <td className="input-td">
                  <DropdownList
                      data={coinInvoiceTypes}
                      value={item.typeId}
                      placeholder="Type"
                      textField="label"
                      valueField="value"
                      onChange={(e) => {
                        changeItemValues("typeId", e.value, i);
                      }}
                    />
                </td>
                <td className="input-td">
                  <DropdownList
                    data={item.numbers}
                    value={item.typeValue}
                    placeholder="ID"
                    onChange={(e) => {
                      changeItemValues("typeValue", e, i);
                    }}
                  />
                </td>
                <td className="input-td">
                  <NumberFormat
                    id="estimate"
                    allowNegative={true}
                    className={classnames({ "input-null": item.estimate === "", 'form-control': true })}
                    value={item.estimate.replace('.00', '')}
                    onValueChange={(e) => {
                      changeItemValues("estimate", e.value, i);
                    }}
                  />
                </td>
                <td
                  className="input-td"
                  style={{
                    fontWeight: 'bold',
                    background: amountOfferDiff > 0 && !offer.approved ? (amountOfferDiff < 10) ? 'yellow' : '#fd6868' : 'white',
                    color:  amountOfferDiff > 0 && !offer.approved ? (amountOfferDiff < 10) ? 'black' : 'white' : 'black',
                    paddingLeft: '1px',
                    borderColor: amountOfferDiff > 0 && !offer.approved ? '#fd6868' : '',
                    borderRight: amountOfferDiff > 0 && !offer.approved ? '1px solid #e9ecef' : ''
                  }}>
                  ${offerAmount.replace('.00', '')}
                </td>
                <td
                  className="input-td text-center"
                  style={{
                    fontWeight: 'bold',
                    background: amountOfferDiff > 0 && !offer.approved ? (amountOfferDiff < 10) ? 'yellow' : '#fd6868' : 'white',
                    color:  amountOfferDiff > 0 && !offer.approved ? (amountOfferDiff < 10) ? 'black' : 'white' : 'black',
                    paddingLeft: '1px',
                    borderColor: amountOfferDiff > 0 && !offer.approved ? '#fd6868' : ''
                  }}>
                  {(parseFloat(offerAmount) < parseFloat(item.estimate)) && !offer.approved && (
                    <Button
                        id="print"
                        className="icon-cross ml-1"
                        color="success"
                        onClick={() => {
                          acceptOffer({
                            itemID: item.id,
                            offerID: offer.id
                          })
                        }}
                      >
                      <i className="fas fa-check-circle"></i>
                    </Button>
                  )}
                </td>
                <td className="input-td text-center">
                  <Button
                    id="print"
                    className="icon-cross"
                    color="warning"
                    onClick={() => {
                      printHandler(item)
                    }}
                  >
                    <i className="fas fa-print"></i>
                  </Button>
                </td>
                <td className="input-td item-column text-center">
                  {(hasAttachment(i)) ? (
                    <ViewInvoiceItemAttachment
                      index={i}
                      files={[attachments[i]]}
                      removePictureInItem={removePictureInItem}
                      key={i}
                    />
                  ) : (
                    <>
                      <Button
                        id="print"
                        className="icon-cross"
                        color="warning"
                        title="Choose a Picture"
                        onClick={() => onChooseFile(i)}
                      >
                        <i className="fas fa-upload"></i>
                      </Button>
                      <input
                        id={'input-file-upload-' + i}
                        type="file"
                        style={{display: 'none'}}
                        accept="image/*"
                        onChange={(e) => onFilePicked (e, i)}/>                    
                    </>
                  )}
                </td>
                {(i >= 0 && props.invoiceItemsDetails.items.length > 1 || params.id && props.invoiceItemsDetails.items.length > 1)&& (
                <td className="input-td text-center">
                    <Button
                      id="remove"
                      className="icon-cross"
                      color="danger"
                      title="remove"
                      onClick={() => {
                        removeRowInItem(i);
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                </td>
                )}
                <td
                  className="input-td"
                  style={{fontWeight: 'bold'}}>
                  ${item.price.replace('.00', '')}
                </td>
                <td className="action-col text-center">
                  {(props.invoiceItemsDetails.items.length == (i+1)) && (
                    <Button
                      id="add-more"
                      className="icon-cross"
                      color="primary"
                      title="Add more"
                      onClick={() => {
                        if (item.price !== "" && item.itemDescription !== "") {
                          addRowInItem();
                          attachments[i+1] = {
                            imageUrl: ''
                          };
                          setAttachments({
                            ... attachments
                          })
                        }
                      }}
                    >
                      <i className="fas fa-plus-square"></i>
                    </Button>
                  )}
                </td>
              </tr>
            );
        });
    };

    return unauthorized ? (
        <Unauthorized />
      ) : (
        <>
          <Header />
          {/* Page content */}
          <Container className="mt--9 main-content" fluid>
            <Card className="card-height shadow">
              <CardHeader className="bg-white d-flex justify-content-between">
                <h2>
                    {params.id ? 'Update Invoice' : 'Create New Invoice'}
                </h2>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    props.history.goBack();
                  }}
                >
                  <i className="fas fa-chevron-left"></i> Back
                </Button>
              </CardHeader>
              <CardBody>
                  <div className="px-4">
                    <h1>Invoice #{params.id ? params.id : ''} - ${formatAmount(getTotalAmount() - getDiscountAmount())}</h1>
                    <div className="pull-right">
                        <div className="text-right my-2">
                            <Button
                              id="save-receipt"
                              color="info"
                              onClick={(e) => {
                                e.preventDefault();
                                setPrintUrl(process.env.REACT_APP_apiUrl + "invoices/get-labels/" + params.id + "?type=2");
                                setViewPrintModal(true);
                                setPrintButtonUrl("invoices/print-labels/" + params.id + "?type=2")
                              }}
                            ><i className="fas fa-print"></i> Print Labels</Button>
                            <Button
                                id="save-receipt"
                                color="primary"
                                disabled={submitting}
                                onClick={formSubmitHandler}
                                >Save Invoice</Button>
                        </div>
                        <Row className="justify-content-center mb-2">
                            <Col lg="9" md="9" className="invoice-delivery-date-label pt-1 pr-0">Billed To:</Col>
                            <Col lg="3" md="3" className="px-1">
                                <Input
                                    id="billedTo"
                                    type="text"
                                    placeholder="Billed To"
                                    autoComplete="off"
                                    size="sm"
                                    name="billedTo"
                                    value={props.invoiceFormDetails.billedTo}
                                    onChange={(e) =>
                                        props.saveInvoiceFormDetails({
                                            name: "billedTo",
                                            value: e.target.value,
                                        })
                                    }
                                    autoFocus={true}/>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mb-2">
                            <Col lg="9" md="9" className="invoice-delivery-date-label pt-1 pr-0">Check Number#:</Col>
                            <Col lg="3" md="3" className="px-1">
                                <Input
                                    id="checkNumber"
                                    type="text"
                                    placeholder="Check Number"
                                    autoComplete="off"
                                    name="checkNumber"
                                    size="sm"
                                    value={props.invoiceFormDetails.checkNumber}
                                    onChange={(e) =>
                                        props.saveInvoiceFormDetails({
                                            name: "checkNumber",
                                            value: e.target.value,
                                        })
                                    }/>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg="9" md="9" className="invoice-delivery-date-label pt-2 pr-0">Delivery Date:</Col>
                            <Col lg="1" md="1" className="mb-2 pr-0 pl-1">
                                <DropdownList
                                    data={monthOptions}
                                    value={props.invoiceFormDetails.deliveryDateMonth}
                                    placeholder="Month"
                                    textField="label"
                                    onChange={(e) => {
                                        props.saveInvoiceFormDetails({
                                          name: "deliveryDateMonth",
                                          value: e,
                                        });
                                    }}
                                />
                            </Col>
                            <Col lg="1" md="1" className="mb-2 px-1">
                                <DropdownList
                                    data={dayOptions}
                                    value={props.invoiceFormDetails.deliveryDateDay}
                                    placeholder="Day"
                                    onChange={(e) => {
                                        props.saveInvoiceFormDetails({
                                          name: "deliveryDateDay",
                                          value: e,
                                        });
                                    }}
                                />
                            </Col>
                            <Col lg="1" md="1" className="mb-2 pr-1 pl-0">
                                <DropdownList
                                    data={years}
                                    value={props.invoiceFormDetails.deliveryDateYear}
                                    placeholder="Year"
                                    onChange={(e) => {
                                        props.saveInvoiceFormDetails({
                                          name: "deliveryDateYear",
                                          value: e,
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div style={{textAlign: 'right', fontSize: '16px'}}>
                      <FormGroup check style={{ fontWeight: 'bold', display: 'inline'}} className="mr-5">
                        <Input
                          type="checkbox"
                          checked={props.invoiceFormDetails.applyDiscount}
                          onChange={(e) => {
                            props.saveInvoiceFormDetails({
                              name: "applyDiscount",
                              value: !props.invoiceFormDetails.applyDiscount,
                            });
                          }}/>Apply 10% discount
                      </FormGroup>
                      <span>Total:</span>&nbsp;
                      <strong>${formatAmount(getTotalAmount() - getDiscountAmount())}</strong>
                    </div>
                    <div className="mt-1">
                      <Table
                        className="add-receipt-item-table manage-invoice-table"
                        style={{margin: 'auto', tableLayout: 'fixed', width: '30%'}}
                        bordered
                        responsive="sm">
                          <thead className="thead-light">
                              <tr>
                                <th scope="col" style={{width: '60px'}} className="pl-2">Qty</th>
                                {/*<th scope="col" style={{width: '120px'}} className="pl-2">Amt Per</th>*/}
                                <th scope="col" style={{width: '300px'}}>Desc</th>
                                <th scope="col" style={{width: '60px'}} className="pl-2">Type</th>
                                <th scope="col" style={{width: '50px'}} className="pl-2">#</th>
                                {/*<th scope="col" style={{maxWidth: '80px'}} className="pl-2">Cost</th>*/}
                                <th scope="col" style={{width: '60px'}} className="pl-2"><div>Min.</div><div>Offer</div></th>
                                <th scope="col" style={{width: '50px'}} className="px-1"><div>Cust.</div><div>Offer</div></th>
                                <th scope="col" style={{width: '60px'}} className="px-1"><div>Accept</div><div>Offer</div></th>
                                {/*<th scope="col" style={{maxWidth: '80px'}} className="pl-2">Cost Per</th>*/}
                                <th scope="col" style={{width: '45px'}} className="px-1">Print</th>
                                <th scope="col" style={{width: '62px'}} className="px-1">Picture</th>
                                {props.invoiceItemsDetails.items.length > 1 && (
                                  <th scope="col" style={{width: '70px'}} className="px-1">Remove</th>
                                )}
                                <th scope="col" style={{width: '70px'}} className="px-1"><div>Ext.</div><div>Amount</div></th>
                                <th></th>
                              </tr>
                          </thead>
                          <tbody>{renderAllItemsHTML()}</tbody>
                          <tfoot className="tfoot-light">
                            {(props.invoiceFormDetails.applyDiscount) && (
                              <tr>
                                <td scope="col" colSpan={props.invoiceItemsDetails.items.length > 1 ? 9 : 8}>Discount:</td>
                                <td scope="col" colSpan="1">
                                  ${formatAmount(getDiscountAmount())}
                                </td>
                              </tr>                            
                            )}
                            <tr>
                              <td scope="col" colSpan={props.invoiceItemsDetails.items.length > 1 ? 9 : 8}>Total:</td>
                              <td scope="col" colSpan="1">
                                ${formatAmount(getTotalAmount() - getDiscountAmount())}
                              </td>
                            </tr>
                          </tfoot>
                      </Table>
                    </div>
                  </div>
              </CardBody>
            </Card>
          </Container>
          {viewPrintModal && (
            <ViewInvoicePrint
              show={viewPrintModal}
              toggle={togglePrintView}
              invoiceUrl={printUrl}
              printButtonUrl={printButtonUrl}
            />
          )}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        invoiceFormDetails: state.invoicesReducer.invoiceFormDetails,
        invoiceItemsDetails: state.invoicesReducer.invoiceItemdetails,
        userType: state.loginUserReducer.userType
    };
  };
  
const mapDispatchToProps = (dispatch) => {
    return {
        saveInvoiceFormDetails: (body) => dispatch(saveInvoiceFormDetails(body)),
        saveItemsDetails: (body) => dispatch(saveItemsDetails(body)),
        resetAddInvoiceForm: () => dispatch(resetAddInvoiceForm()),
        resetState: () => dispatch(resetState())
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewCoinInvoice);