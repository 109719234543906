import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  Button,
  Input,
  Table,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { resetState } from "../../redux/common/commonAction";
import Unauthorized from "views/Pages/Unauthorized";
import { DropdownList } from "react-widgets";
import {
  fetchQuoteListing,
  fetchQuoteListingSuccess,
} from "../../redux/Quotes/quoteAction";
import {
    GetApiActionWithAuthorization,
    PostApiWithAuthorizationAction
} from "../../constantFunctions/apiActions";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import AddNewCommentModal from "views/Modal/Quotes/AddNewCommentModal";
import { toast } from "react-toastify";
import { openInNewTab } from "../../libs/common"

function Quotes(props) {
  const [commentModal, setCommentModal] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const [editCommentData, setEditCommentData] = useState({
    id: 0,
    comment:''
  });
  const [delId, setDelId] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    props.fetchQuoteListing({
        page: props.quoteDetails.page
    });
  }, []);

  if (props.quoteDetails.error && !unauthorized) {
    if (props.quoteDetails.error.status === 403) {
      setUnauthorized(true);
    } else if (props.quoteDetails.error.status === 440) {
      props.resetState();
      toast.error(props.quoteDetails.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }
  const toggleCommentSave = (id = 0) => {
    setEditCommentData({
        id,
        comment: ''
    });
    setCommentModal(!commentModal);
  };
  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  const confirmed = (e) => {
    GetApiActionWithAuthorization("quotes/delete-from-quote-list/" + delId)
      .then((res) => {
        props.fetchQuoteListing({
          page: props.quoteDetails.page
        });
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        setConfirm(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const attachTicketToQuote = (params = {}) => {
    PostApiWithAuthorizationAction("quotes/make-ticket-from-quote", params)
      .then((res) => {
        props.fetchQuoteListing({
            page: props.quoteDetails.page
        });
        //open ticket in a new page
        if (params.ticketType === "metro") {
            openInNewTab('/portal/metro-tickets/view-items/' + res.quote.ticketId);
        } else if (params.ticketType === "coin") {
            openInNewTab('/portal/coin-tickets?id=' + res.quote.ticketId);
        }
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        setConfirm(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderQuotesTableData = (e) => {
    return props.quoteDetails.data.map((quote, i) => {
      let itemDescription = quote.quote_items.map(q=>q.description).join('<br/>');
      let amount = quote.quote_items.map(q=>q.recentPrice).reduce((a, b) => a + b, 0);
      return (
        <tr key={i} style={{backgroundColor: quote.contactLog.trim() === "" ? "pink": "white"}}>
          <td>{quote.id}</td>
          <td>
            <Moment format="MMM Do YYYY" tz="America/Los_Angeles">
              {quote.date.toString().length === 10
                ? quote.date * 1000
                : quote.date}
            </Moment>
          </td>
          <td>
            <span
                className="font-weight-600"
                style={{color: amount > 0 ? 'green': '#333'}}>${amount.toString().replace('.00', '')}</span>
          </td>
          <td>
              {quote.customerName.length > 0 && (quote.customerName)}
              {quote.customerName.length == 0 && (
                  <span className="text-center">-</span>
              )}
          </td>
          <td>
              {quote.contactMethod === 'phone' && quote.contactValue.length > 0 && (
                <a href={"tel:" + quote.contactValue}>
                    <Button
                        id ="edit"
                        color="primary"
                        size="sm">
                        <i class="fas fa-phone-alt"></i> {quote.contactValue}
                    </Button>
                </a>
              )}
              {quote.contactMethod === 'email' && quote.contactValue.length > 0 && (
                <a href={"mailto:" + quote.contactValue}>
                    <Button
                        id ="edit"
                        color="primary"
                        size="sm">
                        <i class="fas fa-envelope"></i> {quote.contactValue}
                    </Button>
                </a>
              )}
              {quote.contactMethod !== 'email' && quote.contactValue.length > 0 && quote.contactMethod !== 'phone' && (
                <span>{quote.contactValue}</span>
              )}
              {quote.contactValue.length == 0 && (
                  <span className="text-center">-</span>
              )}
          </td>
          <td>
            {itemDescription.length > 0 && (<div dangerouslySetInnerHTML={{ __html: itemDescription }}></div>)}
            {itemDescription.length == 0 && (
                <span className="text-center">-</span>
            )}
          </td>
            <td>
                [<a href={"/portal/quotes/edit/" + quote.id}>Edit</a>]
                [<Link href="#" onClick={(e) => toggleCommentSave(quote.id)}>Comment</Link>]
                [<Link href="#" onClick={(e) => toggleConfirm(quote.id)}>Delete</Link>]
                { quote.ticketId === "" && (
                    <DropdownList
                        id="createTicket"
                        data={[
                            {value: 'metro', label: 'Metro Ticket'},
                            {value: 'coin', label: 'Coin Ticket'},
                            /*{value: 'coinReceipt', label: 'Coin Receipt'},
                            {value: 'jewelryReceipt', label: 'Jewelry Receipt'},*/
                        ]}
                        textField="label"
                        valueField="value"
                        onChange={(e) =>
                            attachTicketToQuote({
                                quoteId: quote.id,
                                ticketType: e.value,
                            })
                        }
                        />
                )}
            </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>Open Quotes</h1>
            </div>
          </CardHeader>
          <CardBody>
          <Row className="align-items-center search-container mb-3">
            <Col md="5" lg="6" xl="7" className="search-left-btn">
              <a href="/portal/quotes/create" id="create-quote">
                <Button color="primary">
                  Create New Quote
                </Button>
              </a>
            </Col>
            <Col md="7" lg="6" xl="5">
                <Form
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    props.fetchQuoteListing({
                        page: props.quoteDetails.page,
                        search: search.trim()
                    });
                  }}
                >
                  <Input
                    type="text"
                    className="mr-2"
                    placeholder={"Quote # / Name / Quote Amount/ Phone / Email"}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button
                    type="submit"
                    className="mx-2"
                    color="primary">
                    Search
                  </Button>
                </Form>
            </Col>
          </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Created</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Name</th>
                  <th scope="col">Contact</th>
                  <th scope="col">Description</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>{renderQuotesTableData()}</tbody>
            </Table>
            {props.quoteDetails.data.length > 0 && (
              <ReactPaginate
                previousLabel={<i className="fas fa-chevron-left"></i>}
                nextLabel={<i className="fas fa-chevron-right"></i>}
                breakLabel={".."}
                breakClassName={"break-me"}
                initialPage={props.quoteDetails.page}
                pageCount={props.quoteDetails.count / 50}
                marginPagesDisplayed={1}
                pageRangeDisplayed={2}
                onPageChange={(e) => {
                  props.fetchQuoteListing({
                    page: e.selected
                  });
                }}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </CardBody>
        </Card>
      </Container>

      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
      {commentModal && (
        <AddNewCommentModal {...props} showComment={commentModal} toggleComment={toggleCommentSave} quoteLog={editCommentData} setQuoteLog={setEditCommentData}/>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    quoteDetails: state.quoteReducer.quotes,
    userType: state.loginUserReducer.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchQuoteListing: (page) => dispatch(fetchQuoteListing(page)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Quotes);
