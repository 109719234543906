import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { resetState } from "../../redux/common/commonAction";
import Unauthorized from "views/Pages/Unauthorized";
import Header from "components/Headers/Header.js";
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Row,
  Col,
  Label,
  Input,
  Button,
  Table,
  CustomInput
} from "reactstrap";
import {
    GetApiActionWithAuthorization,
    PostApiWithAuthorizationAction
} from "constantFunctions/apiActions";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import AddNotificationEmailModal from "views/Modal/NotificationEmail/AddNotificationEmailModal";
import EditNotificationEmailModal from "views/Modal/NotificationEmail/EditNotificationEmailModal";
import Moment from "react-moment";

function EmailNotification(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [alertMetroTicketAmount, setAlertMetroTicketAmount] = useState(0);
  const [alertCoinTicketAmount, setAlertCoinTicketAmount] = useState(0);
  const [alertCoinReceiptAmount, setAlertCoinReceiptAmount] = useState(0);
  const [alertJewelryReceiptAmount, setAlertJewelryReceiptAmount] = useState(0);
  const [alertMetroDailyAmountTotal, setAlertMetroDailyAmountTotal] = useState(0);
  const [alertCoinDailyAmountTotal, setAlertCoinDailyAmountTotal] = useState(0);
  const [emailData, setEmailData] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");

  useEffect(() => {
    GetApiActionWithAuthorization("common/get-check-setting").then((res) => {
      setAlertMetroTicketAmount(res.data.alertMetroTicketAmount);
      setAlertCoinTicketAmount(res.data.alertCoinTicketAmount);
      setAlertCoinReceiptAmount(res.data.alertCoinReceiptAmount);
      setAlertJewelryReceiptAmount(res.data.alertJewelryReceiptAmount);
      setAlertCoinDailyAmountTotal(res.data.alertCoinDailyAmountTotal);
      setAlertMetroDailyAmountTotal(res.data.alertMetroDailyAmountTotal);
    }).catch((err) => {
      if (err.status === 440) {
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
    //load notification emails data
    load();
  }, []);

  const load = () => {
    GetApiActionWithAuthorization("notification-emails/all").then((res) => {
        setEmailData(res.data);
    }).catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
    });
  };

  const resetData = (data) => {
    setEmailData(data);
    setEditData({email: ""})
  };

  const toggleAdd = (e) => {
    setAddModal(!addModal);
  };

  const toggleEdit = (data) => {
    setEditModal(!editModal);
    setEditData({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        id: data.id
    });
  };

  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  const confirmed = (e) => {
    GetApiActionWithAuthorization("notification-emails/delete-email/" + delId)
      .then((res) => {
        load();
        setConfirm(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const updateEnableStatus = (e, id) => {
    let body = {
      enabled: e.target.checked,
      id: id,
    };
    PostApiWithAuthorizationAction("notification-emails/edit-email-data", body)
      .then((res) => {
        resetData(res.data);
      })
      .catch((err) => {
        
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderTableData  = (e) => {
    return emailData.map((data, i) => {
        return (
          <tr key={i}>
            <td>{data.firstName} {data.lastName}</td>
            <td>{data.email}</td>
            <td>
                <CustomInput
                    type="switch"
                    id={"showEnableEmailNotifier" + i}
                    name="enabled"
                    checked={data.enabled}
                    onChange={(e) => updateEnableStatus(e, data.id)}
                    />
            </td>
            <td>
                <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
                {data.createdAt.toString().length === 10
                  ? new Date(data.createdAt * 1000)
                  : new Date(data.createdAt)}
                </Moment>
            </td>
            <td>
                <Button
                    className="edit-item"
                    color="warning"
                    size="sm"
                    onClick={(e) => {
                        toggleEdit(data);
                    }}>
                    <i class="fas fa-edit"></i> Edit
                </Button>
                <Button
                    id={"delete-item"+i}
                    color="danger"
                    size="sm"
                    onClick={(e) => {
                        toggleConfirm(data.id);
                    }}>
                    <i class="fas fa-trash-alt"></i> Delete
                </Button>
            </td>
          </tr>
        );
      });
  };

  const saveCheckSettingHandler = (e) => {
    let body = {
      alertMetroTicketAmount,
      alertCoinTicketAmount,
      alertCoinReceiptAmount,
      alertJewelryReceiptAmount,
      alertCoinDailyAmountTotal,
      alertMetroDailyAmountTotal
    };
    PostApiWithAuthorizationAction("common/save-check-setting", body)
      .then((res) => {
        toast.success("Setting Updated Successfully!", { position: toast.POSITION.TOP_RIGHT });
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader className="">
            <div className="text-center">
              <h1>Email Notifications</h1>
            </div>
          </CardHeader>
          <CardBody className="checks-main-content page-main-content">
            <Tabs className="mt-2">
                <TabList>
                    <Tab>Amount Setting</Tab>
                    <Tab>Email Management</Tab>
                </TabList>
                <TabPanel>
                  <div
                    style={{fontWeight: 'bold', fontSize: '16px'}}
                    className="text-center">Ticket Amount</div>
                  <hr className="m-1" style={{borderWidth: 'medium', borderColor: '#ddd'}}/>
                  <Row className="mb-1">
                    <Col md="4">
                      <Label className="form-control-label font-weight-bold"
                      for="metroAmount">Metro Amount</Label>
                      <Input
                      id="metroAmount"
                      type="text"
                      placeholder="Metro Amount"
                      value={alertMetroTicketAmount}
                      onChange={(e) => setAlertMetroTicketAmount(e.target.value)}
                      />
                      <span className="text-danger"><small>*required</small>*</span>
                    </Col>
                    <Col md="4">
                        <Label className="form-control-label font-weight-bold"
                        for="coinAmount">Coin Amount</Label>
                        <Input
                            id="coinAmount"
                            type="text"
                            placeholder="Coin Amount"
                            value={alertCoinTicketAmount}
                            onChange={(e) => setAlertCoinTicketAmount(e.target.value)}
                        />
                        <span className="text-danger"><small>*required</small>*</span>
                    </Col>
                  </Row>
                  <div
                    style={{fontWeight: 'bold', fontSize: '16px'}}
                    className="text-center">
                    Daily Amount Total
                  </div>
                  <hr
                    className="m-1"
                    style={{borderWidth: 'medium', borderColor: '#ddd'}}/>
                  <Row className="mb-1">
                    <Col md="4">
                      <Label className="form-control-label font-weight-bold"
                      for="metroAmount">Metro Total Amount</Label>
                      <Input
                      id="metroAmount"
                      type="text"
                      placeholder="Metro Amount"
                      value={alertMetroDailyAmountTotal}
                      onChange={(e) => setAlertMetroDailyAmountTotal(e.target.value)}
                      />
                      <span className="text-danger"><small>*required</small>*</span>
                    </Col>
                    <Col md="4">
                        <Label className="form-control-label font-weight-bold"
                        for="coinAmount">Coin Total Amount</Label>
                        <Input
                            id="coinAmount"
                            type="text"
                            placeholder="Coin Amount"
                            value={alertCoinDailyAmountTotal}
                            onChange={(e) => setAlertCoinDailyAmountTotal(e.target.value)}
                        />
                        <span className="text-danger"><small>*required</small>*</span>
                    </Col>
                  </Row>
                  <div className="text-right">
                    <Button
                      id="add-btn"
                      color="primary"
                      onClick={saveCheckSettingHandler}
                      disabled={alertMetroTicketAmount <= 0 || alertCoinTicketAmount <= 0 || alertCoinDailyAmountTotal <= 0 || alertMetroDailyAmountTotal <= 0}
                    >
                      Save Changes
                    </Button>
                  </div>
                </TabPanel>
                <TabPanel>
                    <div className="text-right mb-2">
                        <Button id="add-new" color="primary" onClick={toggleAdd}>
                            New Email
                        </Button>
                    </div>
                    <Table
                        className="table-listing locks-table"
                        responsive
                        bordered>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Enabled</th>
                                <th scope="col">Created At</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>{renderTableData()}</tbody>
                    </Table>
                </TabPanel>
            </Tabs>
          </CardBody>
        </Card>
      </Container>
      {addModal && (
        <AddNotificationEmailModal
            {...props}
            show={addModal}
            toggle={toggleAdd}
            load={load} />
      )}
      {editModal && (
        <EditNotificationEmailModal
          {...props}
          resetData={resetData}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
          load={load}
        />
      )}
      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
    </>
  );
}


const mapStateToProps = (state) => {
    return {
      
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      resetState: () => dispatch(resetState()),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EmailNotification);