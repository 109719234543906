import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { transactionOptions } from "constants/ticketConstants";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
} from "reactstrap";

function CreateNewTransaction(props) {
  const [fromTransactionOptions, setFromTransactionOptions] = useState(transactionOptions)
  const [transactionData, setTransactionData] = useState({
    src: "",
    dest: "",
    denom100: 0,
    //denom50: 0,
    denom20: 0,
    denom10: 0,
    denom5: 0,
    denom1: 0,
    comment: "",
  });
  const submitHandler = (e) => {
    if (
      transactionData.dest === "" ||
      transactionData.src === ""
    ) {
      toast.error("To,From fields are required*", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      PostApiWithAuthorizationAction(
        "common/create-new-transaction",
        transactionData
      ).then((res) => {
        props.fetchTransactionListing({
          returnCount: true
        });
        //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
        props.toggle();
      });
    }
  };

  return (
    <Modal size="lg" isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Create New Transaction</ModalHeader>
      <ModalBody>
        <Table className="transaction-table" striped bordered>
          <tbody>
            <tr>
              <td style={{ width: "100px" }}>To:</td>
              <td colSpan="2">
                <div className="d-flex align-items-end">
                  <DropdownList
                    id="from"
                    data={transactionOptions}
                    textField="label"
                    valueField='value'
                    containerClassName="from-drop"
                    value={transactionData.dest}
                    onChange={(e) => {
                      if (e === transactionData.dest) {
                        toast.error("To and From must have different values", {
                          position: toast.POSITION.TOP_CENTER,
                        });
                      } else {
                        setTransactionData({
                          ...transactionData,
                          dest: e.value,
                        });
                      }
                      //
                      if (e.value === 'pettycash') {
                        setFromTransactionOptions([
                          { value: "metrodrawer", label: "Metro Drawer" }
                        ])
                      } else {
                        setFromTransactionOptions(transactionOptions)
                      }
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>From:</td>
              <td colSpan="2">
                <div className="d-flex align-items-end">
                  <DropdownList
                    id="from"
                    data={fromTransactionOptions}
                    containerClassName="from-drop"
                    value={transactionData.src}
                    textField="label"
                    valueField='value'
                    onChange={(e) => {
                      if (e === transactionData.dest) {
                        toast.error("To and From must have different values", {
                          position: toast.POSITION.TOP_CENTER,
                        });
                      } else {
                        setTransactionData({
                          ...transactionData,
                          src: e.value,
                        });
                      }
                    }}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>Comment:</td>
              <td colSpan="2" className="comment">
                <Input
                  type="text"
                  placeholder="Comment"
                  value={transactionData.comment}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      comment: e.target.value,
                    });
                  }}
                />
              </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$100</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom100}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom100: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${100 * transactionData.denom100}</td>
            </tr>
            {/*<tr>
              <td style={{ width: "100px" }}>$50</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom50}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom50: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${50 * transactionData.denom50} </td>
                </tr>*/}
            <tr>
              <td style={{ width: "100px" }}>$20</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom20}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom20: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${20 * transactionData.denom20} </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$10</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom10}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom10: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td>${10 * transactionData.denom10} </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$5</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom5}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom5: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${5 * transactionData.denom5} </td>
            </tr>
            <tr>
              <td style={{ width: "100px" }}>$1</td>
              <td>
                <Input
                  type="number"
                  value={transactionData.denom1}
                  onChange={(e) => {
                    setTransactionData({
                      ...transactionData,
                      denom1: e.target.value,
                    });
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </td>
              <td> ${1 * transactionData.denom1}</td>
            </tr>
            <tr>
              <td colSpan="2">Total:</td>
              <td>
                $
                {transactionData.denom100 * 100 +
                  //transactionData.denom50 * 50 +
                  transactionData.denom20 * 20 +
                  transactionData.denom10 * 10 +
                  transactionData.denom5 * 5 +
                  transactionData.denom1 * 1}{" "}
              </td>
            </tr>
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={(e) => submitHandler()}>
          Save
        </Button>{" "}
        <Button color="secondary" onClick={(e) => props.toggle()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default CreateNewTransaction;
