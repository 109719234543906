import {
    FETCH_QUOTE_LISTING_SUCCESS,
    FETCH_QUOTE_LISTING_FAILURE,
    SAVE_QUOTE_ITEMS_DETAILS,
    SAVE_QUOTE_FORM_DETAILS,
    RESET_SAVE_QUOTE_DETAILS
  } from "./quoteType";
  import {
    GetApiActionWithAuthorization,
  } from "../../constantFunctions/apiActions";
  import {
    setLoaderRequestTrue,
    setLoaderRequestFalse,
  } from "../common/commonAction";
  
  /*_________________________________________________________________Get Block Customer listing __________________________________________________________________ */
  export const fetchQuoteListing = (params = {page: 0}) => {
    return (dispatch) => {
      dispatch(setLoaderRequestTrue());
      GetApiActionWithAuthorization("quotes/get-all-data", params)
        .then((res) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchQuoteListingSuccess({data:res.data,page: params.page,count:res.count}));
        })
        .catch((err) => {
          dispatch(setLoaderRequestFalse());
          dispatch(fetchQuoteListingFailure(err));
        });
    };
  };
  
  export const fetchQuoteListingSuccess = (data) => {
    return {
      type: FETCH_QUOTE_LISTING_SUCCESS,
      payload: data,
    };
  };
  
  export const fetchQuoteListingFailure = (error) => {
    return {
      type: FETCH_QUOTE_LISTING_FAILURE,
      payload: error,
    };
  };
  /*__________________________________________________________________________________________________________________________________________ */
  
  export const saveQuoteFormDetails = ({ name, value }) => {
    return {
      type: SAVE_QUOTE_FORM_DETAILS,
      payload: { name, value },
    };
  };
  
  export const saveQuoteItemDetails = (items) => {
    return {
      type: SAVE_QUOTE_ITEMS_DETAILS,
      payload: items,
    };
  };

  export const resetSaveQuoteForm = () => {
    return {
      type: RESET_SAVE_QUOTE_DETAILS,
    };
  };