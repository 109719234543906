import {
  FETCH_BLOCK_LISTING_FAILURE,
  FETCH_BLOCK_LISTING_SUCCESS,
  RESET,
} from "./BlockListTypes";

const initialState = {
  blockData: [],
  count:0,
  page:0,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOCK_LISTING_SUCCESS:
      return {
        blockData: action.payload.data,
          page: action.payload.page,
          count: action.payload.count,
          error: null,
      };
    case FETCH_BLOCK_LISTING_FAILURE:
      return {
        ...state,
        blockData: [],
        count:0,
        page:0,
        error: action.payload,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
