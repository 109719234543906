import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import React, { useEffect, useState } from "react";
import { DropdownList } from "react-widgets";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

function ChangeBoothForSigPad(props) {
  const [booth, setBooth] = useState(localStorage.getItem("booth"));
  const [boothOptions, setBoothOptions] = useState([]);

  useEffect(() => {
    setBooth({ name: localStorage.getItem("booth") });
    GetApiActionWithAuthorization("common/get-all-booths").then((res) => {
      setBoothOptions(res.data);
    });
  }, []);
  const saveBoothAndStation = (e) => {
    if (booth) {
      localStorage.setItem("booth", booth.name);
      props.toggle();
    }
  };
  return (
    <Modal isOpen={props.show} toggle={props.toggle} backdrop={false}>
      <ModalHeader>Select Booth!</ModalHeader>
      <ModalBody>
        <DropdownList
          id="booth"
          data={boothOptions}
          textField="name"
          value={booth}
          placeholder="Select Booth"
          onChange={(e) => {
            setBooth(e);
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button id="save" color="primary" onClick={saveBoothAndStation}>
          Save
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

export default ChangeBoothForSigPad;
