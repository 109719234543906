
import { stationOptions } from "constants/ticketConstants";

const getStationValue =( )=>{
  if(localStorage.getItem("lastUsedPrinter")){
    return stationOptions.find(s => s.value === localStorage.getItem("lastUsedPrinter"));
  }
  else{
    return { value: "", label: "None" };
  }
}

export const initialState = {
  
  checks: {
    ticketChecks: [],
    coinChecks: [],
    checks: [],
    error: null,
  }
};


