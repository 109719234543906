import {
  FETCH_LOGIN_USER_SUCCESS,
  FETCH_LOGIN_USER_FAILURE,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  RESET,
} from "./loginUserTypes";

const initialState = {
  userDetails: {},
  userPermissions: [],
  userType:"",
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOGIN_USER_SUCCESS:
      return {
        userDetails: action.payload.userdetails,
        userPermissions: action.payload.permissions,
        userType: (action.payload.userdetails.group.name).toLowerCase(),
        error: null,
      };
    case FETCH_LOGIN_USER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
      case UPDATE_USER_DETAILS_SUCCESS:
        return {
          ...state,
          userDetails: action.payload,
          error: null,
        };
      case UPDATE_USER_DETAILS_FAILURE:
        return {
          ...state,
          error: action.payload,
        };
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
