import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";

function EditCheckModal(props) {
  const [checkAmount, setCheckAmount] = useState(props.data.amountAsNumber);
  const [checkNumber, setCheckNumber] = useState(props.data.checkNumber);

  const updateCheckDetail = () => {
    let body = {
      checkNumber,
      checkAmount,
    };
    PostApiWithAuthorizationAction("common/edit-checks-detail/" + props.data.id, body)
      .then((res) => {
        props.refreshList();
        props.toggle();
      })
      .catch((e) => props.toggle());
  };

  /*const printCheckHandler = (id, type) => {
    let body = {
      ticketId: id,
      ticketType: type,
      checkNumber,
      checkAmount,
    };
    PostApiWithAuthorizationAction("common/check-print", body)
      .then((res) => {
        //toast.success(res.message);
        props.toggle();
      })
      .catch((e) => props.toggle());
  };*/

  return (
    <Modal isOpen={props.show} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Edit Check Info!</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="form-control-label">Check Amount:</Label>
          <Input
          id="checkAmount"
            type="number"
            className="ml-1"
            placeholder="Check Amount"
            autoComplete="off"
            value={checkAmount}
            onChange={(e) => setCheckAmount(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="form-control-label">Check Number:</Label>
          <Input
          id="checkNumber"
            type="text"
            className="ml-1"
            placeholder="Check Number"
            autoComplete="off"
            value={checkNumber}
            onChange={(e) => setCheckNumber(e.target.value)}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          id="print"
          color="primary"
          onClick={(e) => updateCheckDetail()}
        >
          Save
        </Button>{" "}
        <Button color="secondary" onClick={(e) => props.toggle()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default EditCheckModal;
