import Header from "components/Headers/Header";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";
import classnames from "classnames";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  Table,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import {
  jewelryPaymentOptionsReceipt,
  paymentCashOptions,
  paymentOptionsReceipt,
  stationOptions,
} from "../../constants/ticketConstants";
import { resetState } from "redux/common/commonAction";
import {
  saveReceiptFormDetails,
  saveItemsDetails,
  resetAddReceiptForm
} from "../../redux/JewelryReceipt/JewelryReceiptAction";
import {
  setLoaderRequestFalse,
  setLoaderRequestTrue,
} from "../../redux/common/commonAction";
import Unauthorized from "views/Pages/Unauthorized";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import Moment from "react-moment";
import { validateJewelryReceiptForm } from "../../constantFunctions/formValidations";
import Autocomplete from "components/AutoComplete/Autocomplete";
import PhoneInput from "react-phone-input-2";
import ViewAndEditReceiptPaymentsModal from "views/Modal/JewelryReceipt/ViewAndEditReceiptPaymentsModal";
import { enableFileUploader } from "libs/common";
import { jsonToFormData } from "libs/common";

function UpdateReceipt(props) {
  const [keywords, setKeywords] = useState([]);
  const [items, setitems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [partialPayments, setPartialPayments] = useState([]);
  const [initialShipment, setInitialShipment] = useState({
    trackingNumber: "",
    address: "",
    state: "",
    city: "",
    postalCode: "",
    phone: ""
  });
  const [unauthorized, setUnauthorized] = useState(false);
  const [keyLoad, setKeyLoad] = useState(false);
  const [allClerks, setAllClerks] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [fromOptions, setFromOption] = useState([]);
  const [boothOptions, setBoothOptions] = useState([]);
  const [editPaymentModal, setEditPaymentModal] = useState(false);
  const [paymentIndex, setPaymentIndex] = useState(0);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    let id = props.match.params.id;
    props.setLoaderRequestTrue();
    GetApiActionWithAuthorization(
      `jewelry-receipts/get-particular-receipt-data/${id}`
    )
      .then((res) => {
        props.setLoaderRequestFalse();
        let obj = {
          ...res.details,
          clerk: { first_name: res.details.clerk },
          fromx: { name: res.details.fromx },
          booth: { name: res.details.booth },
          paidBy: jewelryPaymentOptionsReceipt.find(
            (fr) => fr.value === res.details.paidBy
          ),
          printer: stationOptions.find(
            (fr) => fr.value === res.details.printer
          ),
        };
        setOtherDetails(obj);
        setSelectedItems(res.items);
        if (res.shipments.length > 0) {
          setInitialShipment(res.shipments[0]);
        }
        setPartialPayments(res.payments)
        //load fileuploader with plain jquery
        window.jQuery(document).ready(function() {
          enableFileUploader();
        });
        return res;
      })
      .then(res => {
        setAttachments(res.details.jewelry_receipts_attachments.map(f => {
          return {
            name: f.url.split('/').pop(),
            type: f.mimeType,
            size: f.fileSize,
            file: f.url,
            local: f.url,
            data: {
              url: f.url,
              thumbnail: f.thumbnailUrl,
              readerForce: true
            }
          }
        }))
      })
      .catch((err) => {
        props.setLoaderRequestFalse();
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  }, []);

  const editPaymentToggle = () => {
    setEditPaymentModal(!editPaymentModal);
  };

  // const getKeywords = (e) => {
  //   if (keywords.length === 0) {
  //     GetApiActionWithAuthorization("common/get-keywords-for-form")
  //       .then((res) => {
  //         setKeyLoad(false);
  //         setKeywords(res.data);
  //       })
  //       .catch((err) => setKeyLoad(false));
  //   }
  // };

  const getFromOptions = (e) => {
    if (e && fromOptions.length === 0) {
      setKeyLoad(true);
      GetApiActionWithAuthorization("common/get-from-select-options")
        .then((res) => {
          setKeyLoad(false);
          setFromOption(res.data);
        })
        .catch((err) => setKeyLoad(false));
    }
  };

  const fetchAllClerks = (e) => {
    if (e && allClerks.length <= 0) {
      GetApiActionWithAuthorization("common/get-clerk-listing")
        .then((res) => {
          setAllClerks(res.data);
        })
        .catch((err) => {
          if (err.status === 403) {
            setUnauthorized(true);
          } else if (err.status === 440) {
            props.resetState();
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/auth/login");
          }
        });
    }
  };

  const fetchAllBooth = (e) => {
    if (e) {
      GetApiActionWithAuthorization("common/get-all-booths?displayOrderType=JewelryReceipt").then((res) => {
        setBoothOptions(res.data);
      });
    }
  };

  const addRowInItem = (e) => {
    let obj = {
      itemDescription: "",
      unitPrice: "",
      price: "",
      qty: "",
      tax: "",
    };
    setSelectedItems([...selectedItems, obj]);
  };

  const removeRowInItem = (index) => {
    let olderItems = [...selectedItems];
    olderItems.splice(index, 1);
    setSelectedItems([...olderItems]);
  };

  const changeItemValues = (name, value, index) => {
    let temp = selectedItems;
    let itemObj = temp[index];
    itemObj[name] = value;
    temp[index] = itemObj;
    if (name === "qty") {
      itemObj["unitPrice"] = (
        parseFloat(itemObj.price) / parseInt(value)
      ).toFixed(2);
    }

    if (name === "price") {
      if (itemObj.qty) {
        itemObj["unitPrice"] = (
          parseFloat(value) / parseInt(itemObj.qty)
        ).toFixed(2);
      } else {
        itemObj["unitPrice"] = parseFloat(value).toFixed(2);
      }
      itemObj["tax"] = ((parseFloat(value) * 8.375) / 100).toFixed(2);
    }
    setSelectedItems([...temp]);
  };

  const formSubmitHandler = (e) => {
    let validation = validateJewelryReceiptForm({
      ... otherDetails,
      shippingAddress: initialShipment.address,
      shippingState: initialShipment.state,
      shippingCity: initialShipment.city,
      shippingPostalCode: initialShipment.postalCode,
      shippingPhone: initialShipment.phone
    }, selectedItems);
    if (validation.success) {
      if (
        otherDetails.oneTimeEdit ||
        (props.userType && props.userType.toLowerCase() === "admin")
      ) {
        let data = jsonToFormData({
          id: props.match.params.id,
          formDetails: {
            ...otherDetails,
            fromx: otherDetails.fromx.name,
            printer: otherDetails.printer.value,
            booth: otherDetails.booth.name,
            payment: otherDetails.paidBy.value,
            clerk: otherDetails.clerk.first_name,
            ticketAmount: selectedItems
              .reduce((prev, curr) => {
                if (curr.price === "") {
                  return prev + 0.0;
                } else {
                  return prev + parseFloat(curr.price);
                }
              }, 0)
              .toFixed(2),
            tax: selectedItems
              .reduce((prev, curr) => {
                if (curr.tax === "") {
                  return prev + 0.0;
                } else {
                  return prev + parseFloat(curr.tax);
                }
              }, 0)
              .toFixed(2),
          },
          items: selectedItems,
          payments: partialPayments,
          shppingDetails: initialShipment
        });
        let existingFiles = window.jQuery('[name="fileuploader-list-files"]').val();
        data.append('existingFiles', existingFiles);
        window.jQuery('[name="files[]"]').each(function( index ) {
          let files = window.jQuery('[name="files[]"]')[index].files;
          for (const file of files) {
            data.append('files[]', file, file.name);
          }
        });
        setLoading(true);
        PostApiWithAuthorizationAction("jewelry-receipts/edit-receipt", data, {
          'Content-Type': 'multipart/form-data'
        })
          .then((res) => {
            setLoading(false);
            //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
            props.history.push("/portal/jewelry-receipts");
          })
          .catch((err) => {
            if (err.status === 403) {
              setUnauthorized(true);
            } else if (err.status === 440) {
              props.resetState();
              toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
              props.history.push("/auth/login");
            }
          });
      }
    } else {
      toast.error(validation.err, {
        position: toast.POSITION.TOP_CENTER,
        closeOnClick: true,
      });
    }
  };

  const loadNewItems = () => {
    let id = props.match.params.id;
    GetApiActionWithAuthorization(
      `jewelry-receipts/get-particular-receipt-data/${id}`
    ).then((res) => {
      props.setLoaderRequestFalse();
      setSelectedItems(res.items);
    }).catch((err) => {
      props.setLoaderRequestFalse();
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const removePaymentRowItem = (item, index) => {
    if (item.id > 0) {//remote delete
      PostApiWithAuthorizationAction(`jewelry-receipts/delete-receipt-payment-data/${item.id}`)
      .then((res) => {
        setPartialPayments([...partialPayments.slice(0, index), ...partialPayments.slice(index + 1)]);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
    } else {
      setPartialPayments([...partialPayments.slice(0, index), ...partialPayments.slice(index + 1)]);
    }
  };

  const changePaymentItemValues = (name, value, index) => {
    let temp = partialPayments;
    let itemObj = temp[index];
    itemObj[name] = value;
    partialPayments[index] = itemObj;
    setPartialPayments([... temp]);
  };

  const getTotal = () => {
    let amount = selectedItems.reduce((prev, curr) => {
        if (curr.price === "") {
          return prev + 0.0;
        } else {
          return prev + parseFloat(curr.price);
        }
    }, 0)
    return amount;
  }

  const getCreditCardRemainder = () => {
    let amount = getTotal() - otherDetails.tendered
    return (amount <= 0) ? 0 : amount.toFixed(2)
  }

  const renderPaymentsData = (e) => {
    return partialPayments.map((item, i) => {
      let tax = ((parseFloat(item.totalAmount) * 8.375) / 100);
      return  (    
        <tr key={i}>
          <td>
            <Moment format="MM/DD/YY HH:mm" tz="America/Los_Angeles">
              {item.createdTime.toString().length === 10
                ? item.createdTime * 1000
                : item.createdTime}
            </Moment>
          </td>
          <td style={{minWidth: "70px"}}>
            <DropdownList
              id="payment"
              data={paymentOptionsReceipt}
              textField="label"
              valueField="value"
              value={item.paidBy}
              onChange={(e) => {
                if (e.value === "creditcard") {
                  changePaymentItemValues("tendered", 0, i);
                }
                changePaymentItemValues("paidBy", e.value, i);
              }}
            />
          </td>
          <td className="font-weight-600 input-td">
            {(item.paidBy == "cash") && (
              <Input
                type="number"
                step="1"
                placeholder="Cash Tendered ($)"
                autoComplete="off"
                name="tendered"
                value={item.tendered}
                onChange={(e) => {
                  changePaymentItemValues("tendered", e.target.value, i);
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              )}
              {(item.paidBy != "cash") && (
                <div className="font-weight-bold">N/A</div>
              )}
          </td>
          <td className="font-weight-600 input-td">
            {(item.paidBy == "creditcard") && (
              <Input
                id="cardNumber"
                type="number"
                style={{color: "black"}}
                className={classnames({
                  "input-null":
                    item.creditCardNumber === "",
                })}
                step="0.01"
                placeholder="Card Last 4 digits"
                autoComplete="off"
                name="creditCardNumber"
                onInput={(e) => {
                  e.target.value = e.target.value
                    .toString()
                    .slice(0, 4);
                }}
                value={item.creditCardNumber}
                onChange={(e) => {
                  changePaymentItemValues("creditCardNumber", e.target.value, i);
                }}
              />
            )}
            {(item.paidBy != "creditcard") && (
              <div className="font-weight-bold">N/A</div>
            )}
          </td>
          <td className="font-weight-600">
            ${ item.totalAmount.toFixed(2) }
            <Button
              className="mx-2"
              color="warning"
              size="sm"
              type="submit"
              onClick={(e) => {
                setPaymentIndex(i);
                editPaymentToggle();
              }}
              ><i class="fas fa-pencil-alt"></i></Button>
          </td>
          <td className="font-weight-600">
            ${ tax.toFixed(2) }
          </td>
          <td className="font-weight-600">
            ${ (item.totalAmount + tax).toFixed(2) }
          </td>
          <td className="pt-2">
            <Button
              className="my-n2"
              color="danger"
              size="sm"
              type="button"
              onClick={() => {
                removePaymentRowItem(item, i);
              }}>
              <i class="fas fa-trash-alt"></i>
            </Button>
          </td>
        </tr>
      );
    });
  };

  const renderAllItemsHTML = (e) => {
    return selectedItems.map((item, i) => {
      return (
        <tr key={i}>
          <td className="input-td">
            <Input
              type="number"
              value={item.qty}
              className={classnames({ "input-null": item.qty === "" })}
              onChange={(e) => {
                changeItemValues("qty", e.target.value, i);
              }}
            />
          </td>
          <td className="input-td">
            <Input
              type="text"
              value={item.itemDescription}
              className={classnames({
                "input-null": item.itemDescription === "",
              })}
              onChange={(e) => {
                changeItemValues("itemDescription", e.target.value, i);
              }}
            />
          </td>
          <td className="input-td">
            <Input type="number" value={item.unitPrice} disabled />
          </td>
          <td className="input-td">
            <Input
              type="number"
              className={classnames({ "input-null": item.price === "" })}
              value={item.price}
              onChange={(e) => {
                changeItemValues("price", e.target.value, i);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </td>
          <td className="action-col">
            <Button
              className="icon-cross"
              color="primary"
              title="Add more"
              onClick={() => {
                if (item.price !== "" && item.itemDescription !== "") {
                  addRowInItem();
                }
              }}
            >
              <i className="fas fa-check-square"></i>
            </Button>
            <Button
              className="icon-cross"
              color="danger"
              title="remove"
              onClick={() => {
                removeRowInItem(i);
              }}
            >
              <i className="fas fa-times"></i>
            </Button>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader
            className={
              "d-flex align-items-center justify-content-between p-2"
              //   (blocked ? " back-red" : " bg-white ")
            }
          >
            <h3>
              Edit Jewelry Receipt{" "}
              {!otherDetails.oneTimeEdit &&
                props.userType.toLowerCase() !== "admin" &&
                "(Not Editable for now!)"}
              <br />
              <small>
                (Receipt ID: JR{props.match.params.id} - Receipt Time:{" "}
                <Moment format="MMM Do YYYY HH:mm" tz="America/Los_Angeles">
                  {otherDetails.ticketTime &&
                  otherDetails.ticketTime.toString().length === 10
                    ? new Date(otherDetails.ticketTime * 1000)
                    : new Date(otherDetails.ticketTime)}
                </Moment>{" "}
                ){" "}
              </small>
            </h3>
            <Button
              type="button"
              color="primary"
              className="h-fit"
              onClick={() => {
                props.history.goBack();
              }}
            >
              <i className="fas fa-chevron-left"></i> Back
            </Button>
          </CardHeader>
          <CardBody>
            <Table className="add-receipt-table" bordered responsive="sm">
              <tbody>
                <tr className={otherDetails.requireShipmentFreeTax ? "back-red" : ""}>
                  <th>First Name:</th>
                  <td className="input-td">
                    <Input
                      id="firstName"
                      type="text"
                      placeholder="First Name"
                      autoComplete="off"
                      name="firstName"
                      value={otherDetails.firstName}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </td>
                  <th>Last Name:</th>
                  <td className="input-td">
                    <Input
                      id="lastName"
                      type="text"
                      placeholder="Last Name"
                      autoComplete="off"
                      name="lastName"
                      value={otherDetails.lastName}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          lastName: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr className="back-red">
                  {(otherDetails.paymentType === 'full') && (
                    <>
                  <th>Paid By:</th>
                  <td>
                    <DropdownList
                      id="payment"
                      data={jewelryPaymentOptionsReceipt}
                      textField="label"
                      value={otherDetails.paidBy}
                      onChange={(e) => {
                        if (e.value === "creditcard") {
                          setOtherDetails({
                            ...otherDetails,
                            tendered: 0,
                            paidBy: e,
                          });
                        } else {
                          setOtherDetails({ ...otherDetails, paidBy: e });
                        }
                      }}
                    />
                  </td>
                  </>
                  )}
                  {otherDetails.paidBy &&
                  otherDetails.paidBy.value === "creditcard" && (
                    <>
                      <th>Card Last 4 digits:</th>
                      <td className="input-td">
                        <Input
                          id="cardnumber"
                          type="number"
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 4);
                          }}
                          step="0.01"
                          placeholder="Card Last 4 digits"
                          autoComplete="off"
                          name="creditCardNumber"
                          value={otherDetails.creditCardNumber}
                          onChange={(e) =>
                            setOtherDetails({
                              ...otherDetails,
                              creditCardNumber: e.target.value,
                            })
                          }
                        />
                      </td>
                    </>
                  )}
                  {otherDetails.paidBy
                    && paymentCashOptions.includes(otherDetails.paidBy.value)
                    && (
                    <>
                      <th>Cash Tendered ($):</th>
                      <td className="input-td">
                        <div className="d-flex">
                          <Input
                            id="tendered"
                            type="number"
                            className={classnames({
                              "input-null": otherDetails.tendered === "",
                            })}
                            step="0.01"
                            placeholder="Cash Tendered ($)"
                            autoComplete="off"
                            name="tendered"
                            value={otherDetails.tendered}
                            onChange={(e) =>
                              setOtherDetails({
                                ...otherDetails,
                                tendered: e.target.value,
                              })
                            }
                          />
                          {otherDetails.paidBy.value === "cash_creditcard" && (
                            <>
                              <span className="pl-4 pr-1 pt-2 font-weight-bold">Card Last 4 digits:</span>
                              <Input
                                id="cardnumber"
                                type="number"
                                onInput={(e) => {
                                  e.target.value = Math.max(
                                    0,
                                    parseInt(e.target.value)
                                  )
                                    .toString()
                                    .slice(0, 4);
                                }}
                                step="0.01"
                                placeholder="Card Last 4 digits"
                                autoComplete="off"
                                name="creditCardNumber"
                                value={otherDetails.creditCardNumber}
                                onChange={(e) =>
                                  setOtherDetails({
                                    ...otherDetails,
                                    creditCardNumber: e.target.value,
                                  })
                                }
                              />
                            </>
                          )}
                        </div>
                      </td>
                    </>
                  )}
                  {otherDetails.paidBy && otherDetails.paidBy.value === "check" && (
                    <>
                      <th>Check Amount:</th>
                      <td className="input-td">
                        <div className="d-flex">
                          <Input
                            type="number"
                            id="checkAmount"
                            placeholder="Check Amount"
                            autoComplete="off"
                            name="checkAmount"
                            value={otherDetails.checkAmount}
                            onChange={(e) =>
                              setOtherDetails({
                                ...otherDetails,
                                checkAmount: e.target.value,
                              })
                            }
                          />
                            <span className="pl-4 pr-1 pt-2 font-weight-bold">Check #:</span>
                            <InputGroup>
                              {(props.userType === 'admin') && (
                              <InputGroupAddon addonType="prepend">
                                <Button
                                  size="sm"
                                  color="danger"
                                  title="Set Check Number Prefix"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    //
                                    props.history.push("/portal/setting-checks");
                                  }}>
                                  <i class="fas fa-money-check-alt"></i>
                                </Button>
                              </InputGroupAddon>
                              )}
                              <Input
                                id="checkNumber"
                                type="text"
                                placeholder="Check Number"
                                autoComplete="off"
                                name="checkNumber"
                                value={otherDetails.checkNumber}
                                onChange={(e) => {
                                  setOtherDetails({
                                    ...otherDetails,
                                    checkNumber: e.target.value,
                                  });
                                }}
                                />
                          </InputGroup>
                        </div>
                      </td>
                    </>
                  )}
                  {(otherDetails.paymentType === 'partial') && (
                    <>
                      <th>Phone:</th>
                      <td>
                        <PhoneInput
                          country={'us'}
                          value={otherDetails.phoneNumber}
                          onChange={phone => setOtherDetails({ ...otherDetails, phoneNumber: phone })}
                        />
                      </td>
                    </>
                  )}
                </tr>
                {/*
                <tr className="back-red">
                  <th>From:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="fromx"
                        data={fromOptions}
                        containerClassName="from-drop"
                        textField="name"
                        onToggle={getFromOptions}
                        value={otherDetails.fromx}
                        onChange={(e) =>
                          setOtherDetails({ ...otherDetails, fromx: e })
                        }
                      />
                    </div>
                  </td>
                      </tr>*/}
                <tr className="back-red">
                  <th>Booth:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="booth"
                        data={boothOptions}
                        containerClassName="booth-drop"
                        textField="name"
                        value={otherDetails.booth}
                        onChange={(e) =>
                          setOtherDetails({ ...otherDetails, booth: e })
                        }
                        onToggle={fetchAllBooth}
                      />
                    </div>
                  </td>
                </tr>
                {props.userType &&
                  !["employee", "employees", "employe"].includes(
                    props.userType.toLowerCase()
                  ) && (
                    <tr>
                      <th>Station:</th>
                      <td colSpan="3">
                        <div className="d-flex align-items-end">
                          <DropdownList
                            id="station"
                            data={stationOptions}
                            containerClassName="station-drop"
                            textField="label"
                            value={otherDetails.printer}
                            onChange={(e) =>
                              setOtherDetails({ ...otherDetails, printer: e })
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                {/*<tr className="back-grey">
                  <th>Keyword:</th>
                  <td colSpan="3" className="autocomplete input-td">
                    <Input
                      id="keyword"
                      type="text"
                      placeholder="Keyword"
                      name="keyword"
                      value={otherDetails.keyword}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          ["keyword"]: e.target.value,
                        })
                      }
                      autoComplete="off"
                    />
                  </td>
                    </tr>*/}
                <tr className="back-red">
                  <th>Clerk Name:</th>
                  <td colSpan="3">
                    <div className="d-flex align-items-end">
                      <DropdownList
                        id="clerk"
                        data={allClerks}
                        containerClassName="booth-drop"
                        textField="first_name"
                        onToggle={fetchAllClerks}
                        value={otherDetails.clerk}
                        onChange={(e) =>
                          setOtherDetails({ ...otherDetails, clerk: e })
                        }
                      />
                    </div>
                  </td>
                </tr>
                <tr className="back-grey">
                  <th>Note:</th>
                  <td colSpan="3" className="input-td">
                    <Input
                      id="note"
                      className="w-75"
                      type="text"
                      placeholder="Note"
                      autoComplete="off"
                      name="note"
                      value={otherDetails.note}
                      onChange={(e) =>
                        setOtherDetails({
                          ...otherDetails,
                          note: e.target.value,
                        })
                      }
                    />
                  </td>
                </tr>
                <tr className="back-grey">
                  <th></th>
                  <td
                    colSpan="3"
                    className="input-td input-shipping-check">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          style={{height: 'auto', marginTop: '2px'}}
                          checked={otherDetails.requireShipmentFreeTax}
                          onChange={(e) => setOtherDetails({
                            ...otherDetails,
                            requireShipmentFreeTax: e.target.checked,
                          })
                          }/>{' '} Allow Free Sales Tax
                      </Label>
                    </FormGroup>
                  </td>
                </tr>
                {otherDetails.requireShipmentFreeTax && (
                  <tr className="back-grey">
                  <th>Shipping Details</th>
                  <td
                    colSpan="3"
                    className="input-td">
                    <Row>
                    <Col md="3">
                        <Input
                          id="shippingTrackingNumber"
                          type="text"
                          placeholder="Tracking #"
                          autoComplete="off"
                          name="shippingTrackingNumber"
                          value={initialShipment.trackingNumber}
                          onChange={(e) => setInitialShipment({
                            ...initialShipment,
                            shippingTrackingNumber: e.target.value,
                          })
                          }
                        />
                      </Col>
                      <Col md="3">
                        <Input
                          id="shippingAddress"
                          type="text"
                          placeholder="Address"
                          autoComplete="off"
                          name="shippingAddress"
                          className={initialShipment.address.length === 0 ? "input-null" : ""}
                          value={initialShipment.address}
                          onChange={(e) => setInitialShipment({
                            ...initialShipment,
                            address: e.target.value,
                          })
                          }
                        />
                      </Col>
                      <Col md="1">
                        <Input
                            id="shippingState"
                            type="text"
                            placeholder="State"
                            autoComplete="off"
                            name="shippingState"
                            value={initialShipment.state}
                            className={initialShipment.state.length === 0 ? "input-null" : ""}
                            onChange={(e) => setInitialShipment({
                              ...initialShipment,
                              state: e.target.value,
                            })
                            }
                          />
                      </Col>
                      <Col md="2">
                        <Input
                            id="shippingCity"
                            type="text"
                            placeholder="City"
                            autoComplete="off"
                            name="shippingCity"
                            value={initialShipment.city}
                            className={initialShipment.city.length === 0 ? "input-null" : ""}
                            onChange={(e) => setInitialShipment({
                              ...initialShipment,
                              city: e.target.value,
                            })
                            }
                          />
                      </Col>
                      <Col md="1">
                        <Input
                            id="shippingPostalCode"
                            type="text"
                            placeholder="Postal"
                            autoComplete="off"
                            name="shippingPostalCode"
                            value={initialShipment.postalCode}
                            className={initialShipment.postalCode.length === 0 ? "input-null" : ""}
                            onChange={(e) => setInitialShipment({
                              ...initialShipment,
                              postalCode: e.target.value,
                            })
                            }
                          />
                      </Col>
                      <Col md="2">
                        <Input
                            id="shippingPhone"
                            type="text"
                            placeholder="Phone"
                            autoComplete="off"
                            name="shippingPhone"
                            value={initialShipment.phone}
                            className={initialShipment.phone.length === 0 ? "input-null" : ""}
                            onChange={(e) => setInitialShipment({
                                ...initialShipment,
                                phone: e.target.value,
                              })
                            }
                          />
                      </Col>
                    </Row>
                  </td>
                </tr>
                )}
                <tr>
                  <th>Attachments:</th>
                  <td className="width-middle" colSpan="3">
                    <input type="file" name="files[]" id="fileuploader"  data-fileuploader-files={JSON.stringify(attachments)}/>
                  </td>
                </tr>
              </tbody>
            </Table>
            {(otherDetails.paymentType === 'partial') && (
              <>
                <Table className="table-listing receipt-payment-table" bordered striped responsive="sm">
                  <thead>
                    <tr>
                      <th className="qty-col">Date</th>
                      <th className="amount-col">Paid By</th>
                      <th className="unit-col">Cash Tendered($)</th>
                      <th className="unit-col">Card Last 4 digits</th>
                      <th className="desc-col">Amount($)</th>
                      <th className="unit-col">Tax($)</th>
                      <th className="unit-col">Total($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    { renderPaymentsData() }
                  </tbody>
                </Table>
                <Row className="mt-2">
                  <Col md="9">
                    <Button
                      className="mx-2"
                      color="primary"
                      size="sm"
                      type="button"
                      onClick={() => {
                        setPartialPayments([...partialPayments, ... [{
                          id: 0,
                          jewelryReceiptId: props.match.params.id,
                          tendered: 0,
                          creditCardNumber: '',
                          paidBy: 'cash',
                          tax: 0,
                          totalAmount: 0,
                          createdTime: Math.floor(Date.now() / 1000)
                        }]])
                      }}
                      >New Payment</Button>
                  </Col>
                  <Col md="3" className="text-right" style={{fontSize: '16px'}}>
                    <span>
                      Tax:&nbsp;
                      <span className="font-weight-bold mr-2" style={{color: '#f5365c'}}>
                      $
                      {partialPayments
                        .reduce((prev, curr) => {
                          if (curr.tax === "") {
                            return prev + 0.0;
                          } else {
                            return prev + parseFloat(curr.tax);
                          }
                        }, 0)
                        .toFixed(2)}
                      </span>
                      Total:&nbsp;
                      <span className="font-weight-bold" style={{color: '#f5365c'}}>
                      $
                      {partialPayments
                        .reduce((prev, curr) => {
                          if (curr.totalAmount === "") {
                            return prev + 0.0;
                          } else {
                            return prev + parseFloat(curr.totalAmount) + parseFloat(curr.tax);
                          }
                        }, 0)
                        .toFixed(2)}
                      </span>
                    </span>
                  </Col>
                </Row>
              </>
            )}
            {(otherDetails.paymentType === 'full') && (
              <Table className="add-receipt-item-table" bordered striped>
                <thead>
                  <tr>
                    <th className="qty-col">Qty</th>
                    <th className="desc-col">DESC</th>
                    <th className="unit-col">UNIT($)</th>
                    <th className="amount-col">Amount($)</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedItems.length === 0 && (
                    <tr>
                      <td className="py-3 text-center" colSpan="4">
                        <Button
                          className="py-1"
                          color="primary"
                          onClick={addRowInItem}
                        >
                          Add item row+
                        </Button>
                      </td>
                    </tr>
                  )}
                  {renderAllItemsHTML()}
                  {otherDetails.paidBy.value === "cash_creditcard" && (
                  <>
                    <tr style={{color: 'red', fontWeight: 'bold'}}>
                      <td colSpan="3" style={{fontSize: '15px'}}>Credit Card Remainder:</td>
                      <td style={{fontSize: '15px'}}>
                        ${getCreditCardRemainder()}
                      </td>
                    </tr>
                  </>
                )}
                  <tr>
                    <td colSpan="3">Tax:</td>
                    <td>
                      $
                      {otherDetails.requireShipmentFreeTax ? "0.00" : selectedItems
                        .reduce((prev, curr) => {
                          if (curr.tax === "") {
                            return prev + 0.0;
                          } else {
                            return prev + parseFloat(curr.tax);
                          }
                        }, 0)
                        .toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="3">Total:</td>
                    <td>
                      $
                      {selectedItems
                        .reduce((prev, curr) => {
                          if (curr.price === "") {
                            return prev + 0.0;
                          } else {
                            return prev + parseFloat(curr.price);
                          }
                        }, 0)
                        .toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            <div className="text-center my-2">
              {!otherDetails.oneTimeEdit &&
              props.userType &&
              props.userType.toLowerCase() !== "admin" ? (
                <Button type="button" color="primary" disabled>
                  Can't Update
                </Button>
              ) : loading ? (
                <div className="text-center my-2">
                  <Button color="primary" disabled>
                    Saving <i className="fas fa-spinner fa-spin"></i>
                  </Button>
                </div>
              ) : (
                <div className="text-center my-2">
                  <Button
                    id="save-receipt"
                    color="primary"
                    onClick={formSubmitHandler}
                  >
                    Save Receipt
                  </Button>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Container>
      { editPaymentModal && (
        <ViewAndEditReceiptPaymentsModal
          {...props}
          show={editPaymentModal}
          paymentIndex={paymentIndex}
          partialPayments={partialPayments}
          loadNewItems={loadNewItems}
          receiptId={props.match.params.id}
          toggle={editPaymentToggle}
          setPartialPayments={setPartialPayments}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    receiptFormDetails: state.JewelryReceiptReducer.addReceiptFormdetails,
    receiptItemsDetails: state.JewelryReceiptReducer.receiptItemdetails,
    userType: state.loginUserReducer.userType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveReceiptFormDetails: (body) => dispatch(saveReceiptFormDetails(body)),
    setLoaderRequestFalse: () => dispatch(setLoaderRequestFalse()),
    setLoaderRequestTrue: () => dispatch(setLoaderRequestTrue()),
    saveItemsDetails: (body) => dispatch(saveItemsDetails(body)),
    resetAddReceiptForm: () => dispatch(resetAddReceiptForm()),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReceipt);
