import React from "react";
import {
  Modal,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
  Button,
} from "reactstrap";
import {
  GetApiActionWithAuthorization,
  PostApiWithAuthorizationAction,
} from "../../../constantFunctions/apiActions";
import {
  getErrorMessages,
  formValid,
  getallErrors,
} from "../../../constantFunctions/formValidations";
import { toast } from "react-toastify";
import { DropdownList } from "react-widgets";

class CreateNewUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      phone: "",
      formValue: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        group_id: "",
        confirm_password: "",
      },
      formErrors: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        group_id: "",
        confirm_password: "",
      },
      loading: false,
      dropLoading: false,
    };
  }

  getGroups = (e) => {
    if (e) {
      this.setState({ dropLoading: true });
      GetApiActionWithAuthorization("users/all-users-groups")
        .then((res) => {
          this.setState({
            groups: res.data,
            dropLoading: false,
          });
        })
        .catch((err) => {
          if (err.status === 440) {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            this.props.history.push("/auth/login");
          } else {
            toast.error("Something went wrong! /n Please clear and retry", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    }
  };

  //Handle input changes
  handleChange = (e) => {
    const { name, value } = e.target;
    let formValues = this.state.formValue;
    formValues[name] = value;
    this.setState(
      {
        formValue: formValues,
      },
      () => {
        let formErrors = { ...this.state.formErrors };
        formErrors = getErrorMessages(formErrors, name, value);
        this.setState({ formErrors: formErrors });
        if (
          this.state.formValue.confirm_password !==
          this.state.formValue.password
        ) {
          formErrors.confirm_password = "*Passwords didn't match";
          this.setState({ formErrors: formErrors });
        } else {
          formErrors.confirm_password = "";
          this.setState({ formErrors: formErrors });
        }
      }
    );
  };

  //Handle dropdownGroup Change
  handleGroupChange = (e) => {
    let id = e.id;
    let formValues = this.state.formValue;
    formValues["group_id"] = id;
    this.setState(
      {
        formValue: formValues,
      },
      () => {
        let formErrors = { ...this.state.formErrors };
        formErrors = getErrorMessages(formErrors, "group_id", id);
        this.setState({ formErrors: formErrors });
      }
    );
  };

  //Handle Contact Change
  handleContactChange = (e) => {
    this.setState(
      {
        phone: e.toString(),
      },
    );
  };

  //Reset form
  resetForm() {
    this.setState({
      formValue: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        group_id: "",
        phone: "",
        confirm_password: "",
      },
      formErrors: {
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        password: "",
        group_id: "",
        phone: "",
        confirm_password: "",
      },
    });
  }

  //Create function to create a group
  createUserHandler = (e) => {
    e.preventDefault();
    if (formValid(this.state)) {
      let body = {
        first_name: (this.state.formValue.first_name).trim(),
        last_name: (this.state.formValue.last_name).trim(),
        username: (this.state.formValue.username).trim(),
        email: (this.state.formValue.email).trim(),
        password: (this.state.formValue.password).trim(),
        group_id: this.state.formValue.group_id,
        phone: (this.state.phone).trim(),
      };
      this.setState({ loading: true });
      PostApiWithAuthorizationAction("users/create-new-user", body)
        .then((res) => {
          this.setState({ loading: false });
          //toast.success(res.message, { position: toast.POSITION.TOP_CENTER });
          this.resetForm();
          this.props.toggle();
          this.props.fetchUserListing("all");
        })
        .catch((err) => {
          if (err.status === 440) {
            toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
            this.props.history.push("/auth/login");
          } else {
            toast.error("Something went wrong! /n Please clear and retry", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    } else {
      let errors = getallErrors(this.state);
      this.setState({ formErrors: errors });
    }
  };

  render() {
    const { formErrors } = this.state;
    return (
      <Modal isOpen={this.props.show} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Create New User</ModalHeader>
        <ModalBody>
          <Form role="form" onSubmit={this.handleSubmit}>
            <Row>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-control-label" for="first_name">
                    First Name
                  </Label>
                  <Input
                    id="first_name"
                    placeholder="First Name"
                    type="text"
                    name="first_name"
                    value={this.state.formValue.first_name}
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  {formErrors.first_name.length > 0 && (
                    <span className="text-danger">
                      <small>{formErrors.first_name}</small>
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-control-label" for="last_name">
                    Last Name
                  </Label>
                  <Input
                    id="last_name"
                    placeholder="Last Name"
                    type="text"
                    name="last_name"
                    value={this.state.formValue.last_name}
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  {formErrors.last_name.length > 0 && (
                    <span className="text-danger">
                      <small>{formErrors.last_name}</small>
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-control-label" for="username">
                    Username
                  </Label>
                  <Input
                    id="username"
                    placeholder="Username"
                    type="text"
                    name="username"
                    value={this.state.formValue.username}
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  {formErrors.username.length > 0 && (
                    <span className="text-danger">
                      <small>{formErrors.username}</small>
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-control-label" for="email">
                    Email
                  </Label>
                  <Input
                    id="email"
                    placeholder="Email"
                    type="text"
                    name="email"
                    value={this.state.formValue.email}
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  {formErrors.email.length > 0 && (
                    <span className="text-danger">
                      <small>{formErrors.email}</small>
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-control-label" for="password">
                    Password
                  </Label>
                  <Input
                    id="password"
                    placeholder="Password"
                    type="password"
                    name="password"
                    value={this.state.formValue.password}
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  {formErrors.password.length > 0 && (
                    <span className="text-danger">
                      <small>{formErrors.password}</small>
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-control-label" for="confirm_password">
                    Confirm Password
                  </Label>
                  <Input
                    id="confirm_password"
                    placeholder="Password"
                    type="password"
                    name="confirm_password"
                    value={this.state.formValue.confirm_password}
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  {formErrors.confirm_password.length > 0 && (
                    <span className="text-danger">
                      <small>{formErrors.confirm_password}</small>
                    </span>
                  )}
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label className="form-control-label" for="group_id">
                    Group
                  </Label>
                  <DropdownList
                    id="group_id"
                    data={this.state.groups}
                    textField="name"
                    placeholder="Select Group"
                    onChange={this.handleGroupChange}
                    onToggle={this.getGroups}
                    busy={this.state.dropLoading}
                  />
                  {formErrors.group_id.length > 0 && (
                    <span className="text-danger">
                      <small>{formErrors.group_id}</small>
                    </span>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          {this.state.loading ? (
            <Button color="primary" disabled>
              loading <i className ="fas fa-spinner fa-spin"></i>
            </Button>
          ) : (
            <Button color="primary" id="create-user-api" onClick={this.createUserHandler}>
              Create
            </Button>
          )}{" "}
          <Button
            color="secondary"
            onClick={() => {
              this.resetForm();
              this.props.toggle();
            }}
            disabled={this.state.loading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CreateNewUserModal;
