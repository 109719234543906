import React, { useEffect, useState } from "react";
// reactstrap components
import {
  Card,
  Container,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  Button,
  CustomInput,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { connect } from "react-redux";
import Unauthorized from "views/Pages/Unauthorized";
import {
  fetchKeywordsListing,
  fetchKeywordsListingSuccess,
} from "../../redux/LeadsOnline/keywordsAction";
import { toast } from "react-toastify";
import { resetState } from "../../redux/common/commonAction";
import AddNewKeywordModal from "views/Modal/LeadsOnline/AddNewKeywordModal";
import ConfirmationDeleteModal from "views/Modal/ConfirmationDeleteModal";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import EditKeywordModal from "views/Modal/LeadsOnline/EditKeywordModal";

function LeadsOnlineSetting(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [delId, setDelId] = useState("");
  useEffect(() => {
    props.fetchKeywordsListing();
  }, []);

  if (props.keywords.error && !unauthorized) {
    if (props.keywords.error.status === 403) {
      setUnauthorized(true);
    } else if (props.keywords.error.status === 440) {
      props.resetState();
      toast.error(props.keywords.error.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      props.history.push("/auth/login");
    }
  }

  const toggleAdd = (e) => {
    setAddModal(!addModal);
  };

  const toggleEdit = (id = "", name = "") => {
    setEditData({ id, name });
    setEditModal(!editModal);
  };

  const toggleConfirm = (id = "") => {
    setDelId(id);
    setConfirm(!confirm);
  };

  const confirmed = (e) => {
    GetApiActionWithAuthorization("leads-online/delete-keyword/" + delId)
      .then((res) => {
        props.addUpdatedData(res);
        setConfirm(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const changeOrder = (decrease, id, order) => {
    let body = { decrease, id, order, type:"ticket_item_keywords" };
    PostApiWithAuthorizationAction("common/change-order", body)
      .then((res) => {
        props.addUpdatedData(res);
      })
      .catch((err) => {
        if (err.status === 403) {
          setUnauthorized(true);
        } else if (err.status === 440) {
          props.resetState();
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        }
      });
  };

  const renderTicketsTableData = (e) => {
    console.log(props)
    return props.keywords.allKeywords.map((keyword, i) => {
      return (
        <tr key={i}>
          <td>
            [
            <span
            id={"update-keyword"+i}
              className="delete-item"
              onClick={(e) => {
                toggleEdit(keyword.id, keyword.name);
              }}
            >
              Edit
            </span>
            ] [
            <span
            id={"delete-keyword"+i}
            className="delete-item"
              onClick={(e) => toggleConfirm(keyword.id)}
            >
              Delete
            </span>
            ]{" "}
          </td>
          <td>{keyword.name}</td>
          <td className="display-order">
            <Button
            className="px-2 py-2"
              onClick={(e) => {
                if (i !== 0) {
                  changeOrder(true, keyword.id, keyword.displayOrder);
                }
              }}
            >
              <i className="fas fa-arrow-up"></i> Up
            </Button>
            <Button
            className="px-2 py-2"
            onClick={(e) => {
                if (i !== props.keywords.allKeywords.length - 1) {
                  changeOrder(false, keyword.id, keyword.displayOrder);
                }
              }}
            >
              {" "}
              <i className="fas fa-arrow-down"></i> Down
            </Button>
          </td>
        </tr>
      );
    });
  };

  return unauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--9 main-content" fluid>
        <Card className="card-height shadow">
          <CardHeader>
            <div className="text-center">
              <h1>LeadsOnline Rules</h1>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col md="6" className="search-left-btn">
                <Button id="add-new" color="primary" onClick={toggleAdd}>
                  New Keyword
                </Button>
              </Col>
            </Row>
            <Table className="ticket-table table-listing" responsive bordered>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Name</th>
                  <th scope="col">Order</th>
                </tr>
              </thead>
              <tbody>{renderTicketsTableData()}</tbody>
            </Table>
          </CardBody>
        </Card>
      </Container>
      {addModal && (
        <AddNewKeywordModal {...props} show={addModal} toggle={toggleAdd} />
      )}
      {editModal && (
        <EditKeywordModal
          {...props}
          show={editModal}
          editData={editData}
          toggle={toggleEdit}
        />
      )}
      {confirm && (
        <ConfirmationDeleteModal
          show={confirm}
          toggle={toggleConfirm}
          confirmed={confirmed}
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    keywords: state.keywordsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchKeywordsListing: () => dispatch(fetchKeywordsListing()),
    addUpdatedData: (data) => dispatch(fetchKeywordsListingSuccess(data)),
    resetState: () => dispatch(resetState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadsOnlineSetting);
