import React from "react";
import { connect } from "react-redux";

// reactstrap components
import { Container, Row, CardBody, Card, Col, CardTitle} from "reactstrap";

function DashboardHeader(props) {
    return (
      <>
        <div className="header pb-8 pt-57 ">
          <Container fluid></Container>
        </div>
      </>
    );
  }
  const mapStateToProps = (state) => {
    return {
      loginUserDetails: state.loginUserReducer,
    };
  };
export default connect(mapStateToProps, null)(DashboardHeader);
