import React, { useEffect, useState } from "react";
import { GetApiActionWithAuthorization } from "constantFunctions/apiActions";
import { DropdownList } from "react-widgets";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";
import { PostApiWithAuthorizationAction } from "constantFunctions/apiActions";
import { toast } from "react-toastify";
import {
  copyToData,
  printCopyOptions,
  raceOptions,
  sexOptions,
} from "../../../constants/ticketConstants";
import BlockModal from "../BlockModal";
import ViewInvoicePrint from "views/Modal/ViewInvoicePrint";

function ViewAndEditCoinItemsModal(props) {
  const [unauthorized, setUnauthorized] = useState(false);
  const [items, setitems] = useState([]);
  const [newItem, setNewItem] = useState({
    qty: 1,
    name: "",
    unit: "0.00",
    cost: "",
  });
  const [copyTo, setCopyTo] = useState({ value: "ticket", label: "Ticket" });
  const [ethSpotPrice, setETHSpotPrice] = useState(0);
  const [btcSpotPrice, setBTCSpotPrice] = useState(0);
  const [resetTime, setResetTime] = useState(false);
  const [copies, setCopies] = useState("");
  const [blockModal, setBlockModal] = useState(false);
  const [printUrl, setPrintUrl] = useState("");
  const [viewPrintModal, setViewPrintModal] = useState(false);
  const [printButtonUrl, setPrintButtonUrl] = useState("");

  useEffect(() => {
    if (props.isBarter) {
      props.fetchCoinBarterItemsListing(props.id);
    } else {
      props.fetchCoinItemsListing(props.id);
    }
    GetApiActionWithAuthorization("common/get-items-select-options")
      .then((res) => {
        setitems(res.data);
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });

    // ___________Get spot prices for ETH and BTC______________
    GetApiActionWithAuthorization("common/get-all-spot-prices").then((res) => {
      setBTCSpotPrice(res.data.find((s) => s.item === "btc").price);
      setETHSpotPrice(res.data.find((s) => s.item === "eth").price);
    });
  }, []);

  // ___________Get spot prices for ETH and BTC(every hour changes)______________
  useEffect(() => {
    let timer = setTimeout(() => {
      if (new Date().getMinutes() === 2) {
        setResetTime(!resetTime);
        GetApiActionWithAuthorization("common/get-all-spot-prices").then(
          (res) => {
            setBTCSpotPrice(res.data.find((s) => s.item === "btc").price);
            setETHSpotPrice(res.data.find((s) => s.item === "eth").price);
          }
        );
      } else {
        setResetTime(!resetTime);
      }
    }, 30000);
    return () => {
      clearTimeout(timer);
    };
  }, [resetTime]);

  const addNewItemInList = (e) => {
    let body = {
      ticketId: props.id,
      itemId: newItem.name.id,
      cost: newItem.cost,
      unit: newItem.unit,
      qty: ["eth", "btc"].includes(newItem.name.name.toLowerCase())
        ? newItem.qty / 0.00000001
        : newItem.qty,
    };
    let appendQuery = (props.isBarter) ? "barter=true" : "";
    if (appendQuery.length > 0) {
      appendQuery = "?" + appendQuery;
    }
    PostApiWithAuthorizationAction("coin-tickets/add-new-item-in-edit" + appendQuery, body)
      .then((res) => {
        //toast.success(res.message);
        if (props.isBarter) {
          props.fetchCoinBarterItemsListingSuccess({ data: res.data });
        } else {
          props.fetchCoinItemsListingSuccess({ data: res.data });
        }
        setNewItem({ qty: 1, name: "", unit: "0.00", cost: "" });
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  const updateItemInList = ({ id, itemId, dataToSave }) => {
    let body = {
      ticketId: props.id,
      id: id,
      itemId,
      dataToSave,
    };
    let appendQuery = (props.isBarter) ? "barter=true" : "";
    if (appendQuery.length > 0) {
      appendQuery = "?" + appendQuery;
    }
    PostApiWithAuthorizationAction("coin-tickets/edit-item-in-edit" + appendQuery, body)
      .then((res) => {
        if (props.isBarter) {
          props.fetchCoinBarterItemsListingSuccess({ data: res.data });
        } else {
          props.fetchCoinItemsListingSuccess({ data: res.data });
        }
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  const deleteItem = (id) => {
    let appendQuery = (props.isBarter) ? "barter=true" : "";
    if (appendQuery.length > 0) {
      appendQuery = "?" + appendQuery;
    }
    GetApiActionWithAuthorization(
      "coin-tickets/delete-item-from-ticket/" + id + "/" + props.id + appendQuery
    )
      .then((res) => {
        if (props.isBarter) {
          props.fetchCoinBarterItemsListingSuccess({ data: res.data });
        } else {
          props.fetchCoinItemsListingSuccess({ data: res.data });
        }
      })
      .catch((err) => {
        if (err.status === 440) {
          toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
          props.history.push("/auth/login");
        } else {
          toast.error("Something went wrong! /n Please clear and retry", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  };

  const toggleBlock = (e) => {
    setBlockModal(!blockModal);
  };

  const renderItems = () => {
    let _items = (props.isBarter) ? props.barterItems : props.items;
    return _items.items.map((item, i) => {
      return (
        <tr key={i} id={"edit-row" + i}>
          <td>
            <Input
              id="create-qty"
              type="number"
              step="0.01"
              value={
                ["eth", "btc"].includes(item.item.name.toLowerCase())
                  ? (item.qty * 0.00000001).toFixed(8)
                  : item.qty
              }
              disabled
            />
          </td>
          <td style={{ width: "35%" }}>
            <DropdownList
              id="create-item"
              data={items}
              textField="name"
              containerClassName="item-drop"
              value={item.item.name}
              onChange={(e) => {
                let attr = {
                  id: item.id,
                };

                if (e.name.toLowerCase() === "eth") {
                  attr.dataToSave = {
                    itemId: e.id,
                    unit: ethSpotPrice,
                    qty: Math.round(
                      parseFloat(item.cost) /
                        parseFloat(ethSpotPrice * 0.00000001)
                    ),
                  };
                } else if (e.name.toLowerCase() === "btc") {
                  attr.dataToSave = {
                    itemId: e.id,
                    unit: btcSpotPrice,
                    qty: Math.round(
                      parseFloat(item.cost) /
                        parseFloat(btcSpotPrice * 0.00000001)
                    ),
                  };
                } else {
                  attr.dataToSave = {
                    itemId: e.id,
                    unit: item.cost,
                    qty: 1,
                  };
                }
                updateItemInList(attr);
              }}
            />
          </td>
          <td>${item.unit && parseFloat(item.unit).toFixed(2)}</td>
          <td>
            <Input
              id="create-cost"
              type="number"
              step="1"
              defaultValue={item.cost}
              onBlur={(e) => {
                let attr = {
                  id: item.id,
                  itemId: item.item.id,
                };
                if (item.item.name.toLowerCase() === "btc") {
                  attr.dataToSave = {
                    cost: e.target.value,
                    unit: btcSpotPrice,
                    qty: Math.round(
                      parseFloat(e.target.value) /
                        parseFloat(btcSpotPrice * 0.00000001)
                    ),
                  };
                } else if (item.item.name.toLowerCase() === "eth") {
                  attr.dataToSave = {
                    cost: e.target.value,
                    unit: ethSpotPrice,
                    qty: Math.round(
                      parseFloat(e.target.value) /
                        parseFloat(ethSpotPrice * 0.00000001)
                    ),
                  };
                } else {
                  attr.dataToSave = {
                    cost: e.target.value,
                    unit: e.target.value / item.qty,
                  };
                }
                updateItemInList(attr);
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </td>
          <td>
            <span
              className="delete-item"
              onClick={(e) => {
                deleteItem(item.id);
              }}
            >
              Delete
            </span>
          </td>
        </tr>
      );
    });
  };

  const printLabels = (e) => {
    let station = (localStorage.getItem("lastUsedPrinter")) ? localStorage.getItem("lastUsedPrinter") : props.items.ticketDetails.station;
    PostApiWithAuthorizationAction("common/print-labels-manually", {
      copies,
      station,
      ticketType: "newCoin",
      id: props.id,
    }).then((res) => {
        setCopies("");
    })
    .catch((err) => {
      console.log(err);
      if (err.status === 403) {
        setUnauthorized(true);
      } else if (err.status === 440) {
        props.resetState();
        toast.error(err.message, { position: toast.POSITION.TOP_CENTER });
        props.history.push("/auth/login");
      }
    });
  };

  const copyToNew = (e) => {
    const {
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
      dOB,
      height,
      weight,
      dLState,
      dLNo,
      otherIDType,
      otherIDNo,
      otherNo,
      race,
      sex,
      fromx,
    } = props.items.ticketDetails;
    let other = {
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
      dOB,
      height,
      weight,
      race: raceOptions.find((fr) => fr.value === race),
      sex: sexOptions.find((fr) => fr.value === sex),
      dLState,
      dLNo,
      otherIDType,
      otherIDNo,
      otherNo,
      fromx: { name: "Repeat" },
    };
    if (copyTo.value === "ticket") {
      props.copyTicketDetailsToMetro(other);
      props.history.push("/portal/metro-tickets/add-new");
    } else {
      props.fetchCoinItemsListingSuccess({ data: [] });
      props.copyTicketDetailsToCoin(other);
      props.history.push("/portal/coin-tickets/add-new");
    }
  };

  const togglePrintView = () => {
    setViewPrintModal(!viewPrintModal);
  };

  return (
    <>
      <Modal size="lg" isOpen={props.show} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>
          View And Edit Coin Items
        </ModalHeader>
        <ModalBody className="p-2">
          <div className="d-flex copy-drop justify-content-end align-items-center">
            <DropdownList
                data={printCopyOptions}
                containerClassName="race-drop"
                placeholder="Copies"
                value={copies}
                onChange={(e) => setCopies(e)}
              />
            <Button
                className="print-go-back mx-1"
                color="primary"
                disabled={copies.length === 0}
                onClick={printLabels}
              >
                Print Labels
              </Button>
            <Label className="m-0">Copy to:</Label>
            <DropdownList
              data={copyToData}
              containerClassName="race-drop"
              textField="label"
              value={copyTo}
              onChange={(e) => setCopyTo(e)}
            />
            <Button
              className="mx-1"
              color="info"
              onClick={copyToNew}
              // disabled={blocked}
            >
              Copy
            </Button>
            <Button
              color="warning"
              className="m-0"
              onClick={(e) => {
                toggleBlock();
              }}
            >
              Block
            </Button>
          </div>

          <h5 className="mb-2">Add New Item</h5>

          <Table className="desc-table" borderless>
            <tbody>
              <tr>
                <td style={{ width: "90px" }}>QTY</td>
                <td style={{ width: "35%" }}>Desc</td>
                <td>Unit</td>
                <td>Total</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Input
                    id="create-qty"
                    type="number"
                    value={newItem.qty}
                    disabled
                  />
                </td>
                <td style={{ width: "35%" }}>
                  <DropdownList
                    id="create-item"
                    data={items}
                    textField="name"
                    containerClassName="item-drop"
                    value={newItem.name}
                    onChange={(e) => {
                      if (e.name.toLowerCase() === "eth") {
                        setNewItem({
                          ...newItem,
                          name: e,
                          unit: ethSpotPrice,
                          qty: newItem.cost
                            ? (
                                parseFloat(newItem.cost) /
                                parseFloat(ethSpotPrice)
                              ).toFixed(8)
                            : "",
                        });
                      } else if (e.name.toLowerCase() === "btc") {
                        setNewItem({
                          ...newItem,
                          name: e,
                          unit: btcSpotPrice,
                          qty: newItem.cost
                            ? (
                                parseFloat(newItem.cost) /
                                parseFloat(btcSpotPrice)
                              ).toFixed(8)
                            : "",
                        });
                      } else {
                        setNewItem({
                          ...newItem,
                          qty:
                            newItem.name &&
                            newItem.name.name.toLowerCase() !== "eth" &&
                            newItem.name.name.toLowerCase() !== "btc"
                              ? newItem.qty
                              : 1,
                          name: e,
                          unit: newItem.cost
                            ? newItem.cost / newItem.qty
                            : "0.00",
                        });
                      }
                    }}
                  />
                </td>
                <td>${newItem.unit && parseFloat(newItem.unit).toFixed(2)}</td>
                <td>
                  <Input
                    id="create-cost"
                    type="number"
                    value={newItem.cost}
                    onChange={(e) => {
                      setNewItem({
                        ...newItem,
                        cost: e.target.value,
                      });
                    }}
                    onBlur={(e) => {
                      if (
                        newItem.name &&
                        newItem.name.name.toLowerCase() === "btc"
                      ) {
                        setNewItem({
                          ...newItem,
                          unit: btcSpotPrice,
                          qty: (
                            parseFloat(e.target.value) /
                            parseFloat(btcSpotPrice)
                          ).toFixed(8),
                          cost: e.target.value,
                        });
                      } else if (
                        newItem.name &&
                        newItem.name.name.toLowerCase() === "eth"
                      ) {
                        setNewItem({
                          ...newItem,
                          unit: ethSpotPrice,
                          qty: (
                            parseFloat(e.target.value) /
                            parseFloat(ethSpotPrice)
                          ).toFixed(8),
                          cost: e.target.value,
                        });
                      } else {
                        setNewItem({
                          ...newItem,
                          unit: e.target.value / newItem.qty,
                          cost: e.target.value,
                        });
                      }
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </td>
                <td>
                  {" "}
                  <Button
                    id="add-item"
                    className="add-item-btn"
                    color="primary"
                    onClick={(e) => {
                      addNewItemInList();
                    }}
                    disabled={newItem.name === "" || !newItem.cost}
                  >
                    Add +
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <hr className="my-2" />
          <h5 className="mb-2 mt-2">All Items</h5>
          <div className="d-flex copy-drop justify-content-end align-items-center">
            <Button
              className="print-go-back mx-1"
              style={{float: 'right', marginTop: '-20px'}}
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setPrintUrl(process.env.REACT_APP_apiUrl + "coin-tickets/get-labels/" + props.id);
                setPrintButtonUrl("coin-tickets/print-labels/" + props.id)
                setViewPrintModal(true);
              }}
            ><i class="fas fa-print"></i> Print Labels</Button>
          </div>
          <Table className="desc-table" borderless>
            <tbody>
              <tr>
                <td style={{ width: "90px" }}>QTY</td>
                <td style={{ width: "35%" }}>Desc</td>
                <td>Unit</td>
                <td>Total</td>
                <td>Delete</td>
              </tr>
              { renderItems() }
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button id="close" color="secondary" onClick={(e) => props.toggle()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {blockModal && (
        <BlockModal
          show={blockModal}
          toggle={toggleBlock}
          setBlock={(e) => {}}
          data={{
            firstName: props.items.ticketDetails.firstName,
            lastName: props.items.ticketDetails.lastName,
            ticketId: props.id,
          }}
        />
      )}
      {viewPrintModal && (
        <ViewInvoicePrint
          show={viewPrintModal}
          toggle={togglePrintView}
          invoiceUrl={printUrl}
          printButtonUrl={printButtonUrl}
        />
      )}
    </>
  );
}

export default ViewAndEditCoinItemsModal;
