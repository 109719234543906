import { boothOptions } from "constants/ticketConstants";
import { stationOptions } from "constants/ticketConstants";

const getStationValue =( )=>{
  if(localStorage.getItem("lastUsedPrinter")){
    return stationOptions.find(s => s.value === localStorage.getItem("lastUsedPrinter"));
  }
  else{
    return { value: "", label: "None" };
  }
}

export const initialState = {
  stores: {
    allStores: [],
    error: null,
  },
  clerks: {
    allClerks: [],
    error: null,
  },
  coin_tickets: {
    allTickets: [],
    page: 0,
    count: 0,
    error: null,
  },
  addCoinFormdetails: {
    payment: { value: "cash", label: "Cash" },
    sex: { value: "M", label: "Male" },
    eyes: { value: "Bro", label: "Bro" },
    hair: { value: "Bro", label: "Bro" },
    booth: { name: ""},
    station: getStationValue(),
    copyTo: { value: "ticket", label: "Ticket" },
    race: { value: "", label: "None" },
    storeId: {},
    clerk: { id: "", first_name: "" },
    fromx: { name: "" },
    coupon: "",
    keyword: "",
    otherNo: "",
    otherIDNo: "",
    otherIDType: "",
    dLNo: "",
    dLState: "",
    weight: "",
    height: "",
    dOB: "",
    zip: "",
    state: "",
    city: "",
    address: "",
    lastName: "",
    middleName: "",
    firstName: "",
    checkNumber: "5",
    copies: "1",
    paymentCheckAmount: "",
    ticketAmount: "",
  },
  coinItemdetails: {
    items: [],
    ticketDetails:{},
    count: 0,
  },
  coinBarterItemdetails: {
    items: [],
    ticketDetails:{},
    count: 0,
  },
};
